import { initialCandidateFilterOptionsValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetCandidateFilterOptionsReducer(state = initialCandidateFilterOptionsValues,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_CANDIDATE_FILTER_OPTIONS_LOADING:
            return {...state,loading:true,error:false};  

        case types.GET_CANDIDATE_FILTER_OPTIONS_SUCCESS:
            const { Classifications=[],OrganizationType=[],Season=[],Chapters=[],CandidateStatus = [],Regions=[],Schools=[], Sogroup = [], Soschool = []} = payload;
            // console.log(payload,"payload")
            return {
                loading:false,CandidateStatus,OrganizationType,Classifications,
                Chapters,Season,
                Sogroup,Soschool,
                error:false
            }; 
            
        case types.GET_CANDIDATE_FILTER_OPTIONS_ERROR:
            return {...state,loading:false,error:true};      
            
        default:
            return state
    }
}

