import { initialNationalIntakeseasonData } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetNationalIntakeSeasonStepsReducer(state = initialNationalIntakeseasonData, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_INTAKE_SEASON_STEPS_LOADING:
            return { ...state, loading: true, error: false };

        case types.GET_INTAKE_SEASON_STEPS_SUCCESS:
            return { ...state, ...payload, loading: false, error: false };

        case types.GET_INTAKE_SEASON_STEPS_ERROR:
            return { ...state, loading: false, error: true };
        case types.SET_INTAKE_SEASON_STEP:
            let { componentName, datax, intakeseasontype } = payload;
            if (intakeseasontype == 'National') {

                let { nationalintakeseasondata } = state;
                let { Steps } = nationalintakeseasondata;
                let index = Steps.findIndex(x => x.componentName === componentName);
                Steps[index] = datax;
                return {
                    ...state,

                    nationalintakeseasondata: {
                        ...nationalintakeseasondata,
                        Steps
                    }

                }
            }

            if (intakeseasontype == 'Regional') {
                let { regionalintakeseasondata } = state;
                let { steps } = regionalintakeseasondata;
                let index = steps.findIndex(x => x.componentName === componentName);
                steps[index] = datax;
                return {
                    ...state,

                    regionalintakeseasondata: {
                        ...regionalintakeseasondata,
                        steps
                    }

                }
            }

            if (intakeseasontype == 'Local') {
                let { localintakeseasondata } = state;
                let { Steps } = localintakeseasondata;
                let index = Steps.findIndex(x => x.componentName === componentName);
                Steps[index] = datax;
                return {
                    ...state,

                    localintakeseasondata: {
                        ...localintakeseasondata,
                        Steps
                    }

                }
            }

        default:
            return state
    }
}



