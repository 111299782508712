import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import { Button, Card, CardContent, CardHeader, Chip, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, RadioGroup, Tooltip } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { GetCollegeOfficialDetails, getCollegeOfficialSchoolHistory, GetIntakeSeasonSteps, GetSchoolProfileDetails, getSchoolsListByOrganizationId } from "../../redux/actions";
import { CustomAlertMessage, CustomPageLoading } from "../../functions/CustomFunctions";
import API from "../../redux/actions/API";
import { authHeader } from "../../functions/CoreFunctions";
import { ADDENTITY_COLLEGE_OFFICIAL_API, UPSERT_COLLEGE_OFFICIAL_VERIFICATION } from "../../constants/apiPaths";
import { useSnackbar } from "notistack";
import AlarmIcon from '@material-ui/icons/Alarm';
import CheckIcon from '@material-ui/icons/Check';
import ModalDialog from '../common/modaldialog'
import AddEditCollegeOfficial from "./addeditcollegeofficials";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { deletecollegeofficialrecord } from '../../redux/actions'
import StyledRadioButton from "../common/styledRadioButton";
import { isMobileScreen, isTabScreen } from "../../functions/commonFunctions";
import { GET_COLLEGE_OFFICIAL_DETAILS_SUCCESS } from "../../redux/actions/ActionTypes";
import CollegeOfficialRelationPopup from './CollegeOfficialRelationPopup';
import InfoIcon from '@material-ui/icons/Info';
import ConfirmationDialog from "../common/ConfirmationDialog";

const useStyles = makeStyles(theme => ({
    checkstyle: {
        // float: 'left',
        borderRadius: '50%',
        backgroundColor: 'green',
        color: '#fff',
        padding: '2px',
        marginRight: '1rem'
    },

    completeStep: {
        float: 'left',
        borderRadius: '50%',
        padding: '2px',
        marginRight: '5px'
    },
    formHeader: {
        textAlign: 'center',
        marginBottom: '5px'
    },
    requestbtn: {
        marginBottom: '5px',
        float: 'right',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        }
    },
    addcollegebtn: {
        marginBottom: '5px',
        float: 'left',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        },
        marginTop: '1rem'
    },
    cardRoot: {
        width: '100%',
        marginTop: '10px',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #c7c2c2',
        borderRadius: '12px 12px 12px 12px',
    },
    cardHeader: {
        paddingBottom: '0px',
        fontSize: '1rem'
    },
    textStyle: {
        fontWeight: 200,
        fontSize: 14
    },
    textStyle2: {
        color: 'red',
        fontWeight: 700
    },
    divstyle: {
        display: 'flex',
        flexDirection: 'column'
    },
    alertStyle: {
        width: '100%',
        marginTop: '10px'
    },
    statusId1: {
        fontWeight: '700', color: "green"
    },
    statusId2: {
        fontWeight: '700'
    },
    statusId4: {
        fontWeight: '700', color: 'red'
    },
    requestsentdivstyle: {
        display: 'flex', marginBottom: '5px'
    },
    modalTitle: {
        fontSize: '1rem',
        margin: 0
    },
    editbtnstyle: {
        marginLeft: '5px'
    },
    deleteBtn: {
        padding: '6px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        fontSize: 5,
        marginLeft: '5px'
    },
    checkstyle2: {
        // float: 'left',
        borderRadius: '50%',
        backgroundColor: '#7dc1f6',
        color: '#fff',
        padding: '2px',
        marginRight: '1rem'
    }
}));

const CollegeOfficialVerification = (props) => {
    const classes = useStyles();
    const { title = '', OrganizationID, schools, AccessLevel, IntakeSeasonID, Verifications, handleCallback, seasonType = '', chapterCertificationSeasonID = 0 } = props;
    console.log(chapterCertificationSeasonID, 'chapterCertificationSeasonID')
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { loading = false } = useSelector(state => state.GetSchoolsListByOrgidReducer);
    const { loading: collegeofficialLoading = false, data: relatedCollegeOfficials = [] } = useSelector(state => state.GetCollegeOfficialSchoolHistoryReducer);
    // let collegeOfficialList = [...relatedCollegeOfficials, { "personId": 0, firstName: '', lastName: '', title: "Add CollegeOfficial" }]
    const [schoolRec, setSchoolRec] = useState(schools);
    const [formLoading, setFormLoading] = useState(false);
    const [selectCollegeOficial, setSelectCollegeOficial] = useState(null);
    const [selectSchoolId, setSelectSchoolId] = useState(0);
    const [showCollegeOffiCialForm, setShowCollegeOffiCialForm] = useState(false);
    const [selectpersonId, setSelectpersonId] = useState(0);

    useEffect(() => {
        if (schoolRec.length == 0) {

            dispatch(getSchoolsListByOrganizationId(OrganizationID, setSchoolRec));

        }


    }, []);

    //handle request verification
    const hanleRequestVerification = async (item, VerificationStatusID, collegeOfficialVerificationId) => {
        // console.log(item,'item')
        let data = {
            collegeOfficialVerificationId: parseInt(collegeOfficialVerificationId) || 0,
            schoolId: parseInt(item.schoolID),
            personId: item.collegeOfficial !== null && parseInt(item.collegeOfficial.sourceOwnerId) > 0 ? parseInt(item.collegeOfficial.sourceOwnerId) : 0,
            intakeSeasonId: parseInt(IntakeSeasonID),
            statusId: parseInt(VerificationStatusID),
            organizationId: parseInt(OrganizationID),
            chapterCertificationSeasonId: parseInt(chapterCertificationSeasonID),
            memberId: 0,
            documentId: 0
        }


        try {
            setFormLoading(true)
            const options = {
                method: 'POST',
                url: UPSERT_COLLEGE_OFFICIAL_VERIFICATION,
                data: data,
                headers: authHeader()
            }

            const { data: apiResponse } = await API(options);
            const { status, message = '' } = apiResponse;
            if (parseInt(status) === 1) {
                // CustomAlertMessage('Saved successfully!', 'success', enqueueSnackbar)
                // if (AccessLevel === 'Local') {

                // }
                let data = { 'OrganizationID': parseInt(OrganizationID), 'DivisionID': 3 }
                if (seasonType == "chapterCertification") {
                    handleCallback()
                } else {
                    dispatch(GetIntakeSeasonSteps(data));
                }
            }
            else {
                CustomAlertMessage(message, 'error', enqueueSnackbar);
            }
        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                CustomAlertMessage(error.response.statusText, 'error', enqueueSnackbar);
            }
        } finally {
            setFormLoading(false)
        }


    }



    const [isDialogOpen, setDialogOpen] = useState(false)
    const [conDigFlag, setConDigFlag] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const dialogOpen = (item) => {
        setDialogOpen(true)
        if (item.collegeOfficial !== null && item.collegeOfficial.sourceOwnerId > 0) {
            dispatch(GetCollegeOfficialDetails(item.collegeOfficial.sourceOwnerId))
            setSelectpersonId(item.collegeOfficial.sourceOwnerId)
        } else {

            dispatch({
                type: GET_COLLEGE_OFFICIAL_DETAILS_SUCCESS,
                payload: { CollegeOfficialDetailRec: {} }
            });
        }
        dispatch(GetSchoolProfileDetails(item.schoolID))


    }
    const dialogClose = () => {
        setDialogOpen(false)

        setSelectCollegeOficial(null);
        setShowCollegeOffiCialForm(false);
    }


    const handleIntakeSeasonStatus = () => {
        let data = { 'OrganizationID': parseInt(OrganizationID), 'DivisionID': 3 }
        if (seasonType == "chapterCertification") {
            handleCallback()
        }
        dispatch(GetIntakeSeasonSteps(data));

    }

    //Remove college official
    const handleDeleteCollegeOfficial = (row) => {
        // console.log(row, 'row')        
        setConDigFlag(!conDigFlag);
        setRecordToDelete(row);
    }

    const deleteConfirmationToggle = () => {
        setConDigFlag(!conDigFlag);
    }

    const handleConfirmation = async () => {
        dispatch(deletecollegeofficialrecord(recordToDelete.collegeOfficial !== undefined ? recordToDelete.collegeOfficial.sourceOwnerId : 0, recordToDelete.schoolID, 7, handleIntakeSeasonStatus))
        if (seasonType == "chapterCertification") {
            handleCallback()
        }

    }

    const dialogProps = {
        isCollegeofficialVerification: true,
        showCollegeOffiCialForm,
        isDialogOpen,
        dialogClose,
        onSuccessCallBack: handleIntakeSeasonStatus,
        OrganizationID,
        selectCollegeOficial, setSelectCollegeOficial, setShowCollegeOffiCialForm, selectSchoolId, selectpersonId, isIniialSchool: true
    }


    const confirmDialogProps = {
        conDigFlag,
        conDigTitle: "Remove College Official",
        conDigContent: recordToDelete ? `Are you sure you want to remove the College Official ?` : '',
        deleteConfirmationToggle,
        handleConfirmation,
        buttonLoading
    };

    return (
        <>
            {formLoading && <CustomPageLoading flag={formLoading} />}
            {loading && <CustomPageLoading flag={loading} />}
            {collegeofficialLoading && <CustomPageLoading flag={collegeofficialLoading} />}
            {schools.length > 0 ?
                <Card className={classes.cardRoot}>
                    <CardHeader
                        className={classes.cardHeader}

                        title={
                            <Typography variant="h6" component="h2" style={{ fontWeight: 400 }}>

                                {title}
                            </Typography>
                        } />


                    <CardContent>
                        <Typography variant="h6" component="h6" style={{ fontSize: 14 }}>

                            <span className={classes.textStyle2}> Before Moving forward the College Official(s) are required to login and verify the following :</span>
                        </Typography>
                        <Typography variant="h6" component="h6" className={classes.textStyle}>

                            *Good Academic status to function as an on campus organization.
                        </Typography>
                        <Typography variant="h6" component="h6" className={classes.textStyle}>

                            *Good status fulfilling financial obligation with all required fees and dues as an organization.
                        </Typography>
                        <Typography variant="h6" component="h6" className={classes.textStyle}>

                            *Good behavioral status with no pending disciplinary actions.
                        </Typography>
                        {schools.map((row, index) => {
                            let Verification = Verifications.find((v) => v.schoolId == row.schoolID)
                            let isParticipateBtnVisible = (schools.length >= 2 && (Verification == undefined || Verification.statusId == 1)) ? true : false
                            // console.log(Verification, 'Verification', Verifications, row.collegeOfficial)
                            return (
                                < Alert icon={(Verification !== undefined && (Verification.statusId == 2 || Verification.statusId == 3 || Verification.statusId == 5)) ? <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon> : ''} severity={(Verification !== undefined && (Verification.statusId == 2 || Verification.statusId == 3 || Verification.statusId == 5)) ? "info" : "warning"} className={classes.alertStyle}
                                    action={!(isMobileScreen() || isTabScreen()) &&
                                        <>
                                            {row.collegeOfficial !== null ?
                                                <div className={classes.divstyle}>
                                                    {Verification == undefined ? (
                                                        <Typography style={{ alignSelf: 'center', fontWeight: 700, color: 'red' }}>

                                                            Not Verified
                                                        </Typography>
                                                    ) : null
                                                    }
                                                    {(Verification !== undefined && Verification.statusId == 1) ?
                                                        <div className={classes.requestsentdivstyle}>
                                                            <AlarmIcon className={classes.completeStep} />
                                                            <Typography className={classes.statusId1}>Request Sent</Typography>
                                                        </div> : null
                                                    }
                                                    {(Verification !== undefined && Verification.statusId == 2) ?
                                                        <Typography className={classes.statusId2} >School will not Participate in intake Season</Typography> : null
                                                    }
                                                    {(Verification !== undefined && Verification.statusId == 3) ?
                                                        <div style={{ display: 'flex' }}>
                                                            <CheckIcon className={classes.checkstyle} />

                                                            <Typography className={classes.statusId1} >College Official Verified Successfully</Typography>
                                                        </div> : null
                                                    }
                                                    {(Verification !== undefined && Verification.statusId == 4) ?
                                                        <Typography className={classes.statusId4} >Support Denied</Typography> : null
                                                    }
                                                    {(Verification !== undefined && Verification.statusId == 5) ?
                                                        <Typography className={classes.statusId4} >Override Successful</Typography> : null
                                                    }
                                                    {((Verification !== undefined && (Verification.statusId == 1 || Verification.statusId == 2)) || (Verification == undefined)) &&
                                                        <Button style={{ marginTop: (Verification !== undefined && Verification.statusId == 1) ? '10px' : 'auto' }} variant="contained" color="secondary" className={classes.requestbtn} onClick={() => hanleRequestVerification(row, 1, (Verification !== undefined && Verification.collegeOfficialVerificationId > 0) ? Verification.collegeOfficialVerificationId : 0)} >
                                                            {(Verification !== undefined && Verification.statusId == 1) ? "Resend Verification" : "Request Verification"}
                                                        </Button>
                                                    }
                                                    {/* {(AccessLevel === 'National' && Verification !== undefined && (Verification.statusId != 2 || Verification.statusId != 3)) &&
                                                <Button variant="outlined" color="secondary" style={{ marginBottom: '5px' }}>
                                                    Override
                            </Button>
                                            } */}
                                                    {(AccessLevel === 'National' && Verification === undefined) &&
                                                        <Button variant="outlined" color="secondary" style={{ marginBottom: '5px' }} onClick={() => hanleRequestVerification(row, 5)}>
                                                            Override
                                                        </Button>
                                                    }
                                                </div>
                                                :
                                                <>
                                                    <div className={classes.divstyle}>
                                                        {(Verification !== undefined && Verification.statusId == 2) ?
                                                            <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                                                                <InfoIcon className={classes.checkstyle2} />
                                                                <Typography className={classes.statusId2} > School will not Participate in intake Season</Typography>
                                                            </div> : null
                                                        }

                                                        <Button variant="contained" color="secondary" className={classes.requestbtn}
                                                            onClick={() => {
                                                                dispatch(getCollegeOfficialSchoolHistory(row.schoolID)); setSelectSchoolId(row.schoolID); dialogOpen(row)
                                                            }}
                                                        >
                                                            Add college Official
                                                        </Button>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }>
                                    <AlertTitle>{row.schoolName || ''}</AlertTitle>

                                    {row.collegeOfficial !== null &&
                                        (
                                            <>
                                                <Typography variant="caption" display="block" gutterBottom style={{ fontSize: 14 }}>

                                                    <Chip
                                                        label={row.collegeOfficial !== null ? row.collegeOfficial.entityName : ''}
                                                        onDelete={(AccessLevel === 'National' || AccessLevel === 'Regional') ? () => {
                                                            dialogOpen(row)
                                                        } : null}
                                                        deleteIcon={(AccessLevel === 'National' || AccessLevel === 'Regional') ? <EditIcon /> : null}
                                                        color="secondary"
                                                    />
                                                    {(AccessLevel === 'National' || AccessLevel === 'Regional') &&
                                                        <Tooltip title='Delete'>
                                                            <IconButton
                                                                aria-label='delete'
                                                                onClick={() => handleDeleteCollegeOfficial(row)}
                                                                color='primary'

                                                                className={classes.deleteBtn}
                                                            >
                                                                <DeleteIcon style={{ fontSize: 16 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }

                                                </Typography>
                                                <Typography>
                                                    {row.collegeOfficial !== null ? row.collegeOfficial.entityData : ''}
                                                </Typography>
                                            </>

                                        )
                                    }
                                    {/* // <Tooltip title="Edit">
//     <IconButton size="small" className={classes.editbtnstyle}>
//         <EditIcon
//             onClick={() => dialogOpen(row)}
//         />
//     </IconButton>
// </Tooltip>
*/}
                                    {row.collegeOfficial === null && (<span style={{ color: 'red' }}>No college official has been assigned to this school</span>)}
                                    <div>

                                        {isParticipateBtnVisible &&
                                            <Button variant="outlined" color="secondary" style={{ marginBottom: '5px' }} onClick={() => hanleRequestVerification(row, 2, (Verification !== undefined && Verification.collegeOfficialVerificationId > 0) ? Verification.collegeOfficialVerificationId : 0)}>
                                                school will not participate in intake season
                                            </Button>
                                        }
                                    </div>
                                    {(isMobileScreen() || isTabScreen()) &&
                                        < div style={{ marginTop: '10px' }}>
                                            {row.collegeOfficial !== null ?
                                                <div className={classes.divstyle}>
                                                    {Verification == undefined ? (
                                                        <Typography style={{ alignSelf: 'center', fontWeight: 700, color: 'red' }}>

                                                            Not Verified
                                                        </Typography>
                                                    ) : null
                                                    }
                                                    {(Verification !== undefined && Verification.statusId == 1) ?
                                                        <div className={classes.requestsentdivstyle}>
                                                            <AlarmIcon className={classes.completeStep} />
                                                            <Typography className={classes.statusId1}>Request Sent</Typography>
                                                        </div> : null
                                                    }
                                                    {(Verification !== undefined && Verification.statusId == 2) ?
                                                        <Typography className={classes.statusId2} >School will not Participate in intake Season</Typography> : null
                                                    }
                                                    {(Verification !== undefined && Verification.statusId == 3) ?
                                                        <div style={{ display: 'flex' }}>
                                                            <CheckIcon className={classes.checkstyle} />

                                                            <Typography className={classes.statusId1} >College Official Verified Successfully</Typography>
                                                        </div> : null
                                                    }
                                                    {(Verification !== undefined && Verification.statusId == 4) ?
                                                        <Typography className={classes.statusId4} >Support Denied</Typography> : null
                                                    }
                                                    {(Verification !== undefined && Verification.statusId == 5) ?
                                                        <Typography className={classes.statusId4} >Override Successful</Typography> : null
                                                    }
                                                    {((Verification !== undefined && (Verification.statusId == 1 || Verification.statusId == 2)) || (Verification == undefined)) &&
                                                        <Button style={{ marginTop: (Verification !== undefined && Verification.statusId == 1) ? '10px' : 'auto' }} variant="contained" color="secondary" className={classes.requestbtn} onClick={() => hanleRequestVerification(row, 1, (Verification !== undefined && Verification.collegeOfficialVerificationId > 0) ? Verification.collegeOfficialVerificationId : 0)} >
                                                            {(Verification !== undefined && Verification.statusId == 1) ? "Resend Verification" : "Request Verification"}
                                                        </Button>
                                                    }
                                                    {/* {(AccessLevel === 'National' && Verification !== undefined && (Verification.statusId != 2 || Verification.statusId != 3)) &&
                                                <Button variant="outlined" color="secondary" style={{ marginBottom: '5px' }}>
                                                    Override
                            </Button>
                                            } */}
                                                    {(AccessLevel === 'National' && Verification === undefined) &&
                                                        <Button variant="outlined" color="secondary" style={{ marginBottom: '5px' }} onClick={() => hanleRequestVerification(row, 5)}>
                                                            Override
                                                        </Button>
                                                    }
                                                </div>
                                                :
                                                <Button variant="contained" color="secondary" className={classes.requestbtn}
                                                    onClick={() => {
                                                        dispatch(getCollegeOfficialSchoolHistory(row.schoolID)); setSelectSchoolId(row.schoolID); dialogOpen(row)
                                                    }}
                                                >
                                                    Add college Official
                                                </Button>
                                            }
                                        </div>
                                    }
                                </Alert >

                            )
                        })}


                    </CardContent>
                </Card>
                : ''
            }

            {isDialogOpen && !collegeofficialLoading &&
                <CollegeOfficialRelationPopup {...dialogProps} />
            }
            {conDigFlag && <ConfirmationDialog {...confirmDialogProps} />}

        </>

    );
}

export default CollegeOfficialVerification