import { initialForgotPasswordValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function ForgotPasswordReducer(state = initialForgotPasswordValues,action){
    const {type , payload} = action;
    switch (type) {
        case types.RESET_FORGOT_PASSWORD_VALUES:
            return{...initialForgotPasswordValues};
        case types.MOVE_STEP_AND_UPDATE_VALUES:
            const {currentStep} = state;
            return{
                ...state,
                currentStep: parseInt(currentStep) + 1,
                ...payload
            }
        default:
            return state
    }
}