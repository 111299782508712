import React, { useEffect, useRef, useState, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  IconButton,
  Box,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CommonSignPad from "../common/CommonSignPad";
import pdfjsLib from "pdfjs-dist/webpack";
import {
  CustomAlertMessage,
  CustomPageLoading,
  DateTimeFormat,
} from "../../functions/CustomFunctions";
import { useSnackbar } from "notistack";
import { Rnd } from "react-rnd";
import {
  authHeader,
  authMultipartHeader,
  getOrganizationName,
  getLoggedEntityTypeID,
  getLoggedSourceOwnerID,
  getLoggedUserMemberId,
} from "../../functions/CoreFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentFormByID,
  getOfficerReportDetails,
} from "../../redux/actions";
import {
  GenerateEventInsurancePDF,
  CreateMasterIntakePdf,
  GenerateOfficerReportPDF,
  GenerateChaperDocumentaionPDF,
} from "../../functions/DocumentGeneration";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  GET_EVENT_INSURANCE_DETAILS_URL,
  GET_MASTER_INTAKE_DETAILS,
  SIGN_DOCUMENT_URL,
  UPSERT_SIGN_POSITION_IN_FORMINFO_URL,
} from "../../constants/apiPaths";
import API from "../../redux/actions/API";
import { isMobileScreen, isTabScreen } from "../../functions/commonFunctions";
import jsPDF from "jspdf";
import { RESET_DOCUMENT_FORM_BY_ID } from "../../redux/actions/ActionTypes";
import checkmarkImg from "../../assets/img/checkmark.png";
import { DTYPE_FOR_DOCUMENT_FORM } from "../../constants/HelperConstants";
import { LOCALSTORAGE_ENTITYTYPEID } from "../../constants/LocalStorage";
import { LOCALSTORAGE_PROFILE, LOCALSTORAGE_QUESTIONBUILDER_DOCUMENT_FORMS } from "../../constants/LocalStorage";

const useStyles = makeStyles((theme) => ({
  submitbtn: {
    color: "#fff",
    width: "250px",
    //margin: '30px 0px 0px 155px',
    margin: "0px 0px 0px 10px",
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: "50px",
    display: "block",
    padding: "10px 35px",
  },
  submitbtncopy: {
    color: "#fff",
    float: "right",
    marginRight: "10px",
    //margin: '0px 65px 0px 155px',
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: "50px",
    display: "block",
    padding: "10px 35px",
  },
  submitbtnmobile: {
    color: "#fff",
    margin: "0px 0px 0px 16px",
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: "50px",
    fontSize: "10px",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  pageBlock: {
    border: "1px solid #dcdada",
    padding: "10px",
  },
  signroot: {
    flexGrow: 1,
  },
  typeStyle: {
    fontFamily: "'Great Vibes', cursive",
    fontSize: "40px",
    textAlign: "left",
    padding: "12px",
    marginBottom: "1rem",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;


  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function SignPadDialogue(props) {
  const profile = localStorage.getItem(LOCALSTORAGE_PROFILE);
  const ProfileString = JSON.parse(profile);
  const { Details = {} } = ProfileString || {};
  const { Positions = [] } = Details || [];

  const QBuilderDocumentForm = localStorage.getItem(LOCALSTORAGE_QUESTIONBUILDER_DOCUMENT_FORMS)
  const QBuilderDocumentID = QBuilderDocumentForm ? JSON.parse(QBuilderDocumentForm).map(item => item.sourceValue) : [];
 
  const currentDate = DateTimeFormat(new Date(), "LL");
  const loggedUserId = getLoggedUserMemberId();
  const ParentOrganizationName = getOrganizationName();
  const {
    type,
    dialogFlag,
    dialogToggleFunc,
    fieldData = [],
    needSignQueue = true,
    download = false,
    signature: signForDownload = null,
    documentData = {},
    callBackPdfFromSignDocument,
    officerreport = false,
    SourceOwnerID: SourceOwnerIDProp = 0,
    calledFromComponent: calledFrom = "",
  } = props;
  // console.log(props, "PROPOSsss");
  const {
    documentId: DocumentID = 0,
    documentUrl: DocumentURL = "",
    dtsid: DtsID = 0,
    documentFormId: DocumentFormID = 0,
    organizationId = 0,
    documentTransactionId = 0,
    documentTransactionFormInfo = "",
    documentFormTypeId = 0,
    sourceOwnerId,
    positionId = 0,
  } = documentData;
  let isChapterProgramDocument = QBuilderDocumentID.some(item => parseInt(item) == DocumentFormID, false);
  const classes = useStyles();
  const canvas_ref = useRef({});
  const dispatch = useDispatch();
  const [signdialogFlag, setSignDialogToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [pdfDimension, setPdfDimension] = useState({
    width: 700,
    height: 1800,
  });
  const [pdfCreatedFlag, setPdfCreatedFlag] = useState(false);
  //const [pdfDimension, setPdfDimension] = useState({ width: isMobileScreen() ? 280 : 700, height: isMobileScreen() ? 395 : 1800 });
  const [signPosition, setSignPosition] = useState(null);
  const [signPositions, setSignPositions] = useState([]);
  const [elementSize, setElementSize] = useState({ w: 150, h: 60 });
  const [signature, setSignature] = useState(null);
  const [noOfPage, setNoOfPage] = useState(null);
  const [signPositionList, setSignPositionList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading: documentFormDataLoading = false,
    data: { documentformDetail = {}, documentTransaction = {}, documentFormFieldsData = [], chapterAdvisorPositionID, chapterHasMembers, chapterPresidentPositionID
    },
  } = useSelector((state) => state.GetDocumentFormByIDReducer);

  const localStorageEntityTypeId = localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID)

  const {
    loading: officerReportDetailsLoading = false,
    data: officerReportDetails = {},
  } = useSelector((state) => state.GetOfficerReportDetailsReducer);
  const [masterIntakeDetails, setMasterIntakeDetails] = useState({});
  const [eventInsuranceDetails, setEventInsuranceDetails] = useState({});
  let EntityTypeID = getLoggedEntityTypeID(),
    SourceOwnerID = getLoggedSourceOwnerID();
  const viewPdfRef = useRef(null);
  if (parseInt(EntityTypeID) == 11) {
    EntityTypeID = 6;
    SourceOwnerID = parseInt(SourceOwnerIDProp);
  }

  const GetMasterIntakeDetails = async () => {
    try {
      const options = {
        method: "GET",
        headers: authHeader(),
        url: GET_MASTER_INTAKE_DETAILS,
        params: {
          DocumentTransactionID: parseInt(documentTransactionId),
        },
      };
      const { data: apiResponse } = await API(options);
      const { status, response } = apiResponse;
      if (parseInt(status) === 1) {
        setMasterIntakeDetails(response);
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        CustomAlertMessage(error.response.statusText, "error", enqueueSnackbar);
      }
    }
  };

  const getEventInsuranceDetails = async (examTransactionID, examID) => {
    setLoading(true);
    try {
      const options = {
        method: "GET",
        url: GET_EVENT_INSURANCE_DETAILS_URL,
        headers: authHeader(),
        params: {
          ExamTransactionID: parseInt(examTransactionID),
          ExamID: parseInt(examID),
          DocumentTransactionID: parseInt(documentTransactionId),
        },
      };
      const { data: apiResponse } = await API(options);
      const { status, response } = apiResponse;
      if (parseInt(status) === 1) {
        setEventInsuranceDetails(response);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };

  const signdialogToggleFunc = () => {
    setSignDialogToggle(!signdialogFlag);
  };

  const callBackSignBad = (type, signature) => {
    setSignDialogToggle(false);
    setSignature({ type, signature });
  };

  const termsProps = {
    dialogFlag: signdialogFlag,
    dialogToggleFunc: signdialogToggleFunc,
    callBackFunc: callBackSignBad,
    modalToggleFunc: signdialogToggleFunc,
    DocumentURL: DocumentURL,
    EntityTypeID,
    SourceOwnerID,
  };


  useEffect(() => {
    // existing pdf
    const {
      documentId = 0,
      documentUrl = "",
      formInfo = "",
    } = documentformDetail || {};
    const {
      documentUrl: documentTransactionUrl = '',
      documentId: documentTransactionDocumentID = ''
    } = documentTransaction || {}

    if (documentformDetail && Object.keys(documentformDetail).length !== 0) {

      if (documentTransactionDocumentID > 0) {
        generateDocumentFromPdfUrl(documentTransactionUrl, formInfo);
      } else if (documentId > 0 && documentUrl !== "" && documentUrl !== null) {
        generateDocumentFromPdfUrl(documentUrl, formInfo); //documentForm - form info
      } else {
        // if (parseInt(documentFormTypeId) !== 1 && !(parseInt(DocumentFormID) === 8 || parseInt(DocumentFormID) === 9)) {
        // if (organizationId > 0 && !officerreport)  //if it is not from officer report then we need to call officer report data
        // {
        //   dispatch(getOfficerReportStatus(organizationId));
        // }
        // if (documentTransactionId !== 0)  //if it is not from officer report then we need to call signqueue data
        // {
        //   dispatch(getSignQueueByID(documentTransactionId));
        // }
        //existing pdf load
        if (DocumentID > 0 && DocumentURL !== "") {
          generateDocumentFromPdfUrl(DocumentURL);
        }
        //}
      }
    }
  }, [documentformDetail]);


  const generateDocumentFromPdfUrl = (documentURL = "", formInfo = null) => {
    if (
      documentURL &&
      (documentFormFieldsData !== null || fieldData.length > 0)
    ) {
      setLoading(true);
      let loadingTask = pdfjsLib.getDocument(documentURL);
      loadingTask.onProgress = function (progress) {
      };
      try {
        loadingTask.promise
          .then(function (doc) {
            setNoOfPage(doc.numPages);
            for (let i = 1; i <= doc.numPages; i++) {
              doc
                .getPage(i)
                .then((page) => {
                  let canvasId = `canvas_document_pdf_${i}`;
                  var canvas = document.getElementById(canvasId);
                  var context = canvas.getContext("2d");
                  let scale = 1.5;
                  var viewport = page.getViewport(scale);
                  canvas.width = viewport.width;
                  canvas.height = viewport.height;
                  // if(isMobileScreen() || isTabScreen())
                  // {
                  //   setPdfDimension({
                  //     width: 280,
                  //     height: 395,
                  //   });
                  // }else{
                  setPdfDimension({
                    width: viewport.width,
                    height: viewport.height,
                  });
                  // }
                  page.render({
                    canvasContext: context,
                    viewport: viewport,
                  });

                  let parsedValue;  // setSignPositions is used to populate Click here to sing button regardless of documentTransaction?.documentId
                  if (formInfo !== "" && formInfo !== null) {
                    parsedValue = JSON.parse(formInfo);
                    setSignPositions(
                      parsedValue?.filter(
                        (element) => element.field_type === "signature"
                      )
                    );
                  }

                  let docID;
                  if (documentTransaction && documentTransaction.hasOwnProperty('documentId')) {
                    docID = documentTransaction.documentId;
                  } else {
                    docID = 0;
                  }


                  // documentTransaction?.documentId == 0 show that the document is already populated by values only signs are remaing 
                  if (formInfo !== "" && formInfo !== null && docID == 0) {

                    {
                      let found1 = parsedValue?.filter(
                        (element) => element.field_type === "signature"
                      );

                      let found2 = parsedValue?.filter(
                        (element) => element.field_type != "signature"
                      );
                      found2.map((pos) => {
                        const {
                          page_number,
                          field_type,
                          field_name,
                          x,
                          y,
                          width,
                          height,
                          current_date,
                          entity_subtype,
                          organizationLevel,
                          positionId,
                        } = pos;

                        // // !po.positionId;
                        let organizationLevelExist = organizationLevel ? true : false
                        // let colgOfficialDetected = po.positionId == 0 && localStorageEntityTypeId == 7
                        let noMembersChapterAdvisorSign = (chapterHasMembers == false && pos.positionId == chapterPresidentPositionID && chapterAdvisorPositionID == pos.positionId) // This means chapter adviser signing for chapter as president 
                        let collegeOfficialSign = ((pos.positionId == 0 && localStorageEntityTypeId == 7)) // This means college offcial needs to sign
                        let regularOfficerSign = pos.positionId === positionId // This means regular officer is Signing 

                        let showButtonOrSignature = noMembersChapterAdvisorSign || collegeOfficialSign || regularOfficerSign

                        let positionFound = false

                        if (showButtonOrSignature == true && organizationLevelExist == true) {
                          positionFound = Positions.some(localStoragePosition => {
                            const match = (positionId == localStoragePosition.PositionID) && (organizationLevel == localStoragePosition.DivisionID)
                            if (match == true) {
                              return true
                            }
                          })
                          if (positionFound == false)
                            showButtonOrSignature = false
                        }
                        let fieldValueData = {};
                        if (fieldData.length > 0) {
                          fieldValueData = fieldData.find(
                            (element) => element.fieldName === entity_subtype
                          );
                        } else {
                          fieldValueData = documentFormFieldsData.find(
                            (element) => element.fieldName === field_name
                          );
                        }
                        const { fieldValue = "" } = fieldValueData || {};
                        if (page_number > 0) {
                          var pageCanvas = document.getElementById(
                            `canvas_document_pdf_${parseInt(page_number)}`
                          );
                          const timer = setTimeout(() => {
                            if (pageCanvas != null) {
                              var pageContext = pageCanvas.getContext("2d");
                              pageContext.fillStyle = "black";
                              pageContext.font = "italic 10pt Verdana";
                              const currentDate = DateTimeFormat(
                                new Date(),
                                "MM/DD/YYYY"
                              );
                              if (field_type === "date" || field_type == "signatureDate" && showButtonOrSignature) {
                                if (current_date) {
                                  pageContext.fillText(
                                    currentDate,
                                    x + 30,
                                    y + 20
                                  );
                                } else {
                                  pageContext.fillText(
                                    fieldValue,
                                    x + 30,
                                    y + 20
                                  );
                                }
                              } else if (field_type === "checkbox") {
                                if (
                                  fieldValue
                                    .replaceAll(/\s/g, "")
                                    .toLowerCase() ===
                                  field_name.replaceAll(/\s/g, "").toLowerCase()
                                ) {
                                  var myImage = new Image();
                                  myImage.src = checkmarkImg;
                                  myImage.onload = function () {
                                    pageContext.drawImage(
                                      myImage,
                                      x,
                                      y,
                                      20,
                                      20
                                    );
                                  };
                                }
                              } else if (field_type != "signatureDate") {
                                pageContext.fillText(
                                  fieldValue,
                                  x + 30,
                                  y + 20
                                );
                              }
                              setPdfCreatedFlag(true);
                              clearTimeout(timer);
                            }
                          }, 3000);
                        }
                      });
                    }
                  }
                  else if (formInfo !== "" && formInfo !== null && docID != 0) {

                    {
                      let found1 = parsedValue?.filter(
                        (element) => element.field_type === "signature"
                      );

                      let found2 = parsedValue?.filter(
                        (element) => element.field_type != "signature"
                      );
                      found2.map((pos) => {
                        const {
                          page_number,
                          field_type,
                          field_name,
                          x,
                          y,
                          width,
                          height,
                          current_date,
                          entity_subtype,
                          organizationLevel,
                          positionId,
                        } = pos;

                        // // !po.positionId;
                        let organizationLevelExist = organizationLevel ? true : false
                        // let colgOfficialDetected = po.positionId == 0 && localStorageEntityTypeId == 7
                        let noMembersChapterAdvisorSign = (chapterHasMembers == false && pos.positionId == chapterPresidentPositionID && chapterAdvisorPositionID == pos.positionId) // This means chapter adviser signing for chapter as president 
                        let collegeOfficialSign = ((pos.positionId == 0 && localStorageEntityTypeId == 7)) // This means college offcial needs to sign
                        let regularOfficerSign = pos.positionId === positionId // This means regular officer is Signing 

                        let showButtonOrSignature = noMembersChapterAdvisorSign || collegeOfficialSign || regularOfficerSign

                        let positionFound = false

                        if (showButtonOrSignature == true && organizationLevelExist == true) {
                          positionFound = Positions.some(localStoragePosition => {
                            const match = (positionId == localStoragePosition.PositionID) && (organizationLevel == localStoragePosition.DivisionID)
                            if (match == true) {
                              return true
                            }
                          })
                          if (positionFound == false)
                            showButtonOrSignature = false
                        }

                        let fieldValueData = {};
                        if (fieldData.length > 0) {
                          fieldValueData = fieldData.find(
                            (element) => element.fieldName === entity_subtype
                          );
                        } else {
                          fieldValueData = documentFormFieldsData.find(
                            (element) => element.fieldName === field_name
                          );
                        }
                        const { fieldValue = "" } = fieldValueData || {};
                        if (page_number > 0) {
                          var pageCanvas = document.getElementById(
                            `canvas_document_pdf_${parseInt(page_number)}`
                          );
                          const timer = setTimeout(() => {
                            if (pageCanvas != null) {
                              var pageContext = pageCanvas.getContext("2d");
                              pageContext.fillStyle = "black";
                              pageContext.font = "italic 10pt Verdana";
                              const currentDate = DateTimeFormat(
                                new Date(),
                                "MM/DD/YYYY"
                              );
                              if ((field_type == "signatureDate" && showButtonOrSignature)) {
                                if (current_date) {
                                  pageContext.fillText(
                                    currentDate,
                                    x + 30,
                                    y + 50
                                  );
                                } else {
                                  pageContext.fillText(
                                    fieldValue,
                                    x + 30,
                                    y + 50
                                  );
                                }
                              }
                              setPdfCreatedFlag(true);
                              clearTimeout(timer);
                            }
                          }, 3000);
                        }
                      });
                    }





                  }
                })
                .catch((error) => {
                  console.error(error, "File Loading");
                  CustomAlertMessage(
                    "File Loading Failed...",
                    "error",
                    enqueueSnackbar
                  );
                });
            }
          })
          .catch((error) => {
            console.error(error, "pdf loading");
            CustomAlertMessage(
              "File Upload Failed...",
              "error",
              enqueueSnackbar
            );
          });
        setLoading(false);
      } catch (error) {
        console.error(error, "pdf loading");
        CustomAlertMessage("File Upload Failed...", "error", enqueueSnackbar);
      }
    }
  };

  useEffect(() => {
    if (signature != null && signPositions.length > 0) {
      let tempSignPos = signPositions;
      let positionFound = false;
      tempSignPos.forEach((row) => {
        let organizationLevelExist = row.organizationLevel ? true : false
        if (organizationLevelExist == false) {
          if (row.positionId == positionId) {
            row.signValue = signature.signature;
          }
        } else if (organizationLevelExist == true) {
          positionFound = Positions.some(localStoragePosition => {
            const match = (row.positionId == localStoragePosition.PositionID) && (row.organizationLevel == localStoragePosition.DivisionID)
            if (match == true) {
              return true
            }
          })
          if (positionFound == true && row.positionId == positionId) {
            row.signValue = signature.signature;
          }
        }
      });
      setSignPositions(tempSignPos);
    }
  }, [signature]);

  useEffect(() => {
    if (parseInt(DocumentFormID) === 8 || parseInt(DocumentFormID) === 9) {
      //officer report
      dispatch(getOfficerReportDetails(organizationId));
      // } else if (parseInt(DocumentFormID) === 44 || parseInt(DocumentFormID) === 1171 || parseInt(DocumentFormID) === 1107) {
    } else if (parseInt(DocumentFormID) === 44 || isChapterProgramDocument) {
      // event insurance & chapter Document (1107 is PRODCUTION & 1171 is STAGING values for Chapter Document)
      let examTransactionID = 0;
      let examID = 0;
      //for testing keeping static values reset to 0 after api update for chapter documentation 
      // let examTransactionID = 1121;
      // let examID = 85;
      if (
        documentTransactionFormInfo != "" &&
        documentTransactionFormInfo != null
      ) {
        let split = documentTransactionFormInfo.split("|");
        const ExamIDValue = split.find((element) =>
          element.startsWith("ExamID")
        );
        const ExamTransactionIDValue = split.find((element) =>
          element.startsWith("ExamTransactionID")
        );

        if (ExamIDValue) examID = ExamIDValue.split("=")[1];
        if (ExamTransactionIDValue)
          examTransactionID = ExamTransactionIDValue.split("=")[1];

        getEventInsuranceDetails(examTransactionID, examID);


      }
    } else if (
      parseInt(documentFormTypeId) === 1
      //  ||
      // parseInt(DocumentFormID) === 4
    ) {
      //master intake
      GetMasterIntakeDetails();
    } else {
      if (DocumentFormID > 0) {
        //for document form preview we didn't call api/DocumentForm/GetDocumentFormDetailTransaction-api
        //so if we don't pass a callback into this component it will prevent this api call
        dispatch(getDocumentFormByID(DocumentFormID, documentTransactionId));
      }
    }
    return () => {
      dispatch({
        type: RESET_DOCUMENT_FORM_BY_ID,
        payload: {},
      });
    };
  }, []);


  useEffect(() => {
    //officer report
    if (
      officerReportDetails &&
      Object.keys(officerReportDetails).length !== 0
    ) {
      const { DocumentFormDetails = {} } = officerReportDetails || {};
      const { documentContent = "" } = DocumentFormDetails || {};
      // if(documentContent == null)
      // {
      //   CustomAlertMessage('Document Content is empty!', 'error',enqueueSnackbar);
      //  }else{
      const orProps = {
        currentDate,
        ParentOrganizationName,
        officerReportData: officerReportDetails,
      };
      const { pdf: outputpdf, signPosition } =
        GenerateOfficerReportPDF(orProps);
      // outputpdf.output('dataurlnewwindow'); //preview to check
      const pdfURL = outputpdf.output("datauristring");
      const { x, y, page_number } = signPosition;
      setSignPositions([
        { id: 1, x: x, y: y, page_number: page_number, height: 60, width: 150 },
      ]);
      generateDocumentFromPdfUrl(pdfURL);
      //  }
    }
  }, [officerReportDetails]);

  useEffect(() => {
    //master intake
    if (
      parseInt(documentFormTypeId) === 1 &&
      masterIntakeDetails &&
      Object.keys(masterIntakeDetails).length !== 0
    ) {
      //Master Intake Document
      const { documentForm = {} } = masterIntakeDetails || {};
      const { documentContent = "" } = documentForm || {};
      if (documentContent == null) {
        CustomAlertMessage(
          "Document Content is empty!",
          "error",
          enqueueSnackbar
        );
      } else {
        const Props = { ParentOrganizationName, masterIntakeDetails };
        const { pdf: outputpdf, signPosition } = CreateMasterIntakePdf(Props);
        const { x, y, page_number } = signPosition;
        setSignPositions([
          {
            id: 1,
            x: x,
            y: y,
            page_number: page_number,
            height: 60,
            width: 150,
          },
        ]);
        const pdfURL = outputpdf.output("datauristring");
        generateDocumentFromPdfUrl(pdfURL);
      }
    }
  }, [masterIntakeDetails]);

  useEffect(() => {
    //event insurance
    if (
      eventInsuranceDetails &&
      Object.keys(eventInsuranceDetails).length !== 0
    ) {
      //Event Insurance
      const {
        documentContent = "",
        examSections = [],
        examTransaction = [],
        signQueue = [],
      } = eventInsuranceDetails || {};
      if (documentContent == null) {
        CustomAlertMessage(
          "Document Content is empty!",
          "error",
          enqueueSnackbar
        );
      } else {
        const orProps = {
          documentContent,
          documentTransactionData: examTransaction,
          examSectionsData: examSections,
          eventInsuranceSignQueue: signQueue,
        };
        const { pdf: outputpdf, signPosition } =
          GenerateEventInsurancePDF(orProps);
        const { x, y, page_number } = signPosition;
        setSignPositions([
          {
            id: 1,
            x: x,
            y: y,
            page_number: page_number,
            height: 60,
            width: 150,
          },
        ]);
        const pdfURL = outputpdf.output("datauristring");
        generateDocumentFromPdfUrl(pdfURL);
      }
    }
  }, [eventInsuranceDetails]);

  const submitDocumentFunc = async () => {
    if (!download && signature === null) {
      CustomAlertMessage(
        "Please Sign the document before submitting",
        "error",
        enqueueSnackbar
      );
    } else {
      let signToUpload = "";
      let documentToUpload = "";
      let pdf = "",
        downloadableUrl = "";
      if (signature && Object.keys(signature).length !== 0) {
        if (signature.type !== "existing") {
          signToUpload = signature.signature;
        }
      }
      if (parseInt(documentFormTypeId) === 1) {
        const Props = {
          ParentOrganizationName,
          masterIntakeDetails,
          currentSign: signature.signature,
        };
        const { pdf: outputpdf } = CreateMasterIntakePdf(Props);
        pdf = outputpdf;
        documentToUpload = outputpdf.output("blob");
      } else if (parseInt(DocumentFormID) === 44) {
        const {
          documentContent = "",
          examSections = [],
          examTransaction = [],
          signQueue = [],
        } = eventInsuranceDetails || {};
        const orProps = {
          type,
          documentContent,
          documentTransactionData: examTransaction,
          examSectionsData: examSections,
          eventInsuranceSignQueue: signQueue,
          currentSign: signature.signature,
        };
        const { pdf: outputpdf } = GenerateEventInsurancePDF(orProps);
        pdf = outputpdf;
        documentToUpload = outputpdf.output("blob");
        // } else if (parseInt(DocumentFormID) === 1171 || parseInt(DocumentFormID) === 1107) {
      } else if (isChapterProgramDocument) {
        const {
          documentContent = "",
          examSections = [],
          examTransaction = [],
          signQueue = [],
        } = eventInsuranceDetails || {};
        const orProps = {
          type,
          documentContent,
          documentTransactionData: examTransaction,
          examSectionsData: examSections,
          eventInsuranceSignQueue: signQueue,
          currentSign: signature.signature,
        };
        const { pdf: outputpdf } = GenerateChaperDocumentaionPDF(orProps);
        pdf = outputpdf;
        documentToUpload = outputpdf.output("blob");
      } else if (
        parseInt(DocumentFormID) === 8 ||
        parseInt(DocumentFormID) === 9
      ) {
        const orProps = {
          currentDate,
          ParentOrganizationName,
          officerReportData: officerReportDetails,
          currentSign: signature.signature,
        };
        const { pdf: outputpdf } = GenerateOfficerReportPDF(orProps);
        pdf = outputpdf;
        documentToUpload = outputpdf.output("blob");
      } else {
        const { documentId = 0, documentUrl = "" } = documentformDetail || {};
        if (documentId > 0 && documentUrl !== "") {
          //attach canvas image in pdf
          const pdf = new jsPDF("p", "px", "a4", true);
          var pageWidth = pdf.internal.pageSize.getWidth();
          var pageHeight = pdf.internal.pageSize.getHeight();
          for (let i = 1; i <= noOfPage; i++) {
            // const { page_number = 0, x, y } = signPosition;
            // const { w, h } = elementSize;
            var canvas = document.getElementById(`canvas_document_pdf_${i}`);
            var context = canvas.getContext("2d");

            signPositions.map((pos) => {
              const {
                x,
                y,
                page_number,
                height,
                width,
                positionId: positionIDVal = '0',
                signValue,
                organizationLevel = 0,
              } = pos || {};
              let positionFound = false

              if (organizationLevel != 0) {
                positionFound = Positions.some(localStoragePosition => {
                  const match = (positionIDVal == localStoragePosition.PositionID) && (organizationLevel == localStoragePosition.DivisionID)
                  if (match == true) {
                    return true
                  }
                })
              }

              if (parseInt(page_number) === parseInt(i)) {
                var myImage = new Image();



                if (download) {
                  myImage.src = signForDownload;
                } else {
                  //for college officials when using new signature 
                  if (signature.type !== "existing" && (positionIDVal == 0 && (localStorageEntityTypeId == 7 || localStorageEntityTypeId == 11))) {
                    myImage.src = signature.signature;
                  }
                  if (
                    // regular officer sign
                    (signature !== null &&
                      (positionIDVal || positionIDVal == 0)) ||
                    (signValue !== null && positionIDVal === positionId)
                  ) {
                    //for college officials
                    if (signature && (positionIDVal == 0 && (localStorageEntityTypeId == 7 || localStorageEntityTypeId == 11))) {
                      myImage.src = signature.signature;
                    } else if (signature != null && chapterHasMembers == false && positionIDVal == chapterPresidentPositionID && chapterAdvisorPositionID == positionId) {
                      // Chapter advisor has to sign if there are no members
                      myImage.src = signature.signature
                    }
                    else
                      if (signValue) {
                        myImage.src = signValue;
                      } // NEED A CHANGE
                    // else {
                    //   myImage.src = signature.signature
                    // }
                  }

                  // else
                  //   if (signature != null && chapterHasMembers == false && positionIDVal == chapterPresidentPositionID && chapterAdvisorPositionID == positionId) {
                  //     myImage.src = signature.signature
                  //     alert("3")
                  //   }

                }

                context.drawImage(
                  myImage,
                  x,
                  y - parseInt(page_number) * 4,
                  width,
                  height
                );
              }
            });
            if (i > 1) {
              pdf.addPage();
            }
            let imgData = canvas.toDataURL("image/png");
            pdf.addImage(
              imgData,
              "png",
              0,
              0,
              pageWidth,
              pageHeight,
              undefined,
              "FAST"
            );
          }
          // pdf.output('dataurlnewwindow');
          // pdf.save("chefkck.pdf");
          documentToUpload = pdf.output("blob");
          downloadableUrl = pdf;
        }
      }

      //  pdf.output('dataurlnewwindow');
      //  pdf.save("two-by-four.pdf");
      if (download) {
        callBackPdfFromSignDocument(downloadableUrl);
      } else if (needSignQueue) {
        signDocument(signToUpload, documentToUpload);
      } else {
        callBackPdfFromSignDocument(documentToUpload, downloadableUrl);
      }
    }
  };

  const signDocument = async (signToUpload, documentToUpload) => {
    try {
      setSubmitLoading(true);
      var formData = new FormData();
      formData.append("DocumentTransactionId", documentTransactionId);
      formData.append("file", documentToUpload);
      formData.append("entityTypeId", EntityTypeID);
      formData.append("sourceOwnerId", SourceOwnerID);
      formData.append("documentID", DocumentID);
      formData.append("dtsID", DtsID);
      formData.append("dType", DTYPE_FOR_DOCUMENT_FORM);
      formData.append("fileExtension", "pdf");
      formData.append("signbase64", signToUpload);
      formData.append("isElectronicSign", signature.type !== "existing");
      const options = {
        method: "POST",
        headers: authMultipartHeader(),
        url: SIGN_DOCUMENT_URL,
        data: formData,
      };
      const { data: apiResponse } = await API(options);
      const { status, response = "" } = apiResponse;
      if (parseInt(status) === 1) {
        CustomAlertMessage("Saved successfully!", "success", enqueueSnackbar);
        if (signPositionList.length > 0) {
          InsertSignPositions();
        }
        callBackPdfFromSignDocument();
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(error.response.statusText, "error", enqueueSnackbar);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const InsertSignPositions = async () => {
    try {
      const options = {
        method: "POST",
        headers: authHeader(),
        url: UPSERT_SIGN_POSITION_IN_FORMINFO_URL,
        data: {
          documentTransactionID: documentTransactionId,
          positionList: JSON.stringify(signPositionList),
        },
      };
      const { data: apiResponse } = await API(options);
      const { status } = apiResponse;
      if (parseInt(status) === 1) {
        console.log("success");
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        CustomAlertMessage(error.response.statusText, "error", enqueueSnackbar);
      }
    }
  };

  return (
    <Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth={true}
        maxWidth={"lg"}
        onClose={dialogToggleFunc}
        aria-labelledby="customized-dialog-title"
        open={dialogFlag}
      >
        <DialogTitle id="customized-dialog-title" onClose={dialogToggleFunc}>
          Sign PDF:
          <div style={{ display: "flex", float: "right", marginRight: "35px" }}>
            {signature === null && (isMobileScreen() || isTabScreen()) && (
              <Button
                variant="contained"
                onClick={signdialogToggleFunc}
                className={
                  isMobileScreen()
                    ? classes.submitbtnmobile
                    : classes.submitbtncopy
                }
              >
                Sign
              </Button>
            )}

            <Button
              variant="contained"
              onClick={submitDocumentFunc}
              className={
                isMobileScreen()
                  ? classes.submitbtnmobile
                  : classes.submitbtncopy
              }
            >
              {download ? "Download PDF" : "Save Document"}
            </Button>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <CustomPageLoading flag={loading} />
          <CustomPageLoading flag={submitLoading} />
          <CustomPageLoading flag={documentFormDataLoading} />
          <CustomPageLoading flag={officerReportDetailsLoading} />
          {!loading && (
            <div className={classes.signroot}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs
                  sm={1}
                  md={2}
                  component={Box}
                  display={{ xs: "none", sm: "block" }}
                />
                <Grid item xs={12} sm={10} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} id="checkpdf">
                      <div
                        id="viewPdf"
                        ref={viewPdfRef}
                        style={{
                          position: "relative",
                          alignSelf: "center",
                          width: pdfDimension.width,
                          //width: isMobileScreen() ? "100%" : pdfDimension.width,
                          height:
                            noOfPage !== null && noOfPage > 1
                              ? pdfDimension.height * noOfPage
                              : pdfDimension.height,
                        }}
                      >
                        {noOfPage !== null &&
                          [...Array(noOfPage)].map((e, i) => {
                            // const { id: canvasId = '', x, y } = signPosition || {};
                            // const { w, h } = elementSize;
                            let j = 0;
                            return (
                              <Fragment key={`canvas_fragment_key_${i + 1}`}>
                                <canvas
                                  key={`canvas_key_${i + 1}`}
                                  id={`canvas_document_pdf_${i + 1}`}
                                  style={{
                                    border: "2px solid #1b3e6f",
                                    display: "inline-block",
                                    width: "100%",
                                  }}
                                  ref={canvas_ref}
                                ></canvas>
                                {
                                  signPositions
                                    .filter(
                                      (x) =>
                                        parseInt(x.page_number) ==
                                        parseInt(i) + 1
                                    )
                                    .map((po) => {

                                      let hasPositionProp = po.hasOwnProperty('positionId');   // = false then this means we are building PDF on fly




                                      // !po.positionId;
                                      let organizationLevelExist = po.organizationLevel ? true : false
                                      let colgOfficialDetected = po.positionId == 0 && localStorageEntityTypeId == 7
                                      let noMembersChapterAdvisorSign = (chapterHasMembers == false && po.positionId == chapterPresidentPositionID && chapterAdvisorPositionID == positionId) // This means chapter adviser signing for chapter as president 
                                      let collegeOfficialSign = (hasPositionProp == false || (po.positionId == 0 && localStorageEntityTypeId == 7)) // This means college offcial needs to sign
                                      let regularOfficerSign = po.positionId === positionId // This means regular officer is Signing 

                                      let showButtonOrSignature = noMembersChapterAdvisorSign || collegeOfficialSign || regularOfficerSign
                                      let positionFound = false

                                      if (showButtonOrSignature == true && regularOfficerSign == true && organizationLevelExist == true) {
                                        positionFound = Positions.some(localStoragePosition => {
                                          const match = (po.positionId == localStoragePosition.PositionID) && (po.organizationLevel == localStoragePosition.DivisionID)
                                          if (match == true) {
                                            return true
                                          }
                                        })
                                        if (positionFound == false)
                                          showButtonOrSignature = false
                                      }

                                      // positionId comes from api/DocumentSignApproval/GetSignQueue
                                      // po is coming from api/DocumentForm/GetDocumentFormDetailTransaction->(formInfo)
                                      return (
                                        <Fragment key={`fragment_${po.id}`}>
                                          <Rnd
                                            disableDragging={true}
                                            key={`divList_${po.id}`}
                                            id={po.id}
                                            size={{
                                              width: po.width,
                                              height: po.height,
                                            }}
                                            position={{
                                              x: isMobileScreen()
                                                ? po.x / 3
                                                : po.x,
                                              y: isMobileScreen()
                                                ? po.y / 3 +
                                                pdfDimension.height *
                                                parseInt(i) +
                                                parseInt(i) * 5
                                                : po.y +
                                                pdfDimension.height *
                                                parseInt(i),
                                            }}
                                          >
                                            {download &&
                                              signForDownload !== null ? (
                                              <img
                                                src={signForDownload}
                                                alt="signature"
                                                width={
                                                  isMobileScreen()
                                                    ? po.width / 2
                                                    : po.width
                                                }
                                                height={
                                                  isMobileScreen()
                                                    ? po.height / 4
                                                    : po.height
                                                }
                                              />
                                            ) :
                                              // //if ChapterHasMembers: False, then if SignQueueRow.positionId = ChapterPresidentPositionID and the LoggedIn Member PositionID = ChapterAdvisorPositionID 
                                              // (signature === null && chapterHasMembers == false && po.positionId == chapterPresidentPositionID && chapterAdvisorPositionID == positionId)
                                              //   ||
                                              //   (signature === null && (hasPositionProp == false || (po.positionId == 0 && localStorageEntityTypeId == 7)))
                                              //   // if we dont have signature  and // if po.positionId != '' or 0  // !po.positionId  if there is no property as "positionId" in PO
                                              //   ||
                                              //   ((signature === null && po.positionId === positionId)
                                              //     //signature = null and po.positionId === positionId
                                              showButtonOrSignature &&
                                                !(isMobileScreen() || isTabScreen())
                                                ?
                                                <>
                                                  {
                                                    signature == null ?
                                                      < div style={{ marginLeft: "25px" }}>
                                                        <Button
                                                          variant="contained"
                                                          size="small"
                                                          onClick={signdialogToggleFunc}
                                                          style={{
                                                            background: "#ffc107",
                                                            fontWeight: isMobileScreen()
                                                              ? ""
                                                              : "bold",
                                                            fontSize: isMobileScreen()
                                                              ? "3px"
                                                              : "10px",
                                                          }}
                                                        >
                                                          Click here to Sign
                                                        </Button>{" "}
                                                      </div> :
                                                      <>
                                                        {(signature !== null) ? (
                                                          (signature) ? (
                                                            <>
                                                              <img
                                                                src={signature?.signature}
                                                                // alt="signature"
                                                                width={po.width}
                                                                height={po.height}
                                                              />
                                                            </>
                                                          ) : (
                                                            ""
                                                          )
                                                        ) :
                                                          <>
                                                            {po?.signValue && (
                                                              <img
                                                                src={po.signValue}
                                                                // alt="signature"
                                                                width={po.width}
                                                                height={po.height}
                                                              />
                                                            )}
                                                          </>}
                                                      </>}

                                                </>
                                                : ''

                                            }

                                            {

                                              // (signature !== null ) ? (

                                              //   (signature) ? (
                                              //     <>
                                              //       <img
                                              //         src={signature?.signature}
                                              //         // alt="signature"
                                              //         width={po.width}
                                              //         height={po.height}
                                              //       />
                                              //     </>
                                              //   ) : (
                                              //     ""
                                              //   )
                                              // ) : po?.signValue ? (
                                              //   <img
                                              //     src={po.signValue}
                                              //     // alt="signature"
                                              //     width={po.width}
                                              //     height={po.height}
                                              //   />
                                              // ) : (
                                              //   ""
                                              // )
                                            }
                                          </Rnd>
                                        </Fragment>
                                      );
                                    })}
                                {/* )} */}
                              </Fragment>
                            );
                          })}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "28px",
                      }}
                    >
                      {signature === null &&
                        (isMobileScreen() || isTabScreen()) && (
                          <Button
                            variant="contained"
                            onClick={signdialogToggleFunc}
                            className={
                              isMobileScreen()
                                ? classes.submitbtnmobile
                                : classes.submitbtn
                            }
                          >
                            Sign
                          </Button>
                        )}
                      <Button
                        variant="contained"
                        onClick={submitDocumentFunc}
                        className={
                          isMobileScreen()
                            ? classes.submitbtnmobile
                            : classes.submitbtn
                        }
                      >
                        {download ? "Download PDF" : "Save Document"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs
                  sm={1}
                  md={2}
                  component={Box}
                  display={{ xs: "none", sm: "block" }}
                />
              </Grid>

              {signdialogFlag && <CommonSignPad {...termsProps} />}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={dialogToggleFunc} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment >
  );
}
