import React, { Fragment } from 'react'
import { CustomEmptyRow, CustomGridLoading, fixedFloatTwoDigit } from '../../../functions/CustomFunctions';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CustomTableHeader from '../../common/table/CustomTableHeader';


//Table Columns
const tableColumns = [
    { id: "name", title: "Name", sort: false },
    { id: "price", title: "Price", sort: false },
    
];

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column"
    },
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    title: {
        flex: "1 1 100%"
    },
    smallText: {
        fontSize: '16px'
    },
    filterIcon: {
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
    },
    exportBtn: {
        marginRight: '10px',
    },
    tablecontainer: {
        maxHeight: 440,
        overflowX: 'hidden',
    },
    continuebtn: {
        color: '#fff',
        // width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px',
        opacity: 1,
        '&:hover': {
            opacity: 0.7,
        },
        marginLeft: '5px',
        float: 'left'
    },
    deleteBtn: {
        padding: '6px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        fontSize: 5
    },
}));

export default function IntakeFeesSummary(props) {
    const classes = useStyles();
    const { tableList = [], loading = false,  } = props;
// console.log(tableList,'tableColumns')
    return (
        <Fragment>
            {tableList && (
                <Fragment>
                   
                        <TableContainer component={Paper} className={classes.tablecontainer}>
                            <Table stickyHeader size='small' >
                                {/* Table Header */}
                                <CustomTableHeader tableColumns={tableColumns} />
                                {/* Table Header */}

                                {/* Table Body */}
                                <TableBody>
                                    {loading ? (
                                        <TableRow>
                                            <TableCell align="center" colSpan={tableColumns.length}>
                                                <CustomGridLoading />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <Fragment>
                                            {!tableList.length ? (
                                                // Empty Row
                                                <CustomEmptyRow colSpanCount={tableColumns.length} />
                                                // Empty Row
                                            ) : (
                                                <Fragment>
                                                    {tableList.map((row, key) => {
                                                        let {
                                                            name = '',
                                                            price='',
                                                        } = row;

                                                        return (
                                                            <TableRow style={{ cursor: 'pointer' }} hover key={`data_${key}`}
                                                                >
                                                                
                                                                <TableCell align="left">
                                                                    {name}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                {`$${fixedFloatTwoDigit(price)}`}
                                                                </TableCell>
                                                                
                                                            </TableRow>
                                                        );
                                                    })}
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}
                                </TableBody>
                                {/* Table Body */}
                            </Table>
                        </TableContainer>
                </Fragment>
            )}
        </Fragment>
    )
}
