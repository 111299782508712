import { getRegionalRosterDetailsInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

const GetRegionalRosterDetailsReducer = (state = getRegionalRosterDetailsInitialValues, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_REGIONAL_ROSTER_DETAILS_LOADING:
            return { payload, loading: true, error: false }
        case types.GET_REGIONAL_ROSTER_DETAILS_SUCCESS:
            return { region: { ...payload }, loading: false, error: false }

        case types.GET_REGIONAL_ROSTER_DETAILS_ERROR:
            return { payload, loading: false, error: true }
        default:
            return state
    }
}

export default GetRegionalRosterDetailsReducer


