import { initialDocumentFilterOptionsValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetDocumentsFilterReducer(state = initialDocumentFilterOptionsValues,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_DOCUMENT_FILTER_OPTIONS_LOADING:
            return {...state,loading:true,error:false};  

        case types.GET_DOCUMENT_FILTER_OPTIONS_SUCCESS:
            const {Chapters = [],DocumentForms = [] , DocumentTransactionStatuses = [],Regions=[],Seasons=[]} = payload;
            return {
                loading:false,
                Chapters,
                DocumentForms,
                DocumentTransactionStatuses,
                Regions,
                Seasons,
                error:false
            }; 
            
        case types.GET_DOCUMENT_FILTER_OPTIONS_ERROR:
            return {...state,loading:false,error:true};      
            
        default:
            return state
    }
}







