// CommonPaymentSuccess
import React, { Fragment } from 'react';

import { Box, Button, Grid, ListItemText, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { isMobileScreen, isTabScreen } from '../../functions/commonFunctions';

import DefaultResponsiveContainer from './DefaultResponsiveContainer';
import paymentSuccessLogo from '../../assets/img/signup_steps/payment_success_logo.png';
import { useHistory } from 'react-router';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({

    modalTitle: {
        fontSize: '1rem',
        margin: 0
    },
    listStyle: {
        border: '1px solid #9c9898'
    },
    activeLineItem: {
        backgroundColor: 'rgb(232, 244, 253)',
        border: '1px solid #9c9898'
    },
    outlineBtn: {
        padding: '10px 35px',
        width: '100%',
        color: theme.primaryColor,
        display: 'block',
        borderRadius: '50px',
    },
    subtextStyle: {
        fontSize: '10px',
        marginTop: '-5px'
    },
    rightAlignText: {
        textAlign: 'right'
    },
    titleSection: {
        backgroundColor: 'rgb(232, 244, 253)',
    },
    pageTitle2: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: '23px',
        fontWeight: 500,
        padding: '10px 10px 10px 10px'
    },
    pageTitle3: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: '17px',
        padding: '0px 0px 13px 0px',
        marginTop: '-28px'
    },
    subDescStyle: {
        fontWeight: 'bold',
        color: 'red'
    },
    borderlessStyle: {
        borderBottom: 'none',
        fontWeight: 600
    },
    totalStyle: {
        fontWeight: 1000,
        fontSize: 'medium'
    },
    totalAmountStyle: {
        borderBottom: 'none',
        color: 'red',
        fontWeight: 1000,
        fontSize: 'large'
    },
    scrollableListSection: {
        height: isTabScreen() ? '55rem' : '40rem',
        //maxHeight: isMobileScreen() ? '65rem' : '52rem',
        overflow: 'auto',
        overflowX: 'hidden',
        padding: '10px'
    },
    updatedImage: {
        width: '180px'
    },
    pageHeader: {
        fontSize: 'x-large',
        // marginBottom: '1rem',
        textAlign: 'center'
    },
    continuebtn: {
        color: '#fff',
        width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px',
    },
}));
export default function CommonPaymentSuccess(props) {

    const { paymentResponseData = {},DialogClose } = props
    const classes = useStyles();
    return (
        <Fragment>
            <Grid item xs={12} >
                <div>
                    <Grid container spacing={3}>
                        <DefaultResponsiveContainer>
                            <div style={{ textAlign: 'center' }}>
                                <img src={paymentSuccessLogo} alt="no data" className={classes.updatedImage} />
                            </div>
                        </DefaultResponsiveContainer>

                        <DefaultResponsiveContainer>
                            {/* <Typography variant="h6" className={classes.pageHeader}>
                                Payment Success
                    </Typography> */}
                        </DefaultResponsiveContainer>
                        {/* {(parseInt(paymentResponseData.planPaymentMethod) === 0) ?
                                <DefaultResponsiveContainer>
                                    <Typography variant="body2" color="textSecondary" component="p" className={classes.textCenter}>
                                        You have successfully enrolled into the payment plan term and processed your first payment.
                        </Typography>
                                </DefaultResponsiveContainer>
                                : ''
                            } */}

                        <DefaultResponsiveContainer>
                            <Alert severity="success" className={classes.alertSuccess}>
                                <AlertTitle>Receipt ID : {paymentResponseData.authorizationCode || ''}</AlertTitle>
                        Email payment confirmation has been sent to <strong>{paymentResponseData.email}</strong>
                            </Alert>
                        </DefaultResponsiveContainer>

                        <DefaultResponsiveContainer>
                            <Button
                                // type='submit'
                                variant='contained'
                                className={classes.continuebtn}
                                onClick={DialogClose}
                            >
                                Continue
                    </Button>


                        </DefaultResponsiveContainer>
                    </Grid>
                </div>
            </Grid>
        </Fragment>
    );
}