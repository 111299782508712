import { initialGetListWithFilters } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetPurchaseOrderListReducer(state = initialGetListWithFilters, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_PURCHASE_ORDER_LIST_LOADING:
            return {payload,loading:true};
        case types.GET_PURCHASE_ORDER_LIST_SUCCESS:
            return {...state,...payload,loading:false,error:false};
        case types.GET_PURCHASE_ORDER_LIST_ERROR:
            return {payload,loading:false};
        default:
            return state;
    }
}




