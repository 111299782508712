import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import StepLabel from '@material-ui/core/StepLabel';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  completed: {
    display: 'inline-block',
  },
}));

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: 'rgb(3 80 253)',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: 'green',
    zIndex: 1,
    fontSize: 18,
  },
  completed2: {
    color: 'blue',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={(!active) ? classes.completed : classes.completed2} /> : <div className={classes.circle} />}
    </div>
  );
}

function FinalStepIcon(props) {
  return (
    <div
      style={{
        color: 'red',
        display: 'flex',
      }}
    >
      <PlaylistAddCheckIcon />
    </div>
  )
}

export default function CustomStepperComponent({ steps = [], activeStep, handleStep, goToSummary, disabled = false }) {
  const classes = useStyles();
  const incompleteStep = steps.find((record) => !record.completed)
  // console.log(disabled, 'disabled')
  return (
    <div className={classes.root}>
      <div>
        <Stepper alternativeLabel nonLinear activeStep={activeStep}
          style={{
            // width: steps.length > 10 ? 100 * (steps.length + 1) : 'auto',
            width: '100%', overflowY: 'auto'
            // padding: '10px 5px'
          }}
        >
          {steps.map(({ title, completed }, index) => {
            const stepProps = {};
            stepProps.completed = completed;

            return (
              <Step key={title} {...stepProps}>

                <StepLabel
                  style={{
                    cursor: 'pointer',
                    // wordBreak: "break-all"
                  }}
                  StepIconComponent={QontoStepIcon} onClick={(disabled) ? '' : handleStep(index)}>{title}</StepLabel>
                {/* <StepLabel style={{ cursor: 'pointer' }} StepIconComponent={QontoStepIcon} onClick={handleStep(index)}>{title}</StepLabel> */}
                {/* <StepButton
                onClick={handleStep(index)}
              >
                {title}
              </StepButton> */}
              </Step>
            );
          })}
          {!incompleteStep && (
            <Step
              key="Summary"
              onClick={goToSummary}
              className={classes.stepStyle}
            >
              <StepLabel
                StepIconComponent={FinalStepIcon}
                onClick={handleStep(steps.length)}
                style={{ cursor: 'pointer' }}
              >
                Summary
              </StepLabel>
            </Step>
          )}
        </Stepper>

      </div>
    </div>
  );
}