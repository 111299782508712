import { adminDashboardInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetAdminDashboardReducer(state = adminDashboardInitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_ADMIN_DASHBOARD_LOADING:
            return {...state,loading:true, error: false};
        case types.GET_ADMIN_DASHBOARD_SUCCESS:
            return {data:payload,loading:false};
        case types.GET_ADMIN_DASHBOARD_ERROR:
            return {...state,loading:false, error: true};
        default:
            return state;
    }
}