import { recommendedChaptersInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetRecommendedChaptersReducer(state = recommendedChaptersInitialValues, action) {
    const { type, payload } = action;
    switch (type) {

        case types.GET_RECOMMENDED_CHAPTERS_LOADING:
            return {
                ...state,
                chapterLoading: true
            };

        case types.GET_RECOMMENDED_CHAPTERS_SUCCESS:
            const { response = [], DefaultImage = '' } = payload;
            return {
                ...state,
                chapterLoading: false,
                initialChaptersList: response,
                chaptersList: response.RecommendChapters,
                ChapterInvites: response.ChapterInvites,
                ChapterInvitationRecords: response.ChapterInvitationRecords,
                defaultImageToCircle: DefaultImage
            };

        case types.GET_FILTERED_CHAPTERS_SUCCESS:
            const { response: filteredData = [], DefaultImage: image = '' } = payload;
            const { initialChaptersList = [] } = state;
            return {
                ...state,
                chapterLoading: false,
                initialChaptersList: (filteredData.length > 0) ? filteredData : initialChaptersList,
                chaptersList: (filteredData.length > 0) ? filteredData : initialChaptersList,
                defaultImageToCircle: image
            };

        case types.SET_CHAPTERS_LIST:
            return {
                ...state,
                chaptersList: payload,
            };

        case types.GET_RECOMMENDED_CHAPTERS_ERROR:
            return {
                ...state,
                chapterLoading: false
            };
        case types.UPDATE_DISTANCE_LEVEL:
            return {
                ...state,
                loadDistance: payload
            };

        default:
            return state
    }
}