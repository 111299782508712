import { Button, Table, Paper, Grid, makeStyles, TextField, Typography, Box, List, ListItem, ListItemText, Divider, Tooltip } from "@material-ui/core";
import React, { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { isMobileScreen, isTabScreen } from "../../functions/commonFunctions";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import CustomTableHeader from "../common/table/CustomTableHeader";
import { LOCALSTORAGE_PROFILE } from "../../constants/LocalStorage";
import { DateTimeFormat, CustomAlertMessage } from "../../functions/CustomFunctions";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DOCUMENT_TRANSACTION_UPSERT_SETTINGSTATE } from "../../constants/apiPaths";
import { authHeader } from "../../functions/CoreFunctions";
import API from "../../redux/actions/API";

const useStyles = makeStyles(theme => ({
    tablecontainer: {
        maxHeight: 300,
        overflowX: 'hidden',
    },
    continuebtn: {
        float: "left",
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        display: 'block',
    },
    completeStep: {
        float: 'left',
        borderRadius: '50%',
        backgroundColor: 'green',
        color: '#fff',
        padding: '2px',
    },
    formHeader: {
        textAlign: 'center',
        marginBottom: '5px'
    },
    listStyle: {
        border: '1px solid #9c9898',
        // paddingLeft: 0,
        // paddingRight: 0

    },
    deletebtn: {
        marginLeft: '10px',
        marginBottom: isMobileScreen() ? '5px' : '0px',
        float: 'right',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        }
    },
    paraStyle: {
        marginTop: '5px'
    },
    paraStyle2: {
        marginTop: '-7px'
    },

}));

//Table Columns
const tableColumns = [
    { id: "FullName", title: "Full Name", sort: false },
    { id: "MembershipNumber", title: "Membership Number", sort: false },
    // { id: "Price", title: "Price", sort: false },
    { id: "Action", title: "Action", sort: false },
];

const ChapterCertificationUGMemberConfirmation = (props) => {
    // console.log(props, "PROPSPROPS");
    const { enqueueSnackbar } = useSnackbar();
    const typingTimeoutRef = useRef(null);
    const { errorMessages = [], stepData = [], orgDetails = {}, handleCallback, documentTransactionId } = props
    const [memberList, setMemberList] = useState(stepData?.detail?.memberRoster || [])
    const classes = useStyles();
    const history = useHistory();
    const { addressInfo = {} } = orgDetails;
    const tableHeadProps = { tableColumns };
    const profile = localStorage.getItem(LOCALSTORAGE_PROFILE);
    const ProfileString = JSON.parse(profile);
    const { Details = {} } = ProfileString || {};
    const { Positions = [] } = Details || [];
    const [gpa, setGPA] = useState();
    const [GraduatedMembers, setGraduatedMembers] = useState([]);
    const [edit, setEdit] = useState(false)
    const [completeCount, setCompleteCount] = useState(0)
    const [MembersToUpsert, setMembersToUpsert] = useState(memberList.map(({ fullName, membershipNumber }) => ({ sourceName: membershipNumber, stringSourceValue: '' })));
    const [addedMembers, setAddedMembers] = useState([])

    useEffect(() => {
        memberList.forEach((member) => {
            if (member.confirmationValue !== "") {
                member.confirmationValue == "true" ? addAsGraduated(member.membershipNumber) : addGPA(member.confirmationValue, member.membershipNumber)
                // setCompleteCount((prevCompleteCount) => prevCompleteCount + 1);
            }
        });
    }, [memberList]);

    const editValues = () => {
        setEdit(true)
    }

    // console.log("SIZE ", addedMembers.length);
    const addAsGraduated = (member) => {
        MembersToUpsert.forEach(obj => {
            if (obj.sourceName == member) {
                obj.stringSourceValue = 'true';
            } else {
                obj.stringSourceValue = obj.stringSourceValue;
            }
        });
        if (!addedMembers.includes(member)) {
            addedMembers.push(member);
        }
        setGraduatedMembers([...GraduatedMembers, member])
        setCompleteCount((prevCompleteCount) => prevCompleteCount + 1);
    }

    const addGPA = async (gpa, member) => {
        if (gpa > 0 || gpa != 0) {
            await MembersToUpsert.forEach(obj => {
                if (obj.sourceName == member) {
                    obj.stringSourceValue = gpa;
                } else {
                    obj.stringSourceValue = obj.stringSourceValue;
                }
            });
            if (!addedMembers.includes(member)) {
                addedMembers.push(member);
            }
            setCompleteCount((prevCompleteCount) => prevCompleteCount + 1);
        } else {
            CustomAlertMessage("GPA Cannot be 0 or less", 'error', enqueueSnackbar);
        }
    }



    const upsertSettingState = async () => {
        let data = {
            componentName: stepData?.componentName,
            listValue: MembersToUpsert,
        }
        try {
            // setFormLoading(true)

            const options = {
                url: DOCUMENT_TRANSACTION_UPSERT_SETTINGSTATE,
                method: 'POST',
                params: {
                    DocumentTransactionID: parseInt(documentTransactionId),
                },
                data: data,
                headers: authHeader()
            };
            const { data: apiResponse } = await API(options)
            const { status } = apiResponse;
            if (status == 1) {
                CustomAlertMessage('Saved successfully!', 'success', enqueueSnackbar)

                handleCallback();
            }
        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                // console.log(error.response,'data message')
                CustomAlertMessage(error.response.statusText, 'error', enqueueSnackbar);
            }
        } finally {
            // setFormLoading(false)
        }

    }

    return (
        <Fragment>

            <Grid spacing={3} style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', }}>


                <div>
                    < Alert
                        severity={"info"}
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}
                    >
                        <AlertTitle>Please review each member below, provide confirmation of Graduation and Confirmation of GPA accordingly. When done please click the “Save” button to complete this step</AlertTitle>

                    </Alert >
                </div>
                <Grid container style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', }}>
                    <Grid item sm={12} md={4}>
                        <span>
                            <Typography><Box fontWeight='fontWeightMedium' display='inline'>Roster Id :</Box> {stepData?.detail?.organizationRoster?.rosterId}</Typography>
                        </span>
                        <span>
                            {/* <Typography><Box fontWeight='fontWeightMedium' display='inline'>Total Records :</Box> {completeCount}addedMembers.length of {memberList?.length} completed</Typography> */}
                            <Typography><Box fontWeight='fontWeightMedium' display='inline'>Total Records :</Box> {addedMembers.length} of {memberList?.length} completed</Typography>
                        </span>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <span>
                            <Typography><Box fontWeight='fontWeightMedium' display='inline'>Fiscal Start Date :</Box> {DateTimeFormat(stepData?.detail?.organizationRoster?.fiscalStartDate, 'YYYY-MM-DD')}</Typography>
                        </span>
                        <span>
                            <Typography><Box fontWeight='fontWeightMedium' display='inline'>Fiscal End Date :</Box> {DateTimeFormat(stepData?.detail?.organizationRoster?.fiscalEndDate, 'YYYY-MM-DD')}</Typography>
                        </span>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        {
                            stepData.completed == true && edit == false ?
                                <Button
                                    style={{ float: 'right', margin: '1rem 1rem', }}
                                    type="button"
                                    variant="contained"
                                    className={classes.continuebtn}
                                    onClick={() => { editValues() }}
                                >
                                    Edit Members Information
                                </Button>
                                :
                                <>
                                    {
                                        // completeCount == memberList.length || completeCount > memberList.length ?
                                        addedMembers.length == memberList.length ?
                                            <Button
                                                style={{ float: 'right', margin: '1rem 1rem', }}
                                                type="button"
                                                variant="contained"
                                                className={classes.continuebtn}
                                                onClick={() => { upsertSettingState() }}
                                            >
                                                Save Members Information
                                            </Button>
                                            :
                                            <Button
                                                style={{ float: 'right', margin: '1rem 1rem', }}
                                                type="button"
                                                variant="contained"
                                                className={classes.continuebtn}
                                                disabled
                                            >
                                                Save Members Information
                                            </Button>
                                    }
                                </>
                        }
                    </Grid>
                </Grid>

                <Grid container sm={12} md={12}>
                    {(isTabScreen() || isMobileScreen()) ?
                        <>
                            <List>
                                {memberList.map(({
                                    fullName = "",
                                    membershipNumber = "",
                                    statusID,
                                    confirmationValue = ''
                                }, index) =>
                                    <Fragment key={`tab_mob_list_${index}`} >
                                        <ListItem alignItems='flex-start'
                                            className={classes.listStyle}
                                        >
                                            <ListItemText
                                                secondary={
                                                    <Fragment>
                                                        <Grid container >
                                                            <Grid item sm={12}>
                                                                <Typography
                                                                    variant='caption'
                                                                    color='textPrimary'
                                                                >
                                                                    <p className={classes.paraStyle}> Full Name : <b>{fullName}</b></p>
                                                                </Typography>
                                                                <Typography
                                                                    variant='caption'
                                                                    color='textPrimary'
                                                                >
                                                                    <p className={classes.paraStyle}> Membership Number : <b>{membershipNumber}</b></p>
                                                                </Typography>
                                                                <Typography
                                                                    variant='caption'
                                                                    color='textPrimary'
                                                                >
                                                                    <p className={classes.paraStyle2}> Action : <br />{
                                                                        confirmationValue != "" ?
                                                                            <>
                                                                                {confirmationValue == "true" ?
                                                                                    <Fragment>
                                                                                        < Alert icon={<CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon>}
                                                                                            severity={"info"}
                                                                                            style={{ display: 'flex', flexDirection: 'row', height: '45px', alignItems: 'center' }}
                                                                                        >
                                                                                            <AlertTitle>Graduated</AlertTitle>
                                                                                        </Alert >
                                                                                    </Fragment> :
                                                                                    <Fragment>
                                                                                        <Typography style={{ display: 'flex', flexDirection: 'row', height: '45px', alignItems: 'center' }}>  GPA : {confirmationValue} </Typography>
                                                                                    </Fragment>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    (statusID == 1 || statusID == 8) &&
                                                                                    <Fragment>
                                                                                        <Grid item sm={12} >
                                                                                            <TextField
                                                                                                id="gpa-helper-text"
                                                                                                label="GPA"
                                                                                                type={'number'}
                                                                                                onChange={(e) => {
                                                                                                    addGPA(parseFloat(e.target.value), membershipNumber)
                                                                                                }}

                                                                                                placeholder='Ex. "2.3"'
                                                                                                // fullWidth
                                                                                                name='gpa'
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                            />
                                                                                        </Grid>
                                                                                    </Fragment>

                                                                                }
                                                                                {
                                                                                    (statusID == 5 || statusID == 7) && !GraduatedMembers.includes(membershipNumber) ?
                                                                                        < Fragment >
                                                                                            <Button
                                                                                                type="button"
                                                                                                variant="contained"
                                                                                                className={classes.continuebtn}
                                                                                                onClick={() => { addAsGraduated(membershipNumber) }}
                                                                                            >
                                                                                                {/* Confirm Graduated */}
                                                                                                Confirm Graduated or Removed From Chapter
                                                                                            </Button>

                                                                                        </Fragment>
                                                                                        :
                                                                                        (statusID == 5 || statusID == 7) && GraduatedMembers.includes(membershipNumber) ?
                                                                                            <Fragment>
                                                                                                <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon>
                                                                                            </Fragment>
                                                                                            : ''
                                                                                }
                                                                            </>
                                                                    }</p>
                                                                </Typography>

                                                            </Grid>
                                                        </Grid>
                                                    </Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="inset" component="li" style={{ marginLeft: 0 }} />
                                    </Fragment>
                                )}

                            </List>
                        </>
                        :
                        <Fragment>
                            <TableContainer component={Paper} className={classes.tablecontainer}>
                                <Table stickyHeader size='small' aria-label="sticky table">

                                    <CustomTableHeader {...tableHeadProps} />
                                    <TableBody>
                                        <Fragment >
                                            {

                                                memberList.map((row, key) => {
                                                    let {
                                                        fullName = "",
                                                        membershipNumber = "",
                                                        statusID,
                                                        confirmationValue = ''
                                                    } = row;
                                                    return (
                                                        <TableRow
                                                            className={classes.tableheaderstyle}
                                                            hover
                                                            key={`data_${key}`}
                                                            style={{ height: '45px' }}
                                                        >
                                                            <TableCell align="left" style={{ height: 'auto !important' }}>
                                                                {fullName}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ height: 'auto !important' }}>
                                                                {membershipNumber}
                                                            </TableCell>

                                                            <TableCell align="left" style={{ height: 'auto !important' }}>
                                                                {
                                                                    edit == false ?
                                                                        confirmationValue != "" ?
                                                                            <>
                                                                                {(confirmationValue == "true") ?
                                                                                    <Fragment>
                                                                                        < Alert icon={<CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon>}
                                                                                            severity={"info"}
                                                                                            style={{ display: 'flex', flexDirection: 'row', height: '45px', alignItems: 'center' }}
                                                                                        >
                                                                                            <AlertTitle>Graduated</AlertTitle> 
                                                                                        </Alert >
                                                                                    </Fragment>
                                                                                    :
                                                                                    <Fragment>
                                                                                        <Typography style={{ display: 'flex', flexDirection: 'row', height: '45px', alignItems: 'center' }}>  {confirmationValue} </Typography>
                                                                                    </Fragment>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    ((statusID == 1 || statusID == 8)) &&
                                                                                    <Fragment>
                                                                                        <Grid item sm={12} >
                                                                                            <TextField
                                                                                                id="gpa-helper-text"
                                                                                                label="GPA"
                                                                                                type={'number'}
                                                                                                // value={confirmationValue}
                                                                                                onChange={(e) => {
                                                                                                    // const newValue = e.target.value;
                                                                                                    // const updatedMemberList = memberList.map((member, i) => {
                                                                                                    //     if (i === key) {
                                                                                                    //         return { ...member, confirmationValue: newValue };
                                                                                                    //     }
                                                                                                    //     return member;
                                                                                                    // });
                                                                                                    // setMemberList(updatedMemberList);
                                                                                                    addGPA(parseFloat(e.target.value) || 0, membershipNumber)
                                                                                                }}
                                                                                                placeholder='Ex. "2.3"'
                                                                                                // fullWidth
                                                                                                name='gpa'
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                            />
                                                                                        </Grid>
                                                                                    </Fragment>
                                                                                }
                                                                                {
                                                                                    ((statusID == 5 || statusID == 7) && !GraduatedMembers.includes(membershipNumber)) ?
                                                                                        < Fragment >
                                                                                            <Button
                                                                                                type="button"
                                                                                                variant="contained"
                                                                                                className={classes.continuebtn}
                                                                                                onClick={() => { addAsGraduated(membershipNumber) }}
                                                                                            >
                                                                                                Confirm Graduated or Removed From Chapter
                                                                                            </Button>
                                                                                        </Fragment>
                                                                                        :
                                                                                        ((statusID == 5 || statusID == 7) && GraduatedMembers.includes(membershipNumber)) ?
                                                                                            <Fragment>
                                                                                                <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon>
                                                                                            </Fragment>
                                                                                            : ''
                                                                                }
                                                                            </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                ((statusID == 1 || statusID == 8)) &&
                                                                                <Fragment>
                                                                                    <Grid item sm={12} >
                                                                                        <TextField
                                                                                            id="gpa-helper-text"
                                                                                            label="GPA"
                                                                                            type={'number'}
                                                                                            value={confirmationValue}
                                                                                            onChange={(e) => {
                                                                                                const newValue = e.target.value;
                                                                                                const updatedMemberList = memberList.map((member, i) => {
                                                                                                    if (i === key) {
                                                                                                        return { ...member, confirmationValue: newValue };
                                                                                                    }
                                                                                                    return member;
                                                                                                });
                                                                                                setMemberList(updatedMemberList);
                                                                                                addGPA(parseFloat(e.target.value) || 0, membershipNumber)
                                                                                            }}
                                                                                            placeholder='Ex. "2.3"'
                                                                                            // fullWidth
                                                                                            name='gpa'
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </Grid>
                                                                                </Fragment>
                                                                            }
                                                                            {
                                                                                ((statusID == 5 || statusID == 7) && !GraduatedMembers.includes(membershipNumber)) &&
                                                                                < Fragment >
                                                                                    <Button
                                                                                        type="button"
                                                                                        variant="contained"
                                                                                        className={classes.continuebtn}
                                                                                        onClick={() => { addAsGraduated(membershipNumber) }}
                                                                                    >
                                                                                        Confirm Graduated or Removed From Chapter
                                                                                    </Button>
                                                                                </Fragment>

                                                                            }
                                                                        </>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Fragment>
                    }
                </Grid>
            </Grid >
        </Fragment >
    )
}

export default ChapterCertificationUGMemberConfirmation