import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Typography } from '@material-ui/core';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const useStyles = makeStyles((theme) => ({
    imgStyle: {
        width: 800,
        height: 450,
        //   border:'1px solid', 
        objectFit: 'contain'
    }
}));

const styles = (theme) => ({
    dialogRoot: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute !important',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.dialogRoot} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function CommonPreviewDocumentDialog(props) {
    const classes = useStyles();
    const { dialogFlag, handleClose, previewData = {} } = props;
    const { url = '', extension = '' } = previewData;
    const documents = [
        { uri: url, fileType: 'doc' },
    ];

    // console.info(url, 'view pdf', documents);

    return (
        <Fragment>
            <Dialog disableBackdropClick disableEscapeKeyDown fullWidth={true} maxWidth={'md'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={dialogFlag}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Preview Document
                </DialogTitle>
                <DialogContent dividers style={{ height: 'auto' }}>
                    <div>
                        {
                            (url.endsWith(".jpg") || url.endsWith(".png")) ?
                                <center><img src={url} alt="Document" className={classes.imgStyle} /></center>
                                : url.endsWith(".pdf") || extension == 'pdf' ?
                                    <iframe src={url} width="100%" height="500px" title="Document Preview">   </iframe>
                                    : <DocViewer pluginRenderers={DocViewerRenderers} documents={documents} style={{ width: "100%", height: "500px" }} />
                            // (['jpg', 'png'].includes(extension)) ?
                            //     <center><img src={url} alt="Document" className={classes.imgStyle} /></center>
                            //     : <iframe src={url} width="100%" height="500px" title="Document Preview" >   </iframe>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}