// MemberPendingRosterEntriesReducer
import { initialGetMemberPendingRosterEntries } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function MemberPendingRosterEntriesReducer(state = initialGetMemberPendingRosterEntries, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_MEMBER_PENDING_ROSTER_ENTRIES_LOADING:
            return { payload, loading: true };
        case types.GET_MEMBER_PENDING_ROSTER_ENTRIES_SUCCESS:
            return { data: [...payload], loading: false };
        case types.GET_MEMBER_PENDING_ROSTER_ENTRIES_ERROR:
            return { payload, loading: false };
        default:
            return state;
    }
}


