import { initialMakePaymentFormValues,makePaymentInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function MakePaymentReducer(state = initialMakePaymentFormValues,action){
    const {type , payload} = action;
    switch (type) {
        case types.MAKE_PAYMENT_GOTO_STEP:
            return {
                ...state,
                currentStep:payload
            };  
        case types.SET_SELECTED_PLAN:
            return {
                ...state,
                ...payload
            };  
        case types.RESET_MAKE_PAYMENT_FORM:
            return {
                ...initialMakePaymentFormValues
            } 
        case types.RESET_PAYMENT_DETAILS:
            return {
                ...state,
                ...makePaymentInitialValues
            }          
        default:
            return state
    }
}