import React, { Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { useSelector } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import { BANK_ACCOUNT_TYPE, CREDIT_CARD_TYPE, PaymentMethodsCardIcon } from '../../constants/HelperConstants';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  paymentRadioBtnStyle: {
    // float: 'right',
    // right: '-1rem',
    marginTop: '8px'
  }
}));

// Custom Checkbox
const CustomRadio = withStyles(theme => ({
  root: {
    color: theme.primaryColor,
    '&$checked': {
      color: theme.primaryColor,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

export default function CommonPaymentMethodsList(props) {
  const { selectedPaymentMethod, handleSelectPaymentMethod, deletePaymentMethod, showPaymentMethodCreateTypes } = props;
  const classes = useStyles();
  const { payload: paymentMethods = {} } = useSelector((state) => state.GetPaymentMethodsReducer);
  const { achPays = [], ccPas = [] } = paymentMethods;

  return (
    <div className={classes.root}>

      {/* Bank Payment Methods  */}

      <List dense={true}>
        {(showPaymentMethodCreateTypes.includes(BANK_ACCOUNT_TYPE) && achPays && achPays.length) ?
          <Grid container spacing={3}>
            {achPays.map(({ bankAccountNumber, bankName, achpayId }, index) =>
              <Fragment key={`pay_list_${index}`}>
                <Grid item xs={1}>
                  <CustomRadio
                    data-payment_type={1}
                    className={classes.paymentRadioBtnStyle}
                    checked={parseInt(selectedPaymentMethod) === parseInt(achpayId)}
                    onChange={() => handleSelectPaymentMethod(achpayId, 1)}
                    value={achpayId}
                    name="selectedPaymentMethod"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <ListItem key={`bank_list_${index}`}>
                    <ListItemAvatar>
                      <Avatar>
                        <Icon className={"fas fa-university"} style={{ width: '100%', marginLeft: '16px', marginTop: '-1px' }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`Account Number : ****${bankAccountNumber}`}
                      secondary={`Bank Name : ${bankName}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete"
                        data-pay_rec_id={achpayId} data-payment_type={1}
                        onClick={deletePaymentMethod}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Grid>
              </Fragment>
            )}
          </Grid>
          : ''
        }

        {/* Bank Payment Methods  */}

        {/* Card Payment Methods  */}
        {(showPaymentMethodCreateTypes.includes(CREDIT_CARD_TYPE) && ccPas && ccPas.length) ?
          <Grid container spacing={1}>
            {ccPas.map(({ cardType = '', expirationDate, cardNumber, ccpayId }, index) => {
              // console.log(cardType, "cardType")
              return (
                <Fragment key={`pay_list_${index}`}>
                  <Grid item xs={1}>
                    <CustomRadio
                      data-payment_type={2}
                      className={classes.paymentRadioBtnStyle}
                      checked={parseInt(selectedPaymentMethod) === parseInt(ccpayId)}
                      onChange={() => handleSelectPaymentMethod(ccpayId, 2)}
                      value={ccpayId}
                      name="selectedPaymentMethod"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <ListItem >
                      <ListItemAvatar>
                        <Avatar style={{ color: '#000' }}>
                          <Icon className={cardType !== '' && PaymentMethodsCardIcon[cardType] !== undefined ? PaymentMethodsCardIcon[cardType].icon : ''} style={{ width: '100%', marginLeft: '12px' }} />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`Card Number : ****${cardNumber}`}
                        secondary={`Expires ${expirationDate.substring(4, 6)}/${expirationDate.substring(0, 4)}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete"
                          data-pay_rec_id={ccpayId} data-payment_type={2}
                          onClick={deletePaymentMethod}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Grid>
                </Fragment>
              )
            })}
          </Grid>
          : ''
        }
        {/* Card Payment Methods  */}
      </List>

    </div>
  );
}