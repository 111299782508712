import { commonListingIArraynitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetSignerPositionsReducer(state = commonListingIArraynitialValues,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_SIGNER_POSITIONS_LOADING:
            return {...state,loading:true,error:false};   

        case types.GET_SIGNER_POSITIONS_SUCCESS:
            return {...state,payload,loading:false,error:false}; 
            
        case types.GET_SIGNER_POSITIONS_ERROR:
            return {...state,loading:false,error:true};  
        default:
            return state
    }
}