import { objectInitialschool } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function SchoolLoggedUserDetailsReducer(state = objectInitialschool, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.SCHOOL_LOGGED_USER_DETAILS_LOADING:
            return {payload,loading:true};
        case types.SCHOOL_LOGGED_USER_DETAILS_SUCCESS:
            return {payload,loading:false};
        case types.SCHOOL_LOGGED_USER_DETAILS_ERROR:
            return {payload,loading:false};
        default:
            return state;
    }
}

