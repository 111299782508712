// API URL Base URL
const API_ENV = {
  local: process.env.REACT_APP_LOCAL_API_URL,
  staging: process.env.REACT_APP_STAGING_API_URL,
  prod: process.env.REACT_APP_PRODUCTION_API_URL,
};
export const API_URL = API_ENV[process.env.REACT_APP_ENVIRONMENT];
export const CONFLUENCE_URL = process.env.REACT_APP_CONFLUENCE_URL;

//Auth
export const GET_LOGIN_API = API_URL + 'api/auth/Login';
export const GET_LOGIN_SSO_API = API_URL + 'api/auth/LoginSSO';
export const POST_SENDVERIFICATIONCODE_API = API_URL + 'c/auth/SendVerificationCode';
export const POST_VALIDATEVERIFICATIONCODE_API = API_URL + 'api/auth/ValidateVerificationCode';
//Signup
export const SIGNUP_MEMBER_URL = API_URL + 'api/Auth/SignupMember';
export const CREATE_MEMBER_URL = API_URL + 'api/SignUp/CreateMember';
//Forgot Password
export const SEND_OTP_CODE_URL = API_URL + 'api/Auth/VerifyEmailWithOTP';
export const VERIFY_OTP_CODE_URL = API_URL + 'api/Auth/ValidateVerificationCode';
export const UPDATE_PASSWORD_URL = API_URL + 'api/Auth/ChangePasswordDirect';
//Sidebar
export const GET_SIDEBAR_MENU_LIST = API_URL + 'api/Member/GetSideBar';
//SignUp
export const POST_MEMBERLOOKUP = API_URL + 'api/signup/MemberLookup';
//Organization
export const GET_ORGANIZATIONCONFIG_API = API_URL + 'api/organization/GetOrganizationConfigs?';
export const GET_ORGANIZATION_DETAIL_FOR_PROSPECT_API = API_URL + 'api/Organization/GetOrganizationDetailForProspectLogin';
export const GET_SIMPLE_ORGANIZATION_API = API_URL + 'api/organization/GetSimpleOrganizations?';
export const GET_ORGANIZATIONS_API = API_URL + 'api/Organization/GetOrganizations?';
export const GET_ORGANIZATION_DETAILS_API = API_URL + 'api/Organization/GetDetail?';
export const GET_REGIONAL_ROSTER_DETAILS_API = API_URL + 'api/Organization/GetRegionalRosterDetail';
export const GET_MEMBERSHIP_DUES_API = API_URL + 'api/OfficerReport/GetMembershipDuesStep';
export const VALIDATE_ORGANIZATION_PASSWORD_API = API_URL + 'api/Organization/ValidateOrganizationPassword';

export const GET_ORGANIZATION_FILTERS_API = API_URL + 'api/Organization/GetOrganizationFilters';
export const UPSERT_ORGANIZATION_API = API_URL + 'api/Organization/UpsertOrganization';
export const UPSERT_MIN_ORGANIZATION_DETAIL_URL = API_URL + 'api/Organization/UpsertMinOrganizationDetail';

//Misc
export const GET_ALLCOUNTRIES_TIMESZONES_API = API_URL + 'api/misc/GetCountriesAndTimezone';
export const GET_COUNTRY_STATE_LIST = API_URL + 'api/Misc/GetGeoInfo';
export const GET_RECOMMENDED_CHAPTERS_LIST = API_URL + 'api/Organization/GetRecommendedChapters';
export const GET_FILTERED_CHAPTERS_LIST = API_URL + 'api/TypeAhead/GetChapters';
export const GET_FILTERED_MEMBERS_LIST = API_URL + 'api/TypeAhead/FindMember';
export const GET_PAYMENT_METHODS_URL = API_URL + 'api/Payment/GetPaymentMethods';
export const CREATE_NEW_PAYMENT_METHOD_URL = API_URL + 'api/Payment/AddPaymentMethod';
export const DELETE_PAYMENT_METHOD = API_URL + 'api/Payment/DeletePaymentMethod';
export const CANCEL_REFUND_PAYMENT_URL = API_URL + 'api/Payment/CancelRefund';
export const GET_DIVISIONS_URL = API_URL + 'api/Misc/GetDivisions';
export const GET_NEGOTIATE_URL = API_URL + 'api/Misc/Negotiate';

//Member
export const GET_MEMBER_PROFILE = API_URL + 'api/Member/GetMemberProfile';
export const GET_MEMBER_PROFILE_DETAILS_URL = API_URL + 'api/Member/GetMemberProfileDetails';
export const GET_MEMBER_DASBOARD_URL = API_URL + 'api/member/GetMemberDashboard';
export const GET_MEMBER_TIMELINE_URL = API_URL + 'api/Post/GetPostMessagesByMember';

export const GET_CANDIDATE_PROFILE_DETAILS_URL = API_URL + 'api/Candidate/GetByID';
export const UPSERT_MEMBER_PROFILE = API_URL + 'api/Member/UpsertMemberProfile';
export const GET_MEMBER_ACCESS_LEVEL_URL = API_URL + 'api/Member/GetMemberAccessLevel';
export const GET_MEMBER_ORGANIZATION_PROFILE_URL = API_URL + 'api/Organization/GetMemberOrganizationProfile';
export const GET_MEMBER_BY_EMAIL_URL = API_URL + 'api/Member/GetMembersByEmailList';
export const GET_MEMBER_PENDING_ROSTER_ENTRIES_URL = API_URL + 'api/Member/GetMemberPendingRosterEntries';

//Auth User
export const CHANGE_MEMBER_PASSWORD_URL = API_URL + 'api/Member/ChangePassword';
//Payment Plan
export const MEMBER_PAYMENT_PLAN_DETAILS_URL = API_URL + 'api/PaymentPlan/GetPaymentPlanDetails';
export const GET_PAYMENT_PLAN_DETAIL_URL = API_URL + 'api/PaymentPlan/GetPaymentPlanDetail';
export const PROCESS_PAYMENT_URL = API_URL + 'api/Payment/ProcessPayment';
export const UPDATE_MEMBER_ORGANIZATION_URL = API_URL + 'api/Member/UpdateMemberOrganization';
export const REQUEST_TO_JOIN_CHAPTER_URL = API_URL + 'api/Member/RequestToJoinChapter';
export const CANCEL_PAYMENT_PLAN_URL = API_URL + 'api/PaymentPlan/CancelPaymentPlan';

//Payment Plan Terms
export const GET_ADMIN_PAYMENT_PLAN_TERMS = API_URL + 'api/PaymentPlan/GetAdminPaymentPlanTerms';
//Account Summary
export const GET_ACCOUNT_SUMMARY_URL = API_URL + 'api/Payment/GetAccountSummary';
//Account Summary Transactions
export const GET_ACCOUNT_SUMMARY_TRANACTIONS_URL = API_URL + 'api/Payment/getAccountSummaryTransactions';
//Withdraw
export const WITHDRAW_AMOUNT_URL = API_URL + 'api/Payment/WithdrawFunds';
//Add Funds
export const ADD_FUNDS_URL = API_URL + 'api/Payment/AddFunds';
export const REFUND_PURCHASE_ORDER_STEP_URL = API_URL + 'api/Payment/RefundPurchaseOrderSteps';

//Purchase Order Details
export const GET_PURCHASE_ORDER_DETAILS_URL = API_URL + 'api/Payment/GetPurchaseOrderDetails';

//Payment Plan Admin List
export const GET_PAYMENT_PLAN_ADMIN_LIST_URL = API_URL + 'api/PaymentPlan/GetAllAdmin';
//Admin Dashboard
export const GET_ADMIN_DASHBOARD_URL = API_URL + 'api/Dashboard/GetAdminDashboard';
//Finance Accounts
export const GET_FINANCIAL_ACCOUNTS_URL = API_URL + 'api/Payment/GetFinancialAccounts';
//Organizarion By Group Relation
export const GET_ORGANIZATION_BY_GROUP_RELATION_URL = API_URL + 'api/Organization/GetOrganizationsByGroupRelation'
export const GET_CALENDEREVENT_BY_ORGANIZATIONID_URL = API_URL + 'api/Event/GetCalendarEventsByOrganizationId'
export const DELETE_PLANNING_DOCUMENT_EVENT_URL = API_URL + 'api/Event/DeletePlanningDocumentEvent'


export const GET_CANDIDATE_SCHOOL_ELIGIBILITY_URL = API_URL + 'api/Candidate/SetSchoolElgibility'

//Get Products by organization
export const GET_PRODUCTS_BY_ORGANIZATION_URL = API_URL + 'api/Product/GetAllAdmin';
//Get Product
export const GET_PRODUCT_URL = API_URL + 'api/Product/Get';
//Upsert Product
export const UPSERT_PRODUCT_URL = API_URL + 'api/Product/Upsert';
//Upsert Product
export const DELETE_PRODUCT_URL = API_URL + 'api/Product/Delete';
export const GET_REFUND_FEE_URL = API_URL + 'api/Product/GetRefundFee';

//Upload Document
export const UPLOAD_DOCUMENT_URL = API_URL + 'api/Document/UploadDocument';
//ReUpload Document
export const REUPLOAD_DOCUMENT_URL = API_URL + 'api/Document/UpdateDocumentWithAmazon';
//Upload Document Form Data
export const UPLOAD_DOCUMENT_FORM_DATA_URL = API_URL + 'api/Document/UploadDocumentFormData';
//Remove Document
export const REMOVE_DOCUMENT_URL = API_URL + 'api/Document/Delete';
//Possible Product Groups
export const GET_POSSIBLE_PRODUCT_GROUP_URL = API_URL + 'api/Product/GetPossibleProductGroups';
//Product Types
export const GET_PRODUCT_TYPES_URL = API_URL + 'api/Product/GetProductTypes';
//Document Detail
export const GET_DOCUMENT_DETAIL_URL = API_URL + 'api/DocumentForm/GetDocumentFormDetailTransaction';
export const GET_DOCUMENT_FORMBYENTITY_URL = API_URL + 'api/DocumentForm/GetDocumentFormByEntity';
//GetDuesProductSummary
export const GET_DUES_PRODUCTSUMMARY_URL = API_URL + 'api/Product/GetDuesProductSummary';
//Members List
export const GET_MEMBERS_LIST_URL = API_URL + 'api/Member/GetMembers';
export const GET_HARD_LIST_URL = API_URL + 'api/Member/GetHardList';
export const ADD_HARD_LIST_ENTRY_URL = API_URL + 'api/Member/AddHardListEntry';
export const GET_ADMIN_DASHBOARD_QUICKLINKS_URL = API_URL + 'api/Member/GetQuickLinks';
export const GET_ADMIN_DASHBOARD_TABS_URL = API_URL + 'api/Member/GetDashboardTabs';
export const GET_FONTEVA_MEMBER_INFORMATION = API_URL + 'api/Member/GetFontevaMemberInformation';

//Candidate List
export const GET_CANDIDATE_LIST_URL = API_URL + 'api/Candidate/Get';
export const UPSERT_CANDIDATE_PERSIONAL_INFORMATION_URL = API_URL + 'api/Candidate/UpsertApplicationPersonalInformation';
export const UPSERT_APPLICATION_BIO_URL = API_URL + 'api/Candidate/UpsertApplicationBio';
export const UPSERT_APPLICATION_REASONNONUNDERGRAD_URL = API_URL + 'api/Candidate/UpsertApplicationReasonNonUndergrad';
export const ADD_APPLICATION_EDUCATION_URL = API_URL + 'api/Candidate/AddApplicationEducation';
export const ADD_APPLICATION_EMPLOYMENT_URL = API_URL + 'api/Candidate/AddApplicationEmployment';
export const DELETE_APPLICATION_EDUCATION_URL = API_URL + 'api/Candidate/DeleteApplicationEducation';
export const DELETE_APPLICATION_EMPLOYMENT_URL = API_URL + 'api/Candidate/DeleteApplicationEmployment';
export const ADD_GREEK_RELATION_URL = API_URL + 'api/Candidate/AddGreekRelationship';
export const ADD_REFERENCE_LETTER_URL = API_URL + 'api/Candidate/AddReference';
export const DELETE_APPLICATION_GREEK_RELATION_URL = API_URL + 'api/Candidate/DeleteGreekRelationship';
export const ADD_APPLICATION_CLASSIFICATION_URL = API_URL + 'api/Candidate/UpsertApplicationClassification';
export const DELETE_REFERENCE_URL = API_URL + 'api/Candidate/DeleteReference';
export const GET_INTAKEFEE_PURCHASE_ORDER_URL = API_URL + 'api/Candidate/GetIntakeFeePurchaseOrder';

export const GET_REFERENCE_LETTER_LIST_URL = API_URL + 'api/Member/GetMemberReferenceLetters';
export const UPSERT_REFERENCE_STATUS_URL = API_URL + 'api/Member/UpdateReferenceStatusAdvanced';
export const UPSERT_MEMBER_DETAILS_URL = API_URL + 'api/Member/UpsertMinMemberDetail';

//School List
export const GET_SCHOOL_LIST_URL = API_URL + 'api/School/GetAll';
export const UPSERT_SCHOOL_URL = API_URL + 'api/School/UpsertSchool';

//School List by organizationid
export const GET_SCHOOL_LIST_BY_ORGID_URL = API_URL + 'api/School/GetSchoolsByOrganization';
export const UPSERT_COLLEGE_OFFICIAL_VERIFICATION = API_URL + 'api/School/UpsertCollegeOfficialVerification';
export const GET_COLLEGE_OFFICIAL_SCHOOL_HISTORY = API_URL + 'api/School/GetCollegeOfficialSchoolHistory';

//School Details
export const GET_SCHOOL_PROFILE_DETAILS_URL = API_URL + 'api/School/Get';

//delete college official recoed
export const DELETE_COLLEGE_OFFICIAL_ENTITY_URL = API_URL + 'api/School/DeleteEntity';
export const DELETE_COLLEGE_OFFICIAL_URL = API_URL + 'api/School/DeleteCollegeOfficial';
export const UPSERT_COLLEGE_OFFICIAL_API = API_URL + 'api/School/UpsertCollegeOfficial';
export const ADDENTITY_COLLEGE_OFFICIAL_API = API_URL + 'api/School/AddEntity';

//college official Details
export const GET_COLLEGE_OFFICIAL_DETAILS_URL = API_URL + 'api/School/GetCollegeOfficial';
//college official List
export const GET_COLLEGE_OFFICIAL_LIST_URL = API_URL + 'api/School/GetAllCollegeOfficials';


//Leadership Organizations List
export const GET_LEADERSHIP_ORGANIZATIONS_LIST_URL = API_URL + 'api/Organization/GetLeadershipOrganizations';
//Get Member Filter Options
export const GET_MEMBER_FILTER_OPTIONS_URL = API_URL + 'api/Member/GetMemberFilterOptions';
//Get Member Candidate Options
export const GET_CANDIDATE_FILTER_OPTIONS_URL = API_URL + 'api/Candidate/GetCandidateFilters';

export const GET_INVITE_MEMBER_URL = API_URL + 'api/Invitations/InviteMember';

//Get Regions
export const GET_REGIONS_LIST_URL = API_URL + 'api/Organization/GetAllRegionDetails';

//events
export const GET_EVENT_FILTER_OPTIONS_URL = API_URL + 'api/Event/GetEventFilters';
export const UPSERT_EVENT_API = API_URL + 'api/Event/Upsert';
export const GET_EVENT_CHECKOUT_DETAILS_API = API_URL + 'api/Event/GetCalendarEventForCheckout';
export const UPSERT_CLUSTER_EVENT_API = API_URL + 'api/Event/UpsertClusterEvent';
export const UPSERT_PLANNING_DOCUMENT_EVENT_API = API_URL + 'api/Event/UpsertPlanningDocumentEvent';
export const PLANNING_DOCUMENT_CHECKIN_API = API_URL + 'api/PlanningDocument/CheckIn';
export const SEND_EVENT_INSURANCE_DOCUMENT_EMAIL_URL = API_URL + 'api/Event/SendEventInsuranceDocumentEmail';

//Set Primary Payment Methos
export const SET_PRIMARY_PAYMENT_METHOD_URL = API_URL + 'api/Payment/SetPrimaryPaymentMethod';
//Update Primary Payment Methos
export const UPDATE_PAYMENT_PLAN_PAYMENT_METHOD = API_URL + 'api/PaymentPlan/UpdatePaymentPlanPaymentMethod';
// RPT Payment Details
export const GET_RPT_PAYMENT_DETAILS_URL = API_URL + 'api/PaymentPlan/RPTPaymentDetails';
// RPT Auto Debit Failure Details
export const GET_AUTO_DEBIT_LOGS_URL = API_URL + 'api/PaymentPlan/RPTAutoDebitFailureDetails';
//Get Upcoming Auto Debits
export const GET_UPCOMING_AUTO_DEBITS_URL = API_URL + 'api/PaymentPlan/GetUpcomingAutoDebits';
//Member Managed Entities
export const GET_MEMBER_MANAGED_ENTITIES_URL = API_URL + 'api/Member/GetMemberManagedEntities';
//Entity Exam Transactions
export const GET_ENTITY_EXAM_TRANSACTIONS_URL = API_URL + 'api/QuestionBuilder/GetEntityExamTransactions';
// Administrative groups
export const GET_ADMINISTRATIVE_GROUPS_URL = API_URL + 'api/Admin/GetAdministrativeGroups';
export const GET_ACCESS_LIST_URL = API_URL + 'api/Admin/GetAccessList?';
export const ADD_MEMBER_TO_GROUP_URL = API_URL + 'api/Admin/AddMemberToGroup';
export const DELETE_MEMBER_FROM_GROUP_URL = API_URL + 'api/Admin/DeleteMemberFromGroup?';
export const ADD_POSITION_TO_GROUP_URL = API_URL + 'api/Admin/AddPositionToGroup';
export const DELETE_POSITION_FROM_GROUP_URL = API_URL + 'api/Admin/DeletePositionFromGroup?';

//Exam Transaction Result
export const UPSERT_EXAM_TRANSACTION_RESULTS_URL = API_URL + 'api/QuestionBuilder/UpsertExamTransactionResults';
//Exam Section Questions
export const GET_EXAM_SECTION_QUESTIONS_URL = API_URL + 'api/QuestionBuilder/GetExamSectionQuestions';
//Complete Exam Transaction
export const COMPLETE_EXAM_TRANSACTION_URL = API_URL + 'api/QuestionBuilder/CompleteExamTransaction';

//Xref Types
export const GET_XREF_TYPES_URL = API_URL + 'api/Organization/GetXrefTypes';
export const UPSERT_XREF_VALUE_URL = API_URL + 'api/Organization/UpsertXrefValue';

//Setting Configs
export const GET_SETTING_CONFIGS_URL = API_URL + 'api/Organization/GetOrganizationConfigsAll';
export const UPSERT_SETTING_CONFIG_URL = API_URL + 'api/Organization/UpsertOrganizationSettingConfig';

//Chapter
export const DELETE_CHAPTER_URL = API_URL + 'api/Organization/RemoveFromChapter';
//Course By Current Season
export const GET_COURSES_BY_CURRENT_SEASON_URL = API_URL + 'api/QuestionBuilder/GetCoursesByCurrentSeason';
export const GET_COURSES_URL = API_URL + 'api/QuestionBuilder/GetCourses';
export const GET_Exams_BY_COURSE_URL = API_URL + 'api/QuestionBuilder/GetExamsByCourse';


//Officer Report
export const GET_OFFICER_REPORT_STATUS_URL = API_URL + 'api/OfficerReport/OfficerReportStatus';
export const GET_OFFICER_REPORT_FILTER_OPTIONS_URL = API_URL + 'api/OfficerReport/GetFilterOptions';
export const START_OFFICER_REPORT_URL = API_URL + 'api/OfficerReport/StartOfficerReport';
export const ADD_CHAPTER_MEETING_LOCATION_URL = API_URL + 'api/OfficerReport/AddChapterMeetingLocation';
export const ADD_EXTERNAL_ENTITY_GOVERNANCE_URL = API_URL + 'api/OfficerReport/AddExternalEntityGovernance';
export const DELETE_EXTERNAL_ENTITY_GOVERNANCE_URL = API_URL + 'api/OfficerReport/DeleteExternalEntityGovernance';
export const SET_STEP_STATUS_URL = API_URL + 'api/OfficerReport/SetStepStatus';
export const OVERRIDE_OFFICER_REPORT_STEP_URL = API_URL + 'api/OfficerReport/OverrideOfficerReportStep';
export const ADD_MEMBER_TO_ROSTER_REPORT = API_URL + 'api/OfficerReport/AddMemberToRosterReport';
export const QUICK_DUES_UPSERT = API_URL + 'api/Product/QuickDuesUpsert';
export const UPSERT_POST_MESSAGE_API = API_URL + 'api/Post/UpsertPostMessage';
export const GET_FINANCIAL_STATUS_URL = API_URL + 'api/Member/GetFinancialStatus';
export const SET_ALL_OFFICER_REPORT_POSITIONS = API_URL + 'api/OfficerReport/SetAllOfficerReportPositions';
export const MEMBER_FROMROSTER_REPORT_URL = API_URL + 'api/OfficerReport/RemoveMemberFromRosterReport';
export const CREATE_ROSTER_REPORT_URL = API_URL + 'api/OfficerReport/CreateNewRoster';
export const GET_SET_OFFICER_REPORT_STEP = API_URL + 'api/OfficerReport/GetSetOfficerReportStep';
export const CLEAR_ALL_OFFICER_REPORT_POSITIONS = API_URL + 'api/OfficerReport/ClearAllOfficerReportPositions'
//dues
export const CHAPTER_DUES_SUMMARY_URL = API_URL + 'api/Dues/GetChapterDuesSummary';
export const UPCOMING_FISCAL_YEAR_DETAIL_URL = API_URL + 'api/Organization/GetUpcomingFiscalYearDetails';

export const GET_ROSTER_REPORT_STATUS_URL = API_URL + 'api/Roster/RosterReportStatus';
export const ROSTER_REPORT_SET_STEP_STATUS_URL = API_URL + 'api/Roster/SetStepStatus';
export const ROSTER_REPORT_SUBMIT_APPROVE_URL = API_URL + 'api/Roster/SubmitApprove';


export const ADDFAVORITE_POST_MESSAGE_API = API_URL + 'api/Post/AddFavoritePostMessage';
export const DELETEFAVORITE_POST_MESSAGE_API = API_URL + 'api/Post/DeleteFavoritePostMessage';
//Remove postid
export const REMOVE_POST_MESSAGE_URL = API_URL + 'api/Post/DeletePostMessage';
//Get My Documents
export const GET_MY_DOCUMENTS_URL = API_URL + 'api/DocumentSignApproval/GetSignQueue';
//Position LIST
export const GET_POSTIONS_URL = API_URL + 'api/Position/GetAllPositions';
export const GET_POSTION_TYPES_URL = API_URL + 'api/DocumentSignApproval/GetAllPositionTypes';

export const SET_CHAPTER_POSITIONS_URL = API_URL + 'api/Position/SetChapterPosition';
export const REMOVE_CHAPTER_POSITIONS_URL = API_URL + 'api/Position/RemoveChapterPosition';

//planning Document event LIST
export const GET_PLANNING_DOCUMENT_EVENT_LIST_URL = API_URL + 'api/PlanningDocument/GetOrganizationEventTypes';
export const UPSERT_CANDIDATE_PORTAL_PASSWORD_URL = API_URL + 'api/PlanningDocument/UpsertCandidatePortalPassword';
export const SET_CLUSTER_EVENT_API = API_URL + 'api/PlanningDocument/SetClusterEvent';

//Document Forms
export const GET_DOCUMENT_FORMS_URL = API_URL + 'api/DocumentForm/GetList';
//Document Forms By Type
export const GET_DOCUMENT_FORMS_BY_TYPE_URL = API_URL + 'api/DocumentForm/GetDocumentFormsByType';
//Approval steps
export const GET_APPROVAL_STEPS_URL = API_URL + 'api/DocumentSignApproval/getApprovalSteps';
//Approval step status
export const GET_APPROVAL_STEP_STATUS_LIST_URL = API_URL + 'api/DocumentSignApproval/GetApprovalStepStatusList';
//Document Form Types
export const GET_DOCUMENT_FORM_TYPES_URL = API_URL + 'api/DocumentForm/GetDocumentFormTypes';
//Document Form Filter
export const GET_DOCUMENT_FORM_FILTER_URL = API_URL + 'api/DocumentForm/GetDocumentFormFilters';
//Document Form Upsert
export const UPSERT_DOCUMENT_FORM_API = API_URL + 'api/DocumentForm/UpsertDocumentForm';
//Upsert Entity SelectionEntry
export const UPSERT_ENTITY_SELECTION_ENTRY = API_URL + 'api/DocumentForm/UpsertEntitySelectionEntry';
//Get Entity SelectionEntries
export const GET_ENTITY_SELECTION_ENTRIES = API_URL + 'api/DocumentForm/GetEntitySelectionEntries';
//Delete Entity Selection Entry
export const DELETE_ENTITY_SELECTION_ENTRY = API_URL + 'api/DocumentForm/DeleteEntitySelectionEntry';
//Submit Entity Selection
export const SUBMIT_ENTITY_SELECTION = API_URL + 'api/DocumentForm/SubmitEntitySelection';
//Get Entity Selections
export const GET_ENTITY_SELECTIONS = API_URL + 'api/DocumentForm/GetEntitySelections';

//signer positions
export const GET_SIGNER_POSITIONS_URL = API_URL + 'api/DocumentSignApproval/GetApprovalStepPositions';

//Delete Sign Queue By Document Transaction
export const DELETE_SIGN_QUEUE_BY_DOCUMENT_TRANSACTION = API_URL + 'api/DocumentSignApproval/DeleteSignQueueByDocumentTransaction';

//signer positionTypes
export const GET_SIGNER_POSITIONTYPES_URL = API_URL + 'api/DocumentSignApproval/GetApprovalStepPositionTypes';

//Position Details
export const GET_POSTION_DETAILS_URL = API_URL + 'api/Position/Get';
//Position Filters
export const GET_POSTION_FILTER_URL = API_URL + 'api/Position/GetPositionFilters';
//Position Add
export const UPSERT_POSITION_API = API_URL + 'api/Position/Upsert';
//Signer Add
export const UPSERT_SIGNER_API = API_URL + 'api/DocumentSignApproval/UpsertApprovalStepPosition';
export const UPSERT_SIGNER_POSITION_TYPE_API = API_URL + 'api/DocumentSignApproval/UpsertApprovalStepPositionType';
//Position delete
export const POSITION_DELETE_API = API_URL + 'api/Position/Delete';

//Approval Step delete
export const APPROVAL_STEP_DELETE_API = API_URL + 'api/DocumentSignApproval/DeleteApprovalStep';
//Update Approval Step Order
export const UPDATE_APPROVAL_STEP_ORDER_API = API_URL + 'api/DocumentSignApproval/UpdateApprovalStepOrder';
//Get Approvalstep By ID
export const GET_APPROVAL_STEP_BY_ID_URL = API_URL + 'api/DocumentSignApproval/GetApprovalStepByID';
//Approval Step Position delete
export const SIGNER_POSITION_DELETE_API = API_URL + 'api/DocumentSignApproval/DeleteApprovalStepPosition';
export const SIGNER_POSITION_TYPE_DELETE_API = API_URL + 'api/DocumentSignApproval/DeleteApprovalStepPositionType';
//Approval Step Upsert
export const UPSERT_APPROVAL_STEP_API = API_URL + 'api/DocumentSignApproval/UpsertApprovalStep';

//Event List
export const GET_EVENT_LIST_URL = API_URL + 'api/Event/GetCalendarEventList';
export const GET_EVENT_DETAILS_URL = API_URL + 'api/Event/GetCalendarEventById';
export const GET_CALENDER_EVENT_DETAILS_URL = API_URL + 'api/Event/GetCalendarEventDetailsByID';

export const DELETE_EVENT_API = API_URL + 'api/Event/Delete';
export const DELETE_CLUSTER_EVENT_API = API_URL + 'api/Event/DeleteClusterEvent';
export const SET_CANCEL_CLUSTER_API = API_URL + 'api/IntakeSeason/SetCancelCluster';
export const CONVERT_PROVINCE_API = API_URL + 'api/IntakeSeason/ConvertProvince';

//Ticket List
export const GET_TICKET_LIST_URL = API_URL + 'api/Ticket/GetTickets';
export const GET_TICKET_DETAILS_URL = API_URL + 'api/Ticket/GetTicketByID';
export const UPSERT_TICKET_API = API_URL + 'api/Ticket/UpsertTicket';
export const DELETE_TICKET_API = API_URL + 'api/Ticket/DeleteTicket';
export const GET_TICKET_FILTER_URL = API_URL + 'api/Ticket/GetTicketFilters';
export const GET_TICKET_PURCHASE_ORDER_HISTORY_URL = API_URL + 'api/Ticket/GetTicketPurchaseOrderHistory';

//FEE
export const GET_FEE_DETERMINE_URL = API_URL + 'api/Fee/DetermineTransactionFee';

//documents
export const GET_DOCUMENT_LIST_URL = API_URL + 'api/DocumentTransaction/GetDocumentTransactionList';

//required Document list
export const GET_REQUIRED_DOCUMENT_LIST_URL = API_URL + 'api/Document/GetRequiredOrganizationDocumentList';

//Get documents Filter Options
export const GET_DOCUMENT_FILTER_OPTIONS_URL = API_URL + 'api/DocumentTransaction/GetDocumentTransactionFilters';
//Upsert Document Transaction
export const UPSERT_DOCUMENT_TRANSACTION = API_URL + 'api/DocumentTransaction/UpsertDocumentTransaction';
//Update Document Transaction Status
export const UPDATE_DOCUMENT_TRANSACTION_STATUS = API_URL + 'api/DocumentTransaction/UpdateDocumentTransactionStatus';



//Supporter
export const CREATE_SUPPORTER_URL = API_URL + 'api/SignUp/CreateSupporter';

//remove officer report
export const DELETE_OFFICER_REPORT = API_URL + 'api/OfficerReport/DeleteOfficerReport';
//submit officer report
export const SUBMIT_OFFICER_REPORT = API_URL + 'api/OfficerReport/SubmitOfficerReport';
//Resubmit officer report
export const RESUBMIT_OFFICER_REPORT = API_URL + 'api/OfficerReport/ReSubmitOfficerReport';
//national intakeseason
export const GET_INTAKE_SEASON_STATUS_URL = API_URL + 'api/IntakeSeason/IntakeSeasonStatus';
export const GET_NATIONAL_INTAKE_SEASON_URL = API_URL + 'api/IntakeSeason/GetIntakeSeasonStatus';
export const GET_ALL_INTAKE_SEASON_URL = API_URL + 'api/IntakeSeason/GetAllIntakeSeasons';

export const UPSERT_INTAKE_SEASON_API = API_URL + 'api/IntakeSeason/UpsertIntakeSeason';
export const UPSERT_INTAKE_FEE_API = API_URL + 'api/Product/QuickIntakeFeeUpsert';
export const NATIONAL_INTAKE_SEASON_STEP_STATUS_API = API_URL + 'api/IntakeSeason/SetStepStatus';
export const REGIONAL_AND_LOCAL_INTAKE_SEASON_STEP_STATUS_API = API_URL + 'api/IntakeSeason/SetStepStatusOrganization';
export const INTAKE_SEASON_PREREQUISITES_API = API_URL + 'api/IntakeSeason/SetPrerequisites';
export const INTAKE_SEASON_POST_TASK_API = API_URL + 'api/IntakeSeason/SetPostTask';

export const INTAKE_SEASON_ELECTRONIC_PAYMENT_API = API_URL + 'api/IntakeSeason/SetRegionalIntakeElectronicPayments';
export const DELETE_INTAKE_FEE_API = API_URL + 'api/Product/Delete';
export const UPSERT_REQUIRED_DOCUMEMT_API = API_URL + 'api/IntakeSeason/SetRequiredDocuments';
export const GET_MASTERINTAKE_DETAILS_API = API_URL + 'api/IntakeSeason/GetMasterIntakeDetails';
export const GET_ALL_INTAKE_SEASON_PREV_NEW_API = API_URL + 'api/IntakeSeason/GetAllIntakeSeasonsPrevNew';
export const GET_INTAKE_SEASON_DETAILS_API = API_URL + 'api/IntakeSeason/GetIntakeSeasonDetails';
export const MASTER_INTAKE_DOCUMENT_IMPORT_API = API_URL + 'api/IntakeSeason/MasterIntakeDocumentImport';


export const INTAKE_SEASON_PLANNING_DOCUMENT_API = API_URL + 'api/IntakeSeason/SetPlanningDocuments';
export const INTAKE_SEASON_APPLICATION_SECTION_API = API_URL + 'api/IntakeSeason/SetIntakeSeasonApplicationSections';
export const GET_PLANNING_DOCUMENT_EVENT_CHECKIN_API = API_URL + 'api/PlanningDocument/GetCheckIn';

//getSign
export const GET_SIGN_URL = API_URL + 'api/DocumentSignApproval/GetSign';
//SIGN Document
export const SIGN_DOCUMENT_URL = API_URL + 'api/DocumentSignApproval/SignDocument';
//getSignqueue
export const GET_SIGN_QUEUE_URL = API_URL + 'api/DocumentSignApproval/GetSignQueueByID';

//planning document meeting Details
export const GET_PLANNING_DOCUMENT_EVENT_DETAILS_URL = API_URL + 'api/PlanningDocument/GetOrganizationEventType';

//Planning Document Add
export const UPSERT_PLANNING_DOCUMENT_API = API_URL + 'api/PlanningDocument/Upsert';

//Planning Document status
export const UPSERT_PLANNING_DOCUMENT_STATUS_API = API_URL + 'api/PlanningDocument/UpsertPlanningDocumentStatus';

//get Planning Document events
export const GET_PLANNING_DOCUMENT_EVENTS_API = API_URL + 'api/PlanningDocument/GetPlanningDocumentEvents';

//planning document meeting Filter
export const GET_PLANNING_DOCUMENT_EVENT_FILTERS_URL = API_URL + 'api/PlanningDocument/GetPlanningDocumentFilters';
//Upsert SignPosition in FormInfo
export const UPSERT_SIGN_POSITION_IN_FORMINFO_URL = API_URL + 'api/DocumentTransaction/UpsertSignPositionInFormInfo';

export const GET_EXAMTRANSACTIONRESULT_URL = API_URL + 'api/QuestionBuilder/GetExamTransactionResult';

export const GET_EXAM_SECTIONS_URL = API_URL + 'api/QuestionBuilder/GetExamSections';

export const DELETE_EXAM_TRANSACTION_URL = API_URL + 'api/QuestionBuilder/DeleteExamTransaction';

export const UPDATE_DOCUMENT_IN_DOCUMENTTRANSACTION_URL = API_URL + 'api/DocumentTransaction/UpdateDocumentTransactionDocument';
//Prospect
export const UPSERT_PROSPECT_URL = API_URL + 'api/Prospect/Upsert';
//Prospect application status
export const GET_PROSPECT_APPLICATION_STATUS_URL = API_URL + 'api/Prospect/GetApplicationStatus';
export const GET_PROSPECT_DETAILS_URL = API_URL + 'api/Prospect/GetProspectDetails';
export const ADD_NEW_PROSPECTS_APPLICATION_URL = API_URL + "api/Prospect/AddNewProspectApplicationStart"
export const CHANGE_PROSPECT_EMAIL_URL = API_URL + "api/Prospect/ChangeProspectEmail"

//examtransaction set complete date
export const CLEAR_EXAMTRANSACTION_COMPLETE_DATE = API_URL + 'api/QuestionBuilder/ClearExamTransactionCompleteDate';

//clear and refresh signqueue
export const CLEAR_AND_REFRESH_SIGNQUEUE = API_URL + 'api/DocumentSignApproval/ClearAndRefreshSignQueue';
export const BACK_TO_PENDING_AND_CLEAR_SIGNQUEUE = API_URL + 'api/DocumentSignApproval/BackToPendingAndClearSignQueue';


//view event insurance in documenttransaction
export const GET_EVENT_INSURANCE_STEP = API_URL + 'api/DocumentTransaction/ViewDocumentTransaction';
export const DocDOCUMENT_TRANSACTION_DETAILS_URL = API_URL + 'api/DocumentTransaction/GetDocumentTransactionFullDetails';

//VERIFY MEMBERSHIP NUMBER URL
export const VERIFY_MEMBERSHIP_NUMBER_URL = API_URL + 'api/Member/VerifyMembershipNumber';

//Update DocumentTransactionStatus
export const UPDATE_DOCUMENTTRANSACTION_STATUS_URL = API_URL + 'api/DocumentTransaction/UpdateDocumentTransactionStatus';

//Get Document Transaction
export const GET_DOCUMENT_TRANSACTION = API_URL + 'api/DocumentTransaction/GetDocumentTransaction';


//Get All EntityTypes
export const GET_ALL_ENTITYTYPES_URL = API_URL + 'api/Misc/GetEntityTypes';
//Get All EntityType By ID
export const GET_ENTITYTYPEBYID_URL = API_URL + 'api/Misc/GetEntityTypeByID';
//Upsert DocumentForm Document
export const UPSERT_DOCUMENTFORM_DOCUMENT_API_URL = API_URL + 'api/DocumentForm/UpsertDocumentFormDocument';
//Delete DocuemntForm
export const DELETE_DOCUEMNTFORM_URL = API_URL + 'api/DocumentForm/DeleteDocumentForm';
//Upsert XrefValue
export const UPSERT_XREFVALUE_URL = API_URL + 'api/IntakeSeason/UpsertXrefValue';
export const SET_PLANNING_DOCUMENT_EVENT_DOESNOT_APPLY_URL = API_URL + 'api/IntakeSeason/SetPlanningDocumentEventDoesNotApply';

//Get Independent Credential Document
export const GET_INDEPENDENT_CREDENTIAL_DOCUMENT = API_URL + 'api/DocumentForm/GetIndependentCredentialDocument';


//Get DocumentPrequisites
export const GET_ALL_DOCUMENT_PREQUISITES_URL = API_URL + 'api/DocumentForm/GetDocumentPrequisites';
//Save DocumentPrequisites
export const SAVE_DOCUMENT_PREQUISITES_URL = API_URL + 'api/DocumentForm/SetDocumentPrequisites';
//get membershipapplication setting
export const GET_MEMBERSHIP_APPLICATION_SETTING_URL = API_URL + 'api/Organization/GetMembershipApplicationSetting';
//upsert membershipapplication setting
export const UPSERT_MEMBERSHIP_APPLICATION_SETTING_URL = API_URL + 'api/Organization/UpsertMembershipApplicationSetting';
export const GET_LATEST_APPROVED_OFFICER_REPORT_URL = API_URL + 'api/Organization/GetLatestApprovedOfficerReport';
export const UPSERT_PENDING_ROSTER_MEMBER_URL = API_URL + 'api/Organization/UpsertPendingRosterMember';

//upsert Exam
export const UPSERT_EXAM_URL = API_URL + 'api/QuestionBuilder/UpsertExam';
//upsert Section
export const UPSERT_SECTION_URL = API_URL + 'api/QuestionBuilder/UpsertSection';
//Get Questions By Section
export const GET_QUESTIONS_BY_SECTION_URL = API_URL + 'api/QuestionBuilder/GetQuestionsBySection';
//Upsert Question
export const UPSERT_QUESTION_URL = API_URL + 'api/QuestionBuilder/UpsertQuestion';
//Delete Section
export const DELETE_SECTION_URL = API_URL + 'api/QuestionBuilder/DeleteSection';
//Delete Question
export const DELETE_QUESTION_URL = API_URL + 'api/QuestionBuilder/DeleteQuestion';
//Get ApplicationRequiredDocument
export const GET_APPLICATION_REQUIRED_DOCUMENT_URL = API_URL + 'api/Organization/GetApplicationRequiredDocument';
//Upsert ApplicationRequiredDocument
export const UPSERT_APPLICATION_REQUIRED_DOCUMENT_URL = API_URL + 'api/Organization/UpsertApplicationRequiredDocument';
//Delete ApplicationRequiredDocument
export const DELETE_APPLICATION_REQUIRED_DOCUMENT_URL = API_URL + 'api/Organization/DeleteApplicationRequiredDocument';
//Delete Exam
export const DELETE_EXAM_URL = API_URL + 'api/Organization/DeleteMembershipApplicationSetting';
//Get ParticipatingRegions
export const GET_PARTICIPATING_REGIONS = API_URL + 'api/IntakeSeason/GetParticipatingRegions';
//Get CheckinReport
export const GET_CHECKINREPORT_URL = API_URL + 'api/PlanningDocument/CheckInReport';
//Update SupplementDocumentID
export const UPDATE_SUPPLEMENTDOCUMENTID_URL = API_URL + 'api/Payment/UpdatePurchaseOrderSupplementDocumentID';
//Update CandidateStatus
export const UPDATE_CANDIDATE_STATUS_URL = API_URL + 'api/Candidate/UpdateCandidateStatus';
//Get Chapter Planning Document Interviews
export const GET_CHAPTER_INTERVIEWS_URL = API_URL + 'api/PlanningDocument/GetChapterInterviews';
//Create Interview Event
export const CREATE_INTERVIEW_EVENT_URL = API_URL + 'api/Event/CreateInterviewEvent';
//Get Possible Intake Fees
export const GET_POSSIBLE_INTAKE_FEES_URL = API_URL + 'api/IntakeSeason/GetPossibleIntakeFees';
export const GET_INTAKE_PAYMENT_SUMMARY_URL = API_URL + 'api/IntakeSeason/GetIntakePaymentSummary';

export const GET_FINANCE_REPORTING_FILTER_URL = API_URL + 'api/FinanceReporting/GetFilter';
export const GET_FINANCE_PRODUCT_TRANSPORT_REPORT_URL = API_URL + 'api/FinanceReporting/GetFinanceProductTransactionReport';


//Set Intake Fee
export const SET_INTAKE_FEE_URL = API_URL + 'api/Candidate/SetIntakeFee';
//Get MasterIntake Details
export const GET_MASTER_INTAKE_DETAILS = API_URL + 'api/IntakeSeason/GetMasterIntakeDetails';
//Get Chapter Pending Signqueues
export const GET_CHAPTER_PENDING_SIGNQUEUE_URL = API_URL + 'api/Organization/GetChapterPendingSignQueue';
//Delete SignQueue
export const DELETE_SIGNQUEUE_URL = API_URL + 'api/DocumentSignApproval/DeleteSignQueue';
//Get Documents By IntakeSeason
export const GET_DOCUMENTS_BY_INTAKESEAON = API_URL + 'api/IntakeSeason/GetDocumentsByIntakeSeason';
//Get Officer Report Details
export const GET_OFFICER_REPORT_DETAILS_URL = API_URL + 'api/OfficerReport/GetOfficerReportDetails';
//Validate Signqueue
export const VALIDATE_SIGNQUEUE_URL = API_URL + 'api/Candidate/ValidateSignQueue';
//set Step Status
export const SET_CANDIDATE_STEP_STATUS_URL = API_URL + 'api/Candidate/SetStepComplete';
//ProgressApprovalStep Signqueue
export const PROGRESS_APPROVAL_STEP_SIGNQUEUE_URL = API_URL + 'api/DocumentSignApproval/ProgressApprovalStepSignQueue';
//Delete SignQueue By DocumentTransaction
export const DELETE_SIGNQUEUE_BY_DOCUMENTTRANSACTION_URL = API_URL + 'api/DocumentSignApproval/DeleteSignQueueByDocumentTransaction';
//Get EventInsuranceDetails
export const GET_EVENT_INSURANCE_DETAILS_URL = API_URL + 'api/Event/GetEventInsuranceDetails';
//Get Invitation Requests
export const GET_INVITATION_REQUESTS_URL = API_URL + 'api/Invitations/GetInvitationRequests';
//Get Invitation Requests by organization
export const GET_INVITATION_REQUESTS_BY_ORGANIZATION_URL = API_URL + 'api/Invitations/GetInvitationRequestsByOrganization';
export const GET_INVITES_URL = API_URL + 'api/Invitations/GetInvites';

//Delete Invitation Request
export const DELETE_INVITATION_REQUEST_URL = API_URL + 'api/Invitations/DeleteInvitationRequest';
//Get Notification
export const GET_NOTIFICATION_URL = API_URL + 'api/Misc/GetNotifications';
export const SYNC_LMS_BY_DATE_URL = API_URL + 'api/Misc/sycnLMSByDate';

//Update InvitationRequestStatus
export const UPDATE_INVITATION_REQUEST_URL = API_URL + 'api/Invitations/UpdateInvitationRequestStatus';
//Get Purchase Orders
export const GET_PURCHASE_ORDERS_URL = API_URL + 'api/Payment/GetPurchaseOrders';
export const GET_PROCESS_WITHDRAW_URL = API_URL + 'api/Payment/ProcessWithdraw';
//Get EventInsurance CheckList
export const GET_EVENT_INSURANCE_CHECKLIST_URL = API_URL + 'api/Event/GetEventInsuranceCheckList';
//Sync Member to LMS
export const SYNC_MEMBER_TO_LMS_URL = API_URL + 'api/Member/SyncMemberToLMS';
//Sync Candidate to LMS
export const SYNC_CANDIDATE_TO_LMS_URL = API_URL + 'api/Candidate/SyncCandidateToLMS';
//Member Registration on Sync
export const CREATE_PEOPLE_PROFILE_RECORD = API_URL + 'api/Member/CreatePeopleProfileRecord';

//Get Data For Authorization form
export const GET_DATA_FOR_AUTHORIZATIONFORM_URL = API_URL + 'api/DocumentForm/GetDataForAuthorizationDocumentForm';
//Confirm Free Ticket
export const CONFIRM_FREE_TICEKT_URL = API_URL + 'api/Event/CofirmFreeTicket';
//Get Officers
export const GET_OFFICERS_URL = API_URL + 'api/Organization/GetOfficers';
//Get MemberCalendar Events
export const GET_MEMBER_CALENDAR_EVENTS_URL = API_URL + 'api/Event/GetMemberCalendarEventsWithFilter';


//Activity
export const GET_CHAPTER_ACTIVITY_BY_CATEGORYID_URL = API_URL + 'api/Activity/GetByCategoryID';
export const DELETE_HARDLIST_URL = API_URL + 'api/Member/DeleteHardList';
export const SEND_SIGNQUEUE_REMINDER_URL = API_URL + 'api/DocumentSignApproval/SendSignQueueReminder';
export const GET_CHECKIN_TIMES_URL = API_URL + 'api/PlanningDocument/GetCheckInTimes';
export const GET_ENTITY_NOTES_URL = API_URL + 'api/Note/GetEntityNotes';
//Upsert Note
export const UPSERT_NOTE = API_URL + 'api/Note/UpsertNote';

//Delete Note
export const DELETE_NOTE = API_URL + 'api/Note/Delete';

export const GET_CHAPTER_TO_CONFIRM_URL = API_URL + 'api/IntakeSeason/GetChaptersToConfirm';
export const SET_CONFIRMED_CHAPTER_URL = API_URL + 'api/IntakeSeason/SetConfirmedChapter';
export const SET_CONFIRMED_CLUSTER_URL = API_URL + 'api/IntakeSeason/SetConfirmedCluster';
export const SEND_PURCHASE_ORDER_EMAIL_NOTIFICATION_URL = API_URL + 'api/Payment/PurchaseOrderRefundWaveRequestEmailNotification';

export const DELETE_INVITATION_URL = API_URL + 'api/Invitations/DeleteInvitation';
export const GET_INTERESTED_PROSPECTS_URL = API_URL + 'api/Prospect/GetInterestedProspects';
export const UPSERT_PAGE_VIEW_URL = API_URL + 'api/PageView/UpsertPageView';
export const DELETE_INTAKE_SEASON_URL = API_URL + 'api/IntakeSeason/DeleteIntakeSeason';

export const IGNORE_DOCUMENT_TRANSACTION_SIGNQUEUE_URL = API_URL + 'api/DocumentSignApproval/IgnoreDocumentTransactionSignQueue';


//SignalR paths
export const GET_LONG_PROCESS = API_URL + 'api/Misc/LongRunningProcess';
export const NEGOTIATE_URL = 'https://jobs-globalgains.azurewebsites.net/api/CountingJob_HttpStart';
export const GET_LONG_PROCESSES_INFO = API_URL + 'api/Misc/LongProcessesInfo';
export const GET_CHAT_HISTORY = API_URL + 'api/Chat/ChatHistory'

export const SEND_LOGIN_SIGNAL = API_URL + 'OnlineStatus';
export const CHAT_MESSAGES = API_URL + 'chat';

//online Users
export const GET_ONLINE_USERS = API_URL + 'api/Chat/OnlineEntities';

export const GET_CHATTED_WITH = API_URL + 'api/Chat/ChattedWith';

//Chpter Certification
export const GET_CHAPTER_CERTIFICATION_STATUS = API_URL + 'api/ChapterCertification/GetStatus';
export const START_NEW_CHAPTER_CERTIFICATION = API_URL + 'api/ChapterCertification/StartNew';

export const GET_CHAPTER_CERTIFICATION_SEASONS = API_URL + 'api/ChapterCertification/GetChapterCertificationSeasons';
export const UPSERT_CHAPTER_CERTIFICATION_SEASON = API_URL + 'api/ChapterCertification/UpsertChapterCertificationSeason';
export const GET_CHAPTER_CERTIFICATION_SEASON_STEPS = API_URL + 'api/ChapterCertification/GetChapterCertificationSeasonSteps';
export const UPSERT_SETTING_STATE = API_URL + 'api/ChapterCertification/UpsertSettingState';
export const GET_CHAPTER_CERTIFICATION_SEASON_SUMMARY = API_URL + 'api/ChapterCertification/GetChapterCertificationSeasonSummary'

//Chapter Documentation
export const GET_CHAPTER_DOCUMENTATION_STATUS = API_URL + 'api/ChapterDocumentation/GetStatus';
export const START_NEW_CHAPTER_DOCUMENTATION = API_URL + 'api/ChapterDocumentation/StartNew';

export const SYNC_OFFICER_REPORT_THIRD_PARTY = API_URL + 'api/OfficerReport/SyncOfficerReportThirdParty'
export const GET_NEXT_MEMBERSHIP_NUMBER = API_URL + 'api/IntakeSeason/GetNextMembershipNumber'


export const CANDIDATE_CONVERT_MASTER_INTAK_DOCUMENT = API_URL + 'api/IntakeSeason/CandidateConvertChapterByMasterIntakeDocument'

export const DOCUMENT_TRANSACTION_UPSERT_SETTINGSTATE = API_URL + 'api/DocumentTransaction/UpsertSettingState'
export const IMPORT_ROSTER = API_URL + 'api/Roster/ImportRoster'