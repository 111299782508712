import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
const parentOrganizationId = process.env.REACT_APP_PARENTORGANIZATION_ID;
const customTheme = {
  4 : { 
    mainColor : 'linear-gradient(270deg, #8C000A 0%, #BF4451 100%)',
    mainColorShadow : '0 2px 8px 0 rgba(140,0,10,0.50)', 
    primaryColor : 'rgba(140,0,10,1)',
    disabledColor : 'rgb(220 178 181)',
    circleShadow:'0 1px 3px 0 rgba(140,0,10,1)',
    cardShadow:'0 4px 20px 0 rgba(140,0,10,0.50)',
    sideDarkColor:'#7b060f',
    cardSubHeadingColor:'#bbb4b4'
  },
  1741 : { 
    mainColor : 'linear-gradient(270deg, #eebf00 0%, #eebf00 100%)',
    mainColorShadow : '0 2px 8px 0 #eebf00', 
    primaryColor : '#eebf00',
    disabledColor : 'rgb(245 215 126)',
    circleShadow:'0 1px 3px 0 #eebf00',
    cardShadow:'0 4px 20px 0 #eebf00',
    sideDarkColor:'#7b060f',
    cardSubHeadingColor:'#403c3c'
  },
  2019 : { 
    mainColor : 'linear-gradient(270deg, #222222 0%, #222222 100%)',
    mainColorShadow : '0 2px 8px 0 #222222', 
    primaryColor : '#222222',
    disabledColor : 'rgb(119 117 117)',
    circleShadow:'0 1px 3px 0 #222222',
    cardShadow:'0 4px 20px 0 #222222',
    sideDarkColor:'#7b060f',
    cardSubHeadingColor:'#fff',
    subHead:'#fff'
  },  
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#556cd6'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fff'
    },
  },
  mainColor: customTheme[parentOrganizationId].mainColor,
  mainColorShadow : customTheme[parentOrganizationId].mainColorShadow, 
  primaryColor : customTheme[parentOrganizationId].primaryColor,
  disabledColor : customTheme[parentOrganizationId].disabledColor,
  circleShadow:customTheme[parentOrganizationId].circleShadow,
  cardShadow:customTheme[parentOrganizationId].cardShadow
});

export default theme;
