import { FinanceReportingFilterInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetFinanceReportingFilterReducer(state = FinanceReportingFilterInitialValues, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_FINANCE_REPORTING_FILTER_LOADING:
            return { loading: true, error: false, data: [] };

        case types.GET_FINANCE_REPORTING_FILTER_SUCCESS:
            return { loading: false, error: false, data: payload };

        case types.GET_FINANCE_REPORTING_FILTER_ERROR:
            return { loading: false, error: true, data: payload };

        default:
            return state
    }
}





