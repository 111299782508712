import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { Fragment } from 'react';
import clsx from 'clsx';
import SignatureCanvas from 'react-signature-canvas';
import { isMobileScreen, isTabScreen } from '../../functions/commonFunctions';
import { CustomAlertMessage, CustomPageLoading } from '../../functions/CustomFunctions';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useEffect } from 'react';
import { GET_SIGN_URL } from '../../constants/apiPaths';
import API from '../../redux/actions/API';
import { getLoggedEntityTypeID, getLoggedSourceOwnerID } from '../../functions/CoreFunctions';

const useStyles = makeStyles((theme) => ({
    // leftEnd:{
    //     float:'left'
    // },
    signInputText: {
        width: '100%',
        marginBottom: '10px',
        float: 'left'
    },
    typeStyle: {
        fontFamily: "'Great Vibes', cursive",
        fontSize: '40px',
        textAlign: 'left',
        padding: '12px',
        marginBottom: '1rem'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function CommonSignPad(props) {
    const {
        dialogFlag,
        dialogToggleFunc,
        callBackFunc,
        EntityTypeID = getLoggedEntityTypeID(),
        SourceOwnerID = getLoggedSourceOwnerID()
    } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const signPad = useRef({});
    const [activeTab, setActiveTab] = useState(0);
    const [signatureType, setSignatureType] = useState('draw');
    const [typedSignature, setTypedSignature] = useState('');
    const [existingSignature, setExistingSignature] = useState('');
    const [signLoading, setSignLoading] = useState(false);
    //Assign Signature
    const assignSignature = () => {
        if (activeTab === 0) { // Draw Signature
            if (signPad.current.isEmpty()) {
                CustomAlertMessage('Please give your signature', 'error', enqueueSnackbar);
            } else {
                const trimmedSignature = signPad.current.getTrimmedCanvas().toDataURL('image/png');
                setSignatureType('draw');
                callBackFunc(signatureType, trimmedSignature);
            }
        }
        else if (activeTab === 1) { // Type Signature
            if (typedSignature) {
                const canvas = document.getElementById('typed_signature_canvas');
                const trimmedSignature = canvas.toDataURL('image/png');
                setSignatureType('typed');
                callBackFunc('typed', trimmedSignature);
            } else {
                CustomAlertMessage('Please give your signature', 'error', enqueueSnackbar);
            }
        }
        else { // Existing
            if (existingSignature) {
                setSignatureType('existing');
                callBackFunc('existing', existingSignature);
            } else {
                CustomAlertMessage('Please create a sign!', 'error', enqueueSnackbar);
            }
        }
    }
    // Clear Signpad
    const clearSignPad = () => {
        if (activeTab === 0) {
            signPad.current.clear();
        } else {
            setTypedSignature('');
            var canvas = document.getElementById("typed_signature_canvas");
            var context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
        }
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setTypedSignature('');
    };

    const handleFieldChange = (event) => {
        const { value } = event.target;
        setTypedSignature(value);
        const canvas = document.getElementById('typed_signature_canvas');
        const ctx = canvas.getContext('2d');
        ctx.font = isMobileScreen() ? '30px Great Vibes' : isTabScreen() ? '50px Great Vibes' : '70px Great Vibes';
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillText(value, 20, 80);
    }

    const getSign = async () => {
        setSignLoading(true);
        try {
            const options = {
                url: GET_SIGN_URL,
                method: 'GET',
                params: {
                    EntityTypeID: parseInt(EntityTypeID),
                    SourceOwnerID: parseInt(SourceOwnerID)
                }
            };
            const { data: apiResponse } = await API(options);
            const { status, response = '' } = apiResponse;
            if (parseInt(status) === 1) {
                setExistingSignature(response.electronicSignature);
            }
        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                CustomAlertMessage(data.message, 'error', enqueueSnackbar);
            }
        } finally {
            setSignLoading(false);
        }
    };

    useEffect(() => {
        getSign();
    }, []);

    return (
        <Fragment>
            <Dialog disableBackdropClick disableEscapeKeyDown onClose={dialogToggleFunc} aria-labelledby="customized-dialog-title" open={dialogFlag}>
                <DialogTitle id="customized-dialog-title" onClose={dialogToggleFunc}>
                    Signature
                    </DialogTitle>
                <DialogContent dividers>
                    <Tabs value={activeTab} onChange={handleTabChange} aria-label="simple tabs example"
                        variant="scrollable" scrollButtons="auto">
                        <Tab label="Draw" {...a11yProps(0)} />
                        <Tab label="Type" {...a11yProps(0)} />
                        {existingSignature &&
                            <Tab label="Existing" {...a11yProps(0)} />
                        }
                    </Tabs>
                    <TabPanel value={activeTab} index={0} style={{ marginTop: '1rem' }}>
                        <div className={clsx(classes.leftEnd)}>
                            <SignatureCanvas
                                ref={signPad}
                                penColor='black'
                                canvasProps={{ width: isMobileScreen() ? 260 : isTabScreen() ? 330 : 500, height: 100, className: 'sigCanvas', style: { border: '1px solid #858585' } }} />
                        </div>
                        <div>
                            <Button variant="contained" onClick={clearSignPad} className={classes.clearBtn}>
                                Clear
                            </Button>
                        </div>
                    </TabPanel>
                    <TabPanel value={activeTab} index={1} style={{ marginTop: '1rem' }}>
                        <TextField name="typedSignature" value={typedSignature} onChange={handleFieldChange}
                            id="standard-basic" placeholder="Signature"
                            className={clsx(classes.signInputText)} />
                        <div className={clsx(classes.leftEnd)}>
                            <canvas id="typed_signature_canvas" width={isMobileScreen() ? 260 : isTabScreen() ? 330 : 500} height="100" style={{ border: '1px solid #858585', marginBottom: '1rem' }}></canvas>
                        </div>
                        <Button variant="contained" onClick={clearSignPad} className={classes.clearBtn}>
                            Clear
                            </Button>
                    </TabPanel>
                    {existingSignature &&
                        <TabPanel value={activeTab} index={2} style={{ marginTop: '1rem' }}>
                            {signLoading ?
                                <CustomPageLoading flag={signLoading} />
                                :
                                existingSignature &&
                                <div>
                                    <img src={existingSignature} alt="existing_signature" width="200px" height="100px"></img>
                                </div>
                            }
                        </TabPanel>
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={assignSignature} color="primary">
                        Submit
                        </Button>
                    <Button autoFocus onClick={dialogToggleFunc} color="danger">
                        Cancel
                        </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}