export const DEFAULT_COUNTRY_NAME = 'United States';


//All Steps Form Data Structure
export const SIGNUP_STEPS_FORM_DATA = {
    UPDATE_CURRENT_INFORMATION: {
        firstName: '',
        lastName: '',
        membershipNumber: '',
        dob: null,
        countryCode: '1', //By Default +1
        phoneNumber: '',
        address: '',
        city: '',
        country: DEFAULT_COUNTRY_NAME, //By Default US
        state: '',
        zip: '',
        email: '',
        financiallyActive: '',
        initiationChapter: '',
        initiationYear: '',
    },
    SELECT_CHAPTER: {
        password: '',
        reach_out_to_me: false,
        organizationId: '',
        searchText: ''
    },
    CREATE_PASSWORD: {
        password: ''
    },
    CHOOSE_PLAN: {
        lineItems: [],
        paymentPlans: [],
        paymentPlanId: '',
        paymentPlanAmount: '',
        paymentPlanName: '',
        termText: '',
        paymentPlanTermText: '',
        total: 0,
        planPaymentMethod: 0
    }
}

// Bank Account Types
export const AccountTypes = ['Checking', 'Savings'];

//Payment Card Icons
// export const PaymentCardIcon = {
//     visa :{icon : 'fab fa-cc-visa',title:'VISA'},
//     mastercard : {icon : 'fab fa-cc-mastercard',title:'MAST'},
//     DISC :{icon : 'fab fa-cc-discover',title:'DISC'},
//     AMEX : {icon : 'fab fa-cc-amex',title:'AMEX'}
// };

//Payment Methods Card Icons
export const PaymentMethodsCardIcon = {
    VISA: { icon: 'fab fa-cc-visa', title: 'VISA' },
    MAST: { icon: 'fab fa-cc-mastercard', title: 'MAST' },
    DISC: { icon: 'fab fa-cc-discover', title: 'DISC' },
    AMEX: { icon: 'fab fa-cc-amex', title: 'AMEX' },
    AMER: { icon: 'fab fa-cc-amex', title: 'AMER' }
};

//Static Values
export const DTYPE_FOR_DOCUMENT_FORM = 6;
export const DTYPE_FOR_PAYMENT_AUTHORIZATION_DOCUMENT_FORM = 14;
export const ENTITY_TYPE_ID_FOR_ORGANIZATION = 1;
export const ENTITY_TYPE_ID_FOR_MEMBER = 2;
export const ENTITY_TYPE_ID_FOR_CANDIDATE = 6;
export const ENTITY_TYPE_ID_FOR_CALENDAR_EVENT = 3;
export const DEFAULT_MERCHANT_ID = 1;
export const ACH_AUTHORIZATION_DOCUMENT_FORM_ID = 25;
export const CC_AUTHORIZATION_DOCUMENT_FORM_ID = 1050;
export const BANK_ACCOUNT_TYPE = 1;
export const CREDIT_CARD_TYPE = 2;
export const DEFAULT_MANAGED_ENTITY_TYPE_ID = 1;
export const PURCHASE_ORDER_STATUS_LIST = [
    { PurchaseOrderStatusID: 1, Name: "Pending" },
    { PurchaseOrderStatusID: 2, Name: "Confirmed" },
    { PurchaseOrderStatusID: 3, Name: "Error" },
    { PurchaseOrderStatusID: 4, Name: "Declined" },
    { PurchaseOrderStatusID: 5, Name: "InsufficientFunds" },
    { PurchaseOrderStatusID: 6, Name: "Voided" },
    { PurchaseOrderStatusID: 7, Name: "Returned" },
    { PurchaseOrderStatusID: 8, Name: "Cancelled" },
    { PurchaseOrderStatusID: 9, Name: "ChargeBack" },
    { PurchaseOrderStatusID: 10, Name: "DuplicateError" },
    { PurchaseOrderStatusID: 11, Name: "Reviewing" },
    { PurchaseOrderStatusID: 12, Name: "Quote" },
    { PurchaseOrderStatusID: 13, Name: "UnableToLocateAccount" },
    { PurchaseOrderStatusID: 14, Name: "UncollectedFunds" },
    { PurchaseOrderStatusID: 15, Name: "InvoiceRequest" },
    { PurchaseOrderStatusID: 16, Name: "InvalidRoutingNumber" },
    { PurchaseOrderStatusID: 17, Name: "ReviewingForRefund" },
    { PurchaseOrderStatusID: 18, Name: "SettlementFundingBalancing" }
];

export const DIVISION_STATUS_LIST = [
    { diviionId: 1, Name: "National" },
    { diviionId: 2, Name: "Regional" },
    { diviionId: 3, Name: "Local" }
]





//For Standalone component
export const NUMBER_REGEX = /^\d+$/;
export const StaticQuestionList = [
    {
        id: 1,
        questionID: 1001,
        examID: 40,
        examTypeID: 2,
        examType: "Open Ended",
        examName: "Exam X",
        questionTypeID: 1,
        questionType: "True or False",
        questionText: "Registered Name?",
        active: true,
        sealed: false,
        orderNumber: 1,
        documentName: null,
        questionAnswers: [],
        answerResult: [],
        questionImageUrl: 'https://s3.amazonaws.com/questionbuilder/Question/2016-08-14.png',
        QuestionDataTypeID: 1,
        validationType: "string",
        validations: [
            {
                type: "required",
                params: ["Required"]
            }
        ]
    },
    {
        id: 2,
        questionID: 1002,
        examID: 40,
        examTypeID: 2,
        examType: "Open Ended",
        examName: "Exam X",
        questionTypeID: 1,
        questionType: "True or False",
        questionText: "Registration Number?",
        active: true,
        sealed: false,
        orderNumber: 2,
        documentName: null,
        questionAnswers: [],
        answerResult: [],
        questionImageUrl: 'https://i.pinimg.com/originals/d5/70/8d/d5708d074647449486390c9e263c8cbd.jpg',
        QuestionDataTypeID: 2,
        validationType: "string",
        validations: [
            {
                type: "required",
                params: ["Required"]
            },
            {
                type: "matches",
                params: [NUMBER_REGEX, "Enter valid number"]
            }
        ]
    },
    {
        id: 3,
        questionID: 1003,
        examID: 40,
        examTypeID: 2,
        examType: "Open Ended",
        examName: "Exam X",
        questionTypeID: 1,
        questionType: "True or False",
        questionText: "Registered Date?",
        active: true,
        sealed: false,
        orderNumber: 3,
        documentName: null,
        questionAnswers: [],
        answerResult: [],
        questionImageUrl: 'https://i.ytimg.com/vi/ZFB_fpsKV8U/maxresdefault.jpg',
        QuestionDataTypeID: 3,
        validationType: "string",
        validations: [
            {
                type: "required",
                params: ["Required"]
            }
        ]
    },
    {
        id: 4,
        questionID: 1004,
        examID: 40,
        examTypeID: 2,
        examType: "Open Ended",
        examName: "Exam X",
        questionTypeID: 2,
        questionType: "Multiple Choice",
        questionText: "What is 2 plus 2?",
        active: true,
        sealed: false,
        orderNumber: 4,
        documentName: null,
        questionAnswers: [
            {
                questionAnswerID: 198,
                answerText: "5",
                isCorrectAnswer: false
            },
            {
                questionAnswerID: 199,
                answerText: "6",
                isCorrectAnswer: false
            },
            {
                questionAnswerID: 200,
                answerText: "4",
                isCorrectAnswer: true
            }
        ],
        answerResult: [],
        questionImageUrl: 'https://s3.amazonaws.com/questionbuilder/Question/lotto.jpg',
        QuestionDataTypeID: 1,
        validationType: "string",
        validations: [
            {
                type: "required",
                params: ["Required"]
            }
        ]
    },
    {
        id: 5,
        questionID: 1005,
        examID: 40,
        examTypeID: 2,
        examType: "Open Ended",
        examName: "Exam X",
        questionTypeID: 3,
        questionType: "Multi Answer plus Free Text",
        questionText: "Spiders have three legs?",
        active: true,
        sealed: false,
        orderNumber: 5,
        documentName: "Question/2016-08-14.png",
        questionAnswers: [
            {
                questionAnswerID: 111,
                answerText: "True",
                isCorrectAnswer: false
            },
            {
                questionAnswerID: 112,
                answerText: "False",
                isCorrectAnswer: true
            }
        ],
        answerResult: [],
        questionImageUrl: "https://s3.amazonaws.com/questionbuilder/Question/2016-08-14.png",
        QuestionDataTypeID: 1,
        validationType: "string",
        validations: [
            {
                type: "required",
                params: ["Required"]
            }
        ]
    },
    {
        id: 6,
        questionID: 1006,
        examID: 40,
        examTypeID: 2,
        examType: "Open Ended",
        examName: "Exam X",
        questionTypeID: 4,
        questionType: "Multi Answer plus Free Text",
        questionText: "How many hours in 1 day?",
        active: true,
        sealed: false,
        orderNumber: 6,
        documentName: null,
        questionAnswers: [
            {
                questionAnswerID: 157,
                answerText: "6",
                isCorrectAnswer: false
            },
            {
                questionAnswerID: 158,
                answerText: "30",
                isCorrectAnswer: false
            },
            {
                questionAnswerID: 190,
                answerText: "12",
                isCorrectAnswer: false
            },
            {
                questionAnswerID: 191,
                answerText: "24",
                isCorrectAnswer: true
            }
        ],
        answerResult: [],
        questionImageUrl: 'https://s3.amazonaws.com/questionbuilder/Question/lotto.jpg',
        QuestionDataTypeID: 1,
        validationType: "string",
        validations: [
            {
                type: "required",
                params: ["Required"]
            }
        ]
    },
    {
        id: 7,
        questionID: 1007,
        examID: 40,
        examTypeID: 2,
        examType: "Open Ended",
        examName: "Exam X",
        questionTypeID: 5,
        questionType: "True or False",
        questionText: "Upload Document",
        active: true,
        sealed: false,
        orderNumber: 7,
        documentName: null,
        questionAnswers: [],
        answerResult: [],
        questionImageUrl: 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png',
        QuestionDataTypeID: 1,
        validationType: "string",
        validations: [
            {
                type: "required",
                params: ["Required"]
            }
        ]
    }
];

const StaticQuestionList2 = [
    {
        id: 1,
        questionID: 1008,
        examID: 40,
        examTypeID: 2,
        examType: "Open Ended",
        examName: "Exam X",
        questionTypeID: 1,
        questionType: "True or False",
        questionText: "Registered Name?",
        active: true,
        sealed: false,
        orderNumber: 1,
        documentName: null,
        questionAnswers: [],
        answerResult: [],
        questionImageUrl: 'https://homepages.cae.wisc.edu/~ece533/images/cat.png',
        QuestionDataTypeID: 1,
        validationType: "string",
        validations: [
            {
                type: "required",
                params: ["Required"]
            }
        ]
    },
    {
        id: 2,
        questionID: 1009,
        examID: 40,
        examTypeID: 2,
        examType: "Open Ended",
        examName: "Exam X",
        questionTypeID: 5,
        questionType: "True or False",
        questionText: "Upload Document",
        active: true,
        sealed: false,
        orderNumber: 7,
        documentName: null,
        questionAnswers: [],
        answerResult: [],
        questionImageUrl: 'https://homepages.cae.wisc.edu/~ece533/images/monarch.png',
        QuestionDataTypeID: 1,
        validationType: "string",
        validations: [
            {
                type: "required",
                params: ["Required"]
            }
        ]
    },
]

export const sections = [
    {
        examSectionID: 1,
        title: "Personal Information",
        questions: StaticQuestionList
    },
    {
        examSectionID: 2,
        title: "Basic Information",
        questions: StaticQuestionList2
    },
    {
        examSectionID: 3,
        title: "Qualifications",
        questions: StaticQuestionList
    },
    {
        examSectionID: 4,
        title: "Experiences",
        questions: StaticQuestionList2
    },
    {
        examSectionID: 5,
        title: "Package Information",
        questions: StaticQuestionList
    },
    {
        examSectionID: 6,
        title: "General Knowledge",
        questions: StaticQuestionList2
    },
]


