import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

export default function CustomTableEmptyRecord({columnLength}){
    return(
        <TableRow>
            <TableCell align='center' colSpan={columnLength}>
            No Record Found!
            </TableCell>
        </TableRow>
    );
}