import React, { Suspense, useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import ConfigureStore from "./redux/store/ConfigureStore.js";
import { ProtectedRoute, PublicRoute } from "./functions/CoreFunctions.js";
import {
  AUTH_LAYOUT_PATH,
  LAYOUT_INITIAL_PATH,
  PAGE_NOT_FOUND_PATH,
  SIGNUP_STEPS_PATH,
  PERSON_LAYOUT_PATH,
  PROSPECT_LAYOUT_PATH,
  EVENT_CHECKOUT_PATH,
  SUPPORTER_LAYOUT_PATH,
  CREATE_PROFILE_PATH,
  ENTITY_CHECKIN_PATH,
  ENTITY_CHECKINBYORG_PATH,
  CHAPTER_CERTIFICATION_FORM_PATH
} from "./constants/RoutePaths.js";
// import PersonAuthLayout from './components/layout/AuthLayout/PersonAuthLayout.js';
import {
  LOCALSTORAGE_AUTHTOKEN,
  LOCALSTORAGE_AUTH_MEMBER_ID,
  LOCALSTORAGE_ENTITYTYPEID,
  LOCALSTORAGE_PARENT_ORGANIZATION_NAME,
  LOGGED_USER_ACCESS_LEVEL
} from "./constants/LocalStorage.js";
import { makeStyles } from "@material-ui/core/styles";
import ChatIcon from "@material-ui/icons/Chat";
import CreateProfile from "./components/auth/login/CreateProfile.jsx";
import EntityCheckin from "./components/intakeSeason/localIntakeSeason/EntityCheckin.jsx";
import { loggedTokenDetails } from "../src/functions/CoreFunctions";
import ChatBox from "./components/chat/miniChat.jsx";
import Fab from "@material-ui/core/Fab";
import SignalRService from "./components/common/SignalRService.js";
import ChatManager from "./components/chat/ChatManager.jsx";
import { addChatBox, removeChatBox } from "./redux/actions/chatBoxActions.js";
import { isMobileScreen, isTabScreen } from "./functions/commonFunctions.js";
import ChapterCertification from "./components/ChapterCertification/index.jsx";
// #rbp Layout Component

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  pageTitle: {
    marginLeft: "-1rem",
    marginTop: "-1rem",
    //borderBottom: '1px solid #eee',
    width: "100%",
    paddingBottom: "8px",
  },
  skipButton: {
    color: theme.primaryColor,
    display: "block",
    padding: "5px 21px",
    borderRadius: "50px",
    width: "100px",
    marginBottom: "28px",
  },
  listStyle: {
    cursor: "pointer",
    display: "flex",
    fontSize: "16px",
    "&:hover": {
      color: theme.primaryColor,
    },
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 9999,
  },
  modal: {
    display: "flex",
    alignItems: "right",
    justifyContent: "right",
  },
}));


const AccessLevel = localStorage.getItem(LOGGED_USER_ACCESS_LEVEL) || null;
const AuthLayout = React.lazy(() =>
  import("./components/layout/AuthLayout/authLayout")
);
const PersonAuthLayout = React.lazy(() =>
  import("./components/layout/AuthLayout/PersonAuthLayout")
);
const ProspectAuthLayout = React.lazy(() =>
  import("./components/layout/AuthLayout/ProspectAuthLayout")
);

const SupporterAuthLayout = React.lazy(() =>
  import("./components/layout/AuthLayout/SupporterAuthLayout")
);

const AdminLayout = React.lazy(() =>
  import("./components/layout/AdminLayout/index.jsx")
);
const PersonAdminLayout = React.lazy(() =>
  import("./components/layout/PersonAdminLayout/index.jsx")
);
const SupporterAdminLayout = React.lazy(() =>
  import("./components/layout/SupporterAdminLayout/index.jsx")
);
const ProspectAdminLayout = React.lazy(() =>
  import("./components/layout/ProspectAdminLayout/index.jsx")
);

const SignupSteps = React.lazy(() =>
  import("./components/auth/signup/SignupSteps")
);
const EventCheckout = React.lazy(() =>
  import("./components/eventCheckout/index.jsx")
);
const PageNotFound = React.lazy(() => import("./components/PageNotFound.jsx"));
//#rbp Store Configuration
export const store = ConfigureStore();


function App() {

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    // store.dispatch(openDefaultMemberChat(true));
    const chatBoxProps = {
      title: "2_3",
      memberName: "Tech Support",
      handelClose: () => {
        setOpen(false)
        store.dispatch(removeChatBox('2_3'))
      },
    }
    store.dispatch(addChatBox(chatBoxProps))
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const classes = useStyles();
  useEffect(() => {
    document.title =
      localStorage.getItem(LOCALSTORAGE_PARENT_ORGANIZATION_NAME) ||
      "SocialOrgz";
  });
  //  const data = getData()
  const [entitytypeId, setEntitytypeId] = useState(null);
  const getData = () => {
    const val = localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID);
    // console.log(val, "text--");
    if (val !== null) {
      setEntitytypeId(val);
    }
    // return val
  };

  useEffect(() => {
    setEntitytypeId(localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID));
  }, [entitytypeId]);


  return (
    <Provider store={store}>

      <BrowserRouter>
        <Suspense
          fallback={
            <center>
              <CircularProgress
                style={{ marginTop: "20rem" }}
                color="primary"
              />
            </center>
          }
        >
          <Switch>
            {/* <Route exact path={SIGNUP_STEPS_PATH} component={SignupSteps} /> */}
            <Route
              exact
              path={SIGNUP_STEPS_PATH}
              render={(props) => <SignupSteps getData={getData} {...props} />}
            />

            {/* <Route exact path={CREATE_PROFILE_PATH} component={CreateProfile} /> */}
            <Route
              path={CREATE_PROFILE_PATH}
              render={(props) => <CreateProfile getData={getData} {...props} />}
            />
            <Route path={ENTITY_CHECKIN_PATH} component={EntityCheckin} />
            <Route path={ENTITY_CHECKINBYORG_PATH} component={EntityCheckin} />

            {/* <Route exact path={EVENT_CHECKOUT_PATH} component={EventCheckout} /> */}
            <Route
              exact
              path={EVENT_CHECKOUT_PATH}
              render={(props) => <EventCheckout getData={getData} {...props} />}
            />
            <Route
              path={PERSON_LAYOUT_PATH}
              render={(props) => (
                <PersonAuthLayout getData={getData} {...props} />
              )}
            />
            <Route
              path={PROSPECT_LAYOUT_PATH}
              render={(props) => (
                <ProspectAuthLayout getData={getData} {...props} />
              )}
            />
            <PublicRoute
              path={AUTH_LAYOUT_PATH}
              render={(props) => <AuthLayout getData={getData} {...props} />}
            />
            <Route
              path={SUPPORTER_LAYOUT_PATH}
              render={(props) => (
                <SupporterAuthLayout getData={getData} {...props} />
              )}
            />

            {/* <ProtectedRoute
              exact
              path={LAYOUT_INITIAL_PATH}
              component={AdminLayout}component={AuthLayout}
component={PersonAuthLayout}
            /> */}
            {entitytypeId !== null && entitytypeId == 2 ? (
              <ProtectedRoute
                path={LAYOUT_INITIAL_PATH}
                component={AdminLayout}
              />
            ) : null}
            {entitytypeId !== null && entitytypeId == 7 ? (
              <ProtectedRoute
                path={LAYOUT_INITIAL_PATH}
                component={PersonAdminLayout}
              />
            ) : null}
            {entitytypeId !== null && entitytypeId == 4 ? (
              <ProtectedRoute
                path={LAYOUT_INITIAL_PATH}
                component={SupporterAdminLayout}
              />
            ) : null}
            {entitytypeId !== null && entitytypeId == 11 ? (
              <ProtectedRoute
                path={LAYOUT_INITIAL_PATH}
                component={ProspectAdminLayout}
              />
            ) : null}

            <ProtectedRoute
              path={CHAPTER_CERTIFICATION_FORM_PATH}
              render={(props) => (
                <ChapterCertification />
              )}
            />
            
            <ProtectedRoute
              path={PAGE_NOT_FOUND_PATH}
              component={PageNotFound}
            />
          </Switch>

        </Suspense>
      </BrowserRouter>
      
      {/* process.env.REACT_APP_CHAT  1 = visible, 0 = disabled (HAVE TO RESTART PROJECT AFTER CHANGING VALUE FROM .env FILE)*/}
      {/* openDefChat use when using redux */}
      {!open && AccessLevel != "National" && process.env.REACT_APP_CHAT == 1 && localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID) && (
        <Fab color="primary" className={classes.fab} onClick={handleOpen}>
          <ChatIcon />
        </Fab>
      )}
      {/* {open && process.env.REACT_APP_CHAT == 1 && localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID) && (
        // <Chat />
        <div style={{ position: "fixed", top: '300px', right: '10', zIndex: '2000' }}>
          <ChatBox title="2_3" onClose={() => handleClose()} />
        </div>
      )} */}

      {process.env.REACT_APP_CHAT == 1 &&
        (
          isMobileScreen() || isTabScreen() ? (
            <div style={{ top: '30%', left: 0, zIndex: '2000', height: '100%', position: 'absolute' }}>
              <ChatManager />
            </div>
          ) : (
            <>
              <div style={{ position: "fixed", bottom: '1rem', left: '1rem', zIndex: '2000', overflow: 'auto' }}>
                <ChatManager />
              </div>
            </>
          )
        )
      }

    </Provider>
  );
}
export default App;
