import { makeStyles } from "@material-ui/core/styles";
import React from 'react';
import { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DefaultResponsiveContainer from "../common/DefaultResponsiveContainer";
import { Button, Card, CardContent, Divider, List, ListItem, ListItemText } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router";
import { isMobileScreen } from "../../functions/commonFunctions";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useParams } from 'react-router'
import CommonPrerequesiteNotificationPage from "../intakeSeason/Common/CommonPrerequesiteNotificationPage";
import { UPSERT_XREFVALUE_URL } from "../../constants/apiPaths";
import { useSelector } from "react-redux";
import { CustomAlertMessage } from "../../functions/CustomFunctions";

const useStyles = makeStyles(theme => ({
    continuebtn: {
        color: '#fff',
        width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px'
    },
    completeStep: {
        float: 'left',
        borderRadius: '50%',
        backgroundColor: 'green',
        color: '#fff',
        padding: '2px',
    },
    formHeader: {
        textAlign: 'center',
        marginBottom: '5px'
    },
    listStyle: {
        border: '1px solid #9c9898'
    },
    deletebtn: {
        marginLeft: '10px',
        marginBottom: isMobileScreen() ? '5px' : '0px',
        float: 'right',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        }
    }
}));


const handleOverride = async () => {
    // const { seasonName = "", intakeSeasonId = 0 } = intakeseasons.CurrentIntakeSeason || {};
    try {
        // setPageLoading(true);
        // const options = {
        //     url: UPSERT_XREFVALUE_URL,
        //     method: 'POST',
        //     data: {
        //         entityTypeId: 1,
        //         sourceOwnerId: parseInt(OrganizationID),
        //         xrefTypeId: 22,
        //         xvalue: parseInt(intakeSeasonId)
        //     },
        //     headers: authHeader(),
        // };
        // const { data: apiResponse } = await API(options);
        // const { status } = apiResponse;
        // if (parseInt(status) === 1) {
        //     commonApiCallback();
        // }
    } catch (error) {
        if (error.response) {
            let { data } = error.response;
            // CustomAlertMessage(data.message, 'error', enqueueSnackbar);
        }
    } finally {
        // setPageLoading(false);
    }
};

const ChapterCertificationPrerequisite = (props) => {
    const { stepData = [], completed = "false", schools = [], handleCallback, chapterCertificationSeasonID } = props
    const { detail = {} } = stepData
    const { preRequisites = {} } = detail
    // console.log(stepData, "stepDatastepDatastepData");
    const { payload: accessLevelData = {} } = useSelector(state => state.MemberAccessLevelReducer);
    const { AccessLevel = '' } = accessLevelData;
    // console.log(stepData?.detail?.preRequisites, "stepDatastepDatastepDatastepData");
    const classes = useStyles();
    const history = useHistory();
    const { id: organizationID } = useParams();


    return (
        <Fragment>
            <Grid spacing={3} style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', }}>

                <CommonPrerequesiteNotificationPage
                    PreRequisiteStatus={completed ? "Passed" : "Failed"} //API
                    errorMessages={preRequisites}  //API PreRequisiteResonses
                    OrganizationID={organizationID}
                    schools={schools}   //API
                    AccessLevel={AccessLevel}
                    IntakeSeasonID={0} //API
                    handleOverride={handleOverride}
                    seasonType={"chapterCertification"}
                    handleCallback={handleCallback}
                    chapterCertificationSeasonID={chapterCertificationSeasonID} />



                {/* {stepData?.detail?.preRequisites.map((row, index) => {

                    return (
                        <div>
                            < Alert icon={row.status == "Passed" ?
                                <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon> : ''}
                                severity={row.status == "Passed" ? "info" : "warning"}
                                style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, justifyContent: 'left', alignItems: 'center' }}
                            >
                                <AlertTitle>{row.prerequisiteName}</AlertTitle>
                                {row.message}
                                {
                                    row.prerequisiteName == "Chapter Program Documentation Required" && row.status == "Failed" &&
                                    <>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            className={classes.continuebtn}
                                            onClick={() => { history.push(`/chapterDocumentation/${organizationID}`) }}
                                        // style={{ width: '10rem' }}
                                        >
                                            Go to Chapter Documentation
                                        </Button>
                                    </>
                                }

                            </Alert >
                        </div>
                    )

                })} */}


            </Grid>
        </Fragment>
    )
}

export default ChapterCertificationPrerequisite