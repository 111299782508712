//#rbp Initiate Action Types
//Authentication
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

//SignUp
export const SIGNUP_GOTO_STEP = 'SIGNUP_GOTO_STEP';
export const SET_FILTER_TEXT = 'SET_FILTER_TEXT';
export const SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_LOADING = 'SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_LOADING';
export const SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_SUCCESS = 'SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_SUCCESS';
export const SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_ERROR = 'SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_ERROR';

//Forgot Password
export const RESET_FORGOT_PASSWORD_VALUES = 'RESET_FORGOT_PASSWORD_VALUES';
export const MOVE_STEP_AND_UPDATE_VALUES = 'MOVE_STEP_AND_UPDATE_VALUES';

//Profile
export const LOGGED_USER_DETAILS_SUCCESS = 'LOGGED_USER_DETAILS_SUCCESS';
export const LOGGED_USER_DETAILS_ERROR = 'LOGGED_USER_DETAILS_ERROR';
export const LOGGED_USER_DETAILS_LOADING = 'LOGGED_USER_DETAILS_LOADING';

//navbar Profile
export const LOGGED_USER_DETAILS_NAVBAR_SUCCESS = 'LOGGED_USER_DETAILS_NAVBAR_SUCCESS';
export const LOGGED_USER_DETAILS_NAVBAR_ERROR = 'LOGGED_USER_DETAILS_NAVBAR_ERROR';
export const LOGGED_USER_DETAILS_NAVBAR_LOADING = 'LOGGED_USER_DETAILS_NAVBAR_LOADING';

//Member Access Level
export const MEMBER_ACCESS_LEVEL_SUCCESS = 'MEMBER_ACCESS_LEVEL_SUCCESS';
export const MEMBER_ACCESS_LEVEL_ERROR = 'MEMBER_ACCESS_LEVEL_ERROR';
export const MEMBER_ACCESS_LEVEL_LOADING = 'MEMBER_ACCESS_LEVEL_LOADING';

//Member Organization Profile
export const GET_MEMBER_ORANIZATION_PROFILE_SUCCESS = 'GET_MEMBER_ORANIZATION_PROFILE_SUCCESS';
export const GET_MEMBER_ORANIZATION_PROFILE_ERROR = 'GET_MEMBER_ORANIZATION_PROFILE_ERROR';
export const GET_MEMBER_ORANIZATION_PROFILE_LOADING = 'GET_MEMBER_ORANIZATION_PROFILE_LOADING';

//Get Recommended Chapters
export const GET_RECOMMENDED_CHAPTERS_SUCCESS = 'GET_RECOMMENDED_CHAPTERS_SUCCESS';
export const UPDATE_DISTANCE_LEVEL = 'UPDATE_DISTANCE_LEVEL';
export const GET_FILTERED_CHAPTERS_SUCCESS = 'GET_FILTERED_CHAPTERS_SUCCESS';
export const GET_RECOMMENDED_CHAPTERS_ERROR = 'GET_RECOMMENDED_CHAPTERS_ERROR';
export const GET_RECOMMENDED_CHAPTERS_LOADING = 'GET_RECOMMENDED_CHAPTERS_LOADING';
export const SET_CHAPTERS_LIST = 'SET_CHAPTERS_LIST';

//Logged User Payment Details
export const LOGGED_USER_PAYMENT_DEYAILS_SUCCESS = 'LOGGED_USER_PAYMENT_DEYAILS_SUCCESS';
export const LOGGED_USER_PAYMENT_DEYAILS_ERROR = 'LOGGED_USER_PAYMENT_DEYAILS_ERROR';
export const LOGGED_USER_PAYMENT_DEYAILS_LOADING = 'LOGGED_USER_PAYMENT_DEYAILS_LOADING';

//Make Payment 
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
export const MAKE_PAYMENT_GOTO_STEP = 'MAKE_PAYMENT_GOTO_STEP';
export const RESET_MAKE_PAYMENT_FORM = 'RESET_MAKE_PAYMENT_FORM';
export const RESET_PAYMENT_DETAILS = 'RESET_PAYMENT_DETAILS';

export const MOVE_SIGNUP_NEXTSTEP = 'MOVE_SIGNUP_NEXTSTEP';
export const MOVE_SIGNUP_PREVSTEP = 'MOVE_SIGNUP_PREVSTEP';
export const UPDATE_ACTIVESTEP = 'UPDATE_ACTIVESTEP';
export const UPDATE_SIGNUP_DETAIL = 'UPDATE_SIGNUP_DETAIL';

export const SET_CHAPTERS = 'SET_CHAPTERS';
export const SET_THIRDPARTYINTEGRATION = 'SET_THIRDPARTYINTEGRATION';

//Misc
export const START_PAGELOADING = 'START_PAGELOADING';
export const STOP_PAGELOADING = 'STOP_PAGELOADING';
export const UPDATE_STATE_COUNTRY_TIMEZONELIST =
  'UPDATE_STATE_COUNTRY_TIMEZONELIST';

//Get Payment Methods
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_ERROR = 'GET_PAYMENT_METHOD_ERROR';
export const GET_PAYMENT_METHOD_LOADING = 'GET_PAYMENT_METHOD_LOADING';

//Get Account Summary 
export const GET_ACCOUNT_SUMMARY_SUCCESS = 'GET_ACCOUNT_SUMMARY_SUCCESS';
export const GET_ACCOUNT_SUMMARY_ERROR = 'GET_ACCOUNT_SUMMARY_ERROR';
export const GET_ACCOUNT_SUMMARY_LOADING = 'GET_ACCOUNT_SUMMARY_LOADING';
export const APPEND_SHOW_MORE_TRANSACTIONSLIST = 'APPEND_SHOW_MORE_TRANSACTIONSLIST';

export const UPDATE_ACCOUNT_SUMMARY_LIST = 'UPDATE_ACCOUNT_SUMMARY_LIST';

//Get Account Summary Transactions
export const GET_ACCOUNT_SUMMARY_TRANACTIONS_SUCCESS = 'GET_ACCOUNT_SUMMARY_TRANACTIONS_SUCCESS';
export const GET_ACCOUNT_SUMMARY_TRANACTIONS_ERROR = 'GET_ACCOUNT_SUMMARY_TRANACTIONS_ERROR';
export const GET_ACCOUNT_SUMMARY_TRANACTIONS_LOADING = 'GET_ACCOUNT_SUMMARY_TRANACTIONS_LOADING';
export const RESET_ACCOUNT_SUMMARY_TRANSACTIONS = 'RESET_ACCOUNT_SUMMARY_TRANSACTIONS';

//Get Payment Plan Admin List
export const GET_PAYMENT_PLAN_ADMIN_LIST_SUCCESS = 'GET_PAYMENT_PLAN_ADMIN_LIST_SUCCESS';
export const GET_PAYMENT_PLAN_ADMIN_LIST_ERROR = 'GET_PAYMENT_PLAN_ADMIN_LIST_ERROR';
export const GET_PAYMENT_PLAN_ADMIN_LIST_LOADING = 'GET_PAYMENT_PLAN_ADMIN_LIST_LOADING';

//Payment Plan Details
export const GET_PAYMENT_PLAN_DETAIL_SUCCESS = 'GET_PAYMENT_PLAN_DETAIL_SUCCESS';
export const GET_PAYMENT_PLAN_DETAIL_ERROR = 'GET_PAYMENT_PLAN_DETAIL_ERROR';
export const GET_PAYMENT_PLAN_DETAIL_LOADING = 'GET_PAYMENT_PLAN_DETAIL_LOADING';

//Admin Dashboard
export const GET_ADMIN_DASHBOARD_SUCCESS = 'GET_ADMIN_DASHBOARD_SUCCESS';
export const GET_ADMIN_DASHBOARD_ERROR = 'GET_ADMIN_DASHBOARD_ERROR';
export const GET_ADMIN_DASHBOARD_LOADING = 'GET_ADMIN_DASHBOARD_LOADING';

//Products by organization
export const GET_PRODUCTS_BY_ORGANIZATION_SUCCESS = 'GET_PRODUCTS_BY_ORGANIZATION_SUCCESS';
export const GET_PRODUCTS_BY_ORGANIZATION_ERROR = 'GET_PRODUCTS_BY_ORGANIZATION_ERROR';
export const GET_PRODUCTS_BY_ORGANIZATION_LOADING = 'GET_PRODUCTS_BY_ORGANIZATION_LOADING';

//Product
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR';
export const GET_PRODUCT_LOADING = 'GET_PRODUCT_LOADING';

//Possible Product Group 
export const GET_POSSIBLE_PRODUCT_GROUP_SUCCESS = 'GET_POSSIBLE_PRODUCT_GROUP_SUCCESS';
export const GET_POSSIBLE_PRODUCT_GROUP_ERROR = 'GET_POSSIBLE_PRODUCT_GROUP_ERROR';
export const GET_POSSIBLE_PRODUCT_GROUP_LOADING = 'GET_POSSIBLE_PRODUCT_GROUP_LOADING';

//Docuement Detail 
export const GET_DOCUMENT_DETAIL_SUCCESS = 'GET_DOCUMENT_DETAIL_SUCCESS';
export const GET_DOCUMENT_DETAIL_ERROR = 'GET_DOCUMENT_DETAIL_ERROR';
export const GET_DOCUMENT_DETAIL_LOADING = 'GET_DOCUMENT_DETAIL_LOADING';

//Possible Product Group 
export const GET_DUES_SUMMARY_SUCCESS = 'GET_DUES_SUMMARY_SUCCESS';
export const GET_DUES_SUMMARY_ERROR = 'GET_DUES_SUMMARY_ERROR';
export const GET_DUES_SUMMARY_LOADING = 'GET_DUES_SUMMARY_LOADING';

//Get Member List
export const GET_MEMBERS_LIST_SUCCESS = 'GET_MEMBERS_LIST_SUCCESS';
export const GET_MEMBERS_LIST_ERROR = 'GET_MEMBERS_LIST_ERROR';
export const GET_MEMBERS_LIST_LOADING = 'GET_MEMBERS_LIST_LOADING';


//Get Member List
export const GET_EXPORT_MEMBERS_LIST_SUCCESS = 'GET_EXPORT_MEMBERS_LIST_SUCCESS';
export const GET_EXPORT_MEMBERS_LIST_ERROR = 'GET_EXPORT_MEMBERS_LIST_ERROR';
export const GET_EXPORT_MEMBERS_LIST_LOADING = 'GET_EXPORT_MEMBERS_LIST_LOADING';

//Get Member List
export const GET_HARD_LIST_SUCCESS = 'GET_HARD_LIST_SUCCESS';
export const GET_HARD_LIST_ERROR = 'GET_HARD_LIST_ERROR';
export const GET_HARD_LIST_LOADING = 'GET_HARD_LIST_LOADING';

//Get Candidate List
export const GET_CANDIDATES_LIST_SUCCESS = 'GET_CANDIDATES_LIST_SUCCESS';
export const GET_CANDIDATES_LIST_ERROR = 'GET_CANDIDATES_LIST_ERROR';
export const GET_CANDIDATES_LIST_LOADING = 'GET_CANDIDATES_LIST_LOADING';

//Get Candidate List
export const GET_EXPORT_CANDIDATES_LIST_SUCCESS = 'GET_EXPORT_CANDIDATES_LIST_SUCCESS';
export const GET_EXPORT_CANDIDATES_LIST_ERROR = 'GET_EXPORT_CANDIDATES_LIST_ERROR';
export const GET_EXPORT_CANDIDATES_LIST_LOADING = 'GET_EXPORT_CANDIDATES_LIST_LOADING';

//Get School List
export const GET_SCHOOLS_LIST_SUCCESS = 'GET_SCHOOLS_LIST_SUCCESS';
export const GET_SCHOOLS_LIST_ERROR = 'GET_SCHOOLS_LIST_ERROR';
export const GET_SCHOOLS_LIST_LOADING = 'GET_SCHOOLS_LIST_LOADING';

//Get School Profile
export const GET_SCHOOL_PROFILE_DETAILS_SUCCESS = 'GET_SCHOOL_PROFILE_DETAILS_SUCCESS';
export const GET_SCHOOL_PROFILE_DETAILS_ERROR = 'GET_SCHOOL_PROFILE_DETAILS_ERROR';
export const GET_SCHOOL_PROFILE_DETAILS_LOADING = 'GET_SCHOOL_PROFILE_DETAILS_LOADING';

//Get college official
export const GET_COLLEGE_OFFICIAL_DETAILS_SUCCESS = 'GET_COLLEGE_OFFICIAL_DETAILS_SUCCESS';
export const GET_COLLEGE_OFFICIAL_DETAILS_ERROR = 'GET_COLLEGE_OFFICIAL_DETAILS_ERROR';
export const GET_COLLEGE_OFFICIAL_DETAILS_LOADING = 'GET_COLLEGE_OFFICIAL_DETAILS_LOADING';



//Get Leadership Organizations List
export const GET_LEADERSHIP_ORGANIZATIONS_LIST_SUCCESS = 'GET_LEADERSHIP_ORGANIZATIONS_LIST_SUCCESS';
export const GET_LEADERSHIP_ORGANIZATIONS_LIST_ERROR = 'GET_LEADERSHIP_ORGANIZATIONS_LIST_ERROR';
export const GET_LEADERSHIP_ORGANIZATIONS_LIST_LOADING = 'GET_LEADERSHIP_ORGANIZATIONS_LIST_LOADING';

//Get Member Filter Options
export const GET_MEMBER_FILTER_OPTIONS_SUCCESS = 'GET_MEMBER_FILTER_OPTIONS_SUCCESS';
export const GET_MEMBER_FILTER_OPTIONS_ERROR = 'GET_MEMBER_FILTER_OPTIONS_ERROR';
export const GET_MEMBER_FILTER_OPTIONS_LOADING = 'GET_MEMBER_FILTER_OPTIONS_LOADING';

//Get Candidate Filter Options
export const GET_CANDIDATE_FILTER_OPTIONS_LOADING = 'GET_CANDIDATE_FILTER_OPTIONS_LOADING';
export const GET_CANDIDATE_FILTER_OPTIONS_SUCCESS = 'GET_CANDIDATE_FILTER_OPTIONS_SUCCESS';
export const GET_CANDIDATE_FILTER_OPTIONS_ERROR = 'GET_CANDIDATE_FILTER_OPTIONS_ERROR';


export const GET_EVENT_FILTER_OPTIONS_LOADING = 'GET_EVENT_FILTER_OPTIONS_LOADING';
export const GET_EVENT_FILTER_OPTIONS_SUCCESS = 'GET_EVENT_FILTER_OPTIONS_SUCCESS';
export const GET_EVENT_FILTER_OPTIONS_ERROR = 'GET_EVENT_FILTER_OPTIONS_ERROR';




//Profile
export const GET_MEMBER_PROFILE_DETAILS_SUCCESS = 'GET_MEMBER_PROFILE_DETAILS_SUCCESS';
export const GET_MEMBER_PROFILE_DETAILS_ERROR = 'GET_MEMBER_PROFILE_DETAILS_ERROR';
export const GET_MEMBER_PROFILE_DETAILS_LOADING = 'GET_MEMBER_PROFILE_DETAILS_LOADING';


//master intake details
export const GET_MASTERINTAKE_DETAILS_SUCCESS = 'GET_MASTERINTAKE_DETAILS_SUCCESS';
export const GET_MASTERINTAKE_DETAILS_ERROR = 'GET_MASTERINTAKE_DETAILS_ERROR';
export const GET_MASTERINTAKE_DETAILS_LOADING = 'GET_MASTERINTAKE_DETAILS_LOADING';

//calender event
export const GET_MEMBER_CALENDER_EVENT_LOADING = 'GET_MEMBER_CALENDER_EVENT_LOADING';
export const GET_MEMBER_CALENDER_EVENT_SUCCESS = 'GET_MEMBER_CALENDER_EVENT_SUCCESS';
export const GET_MEMBER_CALENDER_EVENT_ERROR = 'GET_MEMBER_CALENDER_EVENT_ERROR';

//calender event
export const GET_MEMBER_TIMELINE_LOADING = 'GET_MEMBER_TIMELINE_LOADING';
export const GET_MEMBER_TIMELINE_SUCCESS = 'GET_MEMBER_TIMELINE_SUCCESS';
export const GET_MEMBER_TIMELINE_ERROR = 'GET_MEMBER_TIMELINE_ERROR';


//Candidate Profile
export const GET_CANDIDATE_PROFILE_DETAILS_SUCCESS = 'GET_CANDIDATE_PROFILE_DETAILS_SUCCESS';
export const GET_CANDIDATE_PROFILE_DETAILS_ERROR = 'GET_CANDIDATE_PROFILE_DETAILS_ERROR';
export const GET_CANDIDATE_PROFILE_DETAILS_LOADING = 'GET_CANDIDATE_PROFILE_DETAILS_LOADING';

//Purchase Order Details
export const GET_PURCHASE_ORDER_DETAILS_SUCCESS = 'GET_PURCHASE_ORDER_DETAILS_SUCCESS';
export const GET_PURCHASE_ORDER_DETAILS_ERROR = 'GET_PURCHASE_ORDER_DETAILS_ERROR';
export const GET_PURCHASE_ORDER_DETAILS_LOADING = 'GET_PURCHASE_ORDER_DETAILS_LOADING';

//Get RPT Payment Details
export const GET_RPT_PAYMENT_DETAILS_SUCCESS = 'GET_RPT_PAYMENT_DETAILS_SUCCESS';
export const GET_RPT_PAYMENT_DETAILS_ERROR = 'GET_RPT_PAYMENT_DETAILS_ERROR';
export const GET_RPT_PAYMENT_DETAILS_LOADING = 'GET_RPT_PAYMENT_DETAILS_LOADING';

//Get RPT Auto Debit Failure Details
export const GET_AUTO_DEBIT_LOGS_SUCCESS = 'GET_AUTO_DEBIT_LOGS_SUCCESS';
export const GET_AUTO_DEBIT_LOGS_ERROR = 'GET_AUTO_DEBIT_LOGS_ERROR';
export const GET_AUTO_DEBIT_LOGS_LOADING = 'GET_AUTO_DEBIT_LOGS_LOADING';

//Get Upcoming Auto Debits
export const GET_UPCOMING_AUTO_DEBITS_SUCCESS = 'GET_UPCOMING_AUTO_DEBITS_SUCCESS';
export const GET_UPCOMING_AUTO_DEBITS_ERROR = 'GET_UPCOMING_AUTO_DEBITS_ERROR';
export const GET_UPCOMING_AUTO_DEBITS_LOADING = 'GET_UPCOMING_AUTO_DEBITS_LOADING';

//Get Entity Exam Transactions
export const GET_ENTITY_EXAM_TRANSACTIONS_SUCCESS = 'GET_ENTITY_EXAM_TRANSACTIONS_SUCCESS';
export const GET_ENTITY_EXAM_TRANSACTIONS_ERROR = 'GET_ENTITY_EXAM_TRANSACTIONS_ERROR';
export const GET_ENTITY_EXAM_TRANSACTIONS_LOADING = 'GET_ENTITY_EXAM_TRANSACTIONS_LOADING';

//Get Organization List
export const GET_ORGANIZATIONS_LIST_SUCCESS = 'GET_ORGANIZATIONS_LIST_SUCCESS';
export const GET_ORGANIZATIONS_LIST_ERROR = 'GET_ORGANIZATIONS_LIST_ERROR';
export const GET_ORGANIZATIONS_LIST_LOADING = 'GET_ORGANIZATIONS_LIST_LOADING';

//Organization/Chapter
export const GET_ORGANIZATION_DETAILS_SUCCESS = 'GET_ORGANIZATION_DETAILS_SUCCESS';
export const GET_ORGANIZATION_DETAILS_ERROR = 'GET_ORGANIZATION_DETAILS_ERROR';
export const GET_ORGANIZATION_DETAILS_LOADING = 'GET_ORGANIZATION_DETAILS_LOADING';

export const GET_REGIONAL_ROSTER_DETAILS_SUCCESS = 'GET_REGIONAL_ROSTER_DETAILS_SUCCESS';
export const GET_REGIONAL_ROSTER_DETAILS_ERROR = 'GET_REGIONAL_ROSTER_DETAILS_ERROR';
export const GET_REGIONAL_ROSTER_DETAILS_LOADING = 'GET_REGIONAL_ROSTER_DETAILS_LOADING';

export const GET_ORGANIZATION_DETAILS_POPUP_SUCCESS = 'GET_ORGANIZATION_DETAILS_POPUP_SUCCESS';
export const GET_ORGANIZATION_DETAILS_POPUP_ERROR = 'GET_ORGANIZATION_DETAILS_POPUP_ERROR';
export const GET_ORGANIZATION_DETAILS_POPUP_LOADING = 'GET_ORGANIZATION_DETAILS_POPUP_LOADING';

//Get Chapter/Organization Filter Options
export const GET_ORGANIZATION_FILTER_OPTIONS_SUCCESS = 'GET_ORGANIZATION_FILTER_OPTIONS_SUCCESS';
export const GET_ORGANIZATION_FILTER_OPTIONS_ERROR = 'GET_ORGANIZATION_FILTER_OPTIONS_ERROR';
export const GET_ORGANIZATION_FILTER_OPTIONS_LOADING = 'GET_ORGANIZATION_FILTER_OPTIONS_LOADING';

//Get Xref Types
export const GET_XREF_TYPES_SUCCESS = 'GET_XREF_TYPES_SUCCESS';
export const GET_XREF_TYPES_ERROR = 'GET_XREF_TYPES_ERROR';
export const GET_XREF_TYPES_LOADING = 'GET_XREF_TYPES_LOADING';

//Get Setting Configs
export const GET_SETTING_CONFIGS_SUCCESS = 'GET_SETTING_CONFIGS_SUCCESS';
export const GET_SETTING_CONFIGS_ERROR = 'GET_SETTING_CONFIGS_ERROR';
export const GET_SETTING_CONFIGS_LOADING = 'GET_SETTING_CONFIGS_LOADING';

//Get Course By Current Season
export const GET_COURSES_BY_CURRENT_SEASON_SUCCESS = 'GET_COURSES_BY_CURRENT_SEASON_SUCCESS';
export const GET_COURSES_BY_CURRENT_SEASON_ERROR = 'GET_COURSES_BY_CURRENT_SEASON_ERROR';
export const GET_COURSES_BY_CURRENT_SEASON_LOADING = 'GET_COURSES_BY_CURRENT_SEASON_LOADING';

//Get Course By Current Season
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_ERROR = 'GET_COURSES_ERROR';
export const GET_COURSES_LOADING = 'GET_COURSES_LOADING';
//Get Course By Current Season
export const GET_EXAMS_BY_COURSE_SUCCESS = 'GET_EXAMS_BY_COURSE_SUCCESS';
export const GET_EXAMS_BY_COURSE_ERROR = 'GET_EXAMS_BY_COURSE_ERROR';
export const GET_EXAMS_BY_COURSE_LOADING = 'GET_EXAMS_BY_COURSE_LOADING';

//Officer Report Status
export const GET_OFFICER_REPORT_STATUS_SUCCESS = 'GET_OFFICER_REPORT_STATUS_SUCCESS';
export const GET_OFFICER_REPORT_STATUS_ERROR = 'GET_OFFICER_REPORT_STATUS_ERROR';
export const GET_OFFICER_REPORT_STATUS_LOADING = 'GET_OFFICER_REPORT_STATUS_LOADING';
export const SET_OFFICER_REPORT_STEP = 'SET_OFFICER_REPORT_STEP';


//Roster Report Status
export const GET_ROSTER_REPORT_STATUS_SUCCESS = 'GET_ROSTER_REPORT_STATUS_SUCCESS';
export const GET_ROSTER_REPORT_STATUS_ERROR = 'GET_ROSTER_REPORT_STATUS_ERROR';
export const GET_ROSTER_REPORT_STATUS_LOADING = 'GET_ROSTER_REPORT_STATUS_LOADING';

//Officer Report Details
export const GET_OFFICER_REPORT_DETAILS_SUCCESS = 'GET_OFFICER_REPORT_DETAILS_SUCCESS';
export const GET_OFFICER_REPORT_DETAILS_ERROR = 'GET_OFFICER_REPORT_DETAILS_ERROR';
export const GET_OFFICER_REPORT_DETAILS_LOADING = 'GET_OFFICER_REPORT_DETAILS_LOADING';

//Officer Report Filter Options
export const GET_OFFICER_REPORT_FILTER_OPTIONS_SUCCESS = 'GET_OFFICER_REPORT_FILTER_OPTIONS_SUCCESS';
export const GET_OFFICER_REPORT_FILTER_OPTIONS_ERROR = 'GET_OFFICER_REPORT_FILTER_OPTIONS_ERROR';
export const GET_OFFICER_REPORT_FILTER_OPTIONS_LOADING = 'GET_OFFICER_REPORT_FILTER_OPTIONS_LOADING';

//Get School List
export const GET_COLLEGEOFFICIAL_LIST_SUCCESS = 'GET_COLLEGEOFFICIAL_LIST_SUCCESS';
export const GET_COLLEGEOFFICIAL_LIST_ERROR = 'GET_COLLEGEOFFICIAL_LIST_ERROR';
export const GET_COLLEGEOFFICIAL_LIST_LOADING = 'GET_COLLEGEOFFICIAL_LIST_LOADING';

//Get SIGN QUEUE
export const GET_SIGNQUEUE_LIST_SUCCESS = 'GET_SIGNQUEUE_LIST_SUCCESS';
export const GET_SIGNQUEUE_LIST_ERROR = 'GET_SIGNQUEUE_LIST_ERROR';
export const GET_SIGNQUEUE_LIST_LOADING = 'GET_SIGNQUEUE_LIST_LOADING';

//Get EXAM TRANSACTION RESULT
export const GET_EXAM_TRANSACTION_RESULT_SUCCESS = 'GET_EXAM_TRANSACTION_RESULT_SUCCESS';
export const GET_EXAM_TRANSACTION_RESULT_ERROR = 'GET_EXAM_TRANSACTION_RESULT_ERROR';
export const GET_EXAM_TRANSACTION_RESULT_LOADING = 'GET_EXAM_TRANSACTION_RESULT_LOADING';

//Get EXAM SECTIONS
export const GET_EXAM_SECTIONS_SUCCESS = 'GET_EXAM_SECTIONS_SUCCESS';
export const GET_EXAM_SECTIONS_ERROR = 'GET_EXAM_SECTIONS_ERROR';
export const GET_EXAM_SECTIONS_LOADING = 'GET_EXAM_SECTIONS_LOADING';

//Financial Status
export const GET_FINANCIAL_STATUS_LOADING = 'GET_FINANCIAL_STATUS_LOADING';
export const GET_FINANCIAL_STATUS_SUCCESS = 'GET_FINANCIAL_STATUS_SUCCESS';
export const GET_FINANCIAL_STATUS_ERROR = 'GET_FINANCIAL_STATUS_ERROR';


//Get POSITON Types
export const GET_POSITON_TYPES_SUCCESS = 'GET_POSITON_TYPES_SUCCESS';
export const GET_POSITON_TYPES_ERROR = 'GET_POSITON_TYPES_ERROR';
export const GET_POSITON_TYPES_LOADING = 'GET_POSITON_TYPES_LOADING';

//Get POSITON Lists
export const GET_POSITONS_SUCCESS = 'GET_POSITONS_SUCCESS';
export const GET_POSITONS_ERROR = 'GET_POSITONS_ERROR';
export const GET_POSITONS_LOADING = 'GET_POSITONS_LOADING';

//Get ENTITY SELECTION Lists
export const GET_ENTITY_SELECTION_SUCCESS = 'GET_ENTITY_SELECTION_SUCCESS';
export const GET_ENTITY_SELECTION_ERROR = 'GET_ENTITY_SELECTION_ERROR';
export const GET_ENTITY_SELECTION_LOADING = 'GET_ENTITY_SELECTION_LOADING';

//Get Document Forms
export const GET_DOCUMENT_FORMS_SUCCESS = 'GET_DOCUMENT_FORMS_SUCCESS';
export const GET_DOCUMENT_FORMS_ERROR = 'GET_DOCUMENT_FORMS_ERROR';
export const GET_DOCUMENT_FORMS_LOADING = 'GET_DOCUMENT_FORMS_LOADING';

//Get Approval Steps
export const GET_APPROVAL_STEPS_SUCCESS = 'GET_APPROVAL_STEPS_SUCCESS';
export const GET_APPROVAL_STEPS_ERROR = 'GET_APPROVAL_STEPS_ERROR';
export const GET_APPROVAL_STEPS_LOADING = 'GET_APPROVAL_STEPS_LOADING';

//Get Approval step By ID
export const GET_APPROVAL_STEP_BY_ID_SUCCESS = 'GET_APPROVAL_STEP_BY_ID_SUCCESS';
export const GET_APPROVAL_STEP_BY_ID_ERROR = 'GET_APPROVAL_STEP_BY_ID_ERROR';
export const GET_APPROVAL_STEP_BY_ID_LOADING = 'GET_APPROVAL_STEP_BY_ID_LOADING';

//Get Divisions
export const GET_DIVISIONS_SUCCESS = 'GET_DIVISIONS_SUCCESS';
export const GET_DIVISIONS_ERROR = 'GET_DIVISIONS_ERROR';
export const GET_DIVISIONS_LOADING = 'GET_DIVISIONS_LOADING';

//Get Approval Step Status
export const GET_APPROVAL_STEP_STATUS_SUCCESS = 'GET_APPROVAL_STEP_STATUS_SUCCESS';
export const GET_APPROVAL_STEP_STATUS_ERROR = 'GET_APPROVAL_STEP_STATUS_ERROR';
export const GET_APPROVAL_STEP_STATUS_LOADING = 'GET_APPROVAL_STEP_STATUS_LOADING';

//Get Document Form Types
export const GET_DOCUMENT_FORM_TYPES_SUCCESS = 'GET_DOCUMENT_FORM_TYPES_SUCCESS';
export const GET_DOCUMENT_FORM_TYPES_ERROR = 'GET_DOCUMENT_FORM_TYPES_ERROR';
export const GET_DOCUMENT_FORM_TYPES_LOADING = 'GET_DOCUMENT_FORM_TYPES_LOADING';

//Get Document Form Filters
export const GET_DOCUMENT_FORM_FILTERS_SUCCESS = 'GET_DOCUMENT_FORM_FILTERS_SUCCESS';
export const GET_DOCUMENT_FORM_FILTERS_ERROR = 'GET_DOCUMENT_FORM_FILTERS_ERROR';
export const GET_DOCUMENT_FORM_FILTERS_LOADING = 'GET_DOCUMENT_FORM_FILTERS_LOADING';

//Get Document Form By ID
export const GET_DOCUMENT_FORM_BY_ID_SUCCESS = 'GET_DOCUMENT_FORM_BY_ID_SUCCESS';
export const GET_DOCUMENT_FORM_BY_ID_ERROR = 'GET_DOCUMENT_FORM_BY_ID_ERROR';
export const GET_DOCUMENT_FORM_BY_ID_LOADING = 'GET_DOCUMENT_FORM_BY_ID_LOADING';
export const RESET_DOCUMENT_FORM_BY_ID = 'RESET_DOCUMENT_FORM_BY_ID';

export const GET_DOCUMENT_FORMBYENTITY_SUCCESS = 'GET_DOCUMENT_FORMBYENTITY_SUCCESS';
export const GET_DOCUMENT_FORMBYENTITY_ERROR = 'GET_DOCUMENT_FORMBYENTITY_ERROR';
export const GET_DOCUMENT_FORMBYENTITY_LOADING = 'GET_DOCUMENT_FORMBYENTITY_LOADING';


//Get SIGNER POSITIONS
export const GET_SIGNER_POSITIONS_SUCCESS = 'GET_SIGNER_POSITIONS_SUCCESS';
export const GET_SIGNER_POSITIONS_ERROR = 'GET_SIGNER_POSITIONS_ERROR';
export const GET_SIGNER_POSITIONS_LOADING = 'GET_SIGNER_POSITIONS_LOADING';

//Get SIGNER POSITIONTYPES
export const GET_SIGNER_POSITION_TYPE_SUCCESS = 'GET_SIGNER_POSITION_TYPE_SUCCESS';
export const GET_SIGNER_POSITION_TYPE_ERROR = 'GET_SIGNER_POSITION_TYPE_ERROR';
export const GET_SIGNER_POSITION_TYPE_LOADING = 'GET_SIGNER_POSITION_TYPE_LOADING';

//Get POSITON DETAILS
export const GET_POSITON_DETAILS_SUCCESS = 'GET_POSITON_DETAILS_SUCCESS';
export const GET_POSITON_DETAILS_ERROR = 'GET_POSITON_DETAILS_ERROR';
export const GET_POSITON_DETAILS_LOADING = 'GET_POSITON_DETAILS_LOADING';

//Get POSITON FILTER
export const GET_POSITON_FILTER_SUCCESS = 'GET_POSITON_FILTER_SUCCESS';
export const GET_POSITON_FILTER_ERROR = 'GET_POSITON_FILTER_ERROR';
export const GET_POSITON_FILTER_LOADING = 'GET_POSITON_FILTER_LOADING';

//school navbar Profile
export const SCHOOL_LOGGED_USER_DETAILS_SUCCESS = 'SCHOOL_LOGGED_USER_DETAILS_SUCCESS';
export const SCHOOL_LOGGED_USER_DETAILS_ERROR = 'SCHOOL_LOGGED_USER_DETAILS_ERROR';
export const SCHOOL_LOGGED_USER_DETAILS_LOADING = 'SCHOOL_LOGGED_USER_DETAILS_LOADING';

//Get EVENT LIST
export const GET_EVENT_LIST_SUCCESS = 'GET_EVENT_LIST_SUCCESS';
export const GET_EVENT_LIST_ERROR = 'GET_EVENT_LIST_ERROR';
export const GET_EVENT_LIST_LOADING = 'GET_EVENT_LIST_LOADING';

//Get Purchase Order List
export const GET_PURCHASE_ORDER_LIST_SUCCESS = 'GET_PURCHASE_ORDER_LIST_SUCCESS';
export const GET_PURCHASE_ORDER_LIST_ERROR = 'GET_PURCHASE_ORDER_LIST_ERROR';
export const GET_PURCHASE_ORDER_LIST_LOADING = 'GET_PURCHASE_ORDER_LIST_LOADING';

//Get EVENT DETAILS
export const GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS';
export const GET_EVENT_DETAILS_ERROR = 'GET_EVENT_DETAILS_ERROR';
export const GET_EVENT_DETAILS_LOADING = 'GET_EVENT_DETAILS_LOADING';

//Get CALENDER EVENT DETAILS
export const GET_CALENDER_EVENT_DETAILS_SUCCESS = 'GET_CALENDER_EVENT_DETAILS_SUCCESS';
export const GET_CALENDER_EVENT_DETAILS_ERROR = 'GET_CALENDER_EVENT_DETAILS_ERROR';
export const GET_CALENDER_EVENT_DETAILS_LOADING = 'GET_CALENDER_EVENT_DETAILS_LOADING';

//Get TICKET LIST
export const GET_TICKET_LIST_SUCCESS = 'GET_TICKET_LIST_SUCCESS';
export const GET_TICKET_LIST_ERROR = 'GET_TICKET_LIST_ERROR';
export const GET_TICKET_LIST_LOADING = 'GET_TICKET_LIST_LOADING';

//Get TICKET DETAILS
export const GET_TICKET_DETAILS_SUCCESS = 'GET_TICKET_DETAILS_SUCCESS';
export const GET_TICKET_DETAILS_ERROR = 'GET_TICKET_DETAILS_ERROR';
export const GET_TICKET_DETAILS_LOADING = 'GET_TICKET_DETAILS_LOADING';


//Get TICKET FILTER
export const GET_TICKET_FILTER_SUCCESS = 'GET_TICKET_FILTER_SUCCESS';
export const GET_TICKET_FILTER_ERROR = 'GET_TICKET_FILTER_ERROR';
export const GET_TICKET_FILTER_LOADING = 'GET_TICKET_FILTER_LOADING';

//Get FEE DETERMINE 
export const GET_FEE_DETERMINE_SUCCESS = 'GET_FEE_DETERMINE_SUCCESS';
export const GET_FEE_DETERMINE_ERROR = 'GET_FEE_DETERMINE_ERROR';
export const GET_FEE_DETERMINE_LOADING = 'GET_FEE_DETERMINE_LOADING';
export const GET_FEE_DETERMINE_RESET = 'GET_FEE_DETERMINE_RESET';

//EVENT CHECKOUT DETAILS
export const GET_EVENT_CHECKOUT_DETAILS_SUCCESS = 'GET_EVENT_CHECKOUT_DETAILS_SUCCESS';
export const GET_EVENT_CHECKOUT_DETAILS_ERROR = 'GET_EVENT_CHECKOUT_DETAILS_ERROR';
export const GET_EVENT_CHECKOUT_DETAILS_LOADING = 'GET_EVENT_CHECKOUT_DETAILS_LOADING';


//DOCUMENT LIST
export const GET_DOCUMENT_LIST_SUCCESS = 'GET_DOCUMENT_LIST_SUCCESS';
export const GET_DOCUMENT_LIST_ERROR = 'GET_DOCUMENT_LIST_ERROR';
export const GET_DOCUMENT_LIST_LOADING = 'GET_DOCUMENT_LIST_LOADING';

//Required Chapter Documents
export const GET_REQUIRED_DOCUMENT_LIST_SUCCESS = 'GET_REQUIRED_DOCUMENT_LIST_SUCCESS';
export const GET_REQUIRED_DOCUMENT_LIST_ERROR = 'GET_REQUIRED_DOCUMENT_LIST_ERROR';
export const GET_REQUIRED_DOCUMENT_LIST_LOADING = 'GET_REQUIRED_DOCUMENT_LIST_LOADING';


//Get DOCUMENT Filter Options
export const GET_DOCUMENT_FILTER_OPTIONS_SUCCESS = 'GET_DOCUMENT_FILTER_OPTIONS_SUCCESS';
export const GET_DOCUMENT_FILTER_OPTIONS_ERROR = 'GET_DOCUMENT_FILTER_OPTIONS_ERROR';
export const GET_DOCUMENT_FILTER_OPTIONS_LOADING = 'GET_DOCUMENT_FILTER_OPTIONS_LOADING';

//INTAKE SEASON STEPS
export const GET_INTAKE_SEASON_STEPS_SUCCESS = 'GET_INTAKE_SEASON_STEPS_SUCCESS';
export const GET_INTAKE_SEASON_STEPS_ERROR = 'GET_INTAKE_SEASON_STEPS_ERROR';
export const GET_INTAKE_SEASON_STEPS_LOADING = 'GET_INTAKE_SEASON_STEPS_LOADING';
export const SET_INTAKE_SEASON_STEP = 'SET_INTAKE_SEASON_STEP';


//Get planning document Lists
export const GET_PLANNING_DOCUMENT_EVENT_SUCCESS = 'GET_PLANNING_DOCUMENT_EVENT_SUCCESS';
export const GET_PLANNING_DOCUMENT_EVENT_ERROR = 'GET_PLANNING_DOCUMENT_EVENT_ERROR';
export const GET_PLANNING_DOCUMENT_EVENT_LOADING = 'GET_PLANNING_DOCUMENT_EVENT_LOADING';

//Get planning document Lists
export const GET_PLANNING_DOCUMENT_DETAILS_SUCCESS = 'GET_PLANNING_DOCUMENT_DETAILS_SUCCESS';
export const GET_PLANNING_DOCUMENT_DETAILS_ERROR = 'GET_PLANNING_DOCUMENT_DETAILS_ERROR';
export const GET_PLANNING_DOCUMENT_DETAILS_LOADING = 'GET_PLANNING_DOCUMENT_DETAILS_LOADING';

//Get planning document filters
export const GET_PLANNING_DOCUMENT_FILTERS_SUCCESS = 'GET_PLANNING_DOCUMENT_FILTERS_SUCCESS';
export const GET_PLANNING_DOCUMENT_FILTERS_ERROR = 'GET_PLANNING_DOCUMENT_FILTERS_ERROR';
export const GET_PLANNING_DOCUMENT_FILTERS_LOADING = 'GET_PLANNING_DOCUMENT_FILTERS_LOADING';


//PLANNING DOCUMENT EVENT CHECKIN
export const GET_PLANNING_DOCUMENT_EVENT_CHECKIN_SUCCESS = 'GET_PLANNING_DOCUMENT_EVENT_CHECKIN_SUCCESS';
export const GET_PLANNING_DOCUMENT_EVENT_CHECKIN_ERROR = 'GET_PLANNING_DOCUMENT_EVENT_CHECKIN_ERROR';
export const GET_PLANNING_DOCUMENT_EVENT_CHECKIN_LOADING = 'GET_PLANNING_DOCUMENT_EVENT_CHECKIN_LOADING';

//Get School List yorganizationid
export const GET_SCHOOLS_LIST_BY_ORGID_SUCCESS = 'GET_SCHOOLS_LIST_BY_ORGID_SUCCESS';
export const GET_SCHOOLS_LIST_BY_ORGID_ERROR = 'GET_SCHOOLS_LIST_BY_ORGID_ERROR';
export const GET_SCHOOLS_LIST_BY_ORGID_LOADING = 'GET_SCHOOLS_LIST_BY_ORGID_LOADING';


//Get organization details for prospect login 
export const GET_ORGANIZATION_DETAILS_FOR_PROSPECT_SUCCESS = 'GET_ORGANIZATION_DETAILS_FOR_PROSPECT_SUCCESS';
export const GET_ORGANIZATION_DETAILS_FOR_PROSPECT_ERROR = 'GET_ORGANIZATION_DETAILS_FOR_PROSPECT_ERROR';
export const GET_ORGANIZATION_DETAILS_FOR_PROSPECT_LOADING = 'GET_ORGANIZATION_DETAILS_FOR_PROSPECT_LOADING';

//Entity Types
export const GET_ALL_ENTITYTYPES_LOADING = 'GET_ALL_ENTITYTYPES_LOADING';
export const GET_ALL_ENTITYTYPES_SUCCESS = 'GET_ALL_ENTITYTYPES_SUCCESS';
export const GET_ALL_ENTITYTYPES_ERROR = 'GET_ALL_ENTITYTYPES_ERROR';

//Entity Type BY ID
export const GET_ENTITYTYPEBYID_LOADING = 'GET_ENTITYTYPEBYID_LOADING';
export const GET_ENTITYTYPEBYID_SUCCESS = 'GET_ENTITYTYPEBYID_SUCCESS';
export const GET_ENTITYTYPEBYID_ERROR = 'GET_ENTITYTYPEBYID_ERROR';

//Get  prospect application status 
export const GET_PROSPECT_APPLICATION_STATUS_SUCCESS = 'GET_PROSPECT_APPLICATION_STATUS_SUCCESS';
export const GET_PROSPECT_APPLICATION_STATUS_ERROR = 'GET_PROSPECT_APPLICATION_STATUS_ERROR';
export const GET_PROSPECT_APPLICATION_STATUS_LOADING = 'GET_PROSPECT_APPLICATION_STATUS_LOADING';

//Get  prospect details 
export const GET_PROSPECT_DETAILS_SUCCESS = 'GET_PROSPECT_DETAILS_SUCCESS';
export const GET_PROSPECT_DETAILS_ERROR = 'GET_PROSPECT_DETAILS_ERROR';
export const GET_PROSPECT_DETAILS_LOADING = 'GET_PROSPECT_DETAILS_LOADING';
export const RESET_PROSPECT_DETAILS = 'RESET_PROSPECT_DETAILS';

//Get Document Prequisite List
export const GET_DOCUMENT_PREQUISITES_SUCCESS = 'GET_DOCUMENT_PREQUISITES_SUCCESS';
export const GET_DOCUMENT_PREQUISITES_ERROR = 'GET_DOCUMENT_PREQUISITES_ERROR';
export const GET_DOCUMENT_PREQUISITES_LOADING = 'GET_DOCUMENT_PREQUISITES_LOADING';

//Get MembershipApplication Setting
export const GET_MEMBERSHIP_APPLICATION_SETTING_SUCCESS = 'GET_MEMBERSHIP_APPLICATION_SETTING_SUCCESS';
export const GET_MEMBERSHIP_APPLICATION_SETTING_ERROR = 'GET_MEMBERSHIP_APPLICATION_SETTING_ERROR';
export const GET_MEMBERSHIP_APPLICATION_SETTING_LOADING = 'GET_MEMBERSHIP_APPLICATION_SETTING_LOADING';

//Get Questions By Section
export const GET_QUESTIONS_BY_SECTION_SUCCESS = 'GET_QUESTIONS_BY_SECTION_SUCCESS';
export const GET_QUESTIONS_BY_SECTION_ERROR = 'GET_QUESTIONS_BY_SECTION_ERROR';
export const GET_QUESTIONS_BY_SECTION_LOADING = 'GET_QUESTIONS_BY_SECTION_LOADING';

//Get Application Required Document
export const GET_APPLICATION_REQUIRED_DOCUMENT_SUCCESS = 'GET_APPLICATION_REQUIRED_DOCUMENT_SUCCESS';
export const GET_APPLICATION_REQUIRED_DOCUMENT_ERROR = 'GET_APPLICATION_REQUIRED_DOCUMENT_ERROR';
export const GET_APPLICATION_REQUIRED_DOCUMENT_LOADING = 'GET_APPLICATION_REQUIRED_DOCUMENT_LOADING';
//Get Reference Letter List
export const GET_REFERENCE_LETTER_LIST_SUCCESS = 'GET_REFERENCE_LETTER_LIST_SUCCESS';
export const GET_REFERENCE_LETTER_LIST_ERROR = 'GET_REFERENCE_LETTER_LIST_ERROR';
export const GET_REFERENCE_LETTER_LIST_LOADING = 'GET_REFERENCE_LETTER_LIST_LOADING';


//latest approved Officer Report 
export const GET_LATEST_APPROVED_OFFICER_REPORT_SUCCESS = 'GET_LATEST_APPROVED_OFFICER_REPORT_SUCCESS';
export const GET_LATEST_APPROVED_OFFICER_REPORT_ERROR = 'GET_LATEST_APPROVED_OFFICER_REPORT_ERROR';
export const GET_LATEST_APPROVED_OFFICER_REPORT_LOADING = 'GET_LATEST_APPROVED_OFFICER_REPORT_LOADING';

//get planning document events
export const GET_PLANNING_DOCUMENT_EVENTS_SUCCESS = 'GET_PLANNING_DOCUMENT_EVENTS_SUCCESS';
export const GET_PLANNING_DOCUMENT_EVENTS_ERROR = 'GET_PLANNING_DOCUMENT_EVENTS_ERROR';
export const GET_PLANNING_DOCUMENT_EVENTS_LOADING = 'GET_PLANNING_DOCUMENT_EVENTS_REPORT_LOADING';

//get chapter planning document interviews
export const GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_SUCCESS = 'GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_SUCCESS';
export const GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_ERROR = 'GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_ERROR';
export const GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_LOADING = 'GET_CHAPTER_PLANNING_DOCUMENT_INTERVIEWS_LOADING';

//Get Region List
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_ERROR = 'GET_REGIONS_ERROR';
export const GET_REGIONS_LOADING = 'GET_REGIONS_LOADING';

//Get Invitation Request List
export const GET_INVITATION_REQUESTS_SUCCESS = 'GET_INVITATION_REQUESTS_SUCCESS';
export const GET_INVITATION_REQUESTS_ERROR = 'GET_INVITATION_REQUESTS_ERROR';
export const GET_INVITATION_REQUESTS_LOADING = 'GET_INVITATION_REQUESTS_LOADING';

//Get School List yorganizationid
export const GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_SUCCESS = 'GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_SUCCESS';
export const GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_ERROR = 'GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_ERROR';
export const GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_LOADING = 'GET_COLLEGEOFFICIAL_SCHOOLS_HISTORY_LOADING';

//Financial Status
export const GET_INTAKE_SEASON_LOADING = 'GET_INTAKE_SEASON_LOADING';
export const GET_INTAKE_SEASON_SUCCESS = 'GET_INTAKE_SEASON_SUCCESS';
export const GET_INTAKE_SEASON_ERROR = 'GET_INTAKE_SEASON_ERROR';

export const GET_TICKET_DETAILS_RESET = 'GET_TICKET_DETAILS_RESET';

//Get Officers
export const GET_OFFICERS_LOADING = 'GET_OFFICERS_LOADING';
export const GET_OFFICERS_SUCCESS = 'GET_OFFICERS_SUCCESS';
export const GET_OFFICERS_ERROR = 'GET_OFFICERS_ERROR';

//Get Officers
export const GET_CHAPTER_ACTIVITY_LOADING = 'GET_CHAPTER_ACTIVITY_LOADING';
export const  GET_CHAPTER_ACTIVITY_SUCCESS = 'GET_CHAPTER_ACTIVITY_SUCCESS';
export const GET_CHAPTER_ACTIVITY_ERROR = 'GET_CHAPTER_ACTIVITY_ERROR';

export const GET_MEMBER_MANAGED_ENTITIES_LOADING='GET_MEMBER_MANAGED_ENTITIES_LOADING';
export const GET_MEMBER_MANAGED_ENTITIES_SUCCESS='GET_MEMBER_MANAGED_ENTITIES_SUCCESS';
export const GET_MEMBER_MANAGED_ENTITIES_ERROR='GET_MEMBER_MANAGED_ENTITIES_ERROR';

export const GET_INTAKE_PAYMENT_SUMMARY_LOADING='GET_INTAKE_PAYMENT_SUMMARY_LOADING';
export const GET_INTAKE_PAYMENT_SUMMARY_SUCCESS='GET_INTAKE_PAYMENT_SUMMARY_SUCCESS';
export const GET_INTAKE_PAYMENT_SUMMARY_ERROR='GET_INTAKE_PAYMENT_SUMMARY_ERROR';


export const GET_FINANCE_PRODUCT_TRANSPORT_REPORT_LOADING='GET_FINANCE_PRODUCT_TRANSPORT_REPORT_LOADING';
export const GET_FINANCE_PRODUCT_TRANSPORT_REPORT_SUCCESS='GET_FINANCE_PRODUCT_TRANSPORT_REPORT_SUCCESS';
export const GET_FINANCE_PRODUCT_TRANSPORT_REPORT_ERROR='GET_FINANCE_PRODUCT_TRANSPORT_REPORT_ERROR';


export const GET_FINANCE_REPORTING_FILTER_LOADING='GET_FINANCE_REPORTING_FILTER_LOADING';
export const GET_FINANCE_REPORTING_FILTER_SUCCESS='GET_FINANCE_REPORTING_FILTER_SUCCESS';
export const GET_FINANCE_REPORTING_FILTER_ERROR='GET_FINANCE_REPORTING_FILTER_ERROR';


//GetChapterDuesSummary  Status
export const GET_CHAPTER_DUES_SUMMARY_LOADING = 'GET_CHAPTER_DUES_SUMMARY_LOADING';
export const GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_LOADING = 'GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_LOADING';
export const GET_CHAPTER_DUES_SUMMARY_SUCCESS = 'GET_CHAPTER_DUES_SUMMARY_SUCCESS';
export const GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_SUCCESS = 'GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_SUCCESS';
export const GET_CHAPTER_DUES_SUMMARY_ERROR = 'GET_CHAPTER_DUES_SUMMARY_ERROR';
export const GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_ERROR = 'GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_ERROR';


//Upcoming Annual Dues 
export const UPCOMING_FISCAL_YEAR_DETAIL_LOADING = 'UPCOMING_FISCAL_YEAR_DETAIL_LOADING';
export const UPCOMING_FISCAL_YEAR_DETAIL_SUCCESS = 'UPCOMING_FISCAL_YEAR_DETAIL_SUCCESS';
export const UPCOMING_FISCAL_YEAR_DETAIL_ERROR = 'UPCOMING_FISCAL_YEAR_DETAIL_ERROR';


//Upcoming Annual Dues 
export const GET_INVITES_LOADING = 'GET_INVITES_LOADING';
export const GET_INVITES_SUCCESS = 'GET_INVITES_SUCCESS';
export const GET_INVITES_ERROR = 'GET_INVITES_ERROR';


//GetQuickLinks
export const GET_QUICK_LINKS_SUCCESS = 'GET_QUICK_LINKS_SUCCESS';
export const GET_QUICK_LINKS_ERROR = 'GET_QUICK_LINKS_ERROR';
export const GET_QUICK_LINKS_LOADING = 'GET_QUICK_LINKS_LOADING';

//GetDashboardTabs
export const GET_DASHBOARD_TABS_SUCCESS = 'GET_DASHBOARD_TABS_SUCCESS';
export const GET_DASHBOARD_TABS_ERROR = 'GET_DASHBOARD_TABS_ERROR';
export const GET_DASHBOARD_TABS_LOADING = 'GET_DASHBOARD_TABS_LOADING';

//Financial Status
export const GET_TICKET_PURCHASE_ORDER_HISTORY_LOADING = 'GET_TICKET_PURCHASE_ORDER_HISTORY_LOADING';
export const GET_TICKET_PURCHASE_ORDER_HISTORY_SUCCESS = 'GET_TICKET_PURCHASE_ORDER_HISTORY_SUCCESS';
export const GET_TICKET_PURCHASE_ORDER_HISTORY_ERROR = 'GET_TICKET_PURCHASE_ORDER_HISTORY_ERROR';

//Member Organization Profile
export const GET_MEMBER_PENDING_ROSTER_ENTRIES_SUCCESS = 'GET_MEMBER_PENDING_ROSTER_ENTRIES_SUCCESS';
export const GET_MEMBER_PENDING_ROSTER_ENTRIES_ERROR = 'GET_MEMBER_PENDING_ROSTER_ENTRIES_ERROR';
export const GET_MEMBER_PENDING_ROSTER_ENTRIES_LOADING = 'GET_MEMBER_PENDING_ROSTER_ENTRIES_LOADING';

//SignalR
export const SIGNALR_CONNECT = 'SIGNALR_CONNECT';
export const SIGNALR_DISCONNECT = 'SIGNALR_DISCONNECT';

//messages
export const ADD_MESSAGE='ADD_MESSAGE';

//chat box 
export const ADD_CHAT_BOX = 'ADD_CHAT_BOX';
export const REMOVE_CHAT_BOX = 'REMOVE_CHAT_BOX';