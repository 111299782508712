import { getOrganizationDetailsforProspectsInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetOrganizationDetailsForProspectsReducer(state = getOrganizationDetailsforProspectsInitialValues, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_ORGANIZATION_DETAILS_FOR_PROSPECT_LOADING:
            return { payload, loading: true, error: false  };
        case types.GET_ORGANIZATION_DETAILS_FOR_PROSPECT_SUCCESS:
            return { ...state, ...payload, loading: false, error: false  };
        case types.GET_ORGANIZATION_DETAILS_FOR_PROSPECT_ERROR:
            return { payload, loading: false, error: true };
        default:
            return state;
    }
}




