import { commonListingIArraynitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetSignQueueListReducer(state = commonListingIArraynitialValues,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_SIGNQUEUE_LIST_LOADING:
            return {data: [],loading:true,error:false};   

        case types.GET_SIGNQUEUE_LIST_SUCCESS:
            return {data: payload,loading:false,error:false}; 
            
        case types.GET_SIGNQUEUE_LIST_ERROR:
            return {data: payload,loading:false,error:true};  
        default:
            return state
    }
}