import { initialEventFilterOptionsValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetEventFilterOptionsReducer(state = initialEventFilterOptionsValues, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_EVENT_FILTER_OPTIONS_LOADING:
            return { ...state, loading: true, error: false };




        case types.GET_EVENT_FILTER_OPTIONS_SUCCESS:
            // return {...state,data:payload,loading:false,error:false}; 

            const { eventTypeSubs = [], organizations = [], eventTypes = [], leadershipOrganizations = [], season = [], allOrganization = [], } = payload;
            return {
                loading: false,
                eventTypeSubs,
                organizations,
                eventTypes,
                leadershipOrganizations,
                season,
                allOrganization,
                error: false
            };

        case types.GET_EVENT_FILTER_OPTIONS_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state
    }
}



