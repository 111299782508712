import React, { useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { CHAT_MESSAGES } from "../../constants/apiPaths";
import {
  LOCALSTORAGE_ENTITYTYPEID,
  LOCALSTORAGE_AUTHTOKEN,
  LOCALSTORAGE_SOURCEOWNERID,
} from "../../constants/LocalStorage";
import ChatBox from "../chat/miniChat";

const ChatCall = async (inputValue, messages, setMessages,connection,messageTo) => {
  // console.log(inputValue, "inputValueinputValue", messages, setMessages);

  const entityTypeId = localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID);
  const sourceOwnerId = localStorage.getItem(LOCALSTORAGE_SOURCEOWNERID);
  const Token = localStorage.getItem(LOCALSTORAGE_AUTHTOKEN);

  const concat = "Bearer " + Token;
  // console.log(concat, " Token");

  // let connection;

  // console.log(connection, "Connection obj from ChatCall");

  if (connection == undefined) {
    connection = new signalR.HubConnectionBuilder()
      .withUrl(CHAT_MESSAGES, { accessTokenFactory: () => concat })
      // .withAutomaticReconnect()
      .withAutomaticReconnect([0, 500, 1000, 1500, 2000, 3000])
      .build();

    connection.onreconnecting((error) => {
      console.warn(`Connection lost due to "${error}", attempting to reconnect...`);
      // You can show a message to users or update UI to reflect the reconnecting status.
    });

    connection.onreconnected((connectionId) => {
      console.log(`Connection reestablished. Connected with connectionId "${connectionId}".`);
      // You can update the UI to show that the connection has been reestablished.
    });

    connection.onclose((error) => {
      console.error("Connection closed with error: ", error);
      // You can display a message to the user or update the UI to show that the connection has been lost.
    });

    await connection
      .start()
      .then(() => console.log("SignalR connection started!"))
      .catch((err) => console.error("Error starting SignalR connection: ", err));
  }

  // if (connection.state === signalR.HubConnectionState.Disconnected) {
  //   await connection.start().then(() => {
  //     console.log("SignalR connection started!");
  //   }).catch((err) => {
  //     console.error("SignalR connection started NOT ", err);
  //   });
  // }
  if (connection) {
    if (entityTypeId == 2 && sourceOwnerId == 3) {

      const newTo = messageTo.split("_")

       connection
        // .send("SendMessage", parseInt(entityTypeId), parseInt(sourceOwnerId), 2, 42419, inputValue)
        .send("SendMessage", parseInt(entityTypeId), parseInt(sourceOwnerId), parseInt(newTo[0]), parseInt(newTo[1]), inputValue)
        .then(() => console.log(`Message send to ${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)},2,42419, inputValue`))
        .catch((error) => console.error("SignalR Error while sending message: ", error));
    } else {
       connection
        .send("SendMessage", parseInt(entityTypeId), parseInt(sourceOwnerId), 2, 3, inputValue)
        .then(() => console.log(`Message send to ${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)},2,3, inputValue`))
        .catch((error) => console.error("SignalR Error while sending message: ", error));
    }

    // connection.on(`Chat:${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}`, async (message) => {
    //   console.log("Received Message:", JSON.stringify(message));
    //   const newMessage = {
    //     from: message.from,
    //     to: message.to,
    //     timestamp: message.timestamp,
    //     message: message.message,
    //   };
    //   setMessages((prevMessages) => [...prevMessages, newMessage]);
    //   connection.off(`Chat:${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}`);
    // });


    // return () => {
    //   connection.off(`Chat:${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}`);
    // };
  }
};

const ChatCallWrapper = async (inputValue, messages, setMessages,connection,messageTo) => {
  await ChatCall(inputValue, messages, setMessages,connection,messageTo);
};

export default ChatCallWrapper;
