import { MemberManagedEntityinitials } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetMemberManagedEntityReducer(state = MemberManagedEntityinitials,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_MEMBER_MANAGED_ENTITIES_LOADING:
            return {data:[],loading:true,error:false};   

        case types.GET_MEMBER_MANAGED_ENTITIES_SUCCESS:
            return {data:payload,loading:false,error:false}; 
            
        case types.GET_MEMBER_MANAGED_ENTITIES_ERROR:
            return {data:[],loading:false,error:true};  
        default:
            return state
    }
}


// GET_MEMBER_MANAGED_ENTITIES_URL