import { getPossibleProductGroupValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetPossibleProductGroupReducer(state = getPossibleProductGroupValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_POSSIBLE_PRODUCT_GROUP_LOADING:
            return {...state,loading:true};
        case types.GET_POSSIBLE_PRODUCT_GROUP_SUCCESS:
            return {...state,data:payload,loading:false};
        case types.GET_POSSIBLE_PRODUCT_GROUP_ERROR:
            return {...state,loading:false};
        default:
            return state;
    }
}