import React, { Fragment, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import { debounce } from 'underscore';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { CustomAlertMessage, detectCardType, staticMonthArray, staticYearArray } from '../../functions/CustomFunctions';
import { AccountTypes, BANK_ACCOUNT_TYPE, CREDIT_CARD_TYPE, DEFAULT_COUNTRY_NAME, ENTITY_TYPE_ID_FOR_MEMBER, PaymentMethodsCardIcon } from '../../constants/HelperConstants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FIVE_DIGIT_NUMBER_REGEX, MAXIMUM_FIVE_DIGITS_REGEX, NINE_DIGIT_REGEX, ZIPCODE_REGEX } from '../../constants/ValidationRegex';
import { CREATE_NEW_PAYMENT_METHOD_URL } from '../../constants/apiPaths';
import { authHeader, getLoggedUserMemberId, loggedTokenDetails } from '../../functions/CoreFunctions';
import API from '../../redux/actions/API';
import { useSnackbar } from 'notistack';
import { getPaymentMethods, getSignUpStepsFormFieldOptions } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
    continuebtn: {
        color: '#fff',
        width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px',
        opacity: 1,
        '&:hover': {
            opacity: 0.7,
        }
    },
    formHeader: {
        fontSize: 'x-large',
        marginBottom: '1rem',
        textAlign: 'center'
    },
    table: {
        maxWidth: 400,
    },
    borderlessStyle: {
        borderBottom: 'none',
        fontWeight: 600
    },
    tabSection: {
        border: `2px solid ${theme.primaryColor}`,
        padding: '1px 0px 1.5px 0px',
        borderRadius: '25px',
        minHeight: '43px'
    },
    activeTabStyle: {
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '25px',
        color: '#fff !important',
        minHeight: '33px',
        minWidth: '186px',
    },
    inactiveTabStyle: {
        color: theme.primaryColor,
        minHeight: '33px',
        minWidth: '160px',
    },
    formControl: {
        width: "100%"
    }
}));

const initialValues = {
    cardHolderName: '',
    achpayId: 0,
    ccpayId: 0,
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    securityCode: '',
    bankName: '',
    accountHolderName: '',
    bankAccountType: '',
    accountNumber: '',
    routingNumber: '',
    sameAsCurrentAddress: false,
    addressLine: '',
    city: '',
    state: '',
    country: 'United States',
    zipCode: '',
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

// Custom Checkbox
const CustomCheckbox = withStyles(theme => ({
    root: {
        color: theme.primaryColor,
        '&$checked': {
            color: theme.primaryColor,
        },
    },
    checked: {},
}))((props) => <Checkbox color="default" {...props} />);

export default function PaymentMethodCreateForm(props) {
    const { currentInformationSource, showPaymentMethodCreateTypes = [], entityTypeId, sourceOwnerId, achPay = {},
        ccPay = {}, handleInitiallizeValues, selectedFromListCallBack = null, setSelectedPaymentMethod = '' } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        countries = [], usStates = []
    } = useSelector((state) => state.GeographyDataReducer);

    const [formValues, setFormValues] = useState(initialValues);
    const [activeTab, setActiveTab] = useState(0);
    const [userCardType, setCardType] = useState('');
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    console.info(formValues, 'formValues')
    //On Change Tab
    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    //Detect Entered Card Type
    const checkGivenCarType = (cardNumber) => {
        let { cardType, isCardValid } = detectCardType(cardNumber);
        if (!isCardValid) setFieldError('cardNumber', 'Invalid Card Number.');
        setCardType(cardType);
    }

    //Debounce
    const delayedQuery = useRef(debounce((cardNumber) => checkGivenCarType(cardNumber), 300)).current;
    //Detect Entered Card Type
    const detectEnteredCardType = ({ target: { name, value } }) => {
        let trimmedValue = value.replace(/[^0-9]/g, '');
        if (trimmedValue) delayedQuery(trimmedValue);
        else setCardType('');
    }

    //Save Form Data
    const saveFormValues = async (values) => {
        // const loggedUserId = getLoggedUserMemberId();
        if (userCardType == 'AMEX' || userCardType == 'AMER') {
            CustomAlertMessage("Unable to accept american express. Please choose a different card.", 'error', enqueueSnackbar);

        } else {

            const {
                firstName, lastName
            } = currentInformationSource;
            const dataToStore = {
                sourceOwnerId: parseInt(sourceOwnerId),
                entityTypeId: entityTypeId,
                firstName,
                lastName,
                paymentType: (parseInt(activeTab) === 0) ? 2 : 1,  // 1 - ACH : Bank Account / 2 - CC : Debit or Credit
                achPaymentInfo: {
                    achpayId: parseInt(values.achpayId),
                    sourceOwnerId: parseInt(sourceOwnerId),
                    entityTypeId: entityTypeId,
                    accountHolderName: values.accountHolderName,
                    bankName: values.bankName,
                    accountType: values.bankAccountType,
                    routingNumber: values.routingNumber,
                    bankAccountNumber: values.accountNumber,
                },
                ccPaymentInfo: {
                    ccpayId: parseInt(values.ccpayId),
                    sourceOwnerId: parseInt(sourceOwnerId),
                    entityTypeId: entityTypeId,
                    accountHolderName: values.cardHolderName,
                    cardNumber: values.cardNumber,
                    cardType: (parseInt(activeTab) === 0) ? PaymentMethodsCardIcon[userCardType].title : '',
                    expirationDate: `${values.expirationYear}${(parseInt(values.expirationMonth) < 10) ? `0${values.expirationMonth}` : values.expirationMonth}`,
                    cvv: values.securityCode,
                },
                billingAddress: {
                    billAddress: values.addressLine,
                    billCity: values.city,
                    billState: values.state,
                    billZip: values.zipCode,
                    billCountry: values.country,
                    ForteBillingAddressToken: ''
                }
            };
            try {
                setLoading(true);
                const options = {
                    url: CREATE_NEW_PAYMENT_METHOD_URL,
                    method: 'POST',
                    data: dataToStore,
                    headers: authHeader(),
                };
                const { data: apiResponse } = await API(options);
                const { status, payRecordId = 0, resp } = apiResponse;
                if (parseInt(status) === 1) {
                    if (selectedFromListCallBack) {
                        selectedFromListCallBack(payRecordId);
                    }
                    if (handleInitiallizeValues) {
                        handleInitiallizeValues();
                    }
                    // dispatch(getPaymentMethods({
                    //     entityTypeId: entityTypeId,
                    //     sourceOwnerId: sourceOwnerId
                    // }));
                } else {
                    CustomAlertMessage(resp, 'error', enqueueSnackbar);
                }
            } catch (error) {
                if (error.response) {
                    let { data } = error.response;
                    CustomAlertMessage(data.message, 'error', enqueueSnackbar);
                }
            } finally {
                setLoading(false);
            }
        }

    }

    //Fill Current Information
    const fillCurrentInformation = (checked) => {
        const {
            address, city, country, state, zip
        } = currentInformationSource;
        // console.log('currentInformationSource',currentInformationSource,countries)
        let address_value = '', city_value = '',
            // country_value = '', 
            state_value = '', zipCode_value = '';
        if (checked) {
            address_value = address; city_value = city; zipCode_value = zip;
            // country_value = countries.find(record => parseInt(record.addressCountryID) === parseInt(country)).name;
            state_value = state;
        }
        setFieldValue('sameAsCurrentAddress', checked);
        setFieldValue('addressLine', address_value);
        setFieldValue('city', city_value);
        setFieldValue('country', country=='US'?'United States':country);
        setFieldValue('state', state_value);
        setFieldValue('zipCode', zipCode_value);
    }

    //Months
    const monthsArray = staticMonthArray().map((month, index) =>
        <MenuItem value={month} key={`month_list_${index}`}>
            {month}
        </MenuItem>
    );

    //Years
    const yearsArray = staticYearArray().map((year, index) =>
        <MenuItem value={year} key={`month_list_${index}`}>
            {year}
        </MenuItem>
    );

    //Bank Account Types    
    const bankAccountTypes = AccountTypes.map((type, index) =>
        <MenuItem value={type} key={`acc_type_${index}`}>
            {type}
        </MenuItem>
    );

    //Countries
    const countriesList = countries.map(({ name }, index) =>
        <MenuItem value={name} key={`cty_code_${index}`}>
            {name}
        </MenuItem>
    );

    //States
    const usstatesList = usStates.map(({ name, abbreviation }, index) =>
        <MenuItem value={name} key={`cty_code_${index}`}>
            {abbreviation}{' '}{name}
        </MenuItem>
    );

    //Schema
    let validationSchema = Yup.object({
        addressLine: Yup.string().trim().required('Address line is required'),
        city: Yup.string().trim().required('City is required'),
        country: Yup.string().trim().required('Country is required'),
        state: Yup.string().trim().required('State is required'),
        // zipCode: Yup.string().trim().required('Zip code is required').matches(FIVE_DIGIT_NUMBER_REGEX, 'Give valid zip code. (ex : xxxxx)')
        zipCode: Yup.string().trim().when('country',
            (country, schema) => {
                // console.info(country, DEFAULT_COUNTRY_NAME,country === DEFAULT_COUNTRY_NAME, 'DEFAULT_COUNTRY_NAME')
                if (country === DEFAULT_COUNTRY_NAME) {
                    return schema.required('Zip code is required')
                        .matches(FIVE_DIGIT_NUMBER_REGEX, 'Give valid zip code. (ex : xxxxx)');
                } else {
                    return schema.required('Zip code is required').matches(ZIPCODE_REGEX, "Must only be 5 digits")
                        .max(10, 'Give valid Zip Code.');
                }
            }),
    });
    if (parseInt(activeTab) === 0) {
        validationSchema = validationSchema.shape({
            cardHolderName: Yup.string().trim().required('Card holder name is required'),
            cardNumber: Yup.string().trim().required('Card number is required'),
            expirationMonth: Yup.string().trim().required('Expiration month is required'),
            expirationYear: Yup.string().trim().required('Expiration year is required'),
            securityCode: Yup.string().trim().required('Security code is required').matches(MAXIMUM_FIVE_DIGITS_REGEX, 'Give valid security code.'),
        });
    } else {
        validationSchema = validationSchema.shape({
            bankName: Yup.string().trim().required('Bank name is required'),
            accountHolderName: Yup.string().trim().required('Account holder name is required'),
            bankAccountType: Yup.string().trim().required('Bank account type is required'),
            accountNumber: Yup.string().trim().required('Account number is required'),
            routingNumber: Yup.string().trim().required('Routing number is required').matches(NINE_DIGIT_REGEX, 'Give valid routing number.  (Must be 9 digits)'),
        });
    };

    //Formik
    const {
        handleSubmit, handleChange, values, errors, touched, setFieldTouched,
        setFieldValue, setFieldError
    } = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values) => saveFormValues(values)
    });

    const {
        cardHolderName = '', cardNumber = '', expirationMonth = '', expirationYear = '', securityCode = '',
        accountHolderName = '', bankAccountType = '', accountNumber = '', routingNumber = '', bankName = '',
        sameAsCurrentAddress = false, addressLine = '', city = '', state = '', country = '', zipCode = ''
    } = values;

    useEffect(() => {
        dispatch(getSignUpStepsFormFieldOptions(enqueueSnackbar));
    }, []);

    useEffect(() => {
        let formData = {}

        if (Object.keys(achPay).length !== 0 && achPay.constructor === Object) {
            formData = achPay
            setActiveTab(1)
        }
        else if (Object.keys(ccPay).length !== 0 && ccPay.constructor === Object) {
            formData = ccPay
            setActiveTab(0)
        }
        if (Object.keys(formData).length !== 0 && formData.constructor === Object) {
            // console.log('formData',formData)
            setFormValues({
                achpayId: formData.achpayId !== undefined ? formData.achpayId : 0,
                ccpayId: formData.ccpayId !== undefined ? formData.ccpayId : 0,
                cardHolderName: formData.cardHolderName !== undefined ? formData.cardHolderName : '',
                cardNumber: formData.cardNumber !== undefined ? formData.cardNumber : '',
                expirationMonth: formData.expirationMonth !== undefined ? formData.expirationMonth : '',
                expirationYear: formData.expirationYear !== undefined ? formData.expirationYear : '',
                securityCode: formData.securityCode !== undefined ? formData.securityCode : '',
                bankName: formData.bankName !== undefined ? formData.bankName : '',
                accountHolderName: formData.accountHolderName !== undefined ? formData.accountHolderName : '',
                bankAccountType: formData.accountType !== undefined ? formData.accountType : '',
                accountNumber: formData.bankAccountNumber !== undefined ? formData.bankAccountNumber : '',
                routingNumber: formData.routingNumber !== undefined ? formData.routingNumber : '',
                sameAsCurrentAddress: false,
                addressLine: formData.billAddress !== undefined && formData.billAddress !== null ? formData.billAddress : '',
                city: formData.billCity !== undefined && formData.billCity !== null ? formData.billCity : '',
                state: formData.billState !== undefined && formData.billState !== null ? formData.billState : '',
                country: 'United States',
                zipCode: formData.billZip !== undefined && formData.billZip !== null ? formData.billZip : '',
            })


        }
    }, [achPay, ccPay])

    useEffect(() => {
        if (showPaymentMethodCreateTypes.length > 1) {
            setActiveTab(0);
        } else {
            setActiveTab(showPaymentMethodCreateTypes.includes(BANK_ACCOUNT_TYPE) ? 1 : 0);
        }

    }, [showPaymentMethodCreateTypes.length]);

    return (
        <Fragment>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    {(showPaymentMethodCreateTypes.length > 1) ?
                        <>
                            {/* <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }}/> */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Tabs
                                    value={activeTab}
                                    onChange={handleChangeTab}
                                // indicatorColor="primary"
                                // textColor="primary"
                                // TabIndicatorProps={{ style: { display: "none", } }}
                                // className={classes.tabSection}
                                // centered
                                // variant="scrollable"
                                // scrollButtons="auto"
                                >
                                    {showPaymentMethodCreateTypes.includes(CREDIT_CARD_TYPE) &&
                                        <Tab label="Card"
                                        // className={(activeTab === 0) ? classes.activeTabStyle : classes.inactiveTabStyle}
                                        />
                                    }
                                    {showPaymentMethodCreateTypes.includes(BANK_ACCOUNT_TYPE) &&
                                        <Tab label="Bank Account"
                                        // className={(activeTab === 1) ? classes.activeTabStyle : classes.inactiveTabStyle}
                                        />
                                    }
                                </Tabs>
                            </Grid>
                            {/* <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }}/> */}
                        </>
                        : ''}

                    <Grid item xs={12}>
                        <TabPanel value={activeTab} index={0}>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors.cardHolderName && touched.cardHolderName}
                                        id="cardHolderName-helper-text"
                                        label="Card Holder Name"
                                        value={cardHolderName}
                                        fullWidth
                                        name='cardHolderName'
                                        helperText={(errors.cardHolderName && touched.cardHolderName) ? errors.cardHolderName : ''}
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors.cardNumber && touched.cardNumber}
                                        id="cardNumber-helper-text"
                                        label="Card Number"
                                        value={cardNumber}
                                        fullWidth
                                        name='cardNumber'
                                        helperText={(errors.cardNumber && touched.cardNumber) ? errors.cardNumber : ''}
                                        variant="outlined"
                                        onChange={(event) => {
                                            setFieldTouched('cardNumber', true);
                                            handleChange(event);
                                            detectEnteredCardType(event);
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {userCardType && <Icon className={PaymentMethodsCardIcon[userCardType].icon} style={{ width: '100%' }} />}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl variant="outlined" className={classes.formControl} error={errors.expirationMonth && touched.expirationMonth}>
                                        <InputLabel id="expirationMonth-label">Expiration Month</InputLabel>
                                        <Select
                                            labelId="expirationMonth-label"
                                            id="expirationMonth"
                                            name='expirationMonth'
                                            value={expirationMonth}
                                            onChange={handleChange}
                                            fullWidth
                                            label="Expiration Month"
                                        >
                                            <MenuItem value="">Select Month</MenuItem>
                                            {monthsArray}
                                        </Select>
                                        <FormHelperText>{(errors.expirationMonth && touched.expirationMonth) ? errors.expirationMonth : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl variant="outlined" className={classes.formControl} error={errors.expirationYear && touched.expirationYear}>
                                        <InputLabel id="expirationYear-label">Expiration Year</InputLabel>
                                        <Select
                                            labelId="expirationYear-label"
                                            id="expirationYear"
                                            name='expirationYear'
                                            value={expirationYear}
                                            onChange={handleChange}
                                            fullWidth
                                            label="Expiration Year"
                                        >
                                            <MenuItem value="">Select Year</MenuItem>
                                            {yearsArray}
                                        </Select>
                                        <FormHelperText>{(errors.expirationYear && touched.expirationYear) ? errors.expirationYear : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <TextField
                                        error={errors.securityCode && touched.securityCode}
                                        id="securityCode-helper-text"
                                        label="Security Code"
                                        value={securityCode}
                                        fullWidth
                                        name='securityCode'
                                        helperText={(errors.securityCode && touched.securityCode) ? errors.securityCode : ''}
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={activeTab} index={1}>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors.bankName && touched.bankName}
                                        id="bankName-helper-text"
                                        label="Bank Name"
                                        value={bankName}
                                        fullWidth
                                        name='bankName'
                                        helperText={(errors.bankName && touched.bankName) ? errors.bankName : ''}
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors.accountHolderName && touched.accountHolderName}
                                        id="accountHolderName-helper-text"
                                        label="Account Holder Name"
                                        value={accountHolderName}
                                        fullWidth
                                        name='accountHolderName'
                                        helperText={(errors.accountHolderName && touched.accountHolderName) ? errors.accountHolderName : ''}
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl variant="outlined" className={classes.formControl} error={errors.bankAccountType && touched.bankAccountType}>
                                        <InputLabel id="bankAccountType-label">Bank Account Type</InputLabel>
                                        <Select
                                            labelId="bankAccountType-label"
                                            id="bankAccountType"
                                            name='bankAccountType'
                                            value={bankAccountType}
                                            onChange={handleChange}
                                            fullWidth
                                            label="Bank Account Type"
                                        >
                                            <MenuItem value="">Select Account Type</MenuItem>
                                            {bankAccountTypes}
                                        </Select>
                                        <FormHelperText>{(errors.bankAccountType && touched.bankAccountType) ? errors.bankAccountType : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        error={errors.accountNumber && touched.accountNumber}
                                        id="accountNumber-helper-text"
                                        label="Account Number"
                                        value={accountNumber}
                                        fullWidth
                                        name='accountNumber'
                                        helperText={(errors.accountNumber && touched.accountNumber) ? errors.accountNumber : ''}
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors.routingNumber && touched.routingNumber}
                                        id="routingNumber-helper-text"
                                        label="Routing Number"
                                        value={routingNumber}
                                        fullWidth
                                        name='routingNumber'
                                        helperText={(errors.routingNumber && touched.routingNumber) ? errors.routingNumber : ''}
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.formHeader}>
                            Provide Billing Address
                        </Typography>
                    </Grid>

                    {(Object.keys(currentInformationSource).length > 0 && (entityTypeId == 2 || entityTypeId == 6)) ?

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<CustomCheckbox checked={sameAsCurrentAddress}
                                    onChange={({ target: { checked } }) => {
                                        fillCurrentInformation(checked);
                                    }}
                                    name="sameAsCurrentAddress"
                                    color="primary" />}
                                label="Same as current address"
                            />
                        </Grid>
                        :
                        null
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <TextField
                                    error={errors.addressLine && touched.addressLine}
                                    id="addressLine-helper-text"
                                    label="Address Line"
                                    value={addressLine}
                                    fullWidth
                                    name='addressLine'
                                    helperText={(errors.addressLine && touched.addressLine) ? errors.addressLine : ''}
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={errors.city && touched.city}
                                    id="city-helper-text"
                                    label="City"
                                    value={city}
                                    fullWidth
                                    name='city'
                                    helperText={(errors.city && touched.city) ? errors.city : ''}
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" className={classes.formControl} error={errors.country && touched.country}>
                                    <InputLabel id="country-label">Country</InputLabel>
                                    <Select
                                        error={errors.country && touched.country}
                                        labelId="country-label"
                                        id="country"
                                        name='country'
                                        value={country}
                                        onChange={handleChange}
                                        fullWidth
                                        label="Country"
                                    >
                                        <MenuItem value="">Select Country</MenuItem>
                                        {countriesList}
                                    </Select>
                                    <FormHelperText>{(errors.country && touched.country) ? errors.country : ''}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                {(country === 'United States') ?
                                    <FormControl variant="outlined" className={classes.formControl} error={errors.state && touched.state}>
                                        <InputLabel id="state-label">State</InputLabel>
                                        <Select
                                            labelId="state-label"
                                            id="state"
                                            name='state'
                                            value={state}
                                            onChange={handleChange}
                                            fullWidth
                                            label="state"
                                            error={errors.state && touched.state}
                                        >
                                            <MenuItem value="">Select State</MenuItem>
                                            {usstatesList}
                                        </Select>
                                        <FormHelperText>{(errors.state && touched.state) ? errors.state : ''}</FormHelperText>
                                    </FormControl>
                                    :
                                    <TextField
                                        error={errors.state && touched.state}
                                        id="state-helper-text"
                                        label="State"
                                        value={state}
                                        fullWidth
                                        name='state'
                                        helperText={(errors.state && touched.state) ? errors.state : ''}
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                }
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    error={errors.zipCode && touched.zipCode}
                                    id="zipCode-helper-text"
                                    label="Zip"
                                    value={zipCode}
                                    fullWidth
                                    name='zipCode'
                                    helperText={(errors.zipCode && touched.zipCode) ? errors.zipCode : ''}
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type='submit' variant='contained' className={classes.continuebtn}>
                            {loading && <CircularProgress size={14} style={{ color: '#fff' }} />}{' '}
                            Save Payment Information
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Fragment>
    );
}