import { objectInitial } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function LoggedUserPaymentDetailsReducer(state = objectInitial, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.LOGGED_USER_PAYMENT_DEYAILS_LOADING:
            return {payload,loading:true};
        case types.LOGGED_USER_PAYMENT_DEYAILS_SUCCESS:
            return {payload,loading:false};
        case types.LOGGED_USER_PAYMENT_DEYAILS_ERROR:
            return {payload,loading:false};
        default:
            return state;
    }
}