import React, { useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { SEND_LOGIN_SIGNAL } from "../../constants/apiPaths";
import {
  LOCALSTORAGE_ENTITYTYPEID,
  LOCALSTORAGE_AUTHTOKEN,
  LOCALSTORAGE_SOURCEOWNERID,
  LOCALSTORAGE_PROFILE
} from "../../constants/LocalStorage";

const LoginSignal = async () => {

  const entityTypeId = localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID)
  const sourceOwnerId = localStorage.getItem(LOCALSTORAGE_SOURCEOWNERID)

  const profile = localStorage.getItem(LOCALSTORAGE_PROFILE);
  const ProfileString = JSON.parse(profile);
  const { Profile = {} } = ProfileString || {};
  const { firstName = '', lastName = '' } = Profile || {};
  const Token = localStorage.getItem(LOCALSTORAGE_AUTHTOKEN)

  const name = firstName + ' ' + lastName;
  const finalToken = "Bearer " + Token

  // console.log("path", SEND_LOGIN_SIGNAL, entityTypeId, " entityTypeId ", sourceOwnerId, "sourceOwnerId");
  // console.log(finalToken, " Token");

  const LoginConnection = new signalR.HubConnectionBuilder()
    .withUrl(SEND_LOGIN_SIGNAL)
    .withAutomaticReconnect()
    .build();

  await LoginConnection.start()
    .then(() => console.log('Login Notified'))
    .catch(error => console.error('SignalR Connection Error: ', error));

  await LoginConnection.send('SendOnlineStatus', parseInt(entityTypeId), parseInt(sourceOwnerId), name)
    .then(() => console.log('SendOnlineStatus Successful'))
    .catch(error => console.error('SendOnlineStatus Error: ', error));

  const intervalId = setInterval(async () => {
    console.log(LoginConnection,"LoginConnection in set interval");
    if (LoginConnection) {
      // console.log("interval called every 5 min", entityTypeId)
      // await connection.send('SendOnlineStatus', entityTypeId, sourceOwnerId)
      await LoginConnection.send('SendOnlineStatus', parseInt(entityTypeId), parseInt(sourceOwnerId), name)
        .then(() => console.log('SendOnlineStatus Successful RECALL ', parseInt(entityTypeId), parseInt(sourceOwnerId)))
        .catch(error => console.error('SignalR Invocation Error from SingIN: ', error));
    }else{
      console.log("interval called every 5 min NOT")
    }
  }, 300000);

  // 300000); // 5 minutes in milliseconds

  return () => {
    clearInterval(intervalId);
    // LoginConnection.stop();
  };
};

export default LoginSignal;
