import { initialGetAccountSummaryTransactions } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetAccountSummaryTransactionsReducer(state = initialGetAccountSummaryTransactions, action) {
    const { type, payload, totalCount, appendWithPreviousList, EntityTypeID } = action;
    switch (type) {
        case types.GET_ACCOUNT_SUMMARY_TRANACTIONS_LOADING:
            return { ...state, loading: true, error: false };

        case types.GET_ACCOUNT_SUMMARY_TRANACTIONS_SUCCESS:
            const { moreTransactions = {} } = state;
            if (EntityTypeID == 2 && appendWithPreviousList) {
                return {
                    ...state,
                    moreTransactions: { lastHundredtransactionsByPurchaseOrders: appendWithPreviousList ? moreTransactions.lastHundredtransactionsByPurchaseOrders.concat(payload.lastHundredtransactionsByPurchaseOrders) : payload.lastHundredtransactionsByPurchaseOrders },
                    totalCount,
                    loading: false,
                    error: false
                };
            } else if (EntityTypeID == 1 && appendWithPreviousList) {

                return {
                    ...state,
                    moreTransactions: { lastHundredtransactions: appendWithPreviousList ? moreTransactions.lastHundredtransactions.concat(payload.lastHundredtransactions) : payload.lastHundredtransactions },
                    totalCount,
                    loading: false,
                    error: false
                };
            }
            else {

                return {
                    ...state,
                    moreTransactions: appendWithPreviousList ? moreTransactions.concat(payload) : payload,
                    totalCount,
                    loading: false,
                    error: false
                };
            }

        case types.GET_ACCOUNT_SUMMARY_TRANACTIONS_ERROR:
            return { ...state, loading: false, error: true };
        case types.RESET_ACCOUNT_SUMMARY_TRANSACTIONS:
            return {
                ...initialGetAccountSummaryTransactions
            };

        default:
            return state
    }
}