import { getchapterDuesSummaryitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetChapterDuesSummaryReducer(state = getchapterDuesSummaryitialValues, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_CHAPTER_DUES_SUMMARY_LOADING:
            return { ...state,error: false, loading: true};
        case types.GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_LOADING:
            return { ...state,IncludeNationalerror: false, IncludeNationalloading: true,  };
        case types.GET_CHAPTER_DUES_SUMMARY_SUCCESS:
            return { ...state, data: payload, loading: false };
        case types.GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_SUCCESS:
            return { ...state, IncludeNationalData: payload, IncludeNationalloading: false };
        case types.GET_CHAPTER_DUES_SUMMARY_ERROR:
            return { error: true, loading: false, data: {} };
        case types.GET_CHAPTER_DUES_SUMMARY_ISNATIONAl_ERROR:
            return { IncludeNationalerror: true, IncludeNationalloading: false, IncludeNationalData: {} };
        default:
            return state;
    }
}
