import { initialSignUpStepsFormValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GeographyDataReducer(state = initialSignUpStepsFormValues,action){
    const {type,countries,usStates, timeZones} = action;
    switch (type) {
        case types.SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_LOADING:
            return {...state,loading:true,error:false};   

        case types.SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_SUCCESS:
            return {...state,loading:false,countries,usStates,timeZones,error:false}; 
            
        case types.SIGNUP_STEPS_SET_FORM_FIELD_OPTIONS_ERROR:
            return {...state,loading:false,error:true};  
        default:
            return state
    }
}