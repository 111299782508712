import { ProspectsdetailsInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetProspectsDetailsReducer(state = ProspectsdetailsInitialValues,action){
    const {type,payload} = action;

    switch (type) {
        case types.GET_PROSPECT_DETAILS_LOADING:
            return {...state,loading:true,error:false};  

        case types.GET_PROSPECT_DETAILS_SUCCESS:
            return {...payload,loading:false,error:false}; 
            
        case types.GET_PROSPECT_DETAILS_ERROR:
            return {...state,loading:false,error:true};  
        case types.RESET_PROSPECT_DETAILS:
            return {state,loading:false,error:false};     
            
        default:
            return state
    }
}






