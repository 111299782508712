import * as types from '../actions/ActionTypes';

const initialState = {
  activeStep: 0,
  chapters: [],
  thirdPartyIntegration: '',
  signupDetail: {
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    hostcode: '',
    membershipNumber: '',
    financiallyActive: false,
    lineItems: [],
    chapterMembers: [],
  },
};

export default function AuthReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.UPDATE_ACTIVESTEP:
      return {
        ...state,
        activeStep: payload,
      };
    case types.SET_CHAPTERS:
      return {
        ...state,
        chapters: payload,
      };
    case types.SET_THIRDPARTYINTEGRATION:
      return {
        ...state,
        thirdPartyIntegration: payload,
      };
    case types.MOVE_SIGNUP_NEXTSTEP:
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    case types.MOVE_SIGNUP_PREVSTEP:
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    case types.UPDATE_SIGNUP_DETAIL: {
      return {
        ...state,
        signupDetail: payload,
      };
    }
    default:
      return state;
  }
}
