import React, { Fragment } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { GetAllowedMaxDate, GetAllowedMinDate, normalizePhoneNumberFormatOnChange } from '../../functions/CustomFunctions';
import { DEFAULT_COUNTRY_NAME } from '../../constants/HelperConstants';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%"
    },
    formHeader: {
        fontSize: 'x-large',
        marginBottom: '1rem',
        textAlign: 'center'
    }
}));

// var currentDate = new Date();
//Max Date
const maxAllowedDate = GetAllowedMaxDate();


export default function CommonUpdateInformationForm(props) {
    const classes = useStyles();
    const { countries = [], usStates = [] } = useSelector((state) => state.GeographyDataReducer);

    const {
        values, handleChange, errors, touched, changeDateField,
        MemberShipNumberForDisabled, setFieldTouched, setFieldValue
    } = props;

    let {
        firstName = '',
        lastName = '',
        membershipNumber = '',
        dob = null,
        countryCode = '1', //By Default +1
        phoneNumber = '',
        address = '',
        city = '',
        country = '', //By Default United States
        state = '',
        zip = '',
    } = values;


    //Min Date
    const minAllowedDate = new Date(GetAllowedMinDate());

    //Country Codes
    const countryCodes = countries.map(({ phoneCountryCode, name, addressCountryID }, index) =>
        <MenuItem value={addressCountryID} key={`cty_code_${index}`}>
            +{phoneCountryCode} {' '}{name}
        </MenuItem>
    );
    //Countries
    const countriesList = countries.map(({ name, addressCountryID }, index) =>
        <MenuItem value={name} key={`cty_code_${index}`}>
            {name}
        </MenuItem>
    );
    //States
    const usstatesList = usStates.map(({ name, addressStateID, abbreviation }, index) =>
        <MenuItem value={name} key={`cty_code_${index}`}>
            {abbreviation}{' '}{name}
        </MenuItem>
    );
    const isLetters = (str) => /^[A-Za-z]*$/.test(str);

    return (
        <Fragment>
            <Grid item xs={12} sm={12} md={6}>
                <TextField
                    error={errors.firstName && touched.firstName}
                    id="firstName-helper-text"
                    label="First Name"
                    value={firstName}
                    fullWidth
                    name='firstName'
                    helperText={(errors.firstName && touched.firstName) ? errors.firstName : ''}
                    variant="outlined"
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <TextField
                    error={errors.lastName && touched.lastName}
                    id="lastName-helper-text"
                    label="Last Name"
                    value={lastName}
                    fullWidth
                    name='lastName'
                    helperText={(errors.lastName && touched.lastName) ? errors.lastName : ''}
                    variant="outlined"
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <TextField
                    error={errors.membershipNumber && touched.membershipNumber}
                    id="membershipNumber-helper-text"
                    label="Membership Number"
                    value={membershipNumber}
                    fullWidth
                    disabled={MemberShipNumberForDisabled ? true : false}
                    name='membershipNumber'
                    helperText={(errors.membershipNumber && touched.membershipNumber) ? errors.membershipNumber : ''}
                    variant="outlined"
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        minDate={minAllowedDate}
                        maxDate={maxAllowedDate}
                        openTo="year"
                        label="Date Of Birth"
                        style={{ width: '100%' }}
                        value={dob}
                        format='MM/DD/YYYY'
                        views={["year", "month", "date"]}
                        name='dob'
                        onChange={changeDateField}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <FormControl variant="outlined" className={classes.formControl} error={errors.countryCode && touched.countryCode}>
                    <InputLabel id="countryCode-label">Country Code</InputLabel>
                    <Select
                        labelId="countryCode-label"
                        id="countryCode"
                        name='countryCode'
                        value={countryCode}
                        onChange={handleChange}
                        fullWidth
                        label="Country Code"
                    >
                        <MenuItem value="">Select Country Code</MenuItem>
                        {countryCodes}
                    </Select>
                    <FormHelperText>{(errors.countryCode && touched.countryCode) ? errors.countryCode : ''}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <TextField
                    error={errors.phoneNumber && touched.phoneNumber}
                    id="phoneNumber-helper-text"
                    label="Phone Number"
                    value={phoneNumber}
                    fullWidth
                    name='phoneNumber'
                    helperText={(errors.phoneNumber && touched.phoneNumber) ? errors.phoneNumber : ''}
                    variant="outlined"
                    onChange={({ target: { value } }) => {
                        setFieldTouched('phoneNumber', true);
                        setFieldValue('phoneNumber', normalizePhoneNumberFormatOnChange(value));
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={errors.address && touched.address}
                    id="address-helper-text"
                    label="Address Line"
                    value={address}
                    fullWidth
                    name='address'
                    helperText={(errors.address && touched.address) ? errors.address : ''}
                    variant="outlined"
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={errors.city && touched.city}
                    id="city-helper-text"
                    label="City"
                    value={city}
                    fullWidth
                    name='city'
                    helperText={(errors.city && touched.city) ? errors.city : ''}
                    variant="outlined"
                    onChange={(e) => {
                        let value = e.target.value
                        if (isLetters(value)) {
                            handleChange(e)
                        } else if (value == '') {
                            setFieldValue('city', '');
                        }
                    }
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl} error={errors.country && touched.country}>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                        labelId="country-label"
                        id="country"
                        name='country'
                        value={country}
                        onChange={(event) => {
                            setFieldTouched('country', true);
                            setFieldValue('state', '');
                            handleChange(event);
                        }}
                        fullWidth
                        label="Country"
                    >
                        <MenuItem value="">Select Country</MenuItem>
                        {countriesList}
                    </Select>
                    <FormHelperText>{(errors.country && touched.country) ? errors.country : ''}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                {(country === DEFAULT_COUNTRY_NAME) ?
                    <FormControl variant="outlined" className={classes.formControl} error={errors.state && touched.state}>
                        <InputLabel id="state-label">State</InputLabel>
                        <Select
                            labelId="state-label"
                            id="state"
                            name='state'
                            value={state}
                            onChange={handleChange}
                            fullWidth
                            label="State"

                        >
                            <MenuItem value="">Select State</MenuItem>
                            {usstatesList}
                        </Select>
                        <FormHelperText>{(errors.state && touched.state) ? errors.state : ''}</FormHelperText>
                    </FormControl>
                    :
                    <TextField
                        error={errors.state && touched.state}
                        id="state-helper-text"
                        label="State"
                        value={state}
                        fullWidth
                        name='state'
                        helperText={(errors.state && touched.state) ? errors.state : ''}
                        variant="outlined"
                        onChange={handleChange}
                    />
                }
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <TextField
                    error={errors.zip && touched.zip}
                    id="zip-helper-text"
                    label="Zip"
                    value={zip}
                    fullWidth
                    name='zip'
                    helperText={(errors.zip && touched.zip) ? errors.zip : ''}
                    variant="outlined"
                    onChange={handleChange}
                />
            </Grid>
        </Fragment>
    );
}