import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from 'react';
import { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DefaultResponsiveContainer from "../../common/DefaultResponsiveContainer";
import { Alert } from "@material-ui/lab";
import { Box, Button, Card, CardContent, CardHeader, FormControlLabel, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip } from '@material-ui/core'
import ComponentTitle from "./ComponentTitle";
import FormGroup from '@material-ui/core/FormGroup';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import EditIcon from '@material-ui/icons/Edit';
import CustomTableHeader from "../../common/table/CustomTableHeader";
import { CustomAlertMessage, CustomPageLoading, fixedFloatTwoDigit } from "../../../functions/CustomFunctions";
import { useSnackbar } from "notistack";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { authHeader } from '../../../functions/CoreFunctions'
import API from '../../../redux/actions/API'
import { UPSERT_INTAKE_FEE_API, DELETE_INTAKE_FEE_API } from '../../../constants/apiPaths'
import { isMobileScreen, isTabScreen } from "../../../functions/commonFunctions";
import AddIcon from '@material-ui/icons/Add';
import { FLOAT_VALUE_REGEX } from "../../../constants/ValidationRegex";
import Switch from '@material-ui/core/Switch';
import IntakeFeesSummary from "./IntakeFeesSummary";
import DeleteIcon from '@material-ui/icons/Delete';
import CustomTableEmptyRecord from "../../common/table/CustomTableEmptyRecord";

// Schema
const validationSchema = Yup.object({
    organizationId: Yup.string().trim().required('Organization is required'),
    price: Yup.string().trim().required('Price is required').matches(FLOAT_VALUE_REGEX, 'Please give valid amount'),

    name: Yup.string().trim().required('Name is required'),
    description: Yup.string().trim().required('Description is required'),

})


const useStyles = makeStyles(theme => ({


    formHeader: {
        textAlign: 'center',
        marginBottom: '5px'
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column"
    },

    formHeader: {
        textAlign: 'center',
        marginBottom: '5px'
    },
    continuebtn: {
        color: '#fff',
        width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px'
    },
    addproduct: {
        color: '#fff',
        fontSize: isMobileScreen() ? '10px' : '12px',
        // width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        // display: 'block',
        padding: '4px 10px'
    },
    formControl: {
        width: '100%'
    },
    completeStep: {
        float: 'left',
        borderRadius: '50%',
        backgroundColor: 'green',
        color: '#fff',
        padding: '2px',
        marginRight: '1rem'
    },
    cardTitle: {
        fontWeight: 900,
        fontSize: '18px'
    },
    balanceTextStyle: {
        // width: '330px'
    },
    addBtn: {
        float: (isMobileScreen() || isTabScreen()) ? 'none' : 'right',
        marginTop: (isMobileScreen() || isTabScreen()) ? '0rem' : '-3rem',
        width: (isMobileScreen() || isTabScreen()) ? '50%' : '20%',
        // marginRight: 0,
        // marginBottom: '1rem',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'opx',
        opacity: 1,
        '&:hover': {
            opacity: 0.7,
        }
    },
    vieweBtn: {
        marginRight: '10px',
        padding: '6px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        fontSize: 5
    },
    backbtn: {
        float: 'right',
        // marginTop:'1rem'
        marginBottom: isMobileScreen() ? '10px' : '0px'
    },
    alertStyle1: {
        color: 'rgb(30, 70, 32)',
        backgroundColor: 'rgb(237, 247, 237)',
        textAlign: 'center',
        padding: '4px'
    },
    warnAlertStyle: {
        color: 'rgb(102, 60, 0)',
        backgroundColor: 'rgb(255, 244, 229)',
        textAlign: 'center',
        marginBottom: '1rem'
    },
    alertStyle: {
        marginTop: (isMobileScreen() || isTabScreen()) ? '1rem' : '-1.2rem',
        color: 'rgb(30, 70, 32)',
        backgroundColor: 'rgb(237, 247, 237)',
        textAlign: 'center',
        padding: '1px',
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: 'Roboto',
        marginBottom: '0.5rem'
    },
    switchStyle: {
        float: 'left',
        marginBottom: '0.5rem',
        marginLeft: '6px'
    },
    switchStyle2: {
        textAlign: 'center',

        color: theme.primaryColor,
        // width: '75%',
        backgroundColor: 'gainsboro',
        // boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px',
        marginBottom: '0.5rem',

    },

}));

//Table Columns
const tableColumns = [
    { id: "name", title: "Name", sort: false },


];

const IntakeFees = (props) => {
    const classes = useStyles();
    const { stepData = {}, organizationName = '', organizationTypeId = '', commonCallback, OrganizationID, intakeSeasonID, handleSeasonStep, handleUpdatevalue } = props;
    const { detail = {}, completed = false, title = '', componentName = '', message = '' } = stepData;
    const { productTypes = [], requiredProductsComplete = false } = detail
    const tableHeadProps = { tableColumns };
    const { enqueueSnackbar } = useSnackbar();
    const [conDigFlag, setConDigFlag] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    // Form Initial Values
    const initialFormValues = {
        productId: 0,
        organizationId: OrganizationID,
        intakeSeasonId: intakeSeasonID,
        productTypeId: 0,
        price: '',
        name: '',
        description: "",
        returnIntakeStep: true

    }
    const [formData, setFormData] = useState(initialFormValues);
    const [formLoading, setFormLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showDetails, setShowDetails] = useState(completed ? true : false);
    const handleaddEditForm = (formValue) => {
        const { productTypeName = '' } = formValue
        const productCount = productTypes.find(x => parseInt(x.productTypeID) === (formValue.productTypeID)).products.length || 0;

        let data = {
            productId: formValue.productId ? formValue.productId : 0,
            organizationId: formValue.organizationId ? formValue.organizationId : OrganizationID,
            intakeSeasonId: formValue.intakeSeasonId ? formValue.intakeSeasonId : intakeSeasonID,
            productTypeId: formValue.productTypeID,
            price: formValue.price ? formValue.price : '',
            name: formValue.name ? formValue.name : (parseInt(organizationTypeId) === 1) ? `${organizationName} - Intake Fee - ${parseInt(productCount) + 1}` : `${productTypeName} Fee`,
            description: formValue.description ? formValue.description : "",
            returnIntakeStep: true
        }
        if (formValue.productId == 0) {
            data = {
                productId: 0,
                organizationId: OrganizationID,
                intakeSeasonId: intakeSeasonID,
                productTypeId: formValue.productTypeID,
                price: '',
                name: formValue.name ? formValue.name : (parseInt(organizationTypeId) === 1) ? `${organizationName} - Intake Fee - ${parseInt(productCount) + 1}` : `${productTypeName} Fee`,
                description: "",
                returnIntakeStep: true
            }
        }
        setFormData(data)
        setShowForm(true)
    }
    const handleEditStep = () => {
        setShowDetails(!showDetails)
    }
    //Remove Product
    const handleDeleteProduct = (row) => {
        const { productId, name } = row;
        setConDigFlag(!conDigFlag);
        setRecordToDelete({ productId, name });
    }

    const deleteConfirmationToggle = () => {
        setConDigFlag(!conDigFlag);
    }

    //Delete Record
    const handleConfirmation = async () => {
        // console.log(recordToDelete, 'recordToDelete')
        try {
            setButtonLoading(true);
            const options = {
                url: DELETE_INTAKE_FEE_API,
                method: 'DELETE',
                params: {
                    ProductId: parseInt(recordToDelete.productId)

                },
                headers: authHeader()
            };
            const { data: apiResponse } = await API(options);
            const { status, response = '' } = apiResponse;
            if (parseInt(status) === 1) {
                CustomAlertMessage(response, 'success', enqueueSnackbar);
                commonCallback();
                // const { status, Step } = apiResponse;
                // dispatch({
                //                     type: SET_INTAKE_SEASON_STEP,
                //                     payload: {
                //                         componentName: Step.componentName,
                //                         datax: Step
                //                     }
                //                 });

                setConDigFlag(!conDigFlag);
            }
        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                CustomAlertMessage(data.message, 'error', enqueueSnackbar);
            }
        } finally {
            setButtonLoading(false);
        }
    };

    //Confirm Dialog Props
    const confirmDialogProps = {
        conDigFlag,
        conDigTitle: "Remove Product",
        conDigContent: recordToDelete ? `Are you sure you want to remove the ${recordToDelete.name}  Product ?` : '',
        deleteConfirmationToggle,
        handleConfirmation,
        buttonLoading
    };

    // Save Form Data
    const saveFormValues = async values => {
        // console.log(values, 'values')
        try {
            setFormLoading(true)

            const options = {
                method: 'POST',
                url: UPSERT_INTAKE_FEE_API,
                data: values,
                headers: authHeader()
            }

            const { data: apiResponse } = await API(options)
            const { status, Step } = apiResponse;
            if (status == 1) {

                CustomAlertMessage('Saved successfully!', 'success', enqueueSnackbar)
                // console.log(Step, 'Step')
                // commonCallback();
                setShowForm(false);
                setFormData(initialFormValues)
                handleUpdatevalue(3, Step);

                // dispatch({
                //     type: SET_INTAKE_SEASON_STEP,
                //     payload: {
                //         componentName: Step.componentName,
                //         datax: Step,
                //         intakeseasontype: 'National'
                //     }
                // });


            }
        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                // console.log(error.response,'data message')
                CustomAlertMessage(error.response.statusText, 'error', enqueueSnackbar);
            }
        } finally {
            setFormLoading(false)
        }


    }





    // Formik
    const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, setFieldValue } = useFormik({
        initialValues: formData,
        enableReinitialize: true,
        validationSchema,
        onSubmit: values => saveFormValues(values)
    })

    let {
        productId = 0,
        organizationId = 0,
        intakeSeasonId = 0,
        productTypeId = 0,
        price = '',
        name = '',
        description = "",
        returnIntakeStep = true
    } = values
    var tableList = []
    productTypes.forEach(p => {
        p.products.forEach(a => {
            tableList.push(a)
        });
    });
    return (
        <Fragment>
            {message != '' && detail == null ?
                <Fragment>
                    <Grid container>

                        <Grid item md={2} component={Box} />
                        <Grid item xs={12} sm={12} md={8}>
                            <Alert severity="error">
                                <Typography>{message}</Typography>
                            </Alert>
                        </Grid>
                        <Grid item md={2} component={Box} />
                    </Grid>
                </Fragment>
                :
                <Fragment>
                    <Paper className={classes.paper}>
                        <ComponentTitle title={title} completed={completed} handleUpdatevalue={handleUpdatevalue} stepData={stepData} handleSeasonStep={handleSeasonStep} handleEditStep={handleEditStep} intakeSeasonID={intakeSeasonID} componentName={componentName} setFormLoading={setFormLoading} />

                        {formLoading && <CustomPageLoading flag={formLoading} />}
                        {completed && showDetails ?
                            <>

                                <Grid container style={{ marginTop: '10px' }}>
                                    <DefaultResponsiveContainer>


                                        <Typography className={classes.cardTitle} style={{ margin: '1rem 0rm' }}>Products</Typography>

                                        <IntakeFeesSummary
                                            tableList={tableList || []}
                                            loading={false} />

                                    </DefaultResponsiveContainer>

                                </Grid>
                            </>
                            : null}
                        {!showForm && !showDetails &&

                            <Grid item xs={12} style={{ marginTop: '1rem' }}>

                                {(productTypes.length > 0) ?
                                    <>

                                        {/* <Grid container spacing={3}>
                                <DefaultResponsiveContainer>
                                    <Alert icon={false} severity="warning">

                                        The step will be set to complete when we have at least 1 product in every product type
                                    </Alert>

                                </DefaultResponsiveContainer>
                            </Grid> */}
                                        {requiredProductsComplete ?
                                            <Grid item xs={12} sm={12} md={6} className={classes.switchStyle2}>
                                                <FormGroup >
                                                    <FormControlLabel
                                                        style={{ fontWeight: 600 }}
                                                        control={<Switch size="small"
                                                            checked={showDetails}
                                                            onChange={() => {
                                                                handleSeasonStep(2, intakeSeasonID, componentName, stepData, setFormLoading);
                                                            }}
                                                        />}
                                                        label={`Done ${title}`}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            : <Grid container spacing={2} >
                                                <Grid item xs={12} className={classes.warnAlertStyle}>
                                                    <div>
                                                        <h4><b>The step will be set to complete when we have at least 1 product in every product type</b></h4>
                                                    </div>
                                                </Grid>
                                            </Grid>}
                                        <Grid container >
                                            {productTypes.map(({ productTypeID, productTypeName, products }, index) =>
                                                <>
                                                    <Grid item xs sm={12} md={5} style={{ marginLeft: isMobileScreen() ? '0rem' : '4rem' }} >
                                                        <Card square expanded={true} style={{ marginTop: '1rem', backgroundColor: products.length == 0 ? 'rgb(255, 244, 229)' : '#fff' }}>
                                                            <CardHeader style={{ paddingBottom: '0px' }}
                                                                action={
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        // style={{ float: 'right', top: -30, position: 'relative' }}
                                                                        className={classes.addproduct}
                                                                        startIcon={<AddIcon />}
                                                                        onClick={() => handleaddEditForm({ productTypeID, productTypeName, productId: 0, name: '', price: '', description: '' })}

                                                                    >
                                                                        Add Product
                                                                    </Button>
                                                                }
                                                                title={productTypeName}
                                                            />

                                                            <CardContent style={{ paddingTop: '0px' }}>
                                                                <TableContainer>
                                                                    <Fragment>
                                                                        {!products.length ? (
                                                                            <CustomTableEmptyRecord columnLength={tableColumns.length} />
                                                                        ) : (
                                                                            <Fragment>
                                                                                {/* style={{maxHeight: "100px"}} */}
                                                                                < Table stickyHeader aria-label="sticky table" >
                                                                                    {/* Table Header */}
                                                                                    <CustomTableHeader {...tableHeadProps} />
                                                                                    {/* Table Header */}

                                                                                    <TableBody>
                                                                                        {products.map(({ productId, name, price, description }, index) =>
                                                                                            <TableRow hover
                                                                                                key={`org_acc_list_${index}`}
                                                                                                // role="checkbox"
                                                                                                tabIndex={-1}>
                                                                                                <TableCell
                                                                                                // className={classes.accTextStyle}
                                                                                                >
                                                                                                    {name}<br />
                                                                                                    <Typography className={classes.balanceText}>
                                                                                                        Price : {`$${fixedFloatTwoDigit(price)}`}
                                                                                                    </Typography>
                                                                                                    <Tooltip title='Edit'>
                                                                                                        <IconButton
                                                                                                            aria-label='edit'
                                                                                                            onClick={() => handleaddEditForm({ productTypeID, productTypeName, productId, name, price, description })}
                                                                                                            color='primary'
                                                                                                            // {...props}
                                                                                                            className={classes.vieweBtn}
                                                                                                        >
                                                                                                            <EditIcon style={{ fontSize: 16 }} />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                    <Tooltip title='Delete'>
                                                                                                        <IconButton
                                                                                                            aria-label='delete'
                                                                                                            onClick={() => handleDeleteProduct({ productId, name })}
                                                                                                            color='primary'
                                                                                                            // {...props}
                                                                                                            className={classes.vieweBtn}
                                                                                                        >
                                                                                                            <DeleteIcon style={{ fontSize: 16 }} />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </TableCell>

                                                                                            </TableRow>
                                                                                        )}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </Fragment>
                                                                        )}
                                                                    </Fragment>
                                                                </TableContainer>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                    {/* <Grid item xs={0} sm={0} md={1} >
                                    </Grid> */}
                                                </>
                                            )}
                                        </Grid>
                                    </>
                                    : ''}


                            </Grid>
                        }

                        {
                            showForm && !showDetails &&
                            <>
                                <div style={{ marginTop: (completed) ? '0rem' : '1rem', }}>

                                    <Button variant="outlined" color="secondary"
                                        onClick={() => { setShowForm(false); setFormData(initialFormValues) }}
                                        className={classes.backbtn}>
                                        Back
                                    </Button>

                                </div>
                                <form noValidate autoComplete='off' onSubmit={handleSubmit}>
                                    <Grid container >
                                        <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                                        <Grid item xs={12} sm={10} md={8}>
                                            <Grid container spacing={3}>
                                                <Fragment>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            error={errors.name && touched.name}
                                                            id="name-helper-text"
                                                            label="Name"
                                                            value={name}
                                                            fullWidth
                                                            name='name'
                                                            helperText={(errors.name && touched.name) ? errors.name : ''}
                                                            variant="outlined"
                                                            disabled={(parseInt(organizationTypeId) === 1)}
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            error={errors.description && touched.description}
                                                            id="description-helper-text"
                                                            label="Description"
                                                            value={description}
                                                            multiline
                                                            rows={3}
                                                            rowsMax={5}
                                                            fullWidth
                                                            name='description'
                                                            helperText={(errors.description && touched.description) ? errors.description : ''}
                                                            variant="outlined"
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            error={errors.price && touched.price}
                                                            id="price-helper-text"
                                                            label="Price"
                                                            value={price}
                                                            type='number'
                                                            fullWidth
                                                            name='price'
                                                            helperText={(errors.price && touched.price) ? errors.price : ''}
                                                            variant="outlined"
                                                            onChange={handleChange}
                                                            InputProps={{ inputProps: { min: 1 } }}
                                                        />
                                                    </Grid>
                                                </Fragment>


                                                <Grid item xs={12}>
                                                    <Button
                                                        type='submit'
                                                        variant='contained'
                                                        className={classes.continuebtn}
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid></Grid></Grid>
                                        <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />

                                    </Grid></form>
                            </>
                        }
                        {/* Delete Dialog */}
                        {conDigFlag && <ConfirmationDialog {...confirmDialogProps} />}
                        {/* Delete Dialog */}
                    </Paper>

                </Fragment>
            }
        </Fragment >

    );
}

export default IntakeFees