import { initialSignalRConnection } from "../store/InitialStore";
import {SIGNALR_CONNECT,SIGNALR_DISCONNECT}  from '../actions/ActionTypes';


export default function SignalrReducer (state = initialSignalRConnection, action) {
  const { type, payload } = action;
    switch (type) {
      case SIGNALR_CONNECT:
        return {
          ...state,
          initialSignalRConnection: payload,
        };
      case SIGNALR_DISCONNECT:
        return {
          ...state,
          initialSignalRConnection: null,
        };
      default:
        return state;
    }
  };