import { getMemberCalenderEventsInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetMemberCalenderEventsRdeucer(state = getMemberCalenderEventsInitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_MEMBER_CALENDER_EVENT_LOADING:
            return {payload,loading:true};
        case types.GET_MEMBER_CALENDER_EVENT_SUCCESS:
            return {...state,...payload,loading:false};
        case types.GET_MEMBER_CALENDER_EVENT_ERROR:
            return {payload,loading:false};
        default:
            return state;
    }
}