import { commonListingObjectInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
import update from 'react-addons-update';

export default function GetOfficerReportStatusReducer(state = commonListingObjectInitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_OFFICER_REPORT_STATUS_LOADING:
            return {error:false,loading:true,data:{}};
        case types.GET_OFFICER_REPORT_STATUS_SUCCESS:
            return {...state,data:payload,loading:false};
        case types.GET_OFFICER_REPORT_STATUS_ERROR:
            return {error:true,loading:false,data:payload};
        case types.SET_OFFICER_REPORT_STEP:
            let { componentName, datax } = payload;
            let { data } = state;
            let { Step } = data;
            let index = Step.findIndex(x => x.componentName === componentName);
            Step[index] = datax;
            return {
                ...state,
                data: {
                  ...data, 
                  Step 
                }
            }
        default:
            return state;
    }
}