import { objectInitial } from "../store/InitialStore";

export default function TestReducer(state = objectInitial, action) { 
    const { type , payload } = action;
    if(type === 'SET_MY_NAME')
    {
        return {name : payload};
    }
    else if(type === 'SET_COUNT')
    {
        return {count : payload};
    }
    return state;   
}