import { getDashboardTabstialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetDashboardTabsReducer(state = getDashboardTabstialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_DASHBOARD_TABS_LOADING:
            return {...state,loading:true, error: false};
        case types.GET_DASHBOARD_TABS_SUCCESS:
            return {data:payload,loading:false};
        case types.GET_DASHBOARD_TABS_ERROR:
            return {...state,loading:false, error: true};
        default:
            return state;
    }
}



