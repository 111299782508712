import { initialEntityExamTransactionValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetEntityExamTransactionsReducer(state = initialEntityExamTransactionValues,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_ENTITY_EXAM_TRANSACTIONS_LOADING:
            return {...state,loading:true,error:false};  

        case types.GET_ENTITY_EXAM_TRANSACTIONS_SUCCESS:
            return {...state,data:payload,loading:false,error:false}; 
            
        case types.GET_ENTITY_EXAM_TRANSACTIONS_ERROR:
            return {...state,loading:false,error:true};          
            
        default:
            return state
    }
}