import { getChapterDetailsInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

const GetChapterDetailsReducer = (state = getChapterDetailsInitialValues, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_ORGANIZATION_DETAILS_LOADING:
            return { payload, loading: true, error: false }
        case types.GET_ORGANIZATION_DETAILS_SUCCESS:
            return { chapter: { ...payload }, loading: false, error: false }

        case types.GET_ORGANIZATION_DETAILS_ERROR:
            return { payload, loading: false, error: true }
        default:
            return state
    }
}

export default GetChapterDetailsReducer
