import { makeStyles } from "@material-ui/core/styles";
import React from 'react';
import { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DefaultResponsiveContainer from "../../common/DefaultResponsiveContainer";
import { Button, Divider, Paper } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { isMobileScreen, isTabScreen } from "../../../functions/commonFunctions";

const useStyles = makeStyles(theme => ({

    completeStep: {
        float: 'left',
        borderRadius: '50%',
        backgroundColor: 'green',
        color: '#fff',
        padding: '2px',
    },
    cardTitle: {
        fontSize: '22px',
        fontWeight: '900',
        marginTop: '5px',
        marginLeft: '8px',
        marginBottom: '-30px'
    },
    editBtn: {
        // float: (isMobileScreen() || isTabScreen()) ? 'none' : 'right',
        // marginTop: (isMobileScreen() || isTabScreen()) ? '0rem' : '-3rem',
        width: '100%',
        // marginRight: 0,
        // marginBottom: '1rem',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: '0px',
        opacity: 1,
        '&:hover': {
            opacity: 0.7,
        }
    },
    divider: {
        margin: `10px 0 0 0`,
    },
}));

const ComponentTitle = (props) => {
    const classes = useStyles();
    const { title = '', completed, handleUpdatevalue, handleSeasonStep, handleEditStep, stepData, intakeSeasonID = 0,
        componentName = '',
        setFormLoading, edit = true } = props;


    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12} sm={12} md={10}>
                    <Typography className={classes.cardTitle}>{title}</Typography>
                    <br />
                    {completed &&
                        <Typography style={{ paddingLeft: '10px', paddingTop: '5px' }}>Completed Successfully</Typography>
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    {(completed && edit) &&
                        // <span style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.editBtn}
                            startIcon={<EditIcon />}
                            onClick={() => {
                                handleEditStep()
                                if (handleSeasonStep) {
                                    handleSeasonStep(1, intakeSeasonID, componentName, stepData, setFormLoading);
                                }
                                if (handleUpdatevalue) {
                                    handleUpdatevalue(1, stepData)
                                }
                            }}
                        >
                            Edit
                    </Button>
                        // </span>
                    }
                </Grid>
            </Grid>
            <Divider variant="inset" className={classes.divider} />

        </Fragment >

    );
}

export default ComponentTitle