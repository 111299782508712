import { commonListingIArraynitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetOfficersReducer(state = commonListingIArraynitialValues,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_OFFICERS_LOADING:
            return {...state,loading:true,error:false};   
        case types.GET_OFFICERS_SUCCESS:
            return {...state,payload,loading:false,error:false}; 
        case types.GET_OFFICERS_ERROR:
            return {...state,loading:false,error:true};  
        default:
            return state
    }
}