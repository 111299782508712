import { getQuickLinksitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetQuicklinksReducer(state = getQuickLinksitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_QUICK_LINKS_LOADING:
            return {...state,loading:true, error: false};
        case types.GET_QUICK_LINKS_SUCCESS:
            return {data:payload,loading:false};
        case types.GET_QUICK_LINKS_ERROR:
            return {...state,loading:false, error: true};
        default:
            return state;
    }
}

