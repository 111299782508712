import { makeStyles } from "@material-ui/core/styles";
import React from 'react';
import { Fragment } from 'react';
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Avatar, Button, FormControlLabel, FormGroup, IconButton, ListItem, ListItemAvatar, ListItemText, Switch, Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { getOfficerReportStatus, OfficerReportSetStepStatus } from "../../redux/actions";
import DefaultResponsiveContainer from "../common/DefaultResponsiveContainer";
import CheckIcon from '@material-ui/icons/Check';
import PublishIcon from '@material-ui/icons/Publish';
import List from '@material-ui/core/List';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from "clsx";
import { isMobileScreen, isTabScreen } from "../../functions/commonFunctions";
import { CustomAlertMessage, CustomPageLoading, DateTimeFormat } from "../../functions/CustomFunctions";
import { SET_STEP_STATUS_URL, UPLOAD_DOCUMENT_URL } from "../../constants/apiPaths";
import { ENTITY_TYPE_ID_FOR_ORGANIZATION } from "../../constants/HelperConstants";
import { fileTypeToStore, getKeyByValue, getLoggedUserMemberId } from "../../functions/CoreFunctions";
import API from "../../redux/actions/API";
import CommonPreviewDocumentDialog from "../common/CommonPreviewDocumentDialog";
import EditIcon from '@material-ui/icons/Edit';
import { SET_OFFICER_REPORT_STEP } from "../../redux/actions/ActionTypes";
// import OverRideSummaryStep from "./OverRideSummaryStep";

const useStyles = makeStyles(theme => ({
  continuebtn: {
    color: '#fff',
    width: '100%',
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: '50px',
    margin: 'auto',
    display: 'block',
    padding: '10px 35px',
    opacity: 1,
    '&:hover': {
      opacity: 0.7,
    }
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '5px'
  },
  formControl: {
    width: "100%"
  },
  contentTitle: {
    fontWeight: 700,
    fontSize: '17px'
  },
  contentDes: {
    fontSize: '12px'
  },
  addBtn: {
    width: '100%',
    marginRight: 0,
    marginBottom: '1rem',
    color: '#fff',
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: '50px',
    margin: 'auto',
    opacity: 1,
    '&:hover': {
      opacity: 0.7,
    }
  },
  listBtn: {
    color: theme.primaryColor,
    width: '100%',
    borderRadius: '50px',
    margin: 'auto',
    display: 'block',
    padding: '10px 35px',
    marginTop: '1rem'
  },
  alertStyle: {
    color: 'rgb(30, 70, 32)',
    backgroundColor: 'rgb(237, 247, 237)',
    // textAlign: 'center',
    marginTop: '-1rem'
  },
  btnButtons: {
    margin: '4px',
    // backgroundColor: '#eee',
    padding: '5px',
    color: '#fff',
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    // color: '#556cd6'
  },
  delButton: {
    margin: isMobileScreen() ? '0px 0px 0px 5px' : '3px',
    // backgroundColor: '#eee',
    padding: '5px',
    color: '#fff',
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    // color: '#d6557d'
  },

  iconSize: {
    fontSize: 16
  },
  avatar: {
    color: '#fff',
    backgroundColor: 'green'
  },
  listStyle: {
    backgroundColor: '#fff',
    marginBottom: '4px'
  },
  input: {
    display: 'none',
  },
  switchStyle: {
    alignItems: 'center',
  },
  switchStyle2: {
    textAlign: 'center',

    color: theme.primaryColor,
    // width: '75%',
    backgroundColor: 'gainsboro',
    // boxShadow: theme.mainColorShadow,
    borderRadius: '50px',
    margin: 'auto',
    display: 'block',
    padding: '10px 35px',
  },
  completeStep: {
    float: 'left',
    borderRadius: '50%',
    backgroundColor: 'green',
    color: '#fff',
    marginRight: '10px',
    padding: '2px',
  }
}));
const ChapterCertificationDocuments = (props) => {

  const { stepData = {}, handleCallback, organizationId, documentTransactionId } = props;
  const { detail = {}, completed = false, title = '', componentName = '', } = stepData;
  const { requireDocuments = [], status = '' } = detail;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const LoggedUserMemberId = getLoggedUserMemberId();

  const [dialogFlag, setDialogFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUploadFinished, setUploadFinished] = useState(completed ? true : false);
  const [previewDocument, setPreviewDocument] = useState(null);
  //Handle Show Type
  const handleToggleShowType = () => {
    toggleChecked(1);

    //update client side step
    let stepValues = stepData;
    stepValues.completed = false;
    // handleCallback()
    // dispatch({
    //   type: SET_OFFICER_REPORT_STEP,
    //   payload: {
    //     componentName: stepValues.componentName,
    //     datax: stepValues
    //   }
    // });
  }
  //Toggle Switch 
  const toggleChecked = async (selectedType) => {
    if (!isUploadFinished) {


      handleCallback()

      // dispatch(OfficerReportSetStepStatus(documentTransactionId, selectedType, componentName, handleCallback, setLoading, enqueueSnackbar));

      // try {
      //     setLoading(true);
      //     const options = {
      //         url: SET_STEP_STATUS_URL,
      //         method: 'PUT',
      //         params: {
      //             DocumentTransactionID: parseInt(documentTransactionId),
      //             Status: selectedType,
      //             ComponentName: componentName
      //         }
      //     };
      //     const { data: apiResponse } = await API(options);
      //     const { status } = apiResponse;
      //     if (parseInt(status) === 1) {
      //         if(selectedType==1){
      //             CustomAlertMessage('Please make any changes', 'success', enqueueSnackbar);
      //             setUploadFinished(false)
      //         }
      //         if(selectedType==2){

      //             CustomAlertMessage('Successfully saved', 'success', enqueueSnackbar);
      //             handleCallback();
      //         }
      //     }
      // } catch (error) {
      //     if (error.response) {
      //         let { data } = error.response;
      //         CustomAlertMessage(data.message, 'error', enqueueSnackbar);
      //     }
      // } finally {
      //     setLoading(false);
      // }
    }
    setUploadFinished((prev) => !prev);
  };

  const handleClose = () => {
    setDialogFlag(false);
  };

  //Choose File
  const handleFileupload = async (event) => {


    const { required_document_id } = event.currentTarget.dataset;
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const { type, name } = selectedFile;
      if (type === 'application/pdf' || type.includes("image/") || type === 'application/msword') {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = async event => {
          var processed_file_base64 = event.target.result.split(",")[1];
          try {
            setLoading(true);
            const options = {
              url: UPLOAD_DOCUMENT_URL,
              method: 'POST',
              data: {
                entityTypeId: ENTITY_TYPE_ID_FOR_ORGANIZATION,
                sourceOwnerId: parseInt(organizationId),
                ownerMemberId: parseInt(LoggedUserMemberId),
                dType: 11,
                base64: processed_file_base64,
                fileExtension: fileTypeToStore[type],
                fileName: name,
                requiredDocumentID: parseInt(required_document_id)
              }
            };
            const { data: apiResponse } = await API(options);
            const { status, message = '' } = apiResponse;
            if (parseInt(status) === 1) {
              CustomAlertMessage(message, 'success', enqueueSnackbar);
              handleCallback()
              dispatch(getOfficerReportStatus(organizationId, enqueueSnackbar));
            }
          } catch (error) {
            if (error.response) {
              let { data } = error.response;
              CustomAlertMessage(data.message, 'error', enqueueSnackbar);
            }
          } finally {
            setLoading(false);
          }
        }
      } else {
        CustomAlertMessage('Image, Pdf and Doc only allowed', 'error', enqueueSnackbar);
      }
    }
  }

  //Handle Preview
  const handlePreview = (event) => {
    const { doc_url = '', file_extension = '' } = event.currentTarget.dataset;
    setPreviewDocument({ url: doc_url, extension: file_extension });
    setDialogFlag(true);
  }

  //Download
  const downloadDocument = (event) => {
    const { url, name, file_extension } = event.currentTarget.dataset;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': getKeyByValue(fileTypeToStore, file_extension),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${name}.${file_extension}`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }

  let showFinishButton = false;
  //Show Finish Button when all documents uploaded
  if (requireDocuments.length && !requireDocuments.find(record => !record.documentId)) {
    showFinishButton = true;
  }

  const withDrawDialogProps = {
    dialogFlag,
    handleClose,
    previewData: previewDocument
  };


  return (
    <Fragment>
      {(loading) && <CustomPageLoading flag={loading} />}
      <Grid container spacing={3}>
        <DefaultResponsiveContainer>
          <Typography variant="h6" className={classes.formHeader}>
            {title}
          </Typography>
        </DefaultResponsiveContainer>
        {(completed && isUploadFinished) &&
          <>
            {status === "Override" ?
              <></>
              // <OverRideSummaryStep />
              :

              <Grid container className={classes.alertStyle} >
                <Grid item xs={12} sm={12} md={10} >
                  <div >
                    <CheckIcon className={classes.completeStep} />

                    <h4><b> Required documents submitted successfully</b></h4>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.addBtn}
                    startIcon={<EditIcon />}
                    onClick={handleToggleShowType}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            }
          </>

        }
        {status !== "Override" ?
          <>

            {(!isMobileScreen() && !isTabScreen()) &&
              <Grid item xs sm={2} md={3} component={Box} display={{ xs: "none", sm: "block" }} />
            }
            <Grid item xs={12} sm={12} md={6}>
              {showFinishButton && !isUploadFinished ?
                <Grid container className={classes.switchStyle2}>

                  <FormGroup className={classes.switchStyle}>
                    <FormControlLabel
                      control={<Switch size="small"
                        checked={isUploadFinished}
                        onChange={() => toggleChecked(2)}
                      />}
                      label="Are you finished uploading document?"
                    />
                  </FormGroup>
                </Grid>
                : ''
              }
              <div className={classes.demo}>
                <List dense={true} >
                  {!requireDocuments.length ?
                    <ListItem className={classes.listStyle} key={`doc_list_0`}>
                      <ListItemText
                        primary={<Fragment><center><p>No Record Found</p></center></Fragment>}
                      />
                    </ListItem>
                    :
                    <>
                      {requireDocuments.map((
                        { name, documentId, requiredDocumentId, createDate = '', documentUrl = '', requireDocExtentions = '', fileExtension
                        }, index) =>
                        <ListItem className={classes.listStyle} key={`doc_list_${index}`}>
                          {documentId != 0 &&
                            <ListItemAvatar>
                              <Avatar className={classes.avatar}>
                                <CheckIcon />
                              </Avatar>
                            </ListItemAvatar>
                          }
                          <ListItemText
                            primary={name}
                            secondary={<Fragment>
                              <div style={{ margin: '10px 0px 10px 0px' }}>
                                {!completed &&
                                  <>
                                    <input accept={requireDocExtentions} className={classes.input} id={`icon-button-file_${requiredDocumentId}`} type="file"
                                      data-required_document_id={requiredDocumentId}
                                      onChange={handleFileupload} />
                                    <label htmlFor={`icon-button-file_${requiredDocumentId}`} >
                                      <Tooltip placement="top" title="Upload Document">
                                        <IconButton color="primary" aria-label="upload picture" component="span" className={clsx(classes.btnButtons)}>
                                          <PublishIcon className={classes.iconSize}
                                          // style={{ color: 'gray' }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </label>
                                  </>
                                }

                                {documentId != 0 &&
                                  <>
                                    {(fileExtension !== 'doc') &&
                                      <Tooltip placement="top" title="Preview Document">
                                        <IconButton edge="end" aria-label="preview"
                                          data-doc_url={documentUrl}
                                          data-file_extension={fileExtension}
                                          onClick={handlePreview}
                                          className={clsx(classes.btnButtons)}>
                                          <VisibilityIcon className={classes.iconSize} />
                                        </IconButton>
                                      </Tooltip>
                                    }
                                    <Tooltip placement="top" title="Download Document">
                                      <IconButton edge="end" aria-label="download"
                                        data-name={name}
                                        data-url={documentUrl}
                                        data-file_extension={fileExtension}
                                        onClick={downloadDocument}
                                        className={clsx(classes.delButton)}>
                                        <GetAppIcon className={classes.iconSize} />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                }
                              </div>
                              {documentId ?
                                <p style={{ color: 'green', margin: 0, fontSize: '13px' }}>Last Updated : {DateTimeFormat(createDate, 'MM/DD/YYYY hh:mm A')}</p> :
                                <p style={{ color: 'red', margin: 0 }}>Please upload document</p>
                              }
                            </Fragment>}
                          />
                        </ListItem>
                      )}
                    </>
                  }
                </List>
              </div>
            </Grid>
            {(!isMobileScreen() && !isTabScreen()) &&
              <Grid item xs sm={2} md={3} component={Box} display={{ xs: "none", sm: "block" }} />
            }
          </>
          : null}
      </Grid>

      {dialogFlag &&
        <CommonPreviewDocumentDialog {...withDrawDialogProps} />
      }

    </Fragment>
  )
}

export default ChapterCertificationDocuments