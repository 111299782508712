import { initialGetAutoDebitLogsInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetAutoDebitLogsReducer(state = initialGetAutoDebitLogsInitialValues,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_AUTO_DEBIT_LOGS_LOADING:
            return {...state,loading:true,error:false};  

        case types.GET_AUTO_DEBIT_LOGS_SUCCESS:
            return {...state,...payload,loading:false,error:false}; 
            
        case types.GET_AUTO_DEBIT_LOGS_ERROR:
            return {...state,loading:false,error:true};      
            
        default:
            return state
    }
}