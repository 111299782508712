import { getMasterIntakeDetailsInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetMasterIntakeDetailsReducer(state = getMasterIntakeDetailsInitialValues, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_MASTERINTAKE_DETAILS_LOADING:
            return { payload, loading: true };
        case types.GET_MASTERINTAKE_DETAILS_SUCCESS:
            return { ...state, ...payload, loading: false };
        case types.GET_MASTERINTAKE_DETAILS_ERROR:
            return { payload, loading: false, error: true };
        default:
            return state;
    }
}




