import { commonListingIArraynitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetInvitationRequestReducer(state = commonListingIArraynitialValues, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_INVITATION_REQUESTS_LOADING:
            return { loading: true, data: [], error: false };
        case types.GET_INVITATION_REQUESTS_SUCCESS:
            return { data: payload, loading: false, error: false };
        case types.GET_INVITATION_REQUESTS_ERROR:
            return { data: payload, loading: false, error: true };
        default:
            return state;
    }
}




