import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import { Button, CardHeader, Checkbox, Chip, Divider, ExpansionPanelActions, FormControl, FormControlLabel, FormHelperText, IconButton, Input, InputLabel, MenuItem, Select, TextField, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { authHeader, getLoggedUserMemberId } from '../../functions/CoreFunctions'
import { ADDENTITY_COLLEGE_OFFICIAL_API, UPSERT_COLLEGE_OFFICIAL_API } from '../../constants/apiPaths'
import API from '../../redux/actions/API'
import { CustomAlertMessage, CustomPageLoading, DateTimeFormat, normalizePhoneNumberFormatOnChange } from '../../functions/CustomFunctions'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect, useDispatch, useSelector } from 'react-redux'
import { EMAIL_REGEX, FIVE_DIGIT_NUMBER_REGEX, US_PHONE_NUMBER_REGEX } from '../../constants/ValidationRegex'
import { deletecollegeofficialrecord, GetCollegeOfficialDetails, GetIntakeSeasonSteps, GetSchoolProfileDetails, getSchoolsList, getSignUpStepsFormFieldOptions } from '../../redux/actions'
import CommonUpdateInformationForm from '../common/CommonUpdateInformationForm';
import Box from "@material-ui/core/Box";
import DeleteIcon from '@material-ui/icons/Delete';
import { CloseRounded, PhotoCamera } from "@material-ui/icons";
import defaultAvatar from '../../assets/img/defaultAvatar.png';
import { ENTITY_TYPE_ID_FOR_MEMBER } from '../../constants/HelperConstants'
import { GetChapterDetails, } from '../../redux/actions/chapteractions'
import { getEventFilterOptions } from '../../redux/actions'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { CHAPTERS_PATH, SCHOOLS_PATH } from '../../constants/RoutePaths'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { isMobileScreen, isTabScreen } from '../../functions/commonFunctions'
import Paper from '@material-ui/core/Paper'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import ModalDialog from '../common/modaldialog'
import { GET_COLLEGE_OFFICIAL_DETAILS_SUCCESS, GET_SCHOOL_PROFILE_DETAILS_SUCCESS } from '../../redux/actions/ActionTypes'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';


// Schema
const validationSchema = Yup.object({
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().required('Last Name is required'),
  // email: Yup.string().trim().required('Email is required').email('Give valid email'),
  email: Yup.string().trim().required('Email is required').email('Give valid email').matches(EMAIL_REGEX, 'Give valid email'),
  title: Yup.string().trim().required('Title is required'),
  phone1: Yup.string().trim().required('Phone number is required').matches(US_PHONE_NUMBER_REGEX, 'Give valid phone number. (ex : xxx-xxx-xxxx)'),

})

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  container: {
    maxHeight: '30rem'
  },
  rowhover: {
    cursor: 'pointer'
  },
  continuebtn: {
    color: '#fff',
    width: '100%',
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: '50px',
    margin: 'auto',
    display: 'block',
    padding: '10px 35px'
  },
  formControl: {
    width: '100%'
  },
  formHeader: {
    fontSize: 'x-large',
    textAlign: 'center'
  },
  formSubHeader: {
    marginBottom: '1rem',
    textAlign: 'center'
  },
  formSubHeader2: {
    fontSize: '12px',
    textAlign: 'center'
  },
  input: {
    display: 'none',
  },
  imgCircle: {
    textAlign: 'center',
    border: '1px solid #eee',
    width: '113px',
    height: '113px',
    padding: '5px',
    backgroundColor: '#eee',
    borderRadius: '50%',
  },
  imgCircleDiv: {
    textAlign: 'center',
  },
  fileInput: {
    marginTop: '-30px',
    marginLeft: '-24px',
    backgroundColor: '#eee',
    padding: '4px',
    '&:hover': {
      backgroundColor: '#eee',
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}))

// ----------------------------------------------------------------------------
const AddEditCollegeOfficial = props => {
  const { selectpersonId: personID = 0, isIniialSchool = false, CallBack, isCollegeofficialVerification = false, ModalClose, isCollegeOfficialDialogOpen = false, collegeOfficialDialogClose, OrganizationID, selectSchoolId } = props
  // const { id: personID } = useParams();
  const classes = useStyles();
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // const { MemberId } = props

  // Initial Filter Values
  const initialFilterValues = {

    schoolName: '',


  };
  // Form Initial Values
  const initialFormValues = {
    personId: 0,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    title: "",
    phone1: "",
  }

  const [formData, setFormData] = useState(initialFormValues);
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [deletePersonID, setDeletePersonID] = useState(0)
  const location = useLocation()

  const { schoolDetailRec } = useSelector(state => state.GetSchoolProfileDetailsReducer);
  const { count: totalCount, data: schoolList = [] } = useSelector(state => state.GetSchoolsListReducer);
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const { loading, CollegeOfficialDetailRec } = useSelector(state => state.GetCollegeOfficialDetailsReducer);

  const [formLoading, setFormLoading] = useState(false);
  const [addentity, setAddentity] = useState(false);
  const { School = {} } = schoolDetailRec !== undefined ? schoolDetailRec : {}
  const { Person = {}, SignQueues = [], CanModify = true, School: Schooldetails = {} } = CollegeOfficialDetailRec !== undefined ? CollegeOfficialDetailRec : {}
  // const tempgroupListList=[]
  const CustomClearIcon = ({ clrfldname }) => <CloseIcon className={classes.clearIcon} data-clrfldname={clrfldname} position="end" onClick={clearSearchField} />

  //Clear Search Field
  const clearSearchField = (event) => {
    let clrfldname = event.currentTarget.dataset.clrfldname;
    if (clrfldname == "schoolName") {
      let fieldValue = Object.assign({}, filterValues)
      fieldValue[clrfldname] = '';
      setFilterValues(fieldValue);

      setSchoolRec(schoolList)
    }
  }

  //On Field Change
  const fieldChange = event => {
    let { name, value } = event.target;
    if (name == "schoolName") {

      setFilterValues({ ...filterValues, [name]: value });
      // handleSearch(value)
    }
  };

  // Save Form Data
  const saveFormValues = async values => {

    // console.log(values, 'data', School)
    let data = { ...values, schoolId: parseInt(selectSchoolId) > 0 ? parseInt(selectSchoolId) : (Schooldetails !== undefined && Schooldetails !== null && Schooldetails.schoolId !== undefined) ? Schooldetails.schoolId : 0 }
    // console.log(data, 'data-----', isCollegeofficialVerification)

    try {
      setFormLoading(true)
      const options = {
        method: 'POST',
        url: UPSERT_COLLEGE_OFFICIAL_API,
        data: data,
        headers: authHeader()
      }
      const { data: apiResponse } = await API(options)
      const { status, message = '' } = apiResponse;
      if (status == 1) {
        CustomAlertMessage('Saved successfully!', 'success', enqueueSnackbar)
        if (isCollegeofficialVerification !== undefined && isCollegeofficialVerification) {
          if (CallBack)
            CallBack()
          // dispatch(GetIntakeSeasonSteps({ 'OrganizationID': parseInt(OrganizationID), 'DivisionID': 3 }));
          ModalClose()
          // }
        } else {
          if (ModalClose)
            ModalClose()
          if (School !== null) {

            dispatch(GetSchoolProfileDetails(School.schoolId))
          }
          backtoschool()
        }
      } else {
        CustomAlertMessage(message, 'error', enqueueSnackbar)

      }

    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(error.response.statusText, 'error', enqueueSnackbar);
      }
    } finally {
      setFormLoading(false)
    }


  }

  // Did Mount
  useEffect(() => {

    if (Person !== null && Object.keys(Person).length !== 0 && Person.constructor === Object) {

      let data = {
        personId: Person.personId,
        firstName: Person.firstName,
        lastName: Person.lastName,
        email: Person.email,
        password: Person ? Person.password ? Person.password : '' : '',
        title: Person.title,
        phone1: Person.phone1,

      }


      setFormData(data);
    }
  }, [Person]);

  useEffect(() => {
    setFormLoading(loading);

  }, [loading]);
  const [schoolRec, setSchoolRec] = useState([]);



  useEffect(() => {
    dispatch(getSchoolsList(setSchoolRec));
    if (personID > 0)
      dispatch(GetCollegeOfficialDetails(personID))
    else {

      dispatch({
        type: GET_COLLEGE_OFFICIAL_DETAILS_SUCCESS,
        payload: { CollegeOfficialDetailRec: {} }
      });
    }
    if (selectSchoolId > 0) {

      dispatch(GetSchoolProfileDetails(parseInt(selectSchoolId)))
    }
    else {

      dispatch({
        type: GET_SCHOOL_PROFILE_DETAILS_SUCCESS,
        payload: { schoolDetailRec: {} }
      });

    }
  }, []);


  const getSchoolFilteredValues = (filterParams, schoolList) => {
    const { schoolName = '' } = filterParams;
    let filterList = schoolList;

    if (schoolName != '') {
      let searchedSchoolRecord = [];
      filterList.filter(data => (data.name.toLowerCase().includes(schoolName.toLowerCase()))
      ).map(filteredData => (
        searchedSchoolRecord.push(filteredData)

      ));
      filterList = searchedSchoolRecord
    }

    setSchoolRec(filterList);
  }

  useEffect(() => {

    if (filterValues) {
      getSchoolFilteredValues(filterValues, schoolList)
    } else {

      setSchoolRec(schoolList)

    }
  }, [filterValues]);
  // console.log(Schools, Schools.length, 'Schools', School)
  // Formik
  const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, setFieldValue } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => saveFormValues(values)
  })
  const backtoschool = () => {
    if (collegeOfficialDialogClose)
      collegeOfficialDialogClose();
    dispatch({
      type: GET_COLLEGE_OFFICIAL_DETAILS_SUCCESS,
      payload: { CollegeOfficialDetailRec: {} }
    });
    dispatch({
      type: GET_SCHOOL_PROFILE_DETAILS_SUCCESS,
      payload: { schoolDetailRec: {} }
    });
    if (School !== null) {
      // console.log('inside data')
      history.push({
        pathname: SCHOOLS_PATH,
        state: { selectedSchoolId: School !== null ? School.schoolId : 0, selectedSchoolName: School !== null ? School.name : '' }
      })
    } else {
      // console.log('outside data')
      history.push(SCHOOLS_PATH)
    }
  }


  const addingschoolToCollegeOfficial = async (item) => {
    // let data = { ...values, schoolId: School.schoolId }
    // let data =  {item.schoolId}
    let data = {
      entityTypeId: 7,
      sourceOwnerId: personId,
      schoolId: item.schoolId
    }


    try {
      setFormLoading(true)
      const options = {
        method: 'POST',
        url: ADDENTITY_COLLEGE_OFFICIAL_API,
        data: data,
        headers: authHeader()
      }

      const { data: apiResponse } = await API(options);
      const { status, message = '' } = apiResponse;
      if (parseInt(status) === 1) {
        CustomAlertMessage('Saved successfully!', 'success', enqueueSnackbar)
        dispatch(GetCollegeOfficialDetails(personId))

        setAddentity(false)
        // history.push(SCHOOLS_PATH)
      }
      else {

        CustomAlertMessage(message, 'error', enqueueSnackbar);
      }




    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(error.response.statusText, 'error', enqueueSnackbar);
      }
    } finally {
      setFormLoading(false)
    }


  }

  const dialogOpen = () => {
    setDialogOpen(true)
  }
  const dialogClose = () => {
    setDialogOpen(false)
  }

  const deleteCollegeOfficialonfirm = () => {
    dispatch(deletecollegeofficialrecord(deletePersonID, Schooldetails.schoolId, 7))
    dialogClose()
  }

  const ConfirmDialogContent = (
    <div>
      <Typography>
        Are you sure you want to remove the School ?
      </Typography>
      <center>
        <label>
          <Button
            component='span'
            variant='contained'
            color='gray'
            onClick={dialogClose}
            style={{ marginTop: '3rem', marginInlineEnd: '5rem', width: '5rem' }}
          >
            NO
          </Button>
        </label>
        <label>
          <Button
            component='span'
            variant='contained'
            color='primary'
            onClick={deleteCollegeOfficialonfirm}
            style={{ marginTop: '3rem', width: '5rem' }}
          >
            YES
          </Button>
        </label>
      </center>
    </div>
  )


  const DialogProps = {
    diaOpen: isDialogOpen,
    diaTitle: 'Confirm?',
    diaContent: ConfirmDialogContent,
    diaClose: dialogClose,
    isFullWidth: false
  }





  const addEntityClicked = () => {
    setAddentity(true)
  }

  let {
    personId = 0,
    firstName = "",
    lastName = "",
    email = "",
    // password = "",
    title = "",
    phone1 = ""
  } = values
  console.log(School != undefined, Schooldetails != undefined, School, Schooldetails, personId, personId > 0, "schoolName")
  return (
    <>
      {loading ? <CustomPageLoading flag={loading} /> :
        <Fragment>



          {addentity ? (
            <center>
              <Paper style={{ width: '70%' }}>
                <Card>
                  <CardHeader
                    action={
                      <div>
                        <IconButton onClick={() => { setAddentity(false) }}>
                          <CloseRounded />
                        </IconButton>
                      </div>
                    }
                    title="Please click on a school below to add."
                  />
                  <Grid item xs={12} sm={8} md={6}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="schoolName"
                      label="School Name"
                      name="schoolName"
                      placeholder="School Name"
                      value={filterValues.schoolName || ""}
                      onChange={fieldChange}
                      InputProps={{
                        endAdornment: filterValues.schoolName ? <CustomClearIcon clrfldname='schoolName' /> : <SearchIcon position="end" />,
                      }}
                    />
                  </Grid>
                  <Divider />
                  <Grid container spacing={3}>

                    <Grid item xs={12}>
                      <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            {schoolRec.length == 0 ? null : (

                              <TableRow>
                                <TableCell className={classes.tableHeaderCell}>ID</TableCell>
                                <TableCell className={classes.tableHeaderCell}>Name</TableCell>
                              </TableRow>)}
                          </TableHead>

                          <TableBody>
                            {schoolRec.length == 0 ? (
                              <TableRow>
                                <TableCell colSpan={2} className={classes.tableCell} style={{ paddingTop: 15, paddingBottom: 15 }}>
                                  There are currently no more school available
                                </TableCell>
                              </TableRow>
                            ) : (
                              <Fragment>
                                {schoolRec.map(item => (
                                  <TableRow
                                    hover
                                    className={classes.rowhover}
                                    key={item.schoolId}
                                    onClick={() => addingschoolToCollegeOfficial(item)}
                                  >
                                    <TableCell className={classes.tableCell} >{item.schoolId}</TableCell>
                                    <TableCell className={classes.tableCell}>{item.name}</TableCell>
                                  </TableRow>
                                ))}
                              </Fragment>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Card>
              </Paper>
            </center>
          ) : (
            <Fragment>
              <form noValidate autoComplete='off' onSubmit={handleSubmit} style={{ marginTop: isMobileScreen() ? '30px' : '0px' }}>
                {formLoading && <CustomPageLoading flag={formLoading} />}
                <Grid container spacing={3}>
                  <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                  <Grid item xs={12} sm={10} md={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" className={classes.formHeader}>
                          {personId && personId > 0 ? 'Edit College Official' : 'Add College Official'}
                        </Typography>

                        {/* {(isCollegeofficialVerification === false && !isCollegeOfficialDialogOpen) ? (<Tooltip title="Go Back" style={{ position: 'absolute', right: isMobileScreen() ? 5 : 70, top: isMobileScreen() ? 130 : 100 }}>
                      <Button variant="outlined" color="secondary" onClick={backtoschool}>
                        Back
                  </Button>
                    </Tooltip>) : ''} */}
                        {personId && personId > 0 && (Schooldetails !== undefined && Object.keys(Schooldetails).length == 0) ?
                          <Tooltip title="Add School" style={{ float: 'right' }}>
                            <Button variant="outlined" color="secondary" onClick={addEntityClicked}>
                              Add School
                            </Button>
                          </Tooltip>
                          : null

                        }
                        {!isIniialSchool && personId > 0 && (Schooldetails !== undefined && Object.keys(Schooldetails).length > 0) ?
                          <Tooltip title="Remove School" style={{ float: 'right' }}>
                            <Button variant="outlined" color="secondary" onClick={() => { setDeletePersonID(personId); dialogOpen() }}>
                              Remove School
                            </Button>
                          </Tooltip>
                          : null

                        }
                      </Grid>
                      {!CanModify &&

                        <Card style={{ backgroundColor: '#FCF8E3', marginBottom: '10px' }}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                              <Typography
                                style={{ padding: '15px', fontSize: '14px' }}
                              >
                                <span style={{ fontWeight: '700' }}>
                                  Please Note:&nbsp;
                                </span>
                                The College Official has verified identification on the system. The name and title cannot be modified. If the college official needs to be removed or replaced click the "Remove" link above next to the school name.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      }
                      <Fragment>
                        {((Schooldetails !== undefined && Schooldetails !== null && Schooldetails.name !== undefined) || (School !== undefined && School !== null && School.name !== undefined)) ?
                          <Grid item xs={12}>

                            <Typography
                              style={{ padding: '15px', fontSize: '14px' }}
                            >
                              <span style={{ fontWeight: '700', marginRight: '5px' }}>
                                School Name:
                              </span>{Schooldetails !== null && Schooldetails.name ? Schooldetails.name : School.name}
                            </Typography>
                          </Grid>
                          : null}
                        <Grid item xs={6}>
                          <TextField
                            error={errors.firstName && touched.firstName}
                            id="title-helper-text"
                            label="First Name"
                            value={firstName}
                            fullWidth
                            disabled={CanModify ? false : true}
                            name='firstName'
                            helperText={(errors.firstName && touched.firstName) ? errors.firstName : ''}
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            error={errors.lastName && touched.lastName}
                            id="title-helper-text"
                            label="Last Name"
                            value={lastName}
                            fullWidth
                            disabled={CanModify ? false : true}
                            name='lastName'
                            helperText={(errors.lastName && touched.lastName) ? errors.lastName : ''}
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={errors.email && touched.email}
                            id="title-helper-text"
                            label="Email"
                            value={email}
                            fullWidth
                            name='email'
                            helperText={(errors.email && touched.email) ? errors.email : ''}
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            error={errors.phone1 && touched.phone1}
                            id="title-helper-text"
                            label="Phone"
                            value={phone1}
                            fullWidth
                            name='phone1'
                            helperText={(errors.phone1 && touched.phone1) ? errors.phone1 : ''}
                            variant="outlined"
                            onChange={({ target: { value } }) => {
                              setFieldTouched('phone1', true);
                              setFieldValue('phone1', normalizePhoneNumberFormatOnChange(value));
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={errors.title && touched.title}
                            id="title-helper-text"
                            label="Title"
                            value={title}
                            fullWidth
                            disabled={CanModify ? false : true}
                            name='title'
                            helperText={(errors.title && touched.title) ? errors.title : ''}
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Fragment>

                      <Grid item xs={12}>
                        <Button
                          type='submit'
                          variant='contained'
                          className={classes.continuebtn}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                </Grid>
              </form>
              <ModalDialog {...DialogProps} />


              {SignQueues !== undefined && SignQueues.length > 0 ?

                <Paper style={{ marginTop: 20 }}>
                  <CustomPageLoading flag={loading} />
                  <Card>
                    <CardHeader

                      title="Activity"
                    // subheader={`ExamTaker `}
                    />
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TableContainer className={classes.container}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {(isMobileScreen() || isTabScreen()) ? <>
                                  <TableCell className={classes.tableHeaderCell}>Activity </TableCell>

                                </> :
                                  <>

                                    <TableCell className={classes.tableHeaderCell}>Document ID </TableCell>
                                    <TableCell className={classes.tableHeaderCell}>Document Name</TableCell>
                                    <TableCell className={classes.tableHeaderCell}>Chapter</TableCell>
                                    <TableCell className={classes.tableHeaderCell}>Date</TableCell>
                                    {/* <TableCell className={classes.tableHeaderCell}>Action</TableCell> */}
                                  </>
                                }
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {(SignQueues !== undefined && SignQueues.length == 0) ? (
                                <TableRow>
                                  <TableCell colSpan={3} className={classes.tableCell}>There are currently no Activity available here. </TableCell>
                                  {/* <TableCell colSpan={7} align="right" style={{ paddingBottom: 15 }}>
                                                 <Button variant="contained" color="primary" onClick={addGroupToExamTakerClicked}>
                                                     Add Group To The ExamTaker
                                                 </Button>
                                             </TableCell> */}
                                </TableRow>
                              ) : (
                                <Fragment>
                                  {SignQueues !== undefined && SignQueues.map(item => (
                                    <TableRow
                                      hover
                                      className={classes.rowhover}
                                      key={item.organizationId}
                                    // onClick={() => goToExams(item)}
                                    >
                                      {(isMobileScreen() || isTabScreen()) ? <>
                                        <TableCell className={classes.tableCell}>({item.documentId}) {item.documentName}<br /> Date -{DateTimeFormat(item.signedDate, 'MM/DD/YYYY hh:mm A')}</TableCell>

                                      </> :
                                        <>
                                          <TableCell align="left">
                                            {item.documentId}
                                          </TableCell>
                                          <TableCell align="left">
                                            {item.documentName}
                                          </TableCell>
                                          <TableCell align="left">
                                            {item.organizationName}
                                          </TableCell>
                                          <TableCell align="left">
                                            {DateTimeFormat(item.signedDate, 'MM/DD/YYYY hh:mm A')}
                                          </TableCell>

                                        </>
                                      }
                                    </TableRow>
                                  ))}
                                </Fragment>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Card></Paper> : null
              }
            </Fragment>)
          }
        </Fragment>

      }
    </>
  )
}

export default AddEditCollegeOfficial
