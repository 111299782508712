import { ADD_CHAT_BOX,REMOVE_CHAT_BOX } from "./ActionTypes";

export const addChatBox = (chatBox) => ({
    type: ADD_CHAT_BOX,
    payload: chatBox
  });
  
  export const removeChatBox = (title) => ({
    type: REMOVE_CHAT_BOX,
    payload: title
  });