import { getProductInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetProductReducer(state = getProductInitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_PRODUCT_LOADING:
            return {...state,loading:true};
        case types.GET_PRODUCT_SUCCESS:
            return {...state,details:payload,loading:false};
        case types.GET_PRODUCT_ERROR:
            return {...state,loading:false};
        default:
            return state;
    }
}