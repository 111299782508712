import { arrayInitial } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetExamTransactionResultReducer(state = arrayInitial,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_EXAM_TRANSACTION_RESULT_LOADING:
            return {data: [],loading:true,error:false};  

        case types.GET_EXAM_TRANSACTION_RESULT_SUCCESS:
            return {data: payload,loading:false,error:false}; 
            
        case types.GET_EXAM_TRANSACTION_RESULT_ERROR:
            return {data: payload,loading:false,error:true};      
            
        default:
            return state
    }
}