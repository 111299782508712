import { documentformentityInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetDocumentFormByEntityReducer(state = documentformentityInitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_DOCUMENT_FORMBYENTITY_LOADING:
            return {data:{}, error: false, loading:true};
        case types.GET_DOCUMENT_FORMBYENTITY_SUCCESS:
            return {data:payload,loading:false, error: false};
        case types.GET_DOCUMENT_FORMBYENTITY_ERROR:
            return {loading:false, data: payload, error: true};
        default:
            return state;
    }
}