import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MinimizeIcon from '@material-ui/icons/Minimize';
import SendIcon from "@material-ui/icons/Send";
import TextField from "@material-ui/core/TextField";
import {
  CustomAlertMessage,
  CustomPageLoading,
  DateTimeFormat,
} from "../../functions/CustomFunctions";
import { WrapText } from "@material-ui/icons";
import ChatCallWrapper from "../common/ChatCall";
import { GET_CHAT_HISTORY, CHAT_MESSAGES, } from "../../constants/apiPaths";
import {
  LOCALSTORAGE_ENTITYTYPEID,
  LOCALSTORAGE_AUTHTOKEN,
  LOCALSTORAGE_SOURCEOWNERID,
  LOCALSTORAGE_SIGNALR_MESSSAGE,
  LOCALSTORAGE_SIGNALR_CONNECTIONID
} from "../../constants/LocalStorage";
import API from "../../redux/actions/API"
import { authHeader } from "../../functions/CoreFunctions";
import { useDispatch, useSelector } from 'react-redux';
import * as signalR from "@microsoft/signalr";
import { addMessage } from "../../redux/actions/messagesActions";
import { useSnackbar } from 'notistack';



const useStyles = makeStyles((theme) => ({
  chatBox: {
    position: "absulate",
    right: theme.spacing(2),
    zIndex: 1000,
    width: 350,
    height: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  chatBoxHeader: {
    paddingLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  chatBoxTitle: {
    fontWeight: "bold",
    fontSize: "1rem",
    height: "2%",
  },
  chatBoxContent: {
    fontSize: "medium",
    padding: theme.spacing(2),
    overflowY: "scroll",
    maxWidth: "100%",
    wordWrap: "break-word",
    wordBreak: "break-all",
    height: "calc(100% - 110px)",
  },
  chatBoxFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  sentMessageContainer: {
    textAlign: "right",
  },
  sentMessage: {
    marginLeft: "20%",
    padding: "0.5rem",
    whiteSpace: "normal",
    wordBreak: "keep-all",
    textAlign: "left",
  },
  receivedMessage: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    whiteSpace: "normal",
    wordBreak: "keep-all",
    marginRight: "20%",
    padding: "0.5rem",
    textAlign: "left",
  },
  timestamp: {
    display: "block",
    fontSize: "0.8rem",
    color: theme.palette.text.secondary,
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  timestamp: {
    marginLeft: theme.spacing(1),
  },
  minimizedChatBox: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    transform: 'translate(0, 100%)',
    zIndex: '100',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
  }
}));
// OnlineStatusHub/Get
function ChatBox({ title, onClose, memberName }) {

  const dispatch = useDispatch()
  const pageNumber = useRef(1);
  const pageSize = 20

  let conn = useSelector(state => state.signalrReducer)
  let connection = conn.initialSignalRConnection

  // console.log(connection, "Connection from Redux");

  if (connection == undefined) {
    let connection = new signalR.HubConnectionBuilder()
      .withUrl(CHAT_MESSAGES)
      .withAutomaticReconnect([0, 500, 1000, 1500, 2000, 3000])
      .build();

    connection.start()
      .then(() => {
        console.log("Reconnected successfully");
      })
      .catch((error) => {
        console.error("Reconnection failed", error);
      });
  }

  const entityTypeId = localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID)
  const sourceOwnerId = localStorage.getItem(LOCALSTORAGE_SOURCEOWNERID)
  const Token = localStorage.getItem(LOCALSTORAGE_AUTHTOKEN)
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [formLoading, setLoading] = useState(false)
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const messagesEndRef = useRef(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const reduxMessages = useSelector(state => state.messagesReducer);
  const containerRef = useRef(null);

  const handleScroll = () => {
    const scrollTop = containerRef.current.scrollTop;
    if (scrollTop === 0) {
      // console.log('Scroll reached the top');
      getChatHistory()


      // console.log(pageNumber);
      // Perform your desired action here, such as calling an API
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);



  useEffect(() => {

    const onlyForUser = reduxMessages.reduce(function (acc, element) {

      if (element.from === title || element.to === title) {
        acc.push(element);
      }
      return acc;
    }, []);
    setMessages(onlyForUser)

  }, [reduxMessages])

  const handleMinimize = () => {
    onClose()
    // setIsMinimized(!isMinimized);
  };
  // fromEntityTypeId=2&toEntityTypeId=2&toSourceOwnerId=3&page=1&pageSize=20
  const getChatHistory = async () => {
    try {
      // setLoading(true);

      // console.log(pageNumber);
      const newTo = title.split("_")
      const options = {
        url: GET_CHAT_HISTORY,
        method: 'GET',
        params: {
          fromEntityTypeId: parseInt(entityTypeId),
          // toEntityTypeId: 2,
          // toSourceOwnerId: 3,
          toEntityTypeId: parseInt(newTo[0]),
          toSourceOwnerId: parseInt(newTo[1]),
          page: pageNumber.current,
          pageSize: pageSize
        },
        headers: authHeader()
      };
      const { data: apiResponse } = await API(options);
      const { status, response = {}, message = '' } = apiResponse;
      if (parseInt(status) === 1) {
        response?.ChatHistory.map(newMessage => dispatch(addMessage(newMessage)))
        pageNumber.current += 1
        // console.log(response.ChatHistory, "Chat History");
      } else {
        console.log("No Chat History found");
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        // console.log("No Chat History found");
      }
    } finally {
      // setLoading(false);
    }
  }

  const handleInputValueChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevents the newline character from being inserted in the input field
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (inputValue) {
      // const newMessage = {
      //   // from: (entityTypeId == 2 && sourceOwnerId == 3) ? `${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}` : "2_42419",
      //   // to: (entityTypeId == 2 && sourceOwnerId == 3) ? "2_42419" : `${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}`,
      //   from: (entityTypeId == 2 && sourceOwnerId == 3) ? `${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}` : title,
      //   to: (entityTypeId == 2 && sourceOwnerId == 3) ? title : `${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}`,
      //   timestamp: new Date(),
      //   message: inputValue,
      // };
      let newMessage = {}
      if (entityTypeId == 2 && sourceOwnerId == 3) {
        newMessage = {
          from: `${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}`,
          to: title,
          timestamp: new Date(),
          message: inputValue,
        };
      } else {
        newMessage = {
          from: `${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}`,
          to: title,
          timestamp: new Date(),
          message: inputValue,
        };
      }

      // dispatch(addMessage(newMessage))
      if (connection != undefined) {
        setMessages([...messages, newMessage]);
        dispatch(addMessage(newMessage))
        ChatCallWrapper(inputValue, messages, setMessages, connection, newMessage.to,)
        setInputValue("");
      } else {
        console.log("Error sending Message please try after relogin");
        CustomAlertMessage("Connection Error! Please login again and try", 'error', enqueueSnackbar);
      }

    }
  };



  // useEffect(() => {
  //   // getChatHistory();   // FEATCH CHAT HISTORY not working for admin side (OTHER USERS WILL GET PROPER FEEDBACK)
  // }, [reduxMessages])

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (

    // <Paper className={classes.chatBox}>
    <Paper className={isMinimized ? classes.minimizedChatBox : classes.chatBox}>
      <div className={classes.chatBoxHeader} >
        <div>
          <Typography className={classes.chatBoxTitle}>{title == "2_3" ? "Tech Support" : memberName}</Typography>
        </div>
        <div>
          <IconButton size="small" onClick={handleMinimize}>
            <MinimizeIcon />
          </IconButton>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.chatBoxContent} ref={containerRef}>
        <Typography
          variant="caption"
          color="textSecondary"
        // className={classes.timestamp}
        >
        </Typography>

        {messages?.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
          .map((message, index) => (
            <div
              key={index}
              className={`${classes.messageContainer} ${message?.from == `${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}` ? classes.sentMessageContainer : ""
                }`}
            >
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.timestamp}
              >
                <div >
                  {/* <span style={{ marginRight: '23%' }}>User name</span> */}
                  <span>
                    {" "}
                    at{" "}
                    {new Date(message?.timestamp).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                      month: "short",
                      day: "2-digit",
                    })}
                  </span>
                </div>
              </Typography>
              <Paper
                className={`${classes.message} ${message?.from == `${parseInt(entityTypeId)}_${parseInt(sourceOwnerId)}` ? classes.sentMessage : classes.receivedMessage
                  }`}
              >
                {message?.message}
              </Paper>
            </div>
          ))}
        <div ref={messagesEndRef} />
      </div>
      {/* <hr></hr> */}
      <div className={classes.chatBoxFooter}>
        <TextField
          fullWidth
          variant="outlined"
          label="Type a message"
          //multiline
          //maxRows={3}
          size="small"
          value={inputValue}
          onChange={handleInputValueChange}
          onKeyDown={handleKeyDown}
        />
        <IconButton onClick={handleSendMessage} aria-label="send">
          <SendIcon />
        </IconButton>
      </div>
    </Paper>

  );
}

export default ChatBox;
