import { objectInitial } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function LoggedUserDetailsReducer(state = objectInitial, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.LOGGED_USER_DETAILS_LOADING:
            return {payload,loading:true};
        case types.LOGGED_USER_DETAILS_SUCCESS:
            return {payload,loading:false};
        case types.LOGGED_USER_DETAILS_ERROR:
            return {payload,loading:false};
        default:
            return state;
    }
}