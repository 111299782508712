// CommonPaymentInformation

import React, { Fragment, useEffect } from 'react';
import { PAYMENT_METHOD_CUSTOMER_TOKEN } from '../../constants/LocalStorage';
import { getLoggedUserMemberId, loggedProspectDetails, loggedUserDetails } from '../../functions/CoreFunctions';
import { fixedFloatTwoDigit, getSignUpStepDataFromLocalStorage } from '../../functions/CustomFunctions';
import { ENTITY_TYPE_ID_FOR_MEMBER } from '../../constants/HelperConstants';
import { getLoggedEntityTypeID, getLoggedSourceOwnerID, loggedSupporterDetails, } from '../../functions/CoreFunctions';
import CommonPaymentForm from '../common/CommonPaymentForm';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Radio, Typography, withStyles } from '@material-ui/core';
import CommonPaymentSuccess from '../common/CommonPaymentSuccess';
import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import { isTabScreen } from '../../functions/commonFunctions';
import { CANDIDATE_DASHBOARD_PATH } from '../../constants/RoutePaths';
import { GetProspectsdetails } from '../../redux/actions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { GET_FEE_DETERMINE_SUCCESS } from '../../redux/actions/ActionTypes';

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: '1rem',
        color: theme.primaryColor,
        display: 'block',
        padding: '5px 21px',
        borderRadius: '50px',
        width: '100%'
    },
    backBtn: {
        // color: theme.primaryColor,
        // width:  isMobileScreen() ? '75%' :'25%',
        float: 'right',
        // boxShadow: theme.mainColorShadow,
        // borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '5px 8px',
        marginTop: '1rem'
        // position: isMobileScreen() ? '' : 'absolute', 
        // right: isMobileScreen() ? 10 : 40, top: isMobileScreen() ? -30 : 100, width: isMobileScreen() ? '20%' : 'auto', float: isMobileScreen() ? 'right' : '' 

    },
    modalTitle: {
        fontSize: '1rem',
        margin: 0
    },
    listStyle: {
        border: '1px solid #9c9898'
    },
    activeLineItem: {
        backgroundColor: 'rgb(232, 244, 253)',
        border: '1px solid #9c9898'
    },
    outlineBtn: {
        padding: '10px 35px',
        width: '100%',
        color: theme.primaryColor,
        display: 'block',
        borderRadius: '50px',
    },
    subtextStyle: {
        fontSize: '10px',
        marginTop: '-5px'
    },
    rightAlignText: {
        textAlign: 'right'
    },
    titleSection: {
        backgroundColor: 'rgb(232, 244, 253)',
    },
    pageTitle2: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: '23px',
        fontWeight: 500,
        padding: '10px 10px 10px 10px'
    },
    pageTitle3: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: '17px',
        padding: '0px 0px 13px 0px',
        marginTop: '-28px'
    },
    subDescStyle: {
        fontWeight: 'bold',
        color: 'red'
    },
    borderlessStyle: {
        borderBottom: 'none',
        fontWeight: 600
    },
    totalStyle: {
        fontWeight: 1000,
        fontSize: 'medium'
    },
    totalAmountStyle: {
        borderBottom: 'none',
        color: 'red',
        fontWeight: 1000,
        fontSize: 'large'
    },
    scrollableListSection: {
        height: isTabScreen() ? '55rem' : '40rem',
        //maxHeight: isMobileScreen() ? '65rem' : '52rem',
        overflow: 'auto',
        overflowX: 'hidden',
        padding: '10px'
    },
    updatedImage: {
        width: '180px'
    },
    pageHeader: {
        fontSize: 'x-large',
        // marginBottom: '1rem',
        textAlign: 'center'
    },
    continuebtn: {
        color: '#fff',
        width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px',
    },
    bg_onmount: {
        backgroundColor: 'rgb(255, 244, 229)'
    }

}));
// Custom Checkbox
const CustomRadio = withStyles(theme => ({
    root: {
        color: theme.primaryColor,
        '&$checked': {
            color: theme.primaryColor,
        },
    },
    checked: {},
}))((props) => <Radio color="default" {...props} />);

export default function CommonPaymentInformation(props) {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch();

    // isShowModalFlag, paymentResponseData = {}, commonApiCallback, setAsShowModalFlag, setPaymentDoneFlagFunc, setPaymentResponse, setAsShowPayment, candidateId, agreement = '', feeLineItems = [], application = {}, standardTransactionFeeCC = {}, achTransactionFee = 0 
    const { paymentPlan, setPaymentDoneFlagFunc, setPaymentResponse,
        setAsShowPayment, agreement = '', feeLineItems = [], lineItemsTotal = 0,
        standardTransactionFeeCC = {}, standardTransactionFeeACH = {},
        BackTo, callBack, AddMemberToRoster, rosterId = 0, isModalFlag = false, ModalClose, isCandidateApplication = false,
        isPaymentPlanFlag = false, loggedUserInformations = {},
        paymentPlanAmount, planPaymentMethod, paymentTerms, paymentPlanId, paymentPlanName, paymentPlanTermId,
        entityTypeId = 0,
        sourceOwnerId = 0,
        feeTypeID = 1
    } = props;
    // console.log(props, 'loggedUserInformations')
    const [isShowLineItem, setAsShowLineItem] = useState(true);
    const [paymentType, setPaymentType] = useState();
    const customerToken = localStorage.getItem(PAYMENT_METHOD_CUSTOMER_TOKEN);
    const [paymentSuccessFlag, setPaymentSuccessFlag] = useState(false);
    const [paymentResponseData, setPaymentResponseData] = useState({});
    const [xPaymentPlanAmount, setxPaymentPlanAmount] = useState(paymentPlanAmount);

    const paymentSuccessCallBack = (paymentResponse) => {
        setPaymentResponseData(paymentResponse);
        if (rosterId > 0) {
            AddMemberToRoster();
        }
        // console.info(paymentResponse, 'paymentResponse')
        if (setPaymentResponse)
            setPaymentResponse(paymentResponse);
        setPaymentSuccessFlag(true);
        setPaymentDoneFlagFunc(true);
    }
    // console.info(paymentSuccessFlag, 'paymentResponse')

    const paymentDialogClose = () => {
        if (callBack)
            callBack();
        if (setAsShowPayment) {
            setAsShowPayment(false)
        }
        setPaymentSuccessFlag(false);

        if (isCandidateApplication) {
            const prospectData = loggedProspectDetails()
            dispatch(GetProspectsdetails({ ProspectID: prospectData.prospectId }));
            history.push(CANDIDATE_DASHBOARD_PATH)
        }
        //clean out transaction fee record due to clean redux
        dispatch({
            type: GET_FEE_DETERMINE_SUCCESS,
            payload: { transactionFeeRecord: {} }
        });


    }

    useEffect(() => {
        setxPaymentPlanAmount(paymentPlanAmount)
    }, [paymentPlanAmount]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    const [backgroundClass, setBackgroundClass] = useState(false);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
    const [selectedamount, setSelectedamount] = useState(0);
    const [showPaymentMode, setShowPaymentMode] = useState(false);
    const { payload = {} } = useSelector(state => state.LoggedUserPaymentDetailsReducer);
    const { paymentPlanItem = '', paymentPlanItemID = 0 } = payload
    let paymentPlanlineItems = [{ description: paymentPlanItem, amount: selectedamount, productId: paymentPlanItemID, lineTypeID: 6 }]
    let paymentPlantotal = selectedamount

    useEffect(() => {
        if (paymentType > 0) {
            setBackgroundClass(true)
            setTimeout(() => {
                setBackgroundClass(false); // set class to none
            }, 1200);
        }
    }, [paymentType]);

    let totalAmount = lineItemsTotal
    let lineItem = feeLineItems
    const { amount: ccamount = 0, } = standardTransactionFeeCC
    const { amount: achAmount = 0 } = standardTransactionFeeACH

    if (parseInt(paymentType) == 1) {
        if (achAmount > 0) {
            totalAmount = lineItemsTotal + achAmount
            lineItem = [...feeLineItems, standardTransactionFeeACH];
        }

    } else if (parseInt(paymentType) == 2) {
        if (ccamount > 0) {
            totalAmount = lineItemsTotal + ccamount
            lineItem = [...feeLineItems, standardTransactionFeeCC];
        }
    }

    const paymentFormProps = {
        total: isPaymentPlanFlag ? paymentPlantotal : totalAmount,
        paymentPlanAmount: xPaymentPlanAmount, planPaymentMethod,
        currentInformationSource: loggedUserInformations,
        buttonLabel: 'Process Payment', 
        lineItems: isPaymentPlanFlag ? paymentPlanlineItems : lineItem,
        customerToken, paymentSuccessCallBack,
        paymentSuccessCallBack,
        entityTypeId: entityTypeId > 0 ? entityTypeId : getLoggedEntityTypeID(),
        sourceOwnerId: sourceOwnerId > 0 ? sourceOwnerId : getLoggedSourceOwnerID(),
        BackTo,
        paymentTerms,
        paymentPlanId, paymentPlanName: paymentPlan? paymentPlan.term:paymentPlanName, paymentPlanTermId,
        setAsShowLineItem,
        setPaymentType,
        standardTransactionFeeACH,
        standardTransactionFeeCC,
        feeTypeID
    };

    // console.log(paymentFormProps,"paymentFormProps");

    const {
        totalBalance = '',
        totalPaymentDue = ''
    } = paymentPlan || {};
    const paymentModes = [{ id: 1, name: 'Total Balance', amount: totalBalance }, { id: 2, name: 'Next Due', amount: totalPaymentDue }]


    return (
        <Fragment>

            <Dialog disableBackdropClick disableEscapeKeyDown fullWidth={true} maxWidth={'lg'} onClose={ModalClose} aria-labelledby="customized-dialog-title" open={isModalFlag}>
                <DialogTitle id="customized-dialog-title" onClose={ModalClose} >
                    <h6 className={classes.modalTitle}>Payment Information
                        <span style={{ float: 'right', cursor: 'pointer' }}>
                            <CloseIcon onClick={() => {
                                ModalClose()
                                if (isPaymentPlanFlag) {

                                    setSelectedamount(0)
                                    setSelectedPaymentMethod(0)
                                    setShowPaymentMode(false)
                                }
                            }

                            } />
                        </span>
                    </h6>
                </DialogTitle>
                <DialogContent dividers style={{ height: 'auto' }}>
                    {isPaymentPlanFlag && !showPaymentMode &&
                        <Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid container >
                                        {paymentModes.map(({ name, amount, id }, index) =>
                                            <Fragment key={`pay_list_${index}`}>
                                                <Grid item xs={1}>
                                                    <CustomRadio
                                                        data-payment_type={1}
                                                        className={classes.paymentRadioBtnStyle}
                                                        checked={parseInt(selectedPaymentMethod) === parseInt(id)}
                                                        onChange={() => { setSelectedPaymentMethod(id); setSelectedamount(amount); setxPaymentPlanAmount(amount) }}
                                                        value={id}
                                                        name="selectedPaymentMethod"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <ListItem key={`bank_list_${index}`}>

                                                        <ListItemText
                                                            primary={`${name}`}
                                                            secondary={`$${fixedFloatTwoDigit(amount)}`}
                                                        />

                                                    </ListItem>
                                                </Grid>
                                            </Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container direction="column" align="center" justify="center" item xs={12} sm={12} md={6}>
                                    {selectedamount > 0 ?
                                        <Typography >Amount to Pay :<span style={{ fontWeight: '700', fontSize: 24 }}>{` $${fixedFloatTwoDigit(selectedamount)}`}</span></Typography>
                                        : ''
                                    }
                                </Grid>
                            </Grid>
                            {selectedamount > 0 ?

                                <Button variant='contained' className={classes.continuebtn} onClick={() => { setShowPaymentMode(true) }}>
                                    Continue
                                </Button>
                                : ''
                            }

                        </Fragment>

                    }
                    {isCandidateApplication &&

                        <Typography style={{ fontWeight: '500', textAlign: 'center', marginBottom: '1rem' }}> Please review the following details for the transaction. </Typography>
                    }
                    {agreement &&
                        <Alert severity="info" style={{ marginBottom: '1rem' }}>
                            <AlertTitle>
                                {agreement}
                            </AlertTitle>
                        </Alert>
                    }
                    <Grid container spacing={3}>
                        <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />

                        <Grid item xs={12} sm={10} md={8}>
                            {isCandidateApplication && isShowLineItem ?
                                <Grid container spacing={3}>

                                    <Grid item xs sm={3} md={2} component={Box} display={{ xs: "none", md: 'block', sm: "block" }} />

                                    <Grid item xs={12} sm={6} md={8}>
                                        <div className={classes.demo}>
                                            <List dense={true}>
                                                {lineItem.map(({ description, amount, productId, per = '', description2, forPaymentPlan }, index) => (
                                                    <ListItem key={`fees_${index}`} className={(index === 1) ? classes.activeLineItem : classes.listStyle}>
                                                        <ListItemText
                                                            primary={description}
                                                            secondary={<Fragment>
                                                                <span className={(forPaymentPlan) ? classes.subDescStyle : ''}>{description2 || ''}</span>
                                                            </Fragment>}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Typography variant="body1" gutterBottom className={classes.rightAlignText}>
                                                                ${fixedFloatTwoDigit(amount)}
                                                            </Typography>
                                                            <p className={classes.subtextStyle}>{per ? per : ''}</p>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                                <ListItem key={`fees_lst`} className={backgroundClass ? classes.bg_onmount : ''}>
                                                    <ListItemText
                                                        primary={<Fragment>
                                                            <Typography variant="h6" gutterBottom >
                                                                Total
                                                            </Typography>
                                                        </Fragment>}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Typography variant="body1" gutterBottom className={classes.totalAmountStyle}>
                                                            ${fixedFloatTwoDigit(totalAmount)}
                                                        </Typography>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </List>
                                        </div>
                                    </Grid>
                                    <Grid item xs sm={3} md={2} component={Box} display={{ xs: "none", md: 'block', sm: "block" }} />
                                </Grid>
                                : ''}
                            <Grid container spacing={3} style={{ marginTop: '10px' }}>
                                {isPaymentPlanFlag ?
                                    showPaymentMode ?

                                        <CommonPaymentForm {...paymentFormProps} />
                                        : null
                                    :
                                    <CommonPaymentForm {...paymentFormProps} />
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                    </Grid>

                </DialogContent>
            </Dialog>
            {paymentSuccessFlag &&
                <Dialog disableBackdropClick disableEscapeKeyDown fullWidth={true} maxWidth={'lg'} onClose={paymentDialogClose} aria-labelledby="customized-dialog-title" open={paymentSuccessFlag}>
                    <DialogTitle id="customized-dialog-title" onClose={paymentDialogClose} >
                        <h6 className={classes.modalTitle}> Payment Success
                            <span style={{ float: 'right', cursor: 'pointer' }}>
                                <CloseIcon onClick={paymentDialogClose} />
                            </span>
                        </h6>
                    </DialogTitle>
                    <DialogContent dividers style={{ height: 'auto' }}>
                        <Grid container spacing={3} style={{ marginTop: '10px' }}>
                            <CommonPaymentSuccess paymentResponseData={paymentResponseData} DialogClose={paymentDialogClose} />
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
        </Fragment>
    );
}