import { upcomingFiscalYearitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetUpcomingFiscalYearDetailReducer(state = upcomingFiscalYearitialValues, action) {
    const { type, payload } = action;
    switch (type) {
        case types.UPCOMING_FISCAL_YEAR_DETAIL_LOADING:
            return { error: false, loading: true, data: {} };
        case types.UPCOMING_FISCAL_YEAR_DETAIL_SUCCESS:
            return { ...state, data: payload, loading: false };
        case types.UPCOMING_FISCAL_YEAR_DETAIL_ERROR:
            return { error: true, loading: false, data: {} };
        default:
            return state;
    }
}



