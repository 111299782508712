import { objectInitial } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetDuesSummaryReducer(state = objectInitial, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_DUES_SUMMARY_LOADING:
            return {...state,loading:true};
        case types.GET_DUES_SUMMARY_SUCCESS:
            return {...state,duesSummary:payload,loading:false};
        case types.GET_DUES_SUMMARY_ERROR:
            return {...state,loading:false};
        default:
            return state;
    }
}