import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { authHeader } from '../../../functions/CoreFunctions'
import { UPSERT_CLUSTER_EVENT_API, UPSERT_PLANNING_DOCUMENT_EVENT_API } from '../../../constants/apiPaths'
import API from '../../../redux/actions/API'
import { CustomAlertMessage, CustomPageLoading } from '../../../functions/CustomFunctions'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import Box from "@material-ui/core/Box";
import { GetEventDetails, getEventFilterOptions, GetIntakeSeasonSteps } from '../../../redux/actions'
import { useHistory, useParams } from 'react-router-dom'
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { EVENTS_PATH } from '../../../constants/RoutePaths'
import { LOCALSTORAGE_AUTH_MEMBER_ID } from '../../../constants/LocalStorage'
import { isMobileScreen } from '../../../functions/commonFunctions'

// Schema
const validationSchema = Yup.object({

})

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    continuebtn: {
        color: '#fff',
        width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px'
    },
    formControl: {
        width: '100%'
    },
    formHeader: {
        fontSize: 'x-large',
        textAlign: 'center'
    },
    formSubHeader: {
        marginBottom: '1rem',
        textAlign: 'center'
    },
    formSubHeader2: {
        fontSize: '12px',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },
    imgCircle: {
        textAlign: 'center',
        border: '1px solid #eee',
        width: '113px',
        height: '113px',
        padding: '5px',
        backgroundColor: '#eee',
        borderRadius: '50%',
    },
    imgCircleDiv: {
        textAlign: 'center',
    },
    fileInput: {
        marginTop: '-30px',
        marginLeft: '-24px',
        backgroundColor: '#eee',
        padding: '4px',
        '&:hover': {
            backgroundColor: '#eee',
        }
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    divider: {
        margin: `0 0 0 0`,
        marginTop: '10px',
        marginBottom: '10px',
    },
}))

// ----------------------------------------------------------------------------
const EntityCheckinQuestions = props => {
    const { questions = [], setIsShowCheckinForm, isCandidateApplication = false, callBack = null } = props
    const classes = useStyles();
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { setIsShowForm, IntakeSeasonID, organizationID, selectedRecord, OrganizationID, ModalClose, selectedMeeting, handleCallback, documentTransactionId } = props
    const loggedMemberId = localStorage.getItem(LOCALSTORAGE_AUTH_MEMBER_ID);



    // Form Initial Values
    const initialFormValues = {
        question: questions

    }


    const [formData, setFormData] = useState(initialFormValues);
    const [formLoading, setFormLoading] = useState(false);


    // Save Form Data
    const saveFormValues = async values => {

    }



    // Formik
    const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, setFieldValue } = useFormik({
        initialValues: formData,
        enableReinitialize: true,
        validationSchema,
        onSubmit: values => saveFormValues(values)
    })



    let {
        question = questions
    } = values



    const questionList = values.question.filter((q) => q.value == null)
    console.log(questionList, 'questionList')

    return (
        <Fragment>
            <form noValidate autoComplete='off' onSubmit={handleSubmit} >
                {formLoading && <CustomPageLoading flag={formLoading} />}
                {question.map((q) => {
                    return (
                        <Fragment>
                            <Grid container>

                                <Grid item xs={isMobileScreen() ? 12 : 10}>
                                    <Typography>{q.title}</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <div style={{ display: 'flex', marginBottom: '5px', marginTop: '5px' }}>

                                        <Button
                                            variant="contained" color={q.value == true ? "secondary" : "primary"}
                                            style={{ marginRight: '5px' }}
                                            onClick={() => {
                                                let index = question.findIndex((a) => a.id === q.id)
                                                question[index].value = true
                                                console.info(question, 'question')
                                                setFieldValue("question", question)
                                            }}
                                        >
                                            yes
                                        </Button>
                                        <Button
                                            variant="contained" color={q.value == false ? "secondary" : "primary"}
                                            onClick={() => {
                                                let index = question.findIndex((a) => a.id === q.id)
                                                question[index].value = false
                                                setFieldValue("question", question)

                                            }}
                                        >
                                            no
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />


                        </Fragment>

                    )
                })}
                {questionList.length == 0 && setIsShowCheckinForm && isCandidateApplication == false ?
                    <Button
                        // type='submit'
                        variant='contained'
                        className={classes.continuebtn}
                        style={{ marginTop: '20px' }}
                        onClick={() => setIsShowCheckinForm(true)}
                    >
                        Next
                    </Button>
                    : null}

                {questionList.length == 0 && isCandidateApplication ?

                    <Button
                        // type='submit'
                        variant='contained'
                        className={classes.continuebtn}
                        onClick={callBack}
                    >
                        check in
                    </Button>
                    : null}

            </form>
        </Fragment>
    )
}

export default EntityCheckinQuestions
