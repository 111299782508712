import { ENTITY_TYPE_ID_FOR_MEMBER } from "../../constants/HelperConstants";

export const objectInitial = {};
export const accessLevelobjectInitial = {};
export const objectInitialnavbsr = {};
export const objectInitialschool = {};
export const objectInitialsupporter = {};
export const arrayInitial = [];
export const stringInitial = '';
export const commonListingIArraynitialValues = {
    loading: false,
    data: [],
    error: false
};
export const ticketpurchaseorderHistoryiitialValues = {
    loading: false,
    data: [],
    error: false
};

export const MemberManagedEntityinitials = {
    loading: false,
    data: {},
    error: false
};

export const initialGetListWithFilters = {
    loading: true,
    count: 0,
    data: [],
    error: false
}
export const signerpositiontypeinitialValues = {
    loading: false,
    data: [],
    error: false
};

export const commonListingObjectInitialValues = {
    loading: false,
    data: {},
    error: false
};
export const documentformentityInitialValues = {
    loading: false,
    data: {},
    error: false
};

//Signup Steps
export const initialSignUpStepsFormValues = {
    loading: false,
    countries: [],
    usStates: [],
    timeZones: [],
    error: false
}

//Payment Mrthods
export const initialGetPaymentMethods = {
    loading: false,
    payload: {},
    error: false
}

//Forgot Password
export const initialForgotPasswordValues = {
    currentStep: 0,
    EntityTypeID: ENTITY_TYPE_ID_FOR_MEMBER,
    Email: '',
    SourceOwnerID: '',
    HostCode: ''
};

//Make Payment Form Data

export const makePaymentInitialValues = {
    PAYMENT_INFORMATION: {
        cardHolderName: '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        securityCode: '',
        bankName: '',
        accountHolderName: '',
        bankAccountType: '',
        accountNumber: '',
        routingNumber: '',
        sameAsCurrentAddress: false,
        addressLine: '',
        city: '',
        state: '',
        country: 'United States',
        zipCode: '',
    }
}
export const initialMakePaymentFormValues = {
    currentStep: 0,
    paymentPlanId: '',
    paymentPlanAmount: '',
    paymentPlanName: '',
    planPaymentMethod: 0,
    ...makePaymentInitialValues
}

//Get Recommened Chapters initial values
export const initialDistanceMiles = 50;
export const recommendedChaptersInitialValues = {
    chapterLoading: false,
    loadDistance: initialDistanceMiles,
    initialChaptersList: [],
    chaptersList: [],
    ChapterInvites: [],
    ChapterInvitationRecords: [],
    defaultImageToCircle: ''
};

//My Finance Account
export const initialGetAccountSummary = {
    loading: false,
    purchaseOrderStatus: [],
    presentBalance: 0,
    withdrawalBalance: 0,
    numericalWithdrawalBalance: 0,
    bankAccountNumber: '',
    entityName: '',
    error: false,
    candidatesThatNeedRefund: [],
    paymentRefundRequestTransactions: [],
    bankHasForteToken: false,
    hasPendingWithdrawal: false
}

export const initialGetAccountSummaryTransactions = {
    loading: false,
    totalCount: 0,
    moreTransactions: {},
    error: false
}

export const initialGetPaymentPlanAdminList = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const initialGetMemberList = {
    loading: false,
    count: 0,
    data: [],
    error: false
}

export const exportinitialGetMemberList = {
    loading: false,
    count: 0,
    data: [],
    error: false
}

export const initialGetHardList = {
    loading: false,
    count: 0,
    data: [],
    error: false
}

export const initialNationalIntakeseasonData = {
    loading: false,

    nationalintakeseasondata: {},
    regionalintakeseasondata: {},
    localintakeseasondata: {},
    error: false
}

export const initialGetMemberTimeList = {
    loading: true,
    count: 0,
    data: [],
    error: false
}
export const initialGetCandidateList = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const exportinitialGetCandidateList = {
    loading: false,
    count: 0,
    data: [],
    error: false
}

export const initialGetSchoolList = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const initialGetReferenceLetterList = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const initialGetSchoolListByorgId = {
    loading: false,
    data: [],
    error: false
}

export const initialGetcollegeofficialSchoolList = {
    loading: false,
    data: [],
    error: false
}


export const initialGetCollegeOfficialList = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const initialLeadershipOrganizationList = {
    loading: false,
    data: [],
    error: false
}
export const initialGetMemberPendingRosterEntries = {
    loading: false,
    data: [],
    error: false
}

export const initialMemberOrganizationProfile = {
    name: '',
    address: '',
    address2: '',
    bio: '',
    city: '',
    country: '',
    organizationId: '',
    organizationTypeId: '',
    orgImage: '',
    parentOrganizationId: '',
    password: '',
    phone: '',
    state: '',
    web: '',
    zip: '',
    profileImageUrl: ''
}

//Admin Dashboard Initial Values
export const adminDashboardInitialValues = {
    activePaymentPlans: 0,
    paymentPlanEarnings: '',
    totalActiveMember: 0,
    totalInActiveMember: 0,
    totalMemberCount: 0,
};

//products
export const getAdminProductInfo = {
    loading: false,
    productInfo: {},
    error: false
}

//product
export const getProductInitialValues = {
    loading: false,
    details: {},
    error: false
}

//product Group
export const getPossibleProductGroupValues = {
    loading: false,
    data: [],
    error: false
}
//Document Detail
export const getDocumentDetailInitialValues = {
    loading: false,
    documentFormDetail: {},
    error: false
}

export const initialMemberFilterOptionsValues = {
    loading: false,
    regions: [],
    positions: [],
    chapters: [],
    error: false
}

export const initialDocumentFilterOptionsValues = {
    loading: false,
    Chapters: [],
    DocumentForms: [],
    DocumentTransactionStatuses: [],
    Regions: [],
    Seasons: [],
    error: false
}

export const initialEventFilterOptionsValues = {
    loading: false,
    eventTypeSubs: [],
    organizations: [],
    eventTypes: [],
    leadershipOrganizations: [],
    season: [],
    allOrganization: [],

    error: false
}

export const initialMemberGeoInfoOptionsValues = {
    loading: false,
    countries: [],
    timeZones: [],
    error: false
}

export const initialCandidateFilterOptionsValues = {
    loading: false, Chapters: [], Season: [], OrganizationType: [], Classifications: [],
    CandidateStatus: [], Sogroup: [], Soschool: [], Regions: [], Schools: [],
    error: false
}

export const getMemberProfileDetailsInitialValues = {
    loading: false,
    documents: [],
    profile: {},
    currentChapters: [],
    chapterHistory: [],
    loginHistory: [],
    positions: [],
    financialStatus: [],
    positionAndGroups: {},
    intakeTrained: null,
    referenceLetters: [],
    ThirdPartyData: {},
};

export const getMasterIntakeDetailsInitialValues = {
    loading: false,
    details: {},
    error: false
};


export const RegionListinitialValues = {
    loading: false,
    count: 0,
    data: [],
    error: false
}

export const getSchoolProfileDetailsInitialValues = {
    loading: false,
    schoolDetailRec: {},

};
export const getCollegeOfficialsDetailsInitialValues = {
    loading: false,
    CollegeOfficialDetailRec: {},
    error: false

};
export const getMemberCalenderEventsInitialValues = {
    loading: false,
    events: {},
    integrationProfile: {},
    organizationDetail: {},
    menuLinks: [],
    CanDoChapterCertification: false
};

export const getCandidateProfileDetailsInitialValues = {
    loading: false,
    Schools: [],
    Candidate: {},
    PurchaseOrder: {},
    StatusHistory: [], DocumentForms: []
};



export const getPurchaseOrderDetailsInitialValues = {
    loading: false,
    po: {},
    poLines: [],
    ledgerEntries: [],
    error: false
};

export const initialGetRPTPaymentDetails = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const initialGetAutoDebitLogsInitialValues = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const initialGetUpcomingAutoDebitsInitialValues = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const initialEntityExamTransactionValues = {
    loading: true,
    count: 0,
    data: [],
    error: false
};

export const initialGetChapterList = {
    loading: false,
    count: 0,
    data: [],
    error: false
}

export const initialGetDocumentList = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const initialGetRequiredDocumentList = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const getChapterDetailsInitialValues = {
    loading: false,
    chapter: {},

    error: false
}

export const getRegionalRosterDetailsInitialValues = {
    loading: false,
    region: {},

    error: false
}


export const getChapterDetailsPopupInitialValues = {
    loading: false,
    chapterdetailspopup: {},
    error: false
}

export const initialChapterFilterOptionsValues = {
    loading: false,
    OrganizationStatus: [],
    OrganizationType: [],
    Sogroup: [],
    Soschool: [],
    Chapters: [],
    error: false
}

//Xref Types
export const initialXrefTypes = {
    loading: false,
    payload: [],
    error: false
}

//Setting Configs
export const initialSettingConfigs = {
    loading: false,
    payload: [],
    error: false
}

//Entity Selection list
export const initialEntitySelectionList = {
    loading: false,
    payload: [],
    error: false
}

//Position list
export const initialPositionLists = {
    loading: false,
    payload: [],
    error: false
}

//Position Types
export const initialPositionTypes = {
    loading: false,
    payload: [],
    error: false
}

export const PositionDetailsInitialValues = {
    loading: false,
    PositionDetailRec: {},

};

export const PlanningDocumentDetailsInitialValues = {
    loading: false,
    PlanningDocumentDetailRec: {},

};

export const PlanningDocumentFilterInitialValues = {
    loading: false,
    PlanningDocumentFilterRec: [],

};

export const PositionFilterInitialValues = {
    loading: false,
    PositionFilterRec: {},

};

export const EventListInitialValues = {
    loading: false,
    count: 0,
    data: [],

};
export const getEventDetailsInitialValues = {
    loading: false,
    eventDetails: {},

};

export const getCalenderEventDetailsInitialValues = {
    loading: false,
    calenderEventDetails: {},

};

export const initialGetTicketList = {
    loading: true,
    count: 0,
    data: [],
    error: false
}

export const getTicketDetailsInitialValues = {
    loading: false,
    ticketDetails: {},
};

export const getTicketFilterInitialValues = {
    loading: false,
    ticketfilters: {},

};

export const getTicketFeeInitialValues = {
    loading: false,
    transactionFeeRecord: {},

};

//planning document list
export const initialPlanningDocumentLists = {
    loading: false,
    payload: [],
    error: false
}

export const getOrganizationDetailsforProspectsInitialValues = {
    loading: false,
    data: {},
    error: false

};

export const planningdocumenteventcheckinInitialValues = {
    loading: false,
    data: {},
    error: false
};

export const ProspectsGetApplicationStatus = {
    loading: false,
    data: {},
    error: false
};

export const ProspectsdetailsInitialValues = {
    loading: false,
    data: {},
    error: false
};

export const LatestApproveOfficerReportInitialValues = {
    loading: false,
    data: {},
    error: false
};
export const IntakeSeasoninitialValues = {
    loading: false,
    data: [],
    error: false
};

export const getcourseInitialValues = {
    loading: false,
    data: {},
    error: false
};
export const getExamBycourseInitialValues = {
    loading: false,
    data: {},
    error: false
};

//ChapterRosterActiity
export const getChapterRosterActiityInitialValues = {
    loading: false,
    activity: [],
    error: false
}

export const IntakePaymentSummaryInitialValues = {
    loading: false,
    data: {},
    error: false
};

export const FinanceReportingFilterInitialValues = {
    loading: false,
    data: {},
    error: false
};

export const FinanceProducrReportInitialValues = {
    loading: false,
    data: {},
    error: false
};


export const getchapterDuesSummaryitialValues = {
    loading: false,
    IncludeNationalloading: false,
    data: {},
    IncludeNationalData: {},
    error: false,
    IncludeNationalerror: false
};

export const upcomingFiscalYearitialValues = {
    loading: false,
    data: {},
    error: false
};

export const getInvitesitialValues = {
    loading: false,
    data: [],
    error: false
};

export const getQuickLinksitialValues = {
    loading: false,
    data: {},
    error: false
};
export const getDashboardTabstialValues = {
    loading: false,
    data: {},
    error: false
};

export const initialSignalRConnection = {};

export const initialMessages = [];

export const chatBoxes = [];