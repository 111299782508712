import { initialGetDocumentList } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

const GetDocumentsListReducer = (state = initialGetDocumentList, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_DOCUMENT_LIST_LOADING:
            return { ...state, loading: true, error: false };

        case types.GET_DOCUMENT_LIST_SUCCESS:
            return { ...state, ...payload, loading: false, error: false };

        case types.GET_DOCUMENT_LIST_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state
    }
}

export default GetDocumentsListReducer
