import { getChapterRosterActiityInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetChapterRosterActivityReducer(state = getChapterRosterActiityInitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_CHAPTER_ACTIVITY_LOADING:
            return {...state,loading:true};
        case types.GET_CHAPTER_ACTIVITY_SUCCESS:
            return {...state,...payload,loading:false};
        case types.GET_CHAPTER_ACTIVITY_ERROR:
            return {...state,loading:false};
        default:
            return state;
    }
}



