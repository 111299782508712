import {
  GET_LOGIN_API,
  GET_LOGIN_SSO_API,
  GET_SIMPLE_ORGANIZATION_API,
  GET_ORGANIZATIONCONFIG_API,
  POST_MEMBERLOOKUP,
  POST_VALIDATEVERIFICATIONCODE_API,
  POST_SENDVERIFICATIONCODE_API,
  CHAT_MESSAGES,
} from "../../constants/apiPaths";
import Axios from "axios";
import {
  LOCALSTORAGE_AUTHTOKEN,
  LOCALSTORAGE_PARENT_ORGANIZATION_NAME,
  LOCALSTORAGE_PROFILE,
  LOCALSTORAGE_AUTH_MEMBER_ID,
  LOCALSTORAGE_THIRDPARTYINTEGRATION,
  LOGGED_USER_ACCESS_LEVEL,
  LOCALSTORAGE_APP_NAME,
  LOCALSTORAGE_ENTITYTYPEID,
  LOCALSTORAGE_AUTH_PERSON_ID,
  LOCALSTORAGE_PRIMARY_COLOR,
  LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID,
  LOCALSTORAGE_AUTH_PROSPECT_ID,
  LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_NAME,
  LOCALSTORAGE_PROSPECT_PLANNING_DOCUMENT_ID,
  LOCALSTORAGE_PROSPECT_PROFILE,
  LOCALSTORAGE_SOURCEOWNERID,
  LOCALSTORAGE_LANDINGPAGEIMAGE,
  LOCALSTORAGE_REQUIRE_EVENT_INSURANCE,
  LOCALSTORAGE_SUPPORTER_DETAILS,
  ISLASTMODIFIEDATEWITHINFISCALDATE,
  LOCALSTORAGE_INTAKELANDINGPAGEIMAGE,
  LOCALSTORAGE_QUESTIONBUILDER_DOCUMENT_FORMS,
  LOCALSTORAGE_AUTH_SUPPORTER_ID,
  LOCALSTORAGE_SIGNALR_MESSSAGE,
  LOCALSTORAGE_SIGNALR_CONNECTIONID
} from "../../constants/LocalStorage";
import {
  MOVE_SIGNUP_NEXTSTEP,
  MOVE_SIGNUP_PREVSTEP,
  SET_CHAPTERS,
  SET_THIRDPARTYINTEGRATION,
  UPDATE_SIGNUP_DETAIL,
  UPDATE_ACTIVESTEP,
  SCHOOL_LOGGED_USER_DETAILS_SUCCESS,
  GET_PROSPECT_DETAILS_SUCCESS,
  SUPPORTER_LOGGED_USER_DETAILS_SUCCESS,
} from "./ActionTypes";
import { CustomAlertMessage } from "../../functions/CustomFunctions";
import { startPageLoading, stopPageLoading } from "./miscactions";
import { getSignalRConnection } from "./signalRCon";
import {
  CANDIDATE_DASHBOARD_PATH,
  DASHBOARD_PATH,
  HOME_PATH,
  PAYMENT_PLAN_PATH,
  SCHOOL_DASHBOARD_PATH,
  SUPPORTER_DASHBOARD_PATH,
} from "../../constants/RoutePaths";
import API from "./API";
import LoginSignal from "../../components/common/LoginSignal";
import SignalRService from "../../components/common/SignalRService";

//actions
export const moveSignupNextStep = () => {
  return {
    type: MOVE_SIGNUP_NEXTSTEP,
  };
};

export const moveSignupPrevStep = () => {
  return {
    type: MOVE_SIGNUP_PREVSTEP,
  };
};

export const setChapters = (data) => {
  return {
    type: SET_CHAPTERS,
    payload: data,
  };
};

export const setThirdPartyIntegration = (data) => {
  return {
    type: SET_THIRDPARTYINTEGRATION,
    payload: data,
  };
};

export const updateSignUpDetails = (data) => {
  return {
    type: UPDATE_SIGNUP_DETAIL,
    payload: data,
  };
};

export const updateSignUpActiveStep = (data) => {
  return {
    type: UPDATE_ACTIVESTEP,
    payload: data,
  };
};

export const login = (
  data,
  history,
  enqueueSnackbar,
  getData,
  successCallback = ""
) => {
  // let values={...data,EntityTypeID:2 }

  return (dispatch) => {
    dispatch(startPageLoading());
    API.post(GET_LOGIN_API, data)
      .then((res) => {
        const {
          Profile = {},
          isLastModifiedDateWithinFiscalDate = true,
          Status,
          Message = "",
          AccessLevel = "",
          Organization = {},
        } = res.data;
        const { parentOrganizationId = 0, organizationId = 0 } =
          Organization || {};
        if (parseInt(Status) == 1) {
          localStorage.setItem(LOCALSTORAGE_AUTHTOKEN, res.data.Token);
          localStorage.setItem(LOCALSTORAGE_ENTITYTYPEID, data.EntityTypeID);
          if (getData !== "") {
            getData();
          }
          if (data.EntityTypeID == 2) {
            const { memberId } = Profile;
            localStorage.setItem(
              ISLASTMODIFIEDATEWITHINFISCALDATE,
              isLastModifiedDateWithinFiscalDate
            );
            localStorage.setItem(LOGGED_USER_ACCESS_LEVEL, AccessLevel);
            localStorage.setItem(
              LOCALSTORAGE_PROFILE,
              JSON.stringify(res.data)
            );
            localStorage.setItem(LOCALSTORAGE_AUTH_MEMBER_ID, memberId);
            localStorage.setItem(LOCALSTORAGE_SOURCEOWNERID, memberId);
            console.info(AccessLevel, "AccessLevel 0");
            if (AccessLevel === "NonFinancial") {
              history.push(PAYMENT_PLAN_PATH);
            } else if (
              AccessLevel === "NoChapter" || AccessLevel === 'NonFinancialLocal' ||
              (AccessLevel === "Local" &&
                parseInt(parentOrganizationId) === parseInt(organizationId))
            ) {
              history.push(HOME_PATH);
            }
            // else if (AccessLevel === 'Local' && rea) {
            //   history.push(HOME_PATH);
            // }
            else {
              history.push(DASHBOARD_PATH);
            }
          }

          else if (data.EntityTypeID == 7) {
            localStorage.setItem(
              LOCALSTORAGE_AUTH_PERSON_ID,
              res.data.Details.Person.personId
            );
            localStorage.setItem(
              LOCALSTORAGE_SOURCEOWNERID,
              res.data.Details.Person.personId
            );
            history.push(SCHOOL_DASHBOARD_PATH);
            dispatch({
              type: SCHOOL_LOGGED_USER_DETAILS_SUCCESS,
              payload: res.data.Details,
            });
            // lydia.bullock@slu.edu
          } else if (data.EntityTypeID == 11) {
            localStorage.setItem(
              LOCALSTORAGE_SOURCEOWNERID,
              res.data.Details.prospect.prospectId
            );
            // localStorage.setItem(LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID, res.data.Details.prospect.organizationId);
            // localStorage.setItem(LOCALSTORAGE_AUTH_PROSPECT_ID, res.data.Details.prospect.prospectId);
            localStorage.setItem(
              LOCALSTORAGE_PROSPECT_PROFILE,
              JSON.stringify(res.data.Details.prospect)
            );

            // localStorage.setItem(LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_NAME, res.data.Details.prospect.prospectId);
            // localStorage.setItem(LOCALSTORAGE_PROSPECT_PLANNING_DOCUMENT_ID, res.data.Details.prospect.prospectId);

            history.push(CANDIDATE_DASHBOARD_PATH);
            dispatch({
              type: GET_PROSPECT_DETAILS_SUCCESS,
              payload: res.data.Details,
            });
            // lydia.bullock@slu.edu
          } else if (data.EntityTypeID == 4) {
            const { supporterId } = res.data.Details || {};
            const paths = window.location.pathname.split("/");
            if (paths[1] !== 'eventcheckout') {
              history.push(SUPPORTER_DASHBOARD_PATH);
            }
            localStorage.setItem(
              LOCALSTORAGE_SOURCEOWNERID,
              parseInt(supporterId)
            );
            localStorage.setItem(
              LOCALSTORAGE_SUPPORTER_DETAILS,
              JSON.stringify(res.data.Details)
            );
          }
          CustomAlertMessage("Login Successfully", "success", enqueueSnackbar);

          const signalRService = SignalRService();

          if (process.env.REACT_APP_CHAT == 1) {
            signalRService.startSignalRConnection();
            signalRService.receiveSignalRMessage();
            dispatch(getSignalRConnection(signalRService.singalRConnection));
          }


          //SignalR notifying process after every 5 mins
          LoginSignal()


          if (successCallback) successCallback();
        } else {
          CustomAlertMessage(res.data.Message, "error", enqueueSnackbar);
        }
      })
      .catch((error) => {
        console.error(error.message);
        CustomAlertMessage(error.message, "error", enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopPageLoading());
      });
  };
};

export const login_SSO = (data, history, enqueueSnackbar) => {
  return (dispatch) => {
    dispatch(startPageLoading());
    API.post(GET_LOGIN_SSO_API, data)
      .then((res) => {
        window.location.assign("http://revwinapp.com");
      })
      .catch((error) => {
        CustomAlertMessage(error.message, "error", enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopPageLoading());
      });
  };
};

export const getOrganizations = (PID) => {
  return (dispatch) => {
    API.get(GET_SIMPLE_ORGANIZATION_API)
      .then((res) => {
        dispatch(setChapters(res.data.response));
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const getOrganizationConfigs = (PID, callBack = null) => {
  return (dispatch) => {
    API.get(GET_ORGANIZATIONCONFIG_API)
      .then((res) => {
        const { response = {} } = res.data;
        const { ParentOrganization = {}, config = [], QuestionBuilderDocumentForms = [] } = response;
        const { formalName = "" } = ParentOrganization;


        let tempThirdPartyIntegration = config.find(
          (element) => element.settingKey === "ThirdPartyIntegration_Member"
        )
        const ThirdPartyIntegration = tempThirdPartyIntegration != undefined ? tempThirdPartyIntegration.settingValue : '';
        let tempappName = config.find(
          (element) => element.settingKey === "AppName"
        )
        const appName = tempappName != undefined ? tempappName.settingValue : '';
        let tempprimaryColor = config.find(
          (element) => element.settingKey === "Theme_PrimaryColor"
        )
        const primaryColor = tempprimaryColor != undefined ? tempprimaryColor.settingValue : '';
        let tempLandingPageImage = config.find(
          (element) => element.settingKey === "Theme_LandingPageImage"
        )
        const LandingPageImage = tempLandingPageImage != undefined ? tempLandingPageImage.settingValue : '';
        let tempRequireEventInsurance = config.find(
          (element) => element.settingKey === "RequireEventInsurance"
        )
        const RequireEventInsurance = tempRequireEventInsurance != undefined ? tempRequireEventInsurance.settingValue : '';
        let tempIntakeLandingPageImage = config.find(
          (element) => element.settingKey === "Theme_IntakeLandingPageImage"
        )
        const IntakeLandingPageImage = tempIntakeLandingPageImage != undefined ? tempIntakeLandingPageImage.settingValue : '';
        if (ThirdPartyIntegration) {
          dispatch(setThirdPartyIntegration(ThirdPartyIntegration));
        }
        localStorage.clear();
        localStorage.setItem(
          LOCALSTORAGE_THIRDPARTYINTEGRATION,
          ThirdPartyIntegration
        );
        localStorage.setItem(LOCALSTORAGE_PARENT_ORGANIZATION_NAME, formalName);
        localStorage.setItem(LOCALSTORAGE_APP_NAME, appName);
        localStorage.setItem(LOCALSTORAGE_PRIMARY_COLOR, primaryColor);
        localStorage.setItem(LOCALSTORAGE_LANDINGPAGEIMAGE, LandingPageImage);
        localStorage.setItem(LOCALSTORAGE_INTAKELANDINGPAGEIMAGE, IntakeLandingPageImage);
        localStorage.setItem(LOCALSTORAGE_QUESTIONBUILDER_DOCUMENT_FORMS, JSON.stringify(QuestionBuilderDocumentForms))
        localStorage.setItem(
          LOCALSTORAGE_REQUIRE_EVENT_INSURANCE,
          RequireEventInsurance
        );


        if (callBack != null) {
          callBack(formalName);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const postMemberLookup = (data, response) => {
  return (dispatch) => {
    dispatch(startPageLoading());
    API.post(POST_MEMBERLOOKUP, data)
      .then((res) => {
        response(res);
      })
      .catch((error) => {
        console.error(error.message);
        // response(error);
      })
      .finally(() => {
        dispatch(stopPageLoading());
      });
  };
};

export const sendVerificationCode = (data, enqueueSnackbar, response) => {
  return (dispatch) => {
    dispatch(startPageLoading());
    Axios.post(POST_SENDVERIFICATIONCODE_API, data)
      .then((res) => {
        if (res.data.status === 1) {
          CustomAlertMessage(
            "Verification code has been sent",
            "success",
            enqueueSnackbar
          );
          response(res);
        } else {
          CustomAlertMessage(
            "Unexpected error occured, please try again later",
            "error",
            enqueueSnackbar
          );
        }
      })
      .catch((error) => {
        console.error(error.message);
        CustomAlertMessage(
          "Unexpected error occured, please try again later",
          "error",
          enqueueSnackbar
        );
      })
      .finally(() => {
        dispatch(stopPageLoading());
      });
  };
};

export const validateVerificationCode = (data, enqueueSnackbar, response) => {
  return (dispatch) => {
    dispatch(startPageLoading());
    Axios.post(POST_VALIDATEVERIFICATIONCODE_API, data)
      .then((res) => {
        if (res.data.status === 1) {
          response();
        } else {
          CustomAlertMessage(res.data.message, "error", enqueueSnackbar);
        }
      })
      .catch((error) => {
        console.error(error.message);
        CustomAlertMessage(error.message, "error", enqueueSnackbar);
      })
      .finally(() => {
        dispatch(stopPageLoading());
      });
  };
};
