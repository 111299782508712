// import { FormControl,   Grid, makeStyles, Paper, Table, Typography } from "@material-ui/core";
import { Button, Table, Paper, Grid, makeStyles } from "@material-ui/core";
import React, { useState } from 'react';
import { Fragment } from 'react';
import { useHistory } from "react-router";
import { isMobileScreen } from "../../functions/commonFunctions";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import CustomTableHeader from "../common/table/CustomTableHeader";
import CommonPaymentInformation from "../common/CommonPaymentInformation"
import { LOCALSTORAGE_PROFILE } from "../../constants/LocalStorage";
import { fixedFloatTwoDigit } from "../../functions/CustomFunctions";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles(theme => ({
    continuebtn: {
        float: "left",
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        display: 'block',
    },
    completeStep: {
        float: 'left',
        borderRadius: '50%',
        backgroundColor: 'green',
        color: '#fff',
        padding: '2px',
    },
    formHeader: {
        textAlign: 'center',
        marginBottom: '5px'
    },
    listStyle: {
        border: '1px solid #9c9898'
    },
    deletebtn: {
        marginLeft: '10px',
        marginBottom: isMobileScreen() ? '5px' : '0px',
        float: 'right',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        }
    }
}));

//Table Columns
const tableColumns = [
    { id: "OrganizationName", title: "Organization Name", sort: false },
    { id: "ProductName", title: "Product Name", sort: false },
    { id: "Price", title: "Price", sort: false },
    { id: "Action", title: "Action", sort: false, align: "center" },
];

const ChapterCertificationPayment = (props) => {

    const { errorMessages = [], stepData = [], orgDetails = {}, handleCallback } = props
    const classes = useStyles();
    const history = useHistory();
    const { addressInfo = {}, organizationName = '', regionName = '' } = orgDetails;
    const tableHeadProps = { tableColumns };
    const profile = localStorage.getItem(LOCALSTORAGE_PROFILE);
    const ProfileString = JSON.parse(profile);
    const { Profile = {} } = ProfileString || {};
    const { email } = Profile || ''
    // const { firstName = '', lastName = '', email = '', address = '', city = '', country = '', state = '', zip = '' } = Profile || {};
    const { address = '', city = '', country = '', state = '', zip = '' } = addressInfo || {};

    const [isShowModalFlag, setAsShowModalFlag] = useState(false);
    const [selectedRecordId, setSelectedRecordId] = useState({});
    const [paymentResponseData, setPaymentResponseData] = useState({});
    const [showPaymentMode, setShowPaymentMode] = useState(false);
    const [total, setTotal] = useState(0);

    let loggedUserInformations = {
        firstName: organizationName, lastName: regionName, email, address, city, country, state, zip
    }


    console.log(loggedUserInformations," TESTTTTTTTT ");
    // let chapterInformations = {
    //     firstName, lastName, email, address, city, country, state, zip
    // }

    const paymentCallBack = () => {
        handleCallback()
    }

    const DialogClose = () => {
        setAsShowModalFlag(false)
        setShowPaymentMode(false)
    }
    return (
        <Fragment>

            <Grid spacing={3} style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', }}>

                <Fragment>
                    <TableContainer component={Paper} className={classes.tablecontainer}>
                        <Table stickyHeader size='small' >
                            <CustomTableHeader {...tableHeadProps} />
                            <TableBody>
                                <Fragment>
                                    {
                                        stepData?.detail?.productsToPay?.map((row, key) => {
                                            let {
                                                name = "",
                                                price = "",
                                                organizationName = "",
                                                paymentStatus = 0,
                                            } = row;
                                            return (
                                                <TableRow
                                                    className={classes.tableheaderstyle}
                                                    hover
                                                    key={`data_${key}`}
                                                    style={{ height: 40 }}
                                                >
                                                    <TableCell align="left" style={{ height: 'auto !important' }}>
                                                        {organizationName}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ height: 'auto !important' }}>
                                                        {name}
                                                    </TableCell>


                                                    <TableCell align="left" style={{ height: 'auto !important' }}>
                                                        ${fixedFloatTwoDigit(price)}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ height: 'auto !important' }}>
                                                        {
                                                            paymentStatus == 1 ?
                                                                <>
                                                                    < Alert icon={<AccessTimeIcon style={{ color: 'green', }}></AccessTimeIcon>}
                                                                        severity="warning"
                                                                        style={{ fontSize: 16 }}
                                                                    >
                                                                        <AlertTitle>Pending</AlertTitle>

                                                                    </Alert >

                                                                </>
                                                                : paymentStatus == 2 ?
                                                                    <>
                                                                        < Alert icon={<CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon>}
                                                                            severity="info"
                                                                            style={{ display: 'flex', fontSize: 16, flexDirection: 'row', marginBottom: 10, justifyContent: isMobileScreen() ? 'left' : 'center', alignItems: 'center' }}
                                                                        >
                                                                            <AlertTitle>Successful</AlertTitle>

                                                                        </Alert >
                                                                    </> :
                                                                    <>
                                                                        <Button
                                                                            variant='contained'
                                                                            className={classes.continuebtn}
                                                                            onClick={() => {
                                                                                setTotal(price)
                                                                                setSelectedRecordId(row)
                                                                                // console.log(row, "ROWROW");
                                                                                setShowPaymentMode(true)
                                                                                setAsShowModalFlag(true)
                                                                            }}
                                                                        >
                                                                            Pay Now
                                                                        </Button>
                                                                    </>

                                                        }

                                                    </TableCell>

                                                </TableRow>
                                            )
                                        })
                                    }

                                    <CommonPaymentInformation
                                        // setPaymentDoneFlagFunc={setPaymentDoneFlag}
                                        // paymentResponseData={paymentResponseData}
                                        entityTypeId={1}
                                        sourceOwnerId={orgDetails?.organizationID}
                                        isShowModalFlag={isShowModalFlag}
                                        setAsShowModalFlag={setAsShowModalFlag}
                                        callBack={paymentCallBack}
                                        // AddMemberToRoster={AddMemberToRoster}
                                        // rosterId={selectedRecordId.rosterId && selectedRecordId.rosterId > 0 ? selectedRecordId.rosterId : 0}
                                        feeLineItems={[Object.assign({}, selectedRecordId, { quantity: 1, amount: selectedRecordId?.price, description: selectedRecordId?.name, lineTypeID: 2 })]} // Object.assign is used to add properties to the selectedRecordId
                                        lineItemsTotal={total}
                                        setPaymentResponse={setPaymentResponseData}
                                        ModalClose={DialogClose}
                                        isModalFlag={showPaymentMode}
                                        loggedUserInformations={
                                            loggedUserInformations
                                            // chapterInformations
                                        }

                                    />

                                </Fragment>
                            </TableBody>
                            {/* Table Body */}
                        </Table>
                    </TableContainer>
                </Fragment>

            </Grid>
        </Fragment>
    )
}

export default ChapterCertificationPayment