import { ticketpurchaseorderHistoryiitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetTicketPurchaseOrderHistoryReducer(state = ticketpurchaseorderHistoryiitialValues, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_TICKET_PURCHASE_ORDER_HISTORY_LOADING:
            return { error: false, loading: true, data: [] };
        case types.GET_TICKET_PURCHASE_ORDER_HISTORY_SUCCESS:
            return { ...state, data: payload, loading: false };
        case types.GET_TICKET_PURCHASE_ORDER_HISTORY_ERROR:
            return { error: true, loading: false, data: [] };
        default:
            return state;
    }
}


