import { Button, Table, Paper, Grid, makeStyles, Modal, Tooltip, IconButton } from "@material-ui/core";
import React, { useState } from 'react';
import { Fragment } from 'react';
import { useHistory } from "react-router";
import { isMobileScreen } from "../../functions/commonFunctions";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import CustomTableHeader from "../common/table/CustomTableHeader";
import CommonPaymentInformation from "../common/CommonPaymentInformation"
import { LOCALSTORAGE_PROFILE } from "../../constants/LocalStorage";
import { CustomPageLoading, fixedFloatTwoDigit } from "../../functions/CustomFunctions";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { GET_ENTITY_SELECTION_ENTRIES, PROGRESS_APPROVAL_STEP_SIGNQUEUE_URL, UPDATE_DOCUMENT_TRANSACTION_STATUS } from "../../constants/apiPaths";
import { authHeader } from "../../functions/CoreFunctions";
import { useSnackbar } from "notistack";
import API from "../../redux/actions/API";
import { CustomAlertMessage } from "../../functions/CustomFunctions";
import SignersListResponsive from "../officerReport/common/SignersListResponsive";
import Modaldialog from "../common/modaldialog";
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from "clsx";
import CommonPreviewDocumentDialog from "../common/CommonPreviewDocumentDialog";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import StarRateSharpIcon from '@material-ui/icons/StarRateSharp';

const useStyles = makeStyles(theme => ({
    warnColor: {
        cursor: 'pointer',
        backgroundColor: '#ffcc00',
        '&:hover': {
            '& $actionColumnStyle': {
                display: "inline-flex",
            },

        },
    },
    iconSize: {
        fontSize: 16
    },
    btnButtons: {
        margin: '4px',
        // backgroundColor: '#eee',
        padding: '5px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        // color: '#556cd6'
    },
    continuebtn: {
        float: "left",
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        display: 'block',
    },
    completeStep: {
        float: 'left',
        borderRadius: '50%',
        backgroundColor: 'green',
        color: '#fff',
        padding: '2px',
    },
    formHeader: {
        textAlign: 'center',
        marginBottom: '5px'
    },
    listStyle: {
        border: '1px solid #9c9898'
    },
    deletebtn: {
        marginLeft: '10px',
        marginBottom: isMobileScreen() ? '5px' : '0px',
        float: 'right',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        }
    },
    staricon: {
        color: theme.primaryColor,
        alignSelf: 'center',
    },
}));

const tableColumns = [
    { id: "DocumentName ", title: "Document Name", sort: false },
    { id: "Status", title: "Document Status", sort: false },
    { id: "Signatures", title: "Signatures", sort: false, align: 'left' },
    { id: "Action", title: "Action", sort: false, align: 'left' },
];


const ChapterCertificationSignDocuments = (props) => {
    const { stepData = [], handleCallback, setSteps, docTransactionDetail, organizationId, organizationName } = props
    console.log(stepData?.detail, "props");

    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const tableHeadProps = { tableColumns };
    const [isShowModalFlag, setAsShowModalFlag] = useState(false);
    const [selectedRecordId, setSelectedRecordId] = useState({});
    const [paymentResponseData, setPaymentResponseData] = useState({});
    const [pageLoading, setPageLoading] = useState(false);
    // const [showPaymentMode, setShowPaymentMode] = useState(false);
    const [total, setTotal] = useState(0);
    const [selectedDoc, setSelectedDoc] = useState({})
    const [previewDocumentUrl, setPreviewDocumentUrl] = useState('');
    const [viewDialogFlag, setViewDialogFlag] = useState(false);

    const dialogClose = () => {
        setAsShowModalFlag(false)
        // setShowPaymentMode(false)
    }
    const refreshSignQueue = async (documentTransactionID) => {
        try {
            setPageLoading(true);
            const options = {
                method: 'PUT',
                url: PROGRESS_APPROVAL_STEP_SIGNQUEUE_URL,
                headers: authHeader(),
                params: {
                    DocumentTransactionID: parseInt(documentTransactionID)
                }
            }
            const { data: apiResponse } = await API(options);
            const { status } = apiResponse;
            if (parseInt(status) === 1) {
                handleCallback()
                CustomAlertMessage("Refresh Successfully!", 'success', enqueueSnackbar);

            }
        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                CustomAlertMessage(data.message, 'error', enqueueSnackbar);
                setPageLoading(false);
            }
        }
        finally {
            setPageLoading(false)
        }
    }

    const UpdateDocumentTransactionStatus = async (notes) => {
        try {
            // setFormLoading(true);
            const options = {
                method: "POST",
                url: UPDATE_DOCUMENT_TRANSACTION_STATUS,
                headers: authHeader(),
                params: {
                    DocumentTransactionID: docTransactionDetail?.documentTransactionId,
                    StatusID: 3,
                    Note: "",
                },
            };
            const { data: apiResponse } = await API(options);
            const { status, response } = apiResponse;
            if (parseInt(status) === 1) {
                CustomAlertMessage("Updated Successfully", "success", enqueueSnackbar);
                dialogClose();

                // await GetEntitySelectionEntries();
            }
        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                CustomAlertMessage(error.response.statusText, "error", enqueueSnackbar);
            }
        } finally {
            // setFormLoading(false);
        }
    };


    const dialogContent = (
        <SignersListResponsive
            signQueueList={selectedDoc?.signQueues}
            pdfUrl={selectedDoc?.documentTransaction?.documentUrl}
            loading={false}
            dcoumentStatusId={0}
            callBack={handleCallback}
            organizationId={organizationId}
            documentTransactionID={selectedDoc?.documentTransaction?.documentTransactionId}
            UpdateDocumentTransactionStatus={
                UpdateDocumentTransactionStatus
            }
        ></SignersListResponsive>
    );

    const modalDialogProps = {
        diaOpen: isShowModalFlag,
        diaTitle: "",
        diaContent: dialogContent,
        diaClose: dialogClose,
        isFullWidth: true,
        organizationId: organizationId,
        organizationName: organizationName,
        documentTransactionId: parseInt(selectedDoc?.documentTransaction?.documentTransactionId),
        // handleSelectedMember: handleSelectedMember,
    };

    const showSignQueue = (row) => {
        setSelectedDoc(row)
        setAsShowModalFlag(true)
    }

    const handleOnClickView = (documentURL) => {
        setPreviewDocumentUrl(documentURL);
        setViewDialogFlag(true);
    }
    const handleViewDialogClose = () => {
        setViewDialogFlag(false);
    };
    const viewDialogProps = {
        dialogFlag: viewDialogFlag,
        handleClose: handleViewDialogClose,
        previewData: {
            url: previewDocumentUrl,
            extension: 'pdf'
        }
    };
    return (
        <Fragment>
            <CustomPageLoading flag={pageLoading} />
            <Grid spacing={3} style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', }}>
                <Fragment>
                    <TableContainer component={Paper} className={classes.tablecontainer}>
                        <Table stickyHeader size='small' >
                            <CustomTableHeader {...tableHeadProps} />
                            <TableBody>
                                <Fragment>
                                    {
                                        stepData?.detail?.map((row, key) => {
                                            let {
                                                documentTransaction = {},
                                                signQueues = []
                                            } = row;
                                            // let dynamicRowStyle = classes.tableRowstyle;

                                            // if (docTransactionDetail?.documentTransactionId == documentTransaction?.documentTransactionId) {
                                            //     dynamicRowStyle = classes.warnColor;
                                            // }
                                            return (
                                                <TableRow
                                                    // className={dynamicRowStyle}
                                                    className={classes.tableheaderstyle}
                                                    hover
                                                    key={`data_${key}`}
                                                    style={{ height: 40, }}
                                                    onClick={() => { showSignQueue(row) }}
                                                    onMouseOver={(e) => { e.target.style.cursor = 'pointer' }}

                                                >
                                                    <TableCell align="left" style={{ height: 'auto !important' }}>
                                                        {docTransactionDetail?.documentTransactionId == documentTransaction?.documentTransactionId && <StarRateSharpIcon className={classes.staricon} />}
                                                        {documentTransaction?.name}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ height: 'auto !important' }}>
                                                        {documentTransaction?.statusName}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ height: 'auto !important' }}>
                                                        {

                                                            signQueues?.map((details, key) => {
                                                                let {
                                                                    statusName = '',
                                                                    statusId,
                                                                } = details;
                                                                return (
                                                                    <>
                                                                        {
                                                                            statusId == 4 ?
                                                                                <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon>
                                                                                :
                                                                                <AccessTimeIcon style={{ color: 'red', }}></AccessTimeIcon>
                                                                        }
                                                                    </>
                                                                )
                                                            })

                                                        }
                                                    </TableCell>
                                                    <TableCell align="left" style={{ height: 'auto !important' }}>
                                                        {
                                                            documentTransaction?.statusName != "Approved" ?
                                                                <Tooltip placement="top" title={`${documentTransaction?.documentTransactionId}- Refresh Sign Queue`}>
                                                                    <IconButton edge="end"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            refreshSignQueue(documentTransaction?.documentTransactionId)
                                                                        }}
                                                                        className={clsx(classes.btnButtons)}>
                                                                        <RefreshIcon className={classes.iconSize} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                :
                                                                <Tooltip placement="top" title="Pdf">
                                                                    <IconButton edge="end" aria-label="preview"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            handleOnClickView(documentTransaction?.documentUrl)
                                                                        }}
                                                                        className={clsx(classes.btnButtons)}>
                                                                        <PictureAsPdfIcon style={{ fontSize: 15 }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </Fragment>
                            </TableBody>
                            {/* Table Body */}
                        </Table>
                    </TableContainer>
                    {isShowModalFlag && <Modaldialog {...modalDialogProps} />}
                    {viewDialogFlag && <CommonPreviewDocumentDialog {...viewDialogProps} />}
                </Fragment>

            </Grid >
        </Fragment >
    )
}

export default ChapterCertificationSignDocuments