import { Skeleton } from '@material-ui/lab';
import React, { Fragment } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { AddCircle, ArrowBackRounded } from '@material-ui/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Backdrop, CircularProgress, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { LOCALSTORAGE_APP_NAME, LOCALSTORAGE_SIGNUP_STEPS_FORM_DATA } from '../constants/LocalStorage';
import moment from "moment";
import logo_4 from '../assets/img/parent_organization_logo/4_logo.png';
import logo_1741 from '../assets/img/parent_organization_logo/1741_logo.jpg';
import logo_2019 from '../assets/img/parent_organization_logo/2019_logo.png';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EjectIcon from '@material-ui/icons/Eject';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
    color: '#fff',
  },
  closeIcon: {
    color: '#fff',
    float: 'right',
    margin: '-8px',
  },
}));

// #rbp Custom Alert Message
export const CustomAlertMessage = (message, type, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    variant: type,
    autoHideDuration: 5000
  });
};

//#rbp  Table Sorting
//Sorting Concept
const desc = (a, b, orderBy) => {
  //Date Sorting
  if (['startDate', 'paymentDate'].includes(orderBy)) {
    if (new Date(b[orderBy]) < new Date(a[orderBy])) {
      return -1;
    }
    if (new Date(b[orderBy]) > new Date(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  //string Sorting
  else if (typeof a[orderBy] == 'string' && a[orderBy] !== null && b[orderBy] !== null) {
    let dataA = a[orderBy].toLowerCase();
    let dataB = b[orderBy].toLowerCase();
    if (dataB < dataA) {
      return -1;
    }
    if (dataB > dataA) {
      return 1;
    }
    return 0;
  }
  else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
};

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getSorting = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
};

//#rbp Custom Delete Button
export const CustomDeleteButton = ({ removeCallBack, ...props }) => {
  return (
    <Tooltip title='Delete'>
      <IconButton
        aria-label='delete'
        onClick={removeCallBack}
        color='secondary'
        {...props}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

//#rbp Custom Edit Button
export const CustomEditButton = ({ editCallBack, ...props }) => {
  return (
    <Tooltip title='Edit'>
      <IconButton
        aria-label='edit'
        onClick={editCallBack}
        color='primary'
        {...props}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

//#rbp Custom View Button
export const CustomViewButton = ({ viewCallBack, ...props }) => {
  return (
    <Tooltip title='View'>
      <IconButton
        aria-label='edit'
        onClick={viewCallBack}
        color='primary'
        {...props}
      >
        <VisibilityIcon />
      </IconButton>
    </Tooltip>
  );
};

//#rbp Custom Ticket Button
export const CustomTicketButton = ({ ticketCallBack, ...props }) => {
  return (
    <Tooltip title='Ticket'>
      <IconButton
        aria-label='edit'
        onClick={ticketCallBack}
        color='primary'
        {...props}
      >
        <EjectIcon />
      </IconButton>
    </Tooltip>
  );
};

//#rbp Custom Add Button
export const CustomAddButton = ({ addCallBack, ...props }) => {
  return (
    <Tooltip title='Add'>
      <IconButton aria-label='add' onClick={addCallBack} {...props}>
        <AddCircle />
      </IconButton>
    </Tooltip>
  );
};

// #rbp Custom Back Button
export const CustomBackButton = ({ backCallBack, ...props }) => {
  return (
    <Tooltip title='Go Back'>
      <IconButton aria-label='back' onClick={backCallBack} {...props}>
        <ArrowBackRounded />
      </IconButton>
    </Tooltip>
  )
}

//#rbp Custom Filter Button
export const CustomFilterButton = ({ filterCallBack, ...props }) => {
  return (
    <Tooltip title='Filter list'>
      <IconButton aria-label='filter list' onClick={filterCallBack} {...props}>
        <FilterListIcon />
      </IconButton>
    </Tooltip>
  );
};

//#rbp Custom Page Loading
export const CustomPageLoading = ({ flag }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={flag}>

      <CircularProgress color='inherit' />
      <Typography style={{ textAlign: 'center', marginLeft: '10px' }}>Processing please wait...</Typography>
    </Backdrop>
  );
};

//Convert into Phone Number Format while  onChange Field
export const normalizePhoneNumberFormatOnChange = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
  return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
}

// Convert into Phone number format
export function converToHyphenedPhoneNumber(phoneNumber) {
  let trimmedString = phoneNumber.replace(/-|\s/g, "");
  return trimmedString.slice(0, 3) + "-" + trimmedString.slice(3, 6) + "-" + trimmedString.slice(6)
}

// Space Separated Phone Number Format
export function converToSpaceSeparatedPhoneNumber(phoneNumber) {
  let trimmedString = phoneNumber.replace(/-|\s/g, "");
  return trimmedString.slice(0, 3) + " " + trimmedString.slice(3, 6) + " " + trimmedString.slice(6)
}

//Get Signup Steps Form Data From Local Storage
export function getSignUpStepDataFromLocalStorage() {
  const local_storage_data = localStorage.getItem(LOCALSTORAGE_SIGNUP_STEPS_FORM_DATA);
  return JSON.parse(local_storage_data) || {};
}

//Parent Organization Logo
export function getParentOrganizationLogo() {
  const parentOrganizationId = process.env.REACT_APP_PARENTORGANIZATION_ID;
  //Return Parent Organization Logo based on the ENV id
  const GET_PARENT_ORGANIZATION_LOGO = {
    4: logo_4,
    1741: logo_1741,
    2019: logo_2019
  };
  return GET_PARENT_ORGANIZATION_LOGO[parentOrganizationId];
}

//Get Static Months Array
export function staticMonthArray() {
  const monthArray = [];
  for (var s_month = 1; s_month <= 12; s_month++) {
    monthArray.push(s_month);
  }
  return monthArray;
};

//Get Static Year Array
export function staticYearArray() {
  const yearArray = [];
  const currentYear = new Date().getFullYear();
  const upToYear = new Date().getFullYear() + 20;
  for (var s_year = currentYear; s_year <= upToYear; s_year++) {
    yearArray.push(s_year);
  }
  return yearArray;
};

//Detect Card Type
export const detectCardType = number => {
  let cardType = null;
  var re = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MAST: /^5[1-5][0-9]{14}$/,
    AMEX: /^3[47][0-9]{13}$/,
    AMER: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    DISC: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/
  };



  for (var key in re) {
    if (re[key].test(number)) {
      cardType = key;
    }
  }
  return { isCardValid: !!cardType, cardType };
};

//Fixed Float Two Digit
export function fixedFloatTwoDigit(value) {
  return parseFloat(value || 0).toFixed(2);
}
export function FormatToAmount(value) {
  return "$" + parseFloat(value || 0).toFixed(2);
}

//Create HTML data
export function createMarkup(data) {
  return { __html: data };
}

// #rbp Custom Grid Loading
export const CustomGridLoading = () => {
  return (
    <Fragment>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) =>
        <Skeleton variant="text" animation="wave" width="100%" height="40px" key={`skel_${index}`} />
      )}
    </Fragment>
  );
}

export function DateFormat(date,format){
  if (moment(date).isValid()) {
    return  moment.utc(date).format(format);
  } else {
    return '';
  }
}

//#rbp Date and Time format
export function DateTimeFormat(date, format) {
  if (moment(date).isValid()) {
    let DateStr = LocalDateTimeFormat(date)
    return moment(DateStr).format(format);
  } else {
    return '';
  }
}

//#rbp local Date and Time format
export function LocalDateTimeFormat(date) {
  if (moment(date).isValid()) {
    // let strDate = new Date(date).toString();
    // let utcTimeFormatDate = new Date(strDate + 'Z');
    // let localTimeFormatDate = utcTimeFormatDate.toLocaleString()
    let localTimeFormatDate = moment.utc(date).local().format()
    return localTimeFormatDate;
  } else {
    return '';
  }
}

export function IsPast(date) {
  if (moment(date).isValid()) {
    return moment(date).isBefore(moment());
  } else {
    return false;
  }
}

//Scroll To Page Top
export function CustomScrollToPageTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

//Get Allowed Min Date
export function GetAllowedMinDate() {
  //Min Date
  var minAllowedDate = new Date();
  return minAllowedDate.setFullYear(minAllowedDate.getFullYear() - 100);
}

//Get Allowed Max Date
export function GetAllowedMaxDate() {
  //Max Date
  var maxAllowedDate = new Date();
  return maxAllowedDate.setFullYear(maxAllowedDate.getFullYear() - 10);
}

//Custom Empty Row
export const CustomEmptyRow = ({ colSpanCount }) => {
  return (
    <TableRow>
      <TableCell align="center" colSpan={colSpanCount} style={{ padding: '50px' }}>
        No Record Found!
      </TableCell>
    </TableRow>
  );
}

//Get App Name
export function getAppName() {
  return localStorage.getItem(LOCALSTORAGE_APP_NAME);
}

export var blobToBase64 = function (blob, callback) {
  var reader = new FileReader();
  reader.onload = function () {
    var dataUrl = reader.result;
    var base64 = dataUrl.split(',')[1];
    callback(base64);
  };
  reader.readAsDataURL(blob);
};

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}