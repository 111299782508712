import {
  START_PAGELOADING,
  STOP_PAGELOADING,
  UPDATE_STATE_COUNTRY_TIMEZONELIST,
} from '../actions/ActionTypes';

const initialState = {
  pageLoading: false,
  states: [],
  countries: [],
  timeZones: [],
};

export default function MiscReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case START_PAGELOADING:
      return {
        ...state,
        pageLoading: true,
      };
    case STOP_PAGELOADING:
      return {
        ...state,
        pageLoading: false,
      };
    case UPDATE_STATE_COUNTRY_TIMEZONELIST:
      return {
        ...state,
        states: payload.states,
        countries: payload.countries,
        timeZones: payload.timeZones,
      };
    default:
      return state;
  }
}
