import React, { Fragment, memo } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';

function ModalDialog(props) {
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // fullScreen={fullScreen}
  const { diaOpen, diaTitle, diaContent, diaClose, isFullWidth = true, maxWidth = 'md', diaSubHeader = '', existingRosterId, commonApiCallback, organizationId, organizationName, handleSuccessCallBack, componentName, currentRosterReport, documentTransactionId, hasDuesProducts, callBackWithList, fromRegionalDues } = props;
  return (
    <Fragment>
      <Dialog
        disableBackdropClick disableEscapeKeyDown
        fullWidth={isFullWidth}
        maxWidth={maxWidth}
        open={diaOpen}
        onClose={diaClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          {diaTitle}{' '}
          <span style={{ float: 'right', cursor: 'pointer' }}>
            <CloseIcon onClick={diaClose} />
          </span>
          {diaSubHeader &&
            <span style={{ fontWeight: '400', fontSize: '12px' }}>
              <br />
              {diaSubHeader}
            </span>
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ overflow: 'hidden', height: '100%', width: '100%' }}>
            <div
              style={{
                paddingRight: 17,
                height: '100%',
                width: '100%',
                boxSizing: 'content-box',
              }}
            >
              <DialogContentText>{diaContent}</DialogContentText>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default memo(ModalDialog);
