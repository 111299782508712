import { combineReducers } from 'redux';
import AuthReducer from './authreducer';
import MiscReducer from './miscreducer';
import SignUpStepsReducer from './SignUpStepsReducer';
import GeographyDataReducer from './GeographyDataReducer';
import ForgotPasswordReducer from './ForgotPasswordReducer';
import LoggedUserDetailsReducer from './LoggedUserDetailsReducer';
import LoggedUserDetailsNavbarReducer from './LoggedUserDetailsNavbarReducer';
import MakePaymentReducer from './MakePaymentReducer';
import LoggedUserPaymentDetailsReducer from './LoggedUserPaymentDetailsReducer';
import MemberAccessLevelReducer from './MemberAccessLevelReducer';
import GetRecommendedChaptersReducer from './GetRecommendedChaptersReducer';
import MemberOrganizationProfileReducer from './MemberOrganizationProfileReducer';
import GetPaymentMethodsReducer from './GetPaymentMethodsReducer';
import TestReducer from './TestReducer';
import GetAccountSummaryTransactionsReducer from './GetAccountSummaryTransactionsReducer';
import GetPaymentPlanAdminListReducer from './GetPaymentPlanAdminListReducer';
import GetPaymentPlanDetailReducer from './GetPaymentPlanDetailReducer';
import GetAccountSummaryReducer from './GetAccountSummaryReducer.js';
import GetAdminDashboardReducer from './GetAdminDashboardReducer';
import GetProductsByOrganizationReducer from './GetProductsByOrganizationReducer';
import GetProductReducer from './GetProductReducer';
import GetPossibleProductGroupReducer from './GetPossibleProductGroupReducer';
import GetDocumentDetailReducer from './GetDocumentDetailReducer';
import GetDuesSummaryReducer from './GetDuesSummaryReducer';
import GetMembersListReducer from './GetMembersListReducer';
import GetCandidatesListReducer from './GetCandidatesListReducer';
import GetCandidateFilterOptionsReducer from './GetCandidateFilterOptionsReducer';
import GetChaptersListReducer from './GetChaptersListReducer';
import GetChapterFilterOptionsReducer from './GetChapterFilterOptionsReducer';
import GetLeadershipOrganizationListReducer from './GetLeadershipOrganizationListReducer';
import GetMemberFilterOptionsReducer from './GetMemberFilterOptionsReducer';
import GetChapterDetailsReducer from './GetChapterDetailsReducer';
import GetChapterDetailsPopupReducer from './GetChapterDetailsPopupReducer';
import GetMemberProfileDetailsReducer from './GetMemberProfileDetailsReducer';
import GetPurchaseOrderDetailsReducer from './GetPurchaseOrderDetailsReducer';
import GetRPTPaymentDetailsReducer from './GetRPTPaymentDetailsReducer';
import GetAutoDebitLogsReducer from './GetAutoDebitLogsReducer';
import GetUpcomingAutoDeitsReducer from './GetUpcomingAutoDeitsReducer';
import GetEntityExamTransactionsReducer from './GetEntityExamTransactionsReducer';
import GetCandidateProfileDetailsReducer from './GetCandidateProfileDetailsReducer';
import GetXrefTypesReducer from './GetXrefTypesReducer';
import GetCourseByCurrentSeasonReducer from './GetCourseByCurrentSeasonReducer';
import GetMemberCalenderEventsRdeucer from './GetMemberCalenderEventsRdeucer'
import GetOfficerReportStatusReducer from './GetOfficerReportStatusReducer';
import GetEventFilterOptionsReducer from './GetEventFilterOptionsReducer';
import GetOfficerReportFilterOptionsReducer from './GetOfficerReportFilterOptionsReducer';
import GetSchoolsListReducer from './GetSchoolsListReducer';
import GetSchoolProfileDetailsReducer from './GetSchoolProfileDetailsReducer';
import GetCollegeOfficialDetailsReducer from './GetCollegeOfficialDetailsReducer';
import GetCollegeOfficialListReducer from './GetCollegeOfficialListReducer'
import GetFinancialStatusReducer from './GetFinancialStatusReducer';
import GetSettingConfigReducer from './GetSettingConfigReducer';
import GetMemberTimeLInesReducer from './GetMemberTimeLInesReducer'
import GetPositionListReducer from './GetPositionListReducer';
import GetPositionDetailsReducer from './GetPositionDetailsReducer';
import GetPositionFilterReducer from './GetPositionFilterReducer';
import SchoolLoggedUserDetailsReducer from './SchoolLoggedUserDetailsReducer';
import GetEventListReducer from './GetEventListReducer';
import GetEventDetailReducer from './GetEventDetailReducer';
import GetTicketListReducer from './GetTicketListReducer';
import GetTicketDetailReducer from './GetTicketDetailReducer';
import GetTicketFilterReducer from './GetTicketFilterReducer';
import GetTicketFeeDetermineReducer from './GetTicketFeeDetermineReducer';
import GetCalenderEventDetailsReducer from './GetCalenderEventDetailsReducer';
import GetEventCheckoutDetailsReducer from './GetEventCheckoutDetailsReducer';
import GetDocumentsListReducer from './GetDocumentsListReducer';
import GetRequiredDocumentsListReducer from './GetRequiredDocumentsListReducer';
import GetDocumentsFilterReducer from './GetDocumentsFilterReducer'
import GetDocumentFormsReducer from './GetDocumentFormsReducer';
import GetApprovalStepsReducer from './GetApprovalStepsReducer';
import GetSignerPositionsReducer from './GetSignerPositionsReducer';
import GetApprovalStepByIDReducer from './GetApprovalStepByIDReducer';
import GetIntakeSeasonStepsReducer from './GetIntakeSeasonStepsReducer';
import GetDocumentFormByIDReducer from './GetDocumentFormByIDReudcer';
import GetDocumentFormTypesReducer from './GetDocumentFormTypesReducer';
import GetDivisionsReducer from './GetDivisionsReducer';
import GetApprovalStepStatusReducer from './GetApprovalStepStatusReducer';
import GetDocumentFormFilterReducer from './GetDocumentFormFilterReducer';
import GetSignerPositiontypesReducer from './GetSignerPositiontypesReducer';
import GetPositionTypeListReducer from './GetPositionTypeListReducer';
import GetSignQueueListReducer from './GetSignQueueListReducer';
import GetPlanningDocumentMeetingReducer from './GetPlanningDocumentMeetingReducer';
import GetPlanningDocumentDetailsReducer from './GetPlanningDocumentDetailsReducer';
import GetPlanningDocumetEventFilterReducer from './GetPlanningDocumetEventFilterReducer';
import GetSchoolsListByOrgidReducer from './GetSchoolsListByOrgidReducer';
import GetExamTransactionResultReducer from './GetExamTransactionResultReducer';
import GetExamSectionsReducer from './GetExamSectionsReducer';
import GetOrganizationDetailsForProspectsReducer from './GetOrganizationDetailsForProspectsReducer'
import GetPlanningDocumentEventCheckinReducer from './GetPlanningDocumentEventCheckinReducer';
import GetAllEntityTypesReducer from './GetAllEntityTypesReducer';
import GetProspectsApplicationStatusReducer from './GetProspectsApplicationStatusReducer';
import GetProspectsDetailsReducer from './GetProspectsDetailsReducer';
import GetDocumentPrequisitesReducer from './GetDocumentPrequisitesReducer';
import GetMembershipApplicationSettingReducer from './GetMembershipApplicationSettingReducer';
import GetQuestionsBySectionReducer from './GetQuestionsBySectionReducer';
import GetApplicationRequiredDocument from './GetApplicationRequiredDocumentReducer';
import GetReferenceLetterListReducer from './GetReferenceLetterListReducer';
import GetLatestApprovedOfficerReportReducer from './GetLatestApprovedOfficerReportReducer';
import GetPlanningDocumentEventsReducer from './GetPlanningDocumentEventsReducer';
import GetChapterPlanningDocumentInterviewsReducer from './GetChapterPlanningDocumentInterviewsReducer';
import GetRegionListReducer from './GetRegionListReducer';
import GetMasterIntakeDetailsReducer from './GetMasterIntakeDetailsReducer';
import GetOfficerReportDetailsReducer from './GetOfficerReportDetailsReducer';
import GetCollegeOfficialSchoolHistoryReducer from './GetCollegeOfficialSchoolHistoryReducer';
import GetRegionalRosterDetailsReducer from './GetRegionalRosterDetailsReducer';
import GetInvitationRequestReducer from './GetInvitationRequestReducer';
import GetPurchaseOrderListReducer from './GetPurchaseOrderListReducer';
import GetIntakeSeasonReducer from './GetIntakeSeasonReducer';
import GetExamByCourseReducer from './GetExamByCourseReducer';
import GetCourseReducer from './GetCourseReducer';
import GetOfficersReducer from './GetOfficersReducer';
import GetChapterRosterActivityReducer from './GetChapterRosterActivityReducer';
import GetHardListReducer from './GetHardListReducer';
import GetMemberManagedEntityReducer from './GetMemberManagedEntityReducer';
import GetEntityTypeByIDReducer from './GetEntityTypeByIDReducer';
import GetIntakePaymentSummaryReducer from './GetIntakePaymentSummaryReducer';
import GetChapterDuesSummaryReducer from './GetChapterDuesSummaryReducer';
import GetFianceProductTransportreportReducer from './GetFianceProductTransportreportReducer';
import GetFinanceReportingFilterReducer from './GetFinanceReportingFilterReducer';
import GetUpcomingFiscalYearDetailReducer from './GetUpcomingFiscalYearDetailReducer';
import GetInvitesReducer from './GetInvitesReducer';
import GetQuicklinksReducer from './GetQuicklinksReducer';
import GetDashboardTabsReducer from './GetDashboardTabsReducer';
import GetRosterReportStatusReducer from './GetRosterReportStatusReducer';
import GetDocumentFormByEntityReducer from './GetDocumentFormByEntityReducer';
import GetTicketPurchaseOrderHistoryReducer from './GetTicketPurchaseOrderHistoryReducer';
import MemberPendingRosterEntriesReducer from './MemberPendingRosterEntriesReducer';
import GetExportCandidatesListReducer from './GetExportCandidatesListReducer';
import GetExportMembersListReducer from './GetExportMembersListReducer';
import GetEntitySelectionListReducer from './GetEnitySelectionListReducer';
import signalrReducer from './SignalRConn';
import messagesReducer from './messagesReducer'
import ChatBoxReducer from './ChatBoxReducer';

const AppReducer = combineReducers({
  SignUpStepsReducer,
  auth: AuthReducer,
  misc: MiscReducer,
  GeographyDataReducer,
  ForgotPasswordReducer,
  LoggedUserDetailsReducer,
  LoggedUserDetailsNavbarReducer,
  MakePaymentReducer,
  LoggedUserPaymentDetailsReducer,
  MemberAccessLevelReducer,
  GetRecommendedChaptersReducer,
  MemberOrganizationProfileReducer,
  GetPaymentMethodsReducer,
  TestReducer,
  GetAccountSummaryReducer,
  GetAccountSummaryTransactionsReducer,
  GetPaymentPlanAdminListReducer,
  GetPaymentPlanDetailReducer,
  GetAdminDashboardReducer,
  GetProductsByOrganizationReducer,
  GetProductReducer,
  GetPossibleProductGroupReducer,
  GetDocumentDetailReducer,
  GetDuesSummaryReducer,
  GetMembersListReducer,
  GetCandidatesListReducer,
  GetCandidateFilterOptionsReducer,
  GetLeadershipOrganizationListReducer,
  GetMemberFilterOptionsReducer,
  GetMemberProfileDetailsReducer,
  GetCandidateProfileDetailsReducer,
  GetPurchaseOrderDetailsReducer,
  GetRPTPaymentDetailsReducer,
  GetAutoDebitLogsReducer,
  GetUpcomingAutoDeitsReducer,
  GetEntityExamTransactionsReducer,
  GetChaptersListReducer,
  GetChapterDetailsReducer,
  GetChapterDetailsPopupReducer,
  GetChapterFilterOptionsReducer,
  GetXrefTypesReducer,
  GetCourseByCurrentSeasonReducer,
  GetMemberCalenderEventsRdeucer,
  GetOfficerReportStatusReducer,
  GetEventFilterOptionsReducer,
  GetOfficerReportFilterOptionsReducer,
  GetSchoolsListReducer,
  GetSchoolProfileDetailsReducer,
  GetCollegeOfficialDetailsReducer,
  GetCollegeOfficialListReducer,
  GetFinancialStatusReducer,
  GetSettingConfigReducer,
  GetMemberTimeLInesReducer,
  GetPositionListReducer,
  GetPositionDetailsReducer,
  GetPositionFilterReducer,
  SchoolLoggedUserDetailsReducer,
  GetEventListReducer,
  GetEventDetailReducer,
  GetTicketListReducer,
  GetTicketDetailReducer,
  GetTicketFilterReducer,
  GetTicketFeeDetermineReducer,
  GetCalenderEventDetailsReducer,
  GetEventCheckoutDetailsReducer,
  GetDocumentsListReducer,
  GetRequiredDocumentsListReducer,
  GetDocumentsFilterReducer,
  GetDocumentFormsReducer,
  GetApprovalStepsReducer,
  GetSignerPositionsReducer,
  GetApprovalStepByIDReducer,
  GetIntakeSeasonStepsReducer,
  GetDocumentFormByIDReducer,
  GetDocumentFormTypesReducer,
  GetDivisionsReducer,
  GetApprovalStepStatusReducer,
  GetDocumentFormFilterReducer,
  GetSignerPositiontypesReducer,
  GetPositionTypeListReducer,
  GetSignQueueListReducer,
  GetPlanningDocumentMeetingReducer,
  GetPlanningDocumentDetailsReducer,
  GetPlanningDocumetEventFilterReducer,
  GetSchoolsListByOrgidReducer,
  GetExamTransactionResultReducer,
  GetExamSectionsReducer,
  GetOrganizationDetailsForProspectsReducer,
  GetPlanningDocumentEventCheckinReducer,
  GetAllEntityTypesReducer,
  GetProspectsApplicationStatusReducer,
  GetProspectsDetailsReducer,
  GetDocumentPrequisitesReducer,
  GetMembershipApplicationSettingReducer,
  GetQuestionsBySectionReducer,
  GetApplicationRequiredDocument,
  GetReferenceLetterListReducer,
  GetLatestApprovedOfficerReportReducer,
  GetPlanningDocumentEventsReducer,
  GetChapterPlanningDocumentInterviewsReducer,
  GetRegionListReducer,
  GetMasterIntakeDetailsReducer,
  GetOfficerReportDetailsReducer,
  GetCollegeOfficialSchoolHistoryReducer,
  GetRegionalRosterDetailsReducer,
  GetInvitationRequestReducer,
  GetPurchaseOrderListReducer,
  GetIntakeSeasonReducer,
  GetExamByCourseReducer,
  GetCourseReducer,
  GetOfficersReducer,
  GetChapterRosterActivityReducer,
  GetHardListReducer,
  GetMemberManagedEntityReducer,
  GetEntityTypeByIDReducer,
  GetIntakePaymentSummaryReducer,
  GetChapterDuesSummaryReducer,
  GetFianceProductTransportreportReducer,
  GetFinanceReportingFilterReducer,
  GetUpcomingFiscalYearDetailReducer,
  GetInvitesReducer,
  GetQuicklinksReducer,
  GetDashboardTabsReducer,
  GetRosterReportStatusReducer,
  GetDocumentFormByEntityReducer,
  GetTicketPurchaseOrderHistoryReducer,
  MemberPendingRosterEntriesReducer,
  GetExportCandidatesListReducer,
  GetExportMembersListReducer,
  GetEntitySelectionListReducer,
  signalrReducer,
  messagesReducer,
  ChatBoxReducer,
});

const RootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return AppReducer(undefined, action)
  }

  return AppReducer(state, action)
}

export default RootReducer;
