import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    }
}));

export default function CustomTableHeader({ tableColumns, orderBy, order, columnSortCallBack, checkallCallback, checkall, intermediate }) {
    const classes = useStyles();
    //Styled head
    const StyledTableCell = withStyles(theme => ({
        head: {
            color: theme.primaryColor,
            backgroundColor: theme.palette.common.white,
            padding: 10
        },
    }))(TableCell);
    return (
        <TableHead>
            <TableRow>
                {tableColumns?.map((column, key) => (
                    <Tooltip title={column.tooltipMessage || ''}>
                        <StyledTableCell
                            key={`column_${key}`}
                            align={column.align || "left"}
                            sortDirection={
                                orderBy === column.id ? order : false
                            }
                            width={column.width}
                            style={column.style}
                        >
                            {(column.html) ?
                                <Checkbox
                                    // indeterminate={intermediate}
                                    checked={checkall}
                                    onChange={checkallCallback}
                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                />
                                :
                                <TableSortLabel
                                    disabled={!column.sort}
                                    active={orderBy === column.id}
                                    direction={
                                        orderBy === column.id ? order : "asc"
                                    }
                                    data-column_id={column.id}
                                    onClick={columnSortCallBack}
                                >
                                    {column.title}
                                    {orderBy === column.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === "desc"
                                                ? "descending"
                                                : "ascending"}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            }
                        </StyledTableCell>
                    </Tooltip>
                ))}
            </TableRow>
        </TableHead>
    );
}