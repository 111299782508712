import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Card, CardContent, Checkbox, Chip, Container, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, TextField, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { authHeader, getParentOrganizationID } from '../../../functions/CoreFunctions'
import { UPSERT_PROSPECT_URL } from '../../../constants/apiPaths'
import API from '../../../redux/actions/API'
import { CustomAlertMessage, CustomPageLoading, FormatToAmount, GetAllowedMaxDate, GetAllowedMinDate, normalizePhoneNumberFormatOnChange, DateTimeFormat } from '../../../functions/CustomFunctions'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import Box from "@material-ui/core/Box";
import { getEventFilterOptions, GetOrganizationDetailsforprospect, GetPositionDetails, getPositionFilters, getPositionList } from '../../../redux/actions'
import { useHistory, useParams } from 'react-router-dom'

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { PROSPECT_LOGIN_PATH } from '../../../constants/RoutePaths'
import { isMobileScreen } from '../../../functions/commonFunctions'
import { Alert, AlertTitle } from '@material-ui/lab'
import { EMAIL_REGEX, MINIMUM_EIGHT_MIXED_PASSWORD, NAME_REGEX, US_PHONE_NUMBER_REGEX } from '../../../constants/ValidationRegex'
import { login } from '../../../redux/actions/authactions'
import { LOCALSTORAGE_INTAKELANDINGPAGEIMAGE, LOCALSTORAGE_PARENT_ORGANIZATION_NAME, LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID } from '../../../constants/LocalStorage'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
// Schema
const validationSchema = Yup.object({

  firstName: Yup.string().trim().required('FirstName is required').matches(NAME_REGEX, 'First letter must be a capital letter'),
  middleName: Yup.string().trim().matches(NAME_REGEX, 'First letter must be a capital letter'),
  lastName: Yup.string().trim().required('Last Name is required').matches(NAME_REGEX, 'First letter must be a capital letter'),
  // dateOfBirth:
  email: Yup.string().trim().required('Email is required').email('Give valid email').matches(EMAIL_REGEX, 'Give valid email'),
  password: Yup.string().required('Password is required').matches(MINIMUM_EIGHT_MIXED_PASSWORD, 'Use 8 or more characters with a mix of letters, numbers & symbols'),
  retypePassword: Yup.string().required('Retype password is required')
    .test('passwords-match', 'Password mismatch', function (value) {
      return this.parent.password === value;
    }),
  phone: Yup.string().trim().required('Phone number is required').matches(US_PHONE_NUMBER_REGEX, 'Give valid phone number. (ex : xxx-xxx-xxxx)'),
  // gpa: Yup.number().required('Gpa is required').positive().max(5, "Maximum is 5")
  // gpa: Yup.number().integer().min(1).required('Price is required'),
  // classification: Yup.string().trim().required('Classification is required'),
  // agree: Yup.boolean().required('Agree term and condition is required')

})

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  continuebtn: {
    color: '#fff',
    width: '50%',
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: '50px',
    margin: 'auto',
    display: 'block',
    padding: '5px 25px',
    marginRight: '5px'
  },
  formControl: {
    width: '100%'
  },
  formHeader: {
    fontSize: 'x-large',
    textAlign: 'center'
  },
  formSubHeader: {
    marginBottom: '1rem',
    textAlign: 'center'
  },
  formSubHeader2: {
    fontSize: '12px',
    textAlign: 'center'
  },
  input: {
    display: 'none',
  },
  imgCircle: {
    textAlign: 'center',
    border: '1px solid #eee',
    width: '113px',
    height: '113px',
    padding: '5px',
    backgroundColor: '#eee',
    borderRadius: '50%',
  },
  imgCircleDiv: {
    textAlign: 'center',
  },
  fileInput: {
    marginTop: '-30px',
    marginLeft: '-24px',
    backgroundColor: '#eee',
    padding: '4px',
    '&:hover': {
      backgroundColor: '#eee',
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  myFlex: {
    flex: '1',
    order: isMobileScreen() && '2',
  },
  loginBeg: {
    height: '100vh',
    display: 'flex',
    marginTop: '0px',
    alignItems: 'center',
    flexDirection: 'column',
    // backgroundImage: `url(${Background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

  },
}))

// ----------------------------------------------------------------------------
const CreateProfile = props => {
  // const { id: positionID } = useParams();

  const classes = useStyles();
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // const { MemberId } = props
  const { getData } = props

  const PID = getParentOrganizationID();
  const ParentOrganizationName = localStorage.getItem(LOCALSTORAGE_PARENT_ORGANIZATION_NAME)

  const { data = {} } = useSelector((state) => state.GetOrganizationDetailsForProspectsReducer);
  const { classifications = [], schools = [], name: chaptername = '', organizationTypeID = 0, organizationID = 0, candidatePortalPassword = '', planningDocument = null } = data


  // Form Initial Values
  const initialFormValues = {
    prospectId: 0,
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: null,
    gpa: '',
    classification: '',
    schoolId: '',
    email: '',
    phone: '',
    createDate: new Date(),
    studentNumber: '',
    password: "",
    retypePassword: '',
    leadershipGroupId: 0,
    organizationId: organizationID,
    clusterEventId: 0,
    resetPasswordRequest: '',
    agree: false,
    planningDocumentTransactionId: (planningDocument != null && planningDocument.documentTransactionId != undefined) ? planningDocument.documentTransactionId : 0

  }

  const [formData, setFormData] = useState(initialFormValues);
  const [formLoading, setFormLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const { loading, PositionDetailRec = {} } = useSelector(state => state.GetPositionDetailsReducer);
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);


  // const { PositionFilterRec = {} } = useSelector(state => state.GetPositionFilterReducer);
  // const { Divisions = [], PositionTypes = [] } = PositionFilterRec
  // console.log(PositionFilterRec, 'PositionFilterRec')
  // const { OrganizationType = [], Soschool = [] } = useSelector(state => state.GetChapterFilterOptionsReducer);
  // console.log(eventTypeSubs,'eventTypeSubs',organizations)

  //   const timeZonesList = timeZones.map(({ timeZoneID, tzLocation, gmt }, index) =>
  //     <MenuItem value={timeZoneID} key={`cty_code_${index}`}>
  //       {tzLocation}{' '}{gmt}
  //     </MenuItem>
  //   )
  //   let orgList = []
  //   if (organizations !== undefined) {
  //     if (organizations.organizationAccounts !== undefined) {
  //       orgList = organizations.organizationAccounts
  //     }
  //   }

  const classificationsList = classifications.map((classification) =>
    <MenuItem key={`classifications_list_${classification}`} value={classification}>
      {classification}
    </MenuItem>
  )
  var schoolsList = []
  if (organizationTypeID == 3 && schools != '') {
    schoolsList = schools.map(({ schoolId = 0, schoolName = '' }) =>
      <MenuItem key={`schools_list_${schoolId}`} value={schoolId}>
        {schoolName}
      </MenuItem>
    )
  }
  //   const eventTypeList = eventTypeSubs.map(({ ID, Name }) =>
  //     <MenuItem key={`eventType_list_${ID}`} value={ID}>
  //       {Name}
  //     </MenuItem>
  //   )

  // Save Form Data
  const saveFormValues = async values => {
    if (agree == false) {
      CustomAlertMessage("Please click the I Agree with the Terms and Conditions checkbox", 'error', enqueueSnackbar);

    } else {
      if (!values.dateOfBirth) {
        CustomAlertMessage('Please give date of birth', 'error', enqueueSnackbar);
      }
      else if (values.dateOfBirth >= maxAllowedDate) {
        CustomAlertMessage('Please give valid date of birth', 'error', enqueueSnackbar);
      }

      else if (organizationTypeID == 3 && (classification == '' || classification == null)) {
        CustomAlertMessage('Please Select clasification', 'error', enqueueSnackbar);

      }
      else if (organizationTypeID == 3 && (schoolId == '' || schoolId == null)) {
        CustomAlertMessage('Please Select School', 'error', enqueueSnackbar);

      }
      else if (organizationTypeID == 3 && (gpa == 0)) {
        CustomAlertMessage('Please Enter Gpa', 'error', enqueueSnackbar);

      }
      else if (organizationTypeID == 3 && (gpa <= 0 || gpa > 5)) {
        CustomAlertMessage('Please Enter a valid Gpa(Maximum is 5)', 'error', enqueueSnackbar);

      }
      // console.log(values.dateOfBirth <= maxAllowedDate, values.dateOfBirth >= maxAllowedDate, values.dateOfBirth, maxAllowedDate, 'maxAllowedDate')

      else {
        let dateOfBirthValue = DateTimeFormat(values.dateOfBirth, 'YYYY-MM-DD')

        let data = {
          prospectId: values.prospectId,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          dob: dateOfBirthValue,
          gpa: parseFloat(changeGpaField(values.gpa) || 0),
          classification: values.classification,
          // schoolId: values.schoolId,
          email: values.email,
          phone: values.phone,
          createDate: values.createDate,
          studentNumber: values.studentNumber,
          password: values.password,
          leadershipGroupId: values.leadershipGroupId,
          planningDocumentTransactionId: values.planningDocumentTransactionId,
          organizationId: values.organizationId,
          clusterEventId: values.clusterEventId,
          resetPasswordRequest: values.resetPasswordRequest
        }

        const submitValues = {
          "schoolId": schoolId ? schoolId : 0,
          "prospect": { ...data }
        }

        // console.log(data, 'values')
        try {
          setDisableBtn(true);
          setFormLoading(true)
          const options = {
            method: 'POST',
            url: UPSERT_PROSPECT_URL,
            data: submitValues,
            // headers: authHeader()
          }
          const { data: apiResponse } = await API(options);
          const { status, response = '', message = '' } = apiResponse;
          if (parseInt(status) === 1) {
            CustomAlertMessage('Saved successfully!', 'success', enqueueSnackbar)
            // const { isRegular } = values;
            let data = {
              email: values.email,
              password: values.password, isRegular: true, EntityTypeID: 11
            }
            dispatch(login(data, history, enqueueSnackbar, getData));
            setDisableBtn(false);

          }
          if (parseInt(status) === 2) {
            CustomAlertMessage(message, 'error', enqueueSnackbar);
            setDisableBtn(false);
          }
        } catch (error) {
          if (error.response) {
            let { data } = error.response;
            CustomAlertMessage(error.response.statusText, 'error', enqueueSnackbar);
            setDisableBtn(false);
          }
        } finally {
          setFormLoading(false)
        }
      }


    }
  }

  // Did Mount
  useEffect(() => {
    let orgid = localStorage.getItem(LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID)
    if (parseInt(orgid) > 0) {
      dispatch(GetOrganizationDetailsforprospect(parseInt(orgid)));
      // setFormData({ ...initialFormValues, organizationId: parseInt(orgid) })
    }
  }, [])

  // Did Mount
  useEffect(() => {
    if (planningDocument !== null && Object.keys(planningDocument).length !== 0 && planningDocument.constructor === Object) {
      //   let data = { ...PositionDetailRec }
      const { documentTransactionId = 0 } = planningDocument
      // let orgid = localStorage.getItem(LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID)
      setFormData({
        ...initialFormValues, organizationId: parseInt(organizationID),
        planningDocumentTransactionId: documentTransactionId
      });
      // console.log(formData, 'formdata',orgid)

    }
  }, [planningDocument]);
  // console.log(formData, 'formdata',organizationID)

  const changeGpaField = (value) => {
    // var value = e.target.value
    // console.log(value, 'gpa')
    // setFieldValue('gpa', parseFloat(e.target.value || 0).toFixed(1))
    // setFieldTouched('gpa', true)
    return parseFloat(value || 0).toFixed(1)
  }

  const changeEndDateField = (date) => {
    setFieldValue('endDate', new Date(date))
    setFieldTouched('endDate', true)
  }
  //   console.log(Divisions,'Divisions')
  // const divisionStatusList = Divisions.map(({ divisionId, divisionName }) =>
  //   <MenuItem key={`Divisions_list_${divisionId}`} value={divisionId}>
  //     {divisionName}
  //   </MenuItem>
  // )
  // const PositionTypesList = PositionTypes.map(({ positionTypeId, positionTypeName }) =>
  //   <MenuItem key={`PositionTypes_list_${positionTypeId}`} value={positionTypeId}>
  //     {positionTypeName}
  //   </MenuItem>
  // )

  // Formik
  const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, setFieldValue } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => saveFormValues(values)
  })

  const backtolist = () => {
    // history.push({
    //     pathname: SETTINGS_PATH,
    //     state: { currentComponent:'setting_positions' }
    //   })
  }

  //Change Date Field
  const changeDateField = (date) => {
    setFieldValue('dateOfBirth', new Date(date));
    setFieldTouched('dateOfBirth', true);
  }

  let {

    prospectId = 0,
    firstName = '',
    middleName = '',
    lastName = '',
    dateOfBirth = '',
    gpa = '',
    classification = '',
    schoolId = '',
    email = '',
    phone = '',
    createDate = new Date(),
    studentNumber = '',
    password = "",
    retypePassword = '',
    leadershipGroupId = 0,
    organizationId = organizationID,
    clusterEventId = 0,
    resetPasswordRequest = '',
    agree = false,
    planningDocumentTransactionId = (planningDocument != null && planningDocument.documentTransactionId != undefined) ? planningDocument.documentTransactionId : 0


  } = values
  // const [shrink1, setShrink1] = useState(false)

  //Min Date
  const minAllowedDate = new Date(GetAllowedMinDate());
  const maxAllowedDate = GetAllowedMaxDate();

  let dateOfBirthValue = null;
  if (dateOfBirth) {
    const resDate = new Date(dateOfBirth);
    if (resDate.getFullYear() >= minAllowedDate.getFullYear()) {
      dateOfBirthValue = dateOfBirth;
    }
  }

  const parentOrganizationName = localStorage.getItem(LOCALSTORAGE_PARENT_ORGANIZATION_NAME) || '';
  const IntakeLandingPageImage = localStorage.getItem(LOCALSTORAGE_INTAKELANDINGPAGEIMAGE) || '';

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowRetypePassword = () => {
    setShowRetypePassword(!showRetypePassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Fragment>

      <div className={classes.loginBeg} style={{ backgroundImage: 'white', overflow: 'auto', }}>
        <Container style={{ marginTop: '2rem', marginBottom: '2rem' }} maxWidth='md'>

          <Grid
            container
            spacing={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid className={classes.myFlex} item xs={12} sm={5} md={10}>
              <Typography style={{ fontWeight: 700, fontSize: 24 }}>

                {parentOrganizationName}

              </Typography>
              <Typography style={{ fontWeight: 700, fontSize: 24 }}>
                Membership Application
              </Typography>
              <Typography>
                {chaptername}
              </Typography>
              {schools.length > 0 ? schools.map(({ schoolName }) => {
                return (
                  <Chip size="small" color="secondary" label={schoolName} style={{ marginRight: '5px' }} />

                )
              }) : null}
            </Grid>
            <Grid item xs={12} sm={5} md={2} style={{ textAlignLast: 'center' }} >
              {/* //if parentorganizationid=4 */}

              <img src={IntakeLandingPageImage} style={{ float: isMobileScreen() ? 'none' : 'right' }} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12}>
              <form noValidate autoComplete='off' onSubmit={handleSubmit} style={{ margin: isMobileScreen() ? '10px' : '0px' }}>
                {formLoading && <CustomPageLoading flag={formLoading} />}
                <Grid container spacing={3} >
                  <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                  <Grid item xs={12} sm={10} md={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" className={classes.formHeader}>
                          Create Profile
                        </Typography>
                        {/* currentComponent:setting_positions 
               
                */}
                        {/* <Tooltip title="Go Back" style={{ position: 'absolute', right: isMobileScreen() ? 20 : 70, top: 100 }}>
                  <Button variant="outlined" color="secondary" onClick={backtolist}>
                    Back
                  </Button>
                </Tooltip> */}
                      </Grid>

                      <Fragment>
                        <Grid item xs={12}>
                          <TextField
                            error={errors.firstName && touched.firstName}
                            id="firstName-helper-text"
                            label="First Name"
                            value={firstName}
                            fullWidth
                            name='firstName'
                            helperText={(errors.firstName && touched.firstName) ? errors.firstName : ''}
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={errors.middleName && touched.middleName}
                            id="middleName-helper-text"
                            label="Middle Name"
                            value={middleName}
                            fullWidth
                            name='middleName'
                            helperText={(errors.middleName && touched.middleName) ? errors.middleName : ''}
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={errors.lastName && touched.lastName}
                            id="lastName-helper-text"
                            label="Last Name"
                            value={lastName}
                            fullWidth
                            name='lastName'
                            helperText={(errors.lastName && touched.lastName) ? errors.lastName : ''}
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              minDate={minAllowedDate}
                              maxDate={maxAllowedDate}
                              label="Date Of Birth"
                              style={{ width: '100%' }}
                              value={dateOfBirthValue}
                              format='MM/DD/YYYY'
                              name='dob'
                              openTo="year"
                              views={["year", "month", "date"]}
                              onChange={changeDateField}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="email-helper-text"
                            label="Email"
                            value={email}
                            onChange={handleChange}
                            fullWidth
                            name='email'
                            variant="outlined"
                            inputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            error={errors.email && touched.email}
                            helperText={(errors.email && touched.email) ? errors.email : ''}
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <TextField
                            error={errors.phone && touched.phone}
                            id="phone-helper-text"
                            label="Phone Number"
                            value={phone}
                            fullWidth
                            name='phone'
                            helperText={(errors.phone && touched.phone) ? errors.phone : ''}
                            variant="outlined"
                            onChange={({ target: { value } }) => {
                              setFieldTouched('phone', true);
                              setFieldValue('phone', normalizePhoneNumberFormatOnChange(value));
                            }}
                          />

                        </Grid>
                        {organizationTypeID == 3 ?
                          <Fragment>
                            <Grid item xs={12} >
                              <TextField
                                id="gpa-helper-text"
                                label="GPA"
                                type={'number'}
                                value={gpa}
                                onChange={({ target: { value } }) => {
                                 
                                    if (value > 0) {
                                        setFieldTouched('gpa', true);
                                        setFieldValue('gpa', value);
                                    }
                                    else {
                                      setFieldTouched('gpa', false);
                                      setFieldValue('gpa', '');
                                    }
                              
                                }}
                                placeholder='Ex. "2.3"'
                                fullWidth
                                name='gpa'
                                variant="outlined"
                                error={errors.gpa && touched.gpa}
                                helperText={(errors.gpa && touched.gpa) ? errors.gpa : ''}
                              />

                            </Grid>
                            <Grid item xs={12}>
                              <FormControl variant="outlined" className={classes.formControl} error={errors.classification && touched.classification}>
                                <InputLabel id="classification-label">Classification</InputLabel>
                                <Select
                                  labelId="classification-label"
                                  id="classification"
                                  name='classification'
                                  value={classification}
                                  onChange={handleChange}
                                  fullWidth
                                  label="Classification"
                                >
                                  <MenuItem value="">Select Classification Type</MenuItem>
                                  {classificationsList}
                                </Select>
                                <FormHelperText>{(errors.classification && touched.classification) ? errors.classification : ''}</FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl variant="outlined" className={classes.formControl} error={errors.schoolId && touched.schoolId}>
                                <InputLabel id="schoolId-label">School</InputLabel>
                                <Select
                                  labelId="schoolId-label"
                                  id="schoolId"
                                  name='schoolId'
                                  value={schoolId}
                                  onChange={handleChange}
                                  fullWidth
                                  label="School"
                                >
                                  <MenuItem value="">Select School </MenuItem>
                                  {schoolsList}
                                </Select>
                                <FormHelperText>{(errors.schoolId && touched.schoolId) ? errors.schoolId : ''}</FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>

                              <TextField
                                error={errors.studentNumber && touched.studentNumber}
                                id="studentNumber-helper-text"
                                label="Student Number"
                                value={studentNumber}
                                fullWidth
                                name='studentNumber'
                                helperText={(errors.studentNumber && touched.studentNumber) ? errors.studentNumber : ''}
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Grid>

                          </Fragment>
                          : null}
                        <Grid item xs={12}>
                          <FormControl className={classes.formControl} variant="outlined" error={errors.password && touched.password}>
                            <InputLabel htmlFor="password-password">Password</InputLabel>
                            <OutlinedInput
                              id="password-password"
                              type={showPassword ? 'text' : 'password'}
                              value={password}
                              name="password"
                              fullWidth
                              label="Password"
                              onChange={handleChange}
                              inputProps={{
                                form: {
                                  autocomplete: 'off',
                                },
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="password"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <FormHelperText>{(errors.password && touched.password) ? errors.password : ''}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className={classes.formControl} variant="outlined" error={errors.retypePassword && touched.retypePassword}>
                            <InputLabel htmlFor="retypePassword-password">Retype Password</InputLabel>
                            <OutlinedInput
                              id="retypePassword-password"
                              type={showRetypePassword ? 'text' : 'password'}
                              value={retypePassword}
                              name="retypePassword"
                              fullWidth
                              label="Retype Password"
                              onChange={handleChange}
                              inputProps={{
                                form: {
                                  autocomplete: 'off',
                                },
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Retype Password"
                                    onClick={handleClickShowRetypePassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showRetypePassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <FormHelperText>{(errors.retypePassword && touched.retypePassword) ? errors.retypePassword : ''}</FormHelperText>
                          </FormControl>
                        </Grid>




                        <Grid item xs={12}>
                          <Typography>Terms and Conditions</Typography>
                          <Card style={{ backgroundColor: '#b6b6b6' }}>
                            <CardContent>
                              <Typography>
                                All data captured by the Services, including personal data remains the sole property of the Subscriber. Subscriber, by using the Services, gives Global Gains, Inc. an irrevocable, full-paid license to use the data during the Subscription period, which is defined as that period from the creation of the account through the time that the Subscriber terminates their account and no longer makes active use of the Services, and expressly allows Global Gains, Inc. to share and distribute any and all such data with the Client ({ParentOrganizationName}). Client is solely responsible for any use of the data received from Global Gains, Inc. by Client.

                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12}>

                          <FormControl className={classes.formControl} variant="outlined" error={errors.agree && touched.agree}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={agree}
                                  name='agree'
                                  value={agree}
                                  onChange={handleChange}
                                />
                              }
                              label=' I Agree with the Terms and Conditions'
                            />
                            <FormHelperText>{(errors.agree && touched.agree) ? errors.agree : false}</FormHelperText>
                          </FormControl>
                        </Grid>




                      </Fragment>

                      <Grid item xs={12} style={{ display: "flex" }}>
                        <Button
                          type='submit'
                          variant='contained'
                          className={classes.continuebtn}
                          disabled={disableBtn}
                        >
                          Create Profile
                        </Button>
                        <Button
                          variant='contained'
                          className={classes.continuebtn}
                          onClick={() => history.push(`/intake/login/${organizationID}`)}
                        >

                          cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment >
  )
}

export default CreateProfile

