 
import { getTicketFeeInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetTicketFeeDetermineReducer(state = getTicketFeeInitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_FEE_DETERMINE_LOADING:
            return {payload,loading:true};
        case types.GET_FEE_DETERMINE_SUCCESS:
            return {...state,...payload,loading:false};
        case types.GET_FEE_DETERMINE_ERROR:
            return {payload,loading:false};
        case types.GET_FEE_DETERMINE_RESET:
            return {payload: {},loading:false};
        default:
            return state;
    }
}

