import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";

export default function DefaultResponsiveContainer(props){
    return(
        <Fragment>
            <Grid item xs sm={2} md={3} component={Box} display={{ xs: "none", sm: "block" }}/>
            <Grid item xs={12} sm={8} md={6}>
               {props.children}
            </Grid>
            <Grid item xs sm={2} md={3} component={Box} display={{ xs: "none", sm: "block" }}/>
        </Fragment>
    );
}