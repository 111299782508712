


import { commonListingObjectInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
import update from 'react-addons-update';

export default function GetRosterReportStatusReducer(state = commonListingObjectInitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_ROSTER_REPORT_STATUS_LOADING:
            return {error:false,loading:true,data:{}};
        case types.GET_ROSTER_REPORT_STATUS_SUCCESS:
            return {...state,data:payload,loading:false};
        case types.GET_ROSTER_REPORT_STATUS_ERROR:
            return {error:true,loading:false,data:payload};
        default:
            return state;
    }
}