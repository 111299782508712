// GetLatestApprovedOfficerReportReducer
import { LatestApproveOfficerReportInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
import update from 'react-addons-update';

export default function GetLatestApprovedOfficerReportReducer(state = LatestApproveOfficerReportInitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_LATEST_APPROVED_OFFICER_REPORT_LOADING:
            return {error:false,loading:true,data:{}};
        case types.GET_LATEST_APPROVED_OFFICER_REPORT_SUCCESS:
            return {...state,data:payload,loading:false};
        case types.GET_LATEST_APPROVED_OFFICER_REPORT_ERROR:
            return {error:true,loading:false,data:{}};
        
        default:
            return state;
    }
}


