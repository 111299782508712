import { objectInitial } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
import { getSignUpStepDataFromLocalStorage } from "../../functions/CustomFunctions";
import { LOCALSTORAGE_SIGNUP_STEPS_FORM_DATA } from "../../constants/LocalStorage";
export default function SignUpStepsReducer(state = objectInitial,action){
    const {type , payload} = action;
    switch (type) {
        case types.SIGNUP_GOTO_STEP:
            return {...state,activeStep:payload};    
        case types.SET_FILTER_TEXT:
            const localStorageFormData = getSignUpStepDataFromLocalStorage();
            const {SELECT_CHAPTER = {}} =  localStorageFormData;
            const valuesToLocalStorage = {
                ...localStorageFormData,
                SELECT_CHAPTER:{
                    ...SELECT_CHAPTER,
                    searchText : payload
                }
            };
            localStorage.setItem(LOCALSTORAGE_SIGNUP_STEPS_FORM_DATA, JSON.stringify(valuesToLocalStorage));
            return state;
        default:
            return state
    }
}