import { objectInitial } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetPaymentPlanDetailReducer(state = objectInitial, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_PAYMENT_PLAN_DETAIL_LOADING:
            return {payload,loading:true};
        case types.GET_PAYMENT_PLAN_DETAIL_SUCCESS:
            return {payload,loading:false};
        case types.GET_PAYMENT_PLAN_DETAIL_ERROR:
            return {payload,loading:false};
        default:
            return state;
    }
}