import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { DateTimeFormat } from '../../../functions/CustomFunctions'
import { IconButton, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx'
import { getLoggedUserMemberId } from '../../../functions/CoreFunctions'
import BorderColorIcon from '@material-ui/icons/BorderColor';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
    inline: {
        display: 'inline',
    },
    checkicon: {
        float: 'left',
        borderRadius: '50%',
        backgroundColor: 'green',
        color: '#fff',
        padding: '2px',
        marginRight: '7px'
    },
    circleText: {
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
    },
    rightAlign: {
        float: 'right',
        marginBottom: '10px',
        textAlign: 'right',
        fontSize: '16px',
        fontWeight: '670'
    },
    redColor: {
        color: 'red'
    },
    greenColor: {
        color: 'green'
    },
    listStyle: {
        paddingLeft: 0,
        paddingRight: 0
    },
    balanceBlock: {
        marginTop: '-21px'
    },
    subTextBlock: {
        fontSize: '13px'
    },
    paraStyle: {
        marginTop: '5px'
    },
    paraStyle2: {
        marginTop: '-7px'
    },
    deleteBtn: {
        padding: '6px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        fontSize: 5
    },
    vieweBtn: {
        margin: '6px',
        padding: '6px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        fontSize: 5
    },
    iconpadding: {
        padding: '6px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        // fontSize: 5,
        // marginRight: '5px'
    },
    iconSize: {
        fontSize: 16
    }
}));




const SignersMobileAndTabView = ({ data, handleOnClickSign, SendEmailReminder, emailSendArray }) => {
    const classes = useStyles();
    const loggedUserId = getLoggedUserMemberId();

    const { createDate, positionName = '', statusId, statusName, entityEmail, entityName, sourceOwnerId, membershipNumber, dtsid } = data;
    let dynamicRowStyle = '';
    if (parseInt(statusId) === 4) {
        dynamicRowStyle = classes.dangerColor;
    }

    return (
        <Fragment>
            <ListItem alignItems='flex-start' className={clsx(classes.listStyle, dynamicRowStyle)}
                role="checkbox"
                tabIndex={-1}
            >
                <ListItemText
                    primary={<>{parseInt(statusId) === 4 &&
                        <CheckIcon className={classes.checkicon} />
                    }
                        <b>{entityName}</b>

                        {positionName !== null ?
                            <>
                                <br />
                                <span>{positionName}</span>
                            </> : ''}
                    </>}
                    secondary={
                        <Fragment>

                            <Typography
                                variant='body1'
                                color='textPrimary'
                            >

                                <p className={classes.paraStyle}>{entityEmail}</p>
                            </Typography>
                            <Typography
                                variant='body1'
                                color='textPrimary'
                            >
                                <p className={classes.paraStyle}>Date: {DateTimeFormat(createDate, 'MM/DD/YYYY')}</p>
                            </Typography>



                            <div >
                                {(statusId == 1 && parseInt(sourceOwnerId) === parseInt(loggedUserId)) ?
                                    <Tooltip title='Sign'>
                                        <IconButton className={classes.iconpadding} aria-label='signpad' onClick={(event) => {
                                            event.stopPropagation()
                                            handleOnClickSign(data)
                                        }}>
                                            <BorderColorIcon className={classes.iconSize} />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    `${statusName}`
                                }
                                {(parseInt(statusId) === 1) &&
                                    <Tooltip title='Send Email Reminder' style={{ float: 'right' }}>
                                        <IconButton disabled={(emailSendArray.indexOf(dtsid) > -1)} className={classes.iconpadding} aria-label='signpad' onClick={(e) => SendEmailReminder(dtsid)}>
                                            <SendIcon className={classes.iconSize} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>


                        </Fragment>
                    }
                />
            </ListItem >
        </Fragment >
    );
}

export default SignersMobileAndTabView
