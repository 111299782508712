// GetChapterDetailsPopupReducer
import { getChapterDetailsPopupInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

const GetChapterDetailsPopupReducer = (state = getChapterDetailsPopupInitialValues, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_ORGANIZATION_DETAILS_POPUP_LOADING:
            return { payload, loading: true, error: false }
        
        case types.GET_ORGANIZATION_DETAILS_POPUP_SUCCESS:
            return { chapterdetailspopup: { ...payload }, loading: false, error: false }

        case types.GET_ORGANIZATION_DETAILS_POPUP_ERROR:
            return { payload, loading: false, error: true }
        default:
            return state
    }
}



export default GetChapterDetailsPopupReducer
