import { Divider, Grid, LinearProgress, Radio, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { SELECTED_PAYMENT_METHOD } from '../../constants/LocalStorage';
import CommonPaymentMethodsList from './CommonPaymentMethodsList';
import PaymentMethodCreateForm from './PaymentMethodCreateForm';
import Box from "@material-ui/core/Box";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { CustomAlertMessage, CustomPageLoading } from '../../functions/CustomFunctions';
import { DELETE_PAYMENT_METHOD } from '../../constants/apiPaths';
import API from '../../redux/actions/API';
import { getPaymentMethods } from '../../redux/actions';
import { useSnackbar } from 'notistack';
import { authHeader } from '../../functions/CoreFunctions';
import { Button } from '@material-ui/core';
import { CREDIT_CARD_TYPE, BANK_ACCOUNT_TYPE } from '../../constants/HelperConstants';

const useStyles = makeStyles((theme) => ({
    leftAlign: {
        float: 'left'
    },
    terms_and_cond: {
        backgroundColor: 'yellow',
        fontWeight: 'bold',
        fontSize: 'medium',
        width: '325px',
        padding: '8px 0px 0px 6px',
    },
    continuebtn: {
        color: '#fff',
        width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px',
        opacity: 1,
        '&:hover': {
            opacity: 0.7,
        }
    },
    tabSection: {
        border: `2px solid ${theme.primaryColor}`,
        padding: '1px 0px 1.5px 0px',
        borderRadius: '25px',
        minHeight: '43px'
    },
    formHeader: {
        fontSize: 'x-large',
        marginBottom: '1rem',
        textAlign: 'center'
    },
    table: {
        maxWidth: 400,
    },
    borderlessStyle: {
        borderBottom: 'none',
        fontWeight: 600
    },
    activeTabStyle: {
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '25px',
        color: '#fff !important',
        minHeight: '33px',
        minWidth: '186px',
    },
    inactiveTabStyle: {
        color: theme.primaryColor,
        minHeight: '33px',
        minWidth: '152px',
    },
    formControl: {
        width: "100%"
    },
    backArrow: {
        float: 'right',
        marginTop: '1.2rem',
    },
    skipButton: {
        marginBottom: '1rem',
        color: theme.primaryColor,
        display: 'block',
        padding: '5px 21px',
        borderRadius: '50px',
        width: '100%'
    },
    totalAmountStyle: {
        borderBottom: 'none',
        fontWeight: 1000,
        color: 'red',
        fontSize: 'medium'
    },
    ellipsesContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkitLineClamp': '3',
        '-webkitBoxOrient': 'vertical',
        cursor: 'pointer',
        paddingLeft: '15px'
    },
    activeLineItem: {
        backgroundColor: 'rgb(232, 244, 253)',
        border: '1px solid #9c9898'
    },
    listStyle: {
        border: '1px solid #9c9898'
    },
    rightAlignText: {
        textAlign: 'right'
    },
    subtextStyle: {
        fontSize: '10px',
        marginTop: '-5px'
    },
    subDescStyle: {
        fontWeight: 'bold',
        color: 'red'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

// Custom Checkbox
const CustomRadio = withStyles(theme => ({
    root: {
        color: theme.primaryColor,
        '&$checked': {
            color: theme.primaryColor,
        },
    },
    checked: {},
}))((props) => <Radio color="default" {...props} />);

export default function CommonPaymentMethodCRUD(props) {
    const { loading, payload: paymentMethods = {} } = useSelector((state) => state.GetPaymentMethodsReducer);
    const { achPays: achPaysList = [], ccPas: ccPasList = [] } = paymentMethods;
    const {
        selectedPaymentMethod, currentInformationSource, resetSelectedPaymentMethodCallBack,
        handleSelectedPaymentMethodCallBack, selectedFromListCallBack, chooseFromListLabel,
        showPaymentMethodCreateTypes = [], entityTypeId, sourceOwnerId, setShowNeedTokenFlag, handleApiCallback, getTransactionFee
    } = props;

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(2);
    const [pageLoading, setPageLoading] = useState(false);
    const [achPays, setAchPays] = useState(achPaysList);
    const [ccPas, setCcPas] = useState(ccPasList);

    // Reset Selected payment method and payment type
    const resetSelectedPaymentMethod = () => {
        localStorage.setItem(SELECTED_PAYMENT_METHOD, JSON.stringify({}));
        resetSelectedPaymentMethodCallBack();
    }



    const handleInitiallizeValues = () => {
        dispatch(getPaymentMethods({
            entityTypeId,
            sourceOwnerId
        },setActiveTab));
        setShowNeedTokenFlag(false);

    }
    //Delete Payment Method
    const deletePaymentMethod = async (event) => {
        const { pay_rec_id, payment_type } = event.currentTarget.dataset;
        //Reset selected payment method if we have only one existing payment method
        if ((achPays.length + ccPas.length) === 1) {
            resetSelectedPaymentMethod();
        }
        try {
            setPageLoading(true);
            const options = {
                url: DELETE_PAYMENT_METHOD,
                method: 'DELETE',
                headers: authHeader(),
                params: {
                    payRecId: parseInt(pay_rec_id),
                    PaymentType: parseInt(payment_type)
                }
            };
            const { data: apiResponse } = await API(options);
            const { status, message } = apiResponse;
            if (parseInt(status) === 1) {
                CustomAlertMessage(message, 'success', enqueueSnackbar);
                dispatch(getPaymentMethods({
                    entityTypeId,
                    sourceOwnerId
                }));
                handleSelectPaymentMethod(null, null);
                if (handleApiCallback)
                    handleApiCallback();
            }
        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                CustomAlertMessage(data.message, 'error', enqueueSnackbar);
            }
        } finally {
            setPageLoading(false);
        }
    }

    //Payment Method Selected Callback
    //Select Patment Method
    const handleSelectPaymentMethod = (paymentMethodId, paymentType) => {
        let currentRecord = {};
        if (parseInt(paymentType) === 1) {
            currentRecord = achPays.find(record => parseInt(record.achpayId) === parseInt(paymentMethodId));
        } else {
            currentRecord = ccPas.find(record => parseInt(record.ccpayId) === parseInt(paymentMethodId));
        }

        currentRecord = {
            ...currentRecord,
            paymentType
        };
        localStorage.setItem(SELECTED_PAYMENT_METHOD, JSON.stringify(currentRecord));
        handleSelectedPaymentMethodCallBack(currentRecord, paymentMethodId, paymentType);
    }
    //Did Mount
    useMemo(() => {
        if (achPaysList.length) {
            let dataList = achPaysList.filter((rec) => rec.fortePaymentMethodToken !== null && rec.fortePaymentMethodToken !== '')
            setAchPays(dataList)
        }
        if (ccPasList.length) {

            let dataList = ccPasList.filter((rec) => rec.fortePaymentMethodToken !== null && rec.fortePaymentMethodToken !== '')
            setCcPas(dataList)
        }
    }, [achPaysList, ccPasList])

    //Did Mount
    useMemo(() => {
        // If we show only one pay method
        if (showPaymentMethodCreateTypes.length === 1) {
            if (!achPays.length || !ccPas.length) {
                setActiveTab(1); //Create New Pay Method       
            } else {
                setActiveTab(2);
            }
        } else {
            if (!achPays.length && !ccPas.length) {
                setActiveTab(1); //Create New Pay Method        
            } else {
                setActiveTab(2);
            }
        }
    }, [achPays.length, ccPas.length]);

    const paymentMethodListProps = {
        selectedPaymentMethod,
        handleSelectPaymentMethod,
        deletePaymentMethod,
        showPaymentMethodCreateTypes
    };

    const paymentMethodCreateFormProps = {
        currentInformationSource,
        showPaymentMethodCreateTypes,
        entityTypeId, sourceOwnerId,
        achPays: achPaysList.length == 1 ? achPaysList[0].fortePaymentMethodToken == null || achPaysList[0].fortePaymentMethodToken == '' ? achPaysList[0] : {} : {},
        ccPas: ccPasList.length == 1 ? ccPasList[0].fortePaymentMethodToken == null || ccPasList[0].fortePaymentMethodToken == '' ? ccPasList[0] : {} : {},
        handleInitiallizeValues
    };

    //Dynamic Tab Restriction
    let showExistingPayMethods = true;
    if (showPaymentMethodCreateTypes.length === 1) {
        if (showPaymentMethodCreateTypes.includes(BANK_ACCOUNT_TYPE) && (achPays.length === 0)) {
            showExistingPayMethods = false;
        } else if (showPaymentMethodCreateTypes.includes(CREDIT_CARD_TYPE) && (ccPas.length === 0)) {
            showExistingPayMethods = false;
        }
    }
    var PaymentList = [{ label: 'New Pay Method', id: 1 }]
    if (showExistingPayMethods)
        PaymentList = [...PaymentList, { label: 'Existing Pay Methods', id: 2 }]
    //On Change Tab
    const handleChangeTab = (event, newValue) => {
        if (showExistingPayMethods)
            setActiveTab(newValue);
        else
            setActiveTab(1);

        if (parseInt(newValue) === 1) {
            resetSelectedPaymentMethod();
        }
    };

    const handleCallBackPaymentMethodPlan = ()=>{
       if(selectedFromListCallBack){
        selectedFromListCallBack(); 
       } 
       
        if(getTransactionFee) {
            getTransactionFee();
        }
    }

    return (
        <Fragment>
            {pageLoading && <CustomPageLoading flag={pageLoading} />}

            <Fragment>
                {/* <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} /> */}
                <Grid container spacing={1}>
                    {PaymentList.map(({ label = '', id = 0 }, index) => {
                        return (
                            <Grid key={`pay_type_${index}`} item xs={6} >
                                <div direction='column' style={{ alignItems: 'center', display: 'inline-flex' }}>
                                    <>
                                        <CustomRadio
                                            className={classes.paymentRadioBtnStyle}
                                            checked={parseInt(activeTab) === parseInt(id)}
                                            onChange={(e) => handleChangeTab(e, id)}
                                            value={activeTab}
                                            name="selectedPaymentMethod"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    </>
                                    <>
                                        <Typography>{label}</Typography>
                                    </>
                                </div>
                            </Grid>

                        )
                    })}
                </Grid>
                <Divider style={{ width: '100%', borderWidth: '1px', marginTop: '1rem' }} />
                {/* <Grid item xs={12} sm={12} md={12}>
                    <Tabs
                        value={activeTab}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        TabIndicatorProps={{ style: { display: "none", } }}
                        // className={classes.tabSection}
                        centered
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {showExistingPayMethods &&
                            <Tab label="Existing Pay Methods" className={(activeTab === 0) ? classes.activeTabStyle : classes.inactiveTabStyle} />
                        }
                        <Tab label="New Pay Method" className={(activeTab === 1) ? classes.activeTabStyle : classes.inactiveTabStyle} />
                    </Tabs>
                </Grid> */}
                {/* <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} /> */}
            </Fragment >


            {
                loading ?
                    <Grid item xs={12}>
                        < LinearProgress />
                    </Grid >
                    :
                    <Grid item xs={12}>
                        {activeTab == 2 &&
                            <>

                                {
                                    loading ?
                                        ''
                                        :
                                        (!achPays.length && !ccPas.length) ?
                                            <Typography variant="h6" display="block" gutterBottom >
                                                No Record Found. Please add new payment method
                                            </Typography>
                                            :
                                            <Fragment>
                                                {!selectedPaymentMethod &&
                                                    <center><p><b>{chooseFromListLabel}</b></p></center>
                                                }
                                                <CommonPaymentMethodsList {...paymentMethodListProps} />
                                            </Fragment>
                                }
                            </>
                        }
                        {activeTab == 1 &&
                            <>
                                <PaymentMethodCreateForm {...paymentMethodCreateFormProps} />
                            </>
                        }
                    </Grid >
            }
            {
                selectedPaymentMethod &&
                <Grid item xs={12}>
                    <Button variant='contained' className={classes.continuebtn} onClick={() => { handleCallBackPaymentMethodPlan(); }}>
                        Continue
                    </Button>
                </Grid>
            }
        </Fragment >
    );
}