import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
  IconButton,
  Tooltip,
  Grid,
  Button,
  List,
  Divider,
} from "@material-ui/core";
import CustomTableHeader from "../../common/table/CustomTableHeader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import {
  CustomAlertMessage,
  CustomGridLoading,
  DateTimeFormat,
} from "../../../functions/CustomFunctions";
import {
  BACK_TO_PENDING_AND_CLEAR_SIGNQUEUE,
  SEND_SIGNQUEUE_REMINDER_URL,
} from "../../../constants/apiPaths";
import {
  authHeader,
  getLoggedUserMemberId,
} from "../../../functions/CoreFunctions";
import { useSnackbar } from "notistack";
import API from "../../../redux/actions/API";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CachedIcon from "@material-ui/icons/Cached";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import SignPadDialogue from "../../MyDocuments/SignPadDialogue";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import RefreshIcon from "@material-ui/icons/Refresh";
import SendIcon from "@material-ui/icons/Send";
import { LOGGED_USER_ACCESS_LEVEL } from "../../../constants/LocalStorage";
import { Alert } from "@material-ui/lab";
import {
  isMobileScreen,
  isTabScreen,
} from "../../../functions/commonFunctions";
import SignersMobileAndTabView from "./SignersMobileAndTabView";
import CommonPreviewDocumentDialog from "../../common/CommonPreviewDocumentDialog";

const tableColumns = [
  { id: "name", title: "Name", sort: false },
  { id: "email", title: "Email", sort: false },
  { id: "createdate", title: "Created Date", sort: false },
  { id: "status", title: "Status", sort: false },
];

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontSize: "22px",
    fontWeight: "900",
    marginTop: "5px",
    marginLeft: "8px",
    marginBottom: "-30px",
  },
  dangerColor: {
    backgroundColor: "#f5c2c2",
  },
  checkicon: {
    float: "left",
    borderRadius: "50%",
    backgroundColor: "green",
    color: "#fff",
    padding: "2px",
    marginRight: "7px",
  },
  btn: {
    color: "#fff",
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: "50px",
    padding: "10px 35px",
    fontSize: "12px",
    "&:hover": {
      opacity: 0.7,
    },
    marginRight: "3px",
  },
  filterIcon: {
    color: "#fff",
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    padding: "5px",
    marginLeft: "14px",
  },
  iconpadding: {
    padding: "6px",
    color: "#fff",
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    // fontSize: 5,
    // marginRight: '5px'
  },
  iconSize: {
    fontSize: 16,
  },
}));

export default function SignersListResponsive(props) {
  console.log(props.pdfUrl, "props");
  const classes = useStyles();
  const tableHeadProps = { tableColumns };
  const {
    reGenerateDocument = null,
    signQueueList = [],
    pdfUrl = "",
    loading = false,
    organizationId = 0,
    documentTransactionID = 0,
    documentTransactionStatus = "",
    callBack,
    refreshSignqueue = null,
    refreshFunc,
    dcoumentStatusId = 0,
    UpdateDocumentTransactionStatus,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [showTable, setShowTable] = useState(true);
  const [formloading, setFormloading] = useState(false);
  const loggedUserId = getLoggedUserMemberId();
  const [signDialogFlag, setSignDialogFlag] = useState(false);
  const [currentDocument, setCurrentDocuemnt] = useState({});
  const [emailSendArray, setEmailSendArray] = useState([]);
  const [hideTable, setHideTable] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(null);
  const [dialogFlag, setDialogFlag] = useState(false);
  const loggedUserAccess = localStorage.getItem(LOGGED_USER_ACCESS_LEVEL);

  const toggleTableBlock = () => {
    setShowTable(!showTable);
  };

  useEffect(() => {
    let temp = signQueueList?.filter(
      (x) =>
        x.documentFormId === 44 &&
        (x.positionName === null || x.positionName === "")
    );
    if (temp.length > 0) {
      setHideTable(true);
    }
  }, [signQueueList]);

  const handleOnClickSign = (row) => {
    setSignDialogFlag(true);
    setCurrentDocuemnt(row);
  };

  const handleSignDialogClose = () => {
    setSignDialogFlag(false);
  };

  const callBackSignPad = (d) => {
    callBack();
    setSignDialogFlag(false);
  };

  const clearSignQueue = async () => {
    setFormloading(true);
    try {
      const options = {
        method: "GET",
        url: BACK_TO_PENDING_AND_CLEAR_SIGNQUEUE,
        headers: authHeader(),
        params: {
          DocumentTransactionID: parseInt(documentTransactionID),
        },
      };
      const { data: apiResponse } = await API(options);
      const { status } = apiResponse;
      if (parseInt(status) === 1) {
        console.info("successfully refreshed");
        callBack();
        // CustomAlertMessage('Signqueue is refreshed successfully!', 'success', enqueueSnackbar);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      setFormloading(false);
    }
  };

  useEffect(() => {
    if (
      documentTransactionStatus != "" &&
      parseInt(documentTransactionStatus) == 1 &&
      !showTable
    ) {
      dcoumentStatusId === 2 ? setShowTable(false) : setShowTable(true);
    } else {
      dcoumentStatusId === 3 ? setShowTable(false) : setShowTable(true);
    }
  }, []);
  console.log("dcoumentStatusId", dcoumentStatusId, documentTransactionStatus);

  const SendEmailReminder = async (id) => {
    try {
      setFormloading(true);
      const options = {
        method: "POST",
        url: SEND_SIGNQUEUE_REMINDER_URL,
        headers: authHeader(),
        params: {
          DTSID: parseInt(id),
        },
      };
      const { data: apiResponse } = await API(options);
      const { status, message } = apiResponse;
      if (parseInt(status) === 1) {
        setEmailSendArray([...emailSendArray, id]);
        CustomAlertMessage(message, "success", enqueueSnackbar);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomAlertMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      setFormloading(false);
    }
  };

  const handlePreview = (event) => {
    const { doc_url = "", file_extension = "" } = event.currentTarget.dataset;
    setPreviewDocument({ url: doc_url, extension: file_extension });
    setDialogFlag(true);
  };

  const handleDialogClose = () => {
    setDialogFlag(false);
  };

  const previewDialogProps = {
    dialogFlag,
    handleClose: handleDialogClose,
    previewData: previewDocument,
  };

  return (
    <Fragment>
      {loading || formloading ? (
        <CustomGridLoading />
      ) : hideTable ? (
        <Alert
          severity="warning"
          action={
            <Button
              color="primary"
              size="small"
              // className={classes.iconpadding}
              onClick={() => refreshFunc()}
            >
              Refresh
            </Button>
          }
        >
          Document is Outdated!
        </Alert>
      ) : (
        signQueueList.length > 0 && (
          <Accordion square expanded={true} style={{ marginTop: "1rem" }}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid container>
                <Grid item xs={4} sm={4} md={6}>
                  {isMobileScreen() || isTabScreen() ? (
                    <Typography className={classes.cardTitle} style={{ float: "right" }}>
                      Sign Queue
                    </Typography>
                  ) : (
                    <>
                      <Typography className={classes.cardTitle}>
                        Sign Queue
                      </Typography>
                      <br />
                      <Typography
                        style={{ paddingLeft: "10px", paddingTop: "5px" }}
                      >
                        DocumentTransactionID: {documentTransactionID}
                      </Typography>
                    </>
                  )}
                </Grid>
                {/* <Grid item xs={6} sm={6} md={6}> */}
                {isMobileScreen() || isTabScreen() ? (
                  <>
                    <Grid item xs={8} sm={8} md={6}>
                      <span >
                        <>
                          {refreshSignqueue !== null && (
                            <Tooltip title={"Refresh SignQueue"}>
                              <IconButton
                                className={classes.filterIcon}
                                aria-label="Refresh SignQueue"
                                onClick={refreshSignqueue}
                              >
                                <RefreshIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {loggedUserAccess === "National" && (
                            <Tooltip title={"Back to Pending"}>
                              <IconButton
                                className={classes.filterIcon}
                                aria-label="Clear Signatures"
                                onClick={clearSignQueue}
                              >
                                <SettingsBackupRestoreIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          {reGenerateDocument !== null &&
                            parseInt(documentTransactionStatus) !== 1 &&
                            loggedUserAccess === "National" && (
                              <Tooltip title={"Regenerate Document"}>
                                <IconButton
                                  className={classes.filterIcon}
                                  aria-label="Regenerate Document"
                                  onClick={reGenerateDocument}
                                >
                                  <CachedIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                        </>

                        {pdfUrl != null && (
                          <Tooltip title={"Pdf"}>
                            <IconButton
                              edge="end"
                              aria-label="preview"
                              data-doc_url={pdfUrl}
                              data-file_extension={"pdf"}
                              onClick={handlePreview}
                              className={classes.filterIcon}
                            >
                              <PictureAsPdfIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title={showTable ? "Hide list" : "Show list"}>
                          <IconButton
                            className={classes.filterIcon}
                            aria-label="Show list"
                            onClick={toggleTableBlock}
                          >
                            {showTable ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      </span>
                    </Grid>

                    <div style={{ marginTop: '3rem' }}>
                      {dcoumentStatusId == 1 && (
                        <span style={{ float: "right" }} sm={2}>
                          <Alert
                            severity="error"
                          // style={{
                          //   width: isMobileScreen() ? '' : "350px",
                          //   marginRight: isMobileScreen() ? '' : "10px",
                          // }}
                          >
                            <Typography style={{ fontSize: 12 }}>
                              Document is pending for Signature.
                            </Typography>
                          </Alert>
                        </span>
                      )}

                      {dcoumentStatusId == 2 && (
                        <span style={{ float: "right" }} sm={12}>
                          <Alert
                            severity="error"
                          // style={{
                          //   width: isMobileScreen() ? '' : "450px",
                          //   marginRight: isMobileScreen() ? '' : "10px",
                          // }}
                          >
                            <Typography style={{ fontSize: 12 }}>All Documents are Signed, Waiting for Approval.</Typography>
                          </Alert>
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <Grid item xs={6} sm={6} md={6}>
                      <span style={{ float: "right" }}>
                        <>
                          {loggedUserAccess === "National" && (
                            <>
                              <Tooltip title={"Back to Pending"}>
                                <IconButton
                                  className={classes.filterIcon}
                                  aria-label="Clear Signatures"
                                  onClick={clearSignQueue}
                                >
                                  <SettingsBackupRestoreIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                          {refreshSignqueue !== null && (
                            <Tooltip title={"Refresh SignQueue"}>
                              <IconButton
                                className={classes.filterIcon}
                                aria-label="Refresh SignQueue"
                                onClick={refreshSignqueue}
                              >
                                <RefreshIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {reGenerateDocument !== null &&
                            parseInt(documentTransactionStatus) !== 1 &&
                            loggedUserAccess === "National" && (
                              <Tooltip title={"Regenerate Document"}>
                                <IconButton
                                  className={classes.filterIcon}
                                  aria-label="Regenerate Document"
                                  onClick={reGenerateDocument}
                                >
                                  <CachedIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                        </>

                        {pdfUrl != null && (
                          <Tooltip placement="top" title="Pdf">
                            <IconButton
                              edge="end"
                              aria-label="preview"
                              data-doc_url={pdfUrl}
                              data-file_extension={"pdf"}
                              onClick={handlePreview}
                              className={classes.filterIcon}
                            >
                              <PictureAsPdfIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title={showTable ? "Hide list" : "Show list"}>
                          <IconButton
                            className={classes.filterIcon}
                            aria-label="Show list"
                            onClick={toggleTableBlock}
                          >
                            {showTable ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      </span>
                      {dcoumentStatusId == 1 && (
                        <span style={{ float: "right" }}>
                          <Alert
                            severity="error"

                            style={{
                              width: isMobileScreen() ? "0px" : "350px",
                              marginRight: isMobileScreen() ? "0px" : "10px",
                            }}
                          >
                            <Typography>
                              Document is pending for Signature.
                            </Typography>
                          </Alert>
                        </span>
                      )}
                      {dcoumentStatusId == 2 && (
                        <span style={{ float: "right" }}>
                          <Alert
                            severity="error"
                            style={{
                              width: "450px",
                              marginRight: "10px",
                            }}
                          >
                            <Typography>All Documents are Signed, Waiting for Approval.</Typography>
                          </Alert>
                        </span>
                      )}
                    </Grid>
                  </>
                )}
                {/* </Grid> */}
                {/* {(isMobileScreen() || isTabScreen()) && (
                  <Grid item xs={12}>
                    <Typography
                      style={{ paddingLeft: "0px", paddingTop: "10px" }}
                    >
                      DocumentTransactionID: {documentTransactionID}
                    </Typography>
                  </Grid>
                )} */}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {showTable && (
                <Fragment>
                  {isMobileScreen() || isTabScreen() ? (
                    <List>
                      {signQueueList &&
                        signQueueList.map((queue, index) => {
                          return (
                            <Fragment key={`tab_mob_list_${index}`}>
                              <SignersMobileAndTabView
                                data={queue}
                                handleOnClickSign={handleOnClickSign}
                                SendEmailReminder={SendEmailReminder}
                                emailSendArray={emailSendArray}
                              />
                              <Divider variant="fullWidth" component="li" />
                            </Fragment>
                          );
                        })}
                    </List>
                  ) : (
                    <TableContainer>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        style={{ marginTop: "10px" }}
                      >
                        <CustomTableHeader {...tableHeadProps} />
                        <TableBody>
                          {signQueueList &&
                            showTable &&
                            signQueueList.map((queue, index) => {
                              const {
                                createDate,
                                positionName = "",
                                statusId,
                                statusName,
                                entityEmail,
                                entityName,
                                sourceOwnerId,
                                membershipNumber,
                                dtsid,
                              } = queue;
                              let dynamicRowStyle = "";
                              if (parseInt(statusId) === 4) {
                                dynamicRowStyle = classes.dangerColor;
                              }
                              return (
                                <TableRow
                                  hover
                                  className={dynamicRowStyle}
                                  key={`org_acc_list_${index}`}
                                  role="checkbox"
                                  tabIndex={-1}
                                >
                                  <TableCell className={classes.textStyle}>
                                    {parseInt(statusId) === 4 && (
                                      <CheckIcon
                                        className={classes.checkicon}
                                      />
                                    )}
                                    <b>{entityName}</b>

                                    {positionName !== null ? (
                                      <>
                                        <br />
                                        <span>{positionName}</span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell className={classes.textStyle}>
                                    {entityEmail}
                                  </TableCell>
                                  <TableCell className={classes.textStyle}>
                                    {DateTimeFormat(createDate, "MM/DD/YYYY")}
                                  </TableCell>
                                  <TableCell className={classes.textStyle}>
                                    {statusId == 1 &&
                                      parseInt(sourceOwnerId) ===
                                      parseInt(loggedUserId) ? (
                                      <Tooltip title="Sign">
                                        <IconButton
                                          className={classes.iconpadding}
                                          aria-label="signpad"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleOnClickSign(queue);
                                          }}
                                        >
                                          <BorderColorIcon
                                            className={classes.iconSize}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      `${statusName}`
                                    )}
                                    {parseInt(statusId) === 1 && (
                                      <Tooltip
                                        title="Send Email Reminder"
                                        style={{ float: "right" }}
                                      >
                                        <IconButton
                                          disabled={
                                            emailSendArray.indexOf(dtsid) > -1
                                          }
                                          className={classes.iconpadding}
                                          aria-label="signpad"
                                          onClick={(e) =>
                                            SendEmailReminder(dtsid)
                                          }
                                        >
                                          <SendIcon
                                            className={classes.iconSize}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Fragment>
              )}
            </AccordionDetails>
          </Accordion>
        )
      )}
      {signDialogFlag && (
        <SignPadDialogue
          dialogFlag={signDialogFlag}
          dialogToggleFunc={handleSignDialogClose}
          documentData={currentDocument}
          callBackPdfFromSignDocument={callBackSignPad}
          officerreport={true}
        ></SignPadDialogue>
      )}
      {dialogFlag && <CommonPreviewDocumentDialog {...previewDialogProps} />}
    </Fragment>
  );
}
