// CollegeOfficialVerificationPopup
import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, makeStyles, RadioGroup, TextField, Typography } from "@material-ui/core"
import AddEditCollegeOfficial from "./addeditcollegeofficials"
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import StyledRadioButton from "../common/styledRadioButton";
import { Fragment, useEffect, useState } from "react";
import { CustomAlertMessage, CustomPageLoading } from "../../functions/CustomFunctions";
import { useSnackbar } from "notistack";
import { ADDENTITY_COLLEGE_OFFICIAL_API } from "../../constants/apiPaths";
import { authHeader } from "../../functions/CoreFunctions";
import API from "../../redux/actions/API";
import SearchIcon from '@material-ui/icons/Search';
import { getCollegeofficialsList } from "../../redux/actions";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column"
    },
    addcollegebtn: {
        marginBottom: '5px',
        float: 'left',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        },
        marginTop: '1rem'
    },
    modalTitle: {
        fontSize: '1rem',
        margin: 0,

    }
}));

const CollegeOfficialRelationPopup = props => {
    const classes = useStyles();
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { loading: collegeofficialLoading = false, data: relatedCollegeOfficials = [] } = useSelector(state => state.GetCollegeOfficialSchoolHistoryReducer);

    const { selectSchoolId, isIniialSchool = false, showCollegeOffiCialForm = false, setShowCollegeOffiCialForm, isCollegeofficialVerification = false, isDialogOpen, onSuccessCallBack, dialogClose, OrganizationID, selectCollegeOficial, setSelectCollegeOficial, selectpersonId = 0 } = props
    const [formLoading, setFormLoading] = useState(false);
    const { count: totalCollegeCount, data: CollegeList = [] } = useSelector(state => state.GetCollegeOfficialListReducer);
    const [collegeofficialTableRec, setCollegeofficialTableRec] = useState([]);
    const [collegefilterValues, setCollegefilterValues] = useState({ collegeName: '' });
    //Clear Search Field
    const clearSearchField = (event) => {
        let clrfldname = event.currentTarget.dataset.clrfldname;

        let fieldValue = Object.assign({}, collegefilterValues)
        fieldValue[clrfldname] = '';
        setCollegefilterValues(fieldValue);
        setCollegeofficialTableRec(CollegeList)


    }
    console.log(selectSchoolId, 'selectSchoolId2')


    const hanleAddCollegeOfficial = async (item) => {
        setSelectCollegeOficial(item)
        // selectSchoolId

        let data = {
            entityTypeId: 7,
            sourceOwnerId: item.personId,
            schoolId: parseInt(selectSchoolId)
        }
        if (item.personId == 0) {
            setShowCollegeOffiCialForm(true)
        } else {


            try {
                setFormLoading(true)
                const options = {
                    method: 'POST',
                    url: ADDENTITY_COLLEGE_OFFICIAL_API,
                    data: data,
                    headers: authHeader()
                }

                const { data: apiResponse } = await API(options);
                const { status, message = '' } = apiResponse;
                if (parseInt(status) === 1) {
                    CustomAlertMessage('Saved successfully!', 'success', enqueueSnackbar)
                    dialogClose()
                    if (onSuccessCallBack)
                        onSuccessCallBack()
                    // setAddentity(false)
                    // history.push(SCHOOLS_PATH)
                }
                else {

                    CustomAlertMessage(message, 'error', enqueueSnackbar);
                }




            } catch (error) {
                if (error.response) {
                    let { data } = error.response;
                    CustomAlertMessage(error.response.statusText, 'error', enqueueSnackbar);
                }
            } finally {
                setFormLoading(false)
            }
        }




    }

    //On Field Change
    const fieldChange = event => {
        let { name, value } = event.target;

        setCollegefilterValues({ [name]: value });
        handleCollegeSearch(value)

    };

    const handleCollegeSearch = (val) => {
        let searchedCollege = [];
        const collegerec = CollegeList.map((record, index) => {
            let dataToReturn = { ...record, 'name': record.firstName + ' ' + record.lastName };
            return dataToReturn;
        });

        collegerec.filter(data => (data.name.toLowerCase().includes(val.toLowerCase()))
        ).map(filteredData => (
            searchedCollege.push(filteredData)
        ));
        if (val) {
            setCollegeofficialTableRec(searchedCollege)
            // setShowCollegeTable(true);
        } else {
            setCollegeofficialTableRec(CollegeList)
            // setShowCollegeTable(true);

        }
    }
    useEffect(() => {
        dispatch(getCollegeofficialsList(setCollegeofficialTableRec));


    }, []);

    const CustomClearIcon = ({ clrfldname }) => <CloseIcon className={classes.clearIcon} data-clrfldname={clrfldname} position="end" onClick={clearSearchField} />
    let collegeofficialRecords = collegefilterValues.collegeName !== '' ? collegeofficialTableRec.slice(0, 5) : [];

    console.log(relatedCollegeOfficials, 'relatedCollegeOfficials')
    return (
        <Fragment>
            {formLoading && <CustomPageLoading flag={formLoading} />}

            <Dialog disableBackdropClick disableEscapeKeyDown fullWidth={true} maxWidth={'md'} onClose={dialogClose} aria-labelledby="customized-dialog-title" open={isDialogOpen}>
                <DialogTitle id="customized-dialog-title" onClose={dialogClose}>
                    <h6 className={classes.modalTitle}>{selectpersonId > 0 ? 'Edit College Official' : showCollegeOffiCialForm ? 'Add a New College Official' : 'Select Existing College Official'}
                        <span style={{ float: 'right', cursor: 'pointer' }}>
                            <CloseIcon onClick={dialogClose} />
                        </span>
                    </h6>
                </DialogTitle>
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    {selectpersonId > 0 ?
                        <AddEditCollegeOfficial selectpersonId={selectpersonId} isIniialSchool={isIniialSchool} selectSchoolId={selectSchoolId} isCollegeofficialVerification={isCollegeofficialVerification} ModalClose={dialogClose} CallBack={onSuccessCallBack} OrganizationID={OrganizationID} />
                        :
                        <>{!showCollegeOffiCialForm &&
                            <Grid container  >

                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        id="collegeName"
                                        label="College Official Name"
                                        name="collegeName"
                                        placeholder="Type CollegeOfficial Name to search"
                                        value={collegefilterValues.collegeName || ""}
                                        onChange={fieldChange}
                                        InputProps={{
                                            endAdornment: collegefilterValues.collegeName ? <CustomClearIcon clrfldname='collegeName' /> : <SearchIcon position="end" />,
                                        }}
                                    />
                                </Grid>
                            </Grid>}
                            {!showCollegeOffiCialForm && collegeofficialRecords.length > 0 ?
                                <>
                                    {
                                        collegeofficialRecords.map((item) => {
                                            return (
                                                <div style={{ marginBottom: '10px' }}>

                                                    <FormControl component='fieldset'>
                                                        <RadioGroup
                                                            aria-label='postas'
                                                            name='customized-radios'
                                                        >
                                                            <FormControlLabel
                                                                value={item.personId}
                                                                control={<StyledRadioButton />}
                                                                label={<>{item.firstName !== '' || item.lastName !== '' ? <><span>{`Name : ${item.firstName} ${item.lastName}`}</span><br /></> : null}
                                                                    <span>{`Title : ${item.title}`}</span></>}
                                                                checked={selectCollegeOficial !== null && selectCollegeOficial.personId == item.personId}
                                                                onChange={() => { hanleAddCollegeOfficial(item); }}
                                                                className='highlight'
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            );
                                        })}
                                </>
                                :
                                <>

                                    {!showCollegeOffiCialForm &&
                                        relatedCollegeOfficials.map((item) => {
                                            return (
                                                <div style={{ marginBottom: '10px' }}>

                                                    <FormControl component='fieldset'>
                                                        <RadioGroup
                                                            aria-label='postas'
                                                            name='customized-radios'
                                                        >
                                                            <FormControlLabel
                                                                value={item.personId}
                                                                control={<StyledRadioButton />}
                                                                label={<>{item.firstName !== '' || item.lastName !== '' ? <><span>{`Name : ${item.firstName} ${item.lastName}`}</span><br /></> : null}
                                                                    <span>{`Title : ${item.title}`}</span></>}
                                                                checked={selectCollegeOficial !== null && selectCollegeOficial.personId == item.personId}
                                                                onChange={() => { hanleAddCollegeOfficial(item); }}
                                                                className='highlight'
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            );
                                        })}
                                </>
                            }
                            {!showCollegeOffiCialForm &&
                                <>
                                    <Grid container  >

                                        <Grid item xs={9} sm={4} md={2}>
                                            <Typography style={{ textAlign: 'center', fontWeight: '900', fontSize: 22, display: 'block' }}>
                                                OR
                                            </Typography>
                                        </Grid>
                                    </Grid>


                                    <Button variant="contained" color="secondary" className={classes.addcollegebtn} onClick={() => hanleAddCollegeOfficial({ "personId": 0 })} >
                                        Add New College Official
                                    </Button>
                                </>
                            }
                        </>
                    }
                    {showCollegeOffiCialForm && selectpersonId == 0 &&
                        <AddEditCollegeOfficial selectpersonId={selectpersonId} isIniialSchool={isIniialSchool} selectSchoolId={selectSchoolId} isCollegeofficialVerification={isCollegeofficialVerification} ModalClose={dialogClose} OrganizationID={OrganizationID} CallBack={onSuccessCallBack} />

                    }
                </DialogContent>
            </Dialog>
        </Fragment>

    )
}

export default CollegeOfficialRelationPopup