import { chatBoxes } from '../store/InitialStore';
import * as types from '../actions/ActionTypes';

const ChatBoxReducer = (state = [...chatBoxes], action) => {
  const { type, payload } = action;
  switch (type) {
    case types.ADD_CHAT_BOX:
      return [...state, payload];

    case types.REMOVE_CHAT_BOX:
      return state.filter((chatBox) => chatBox.title !== payload);
    default:
      return state;
  }
};

export default ChatBoxReducer;