import { initialPositionTypes } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetPositionTypeListReducer(state = initialPositionTypes,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_POSITON_TYPES_LOADING:
            return {...state,loading:true,error:false};   

        case types.GET_POSITON_TYPES_SUCCESS:
            return {...state,payload,loading:false,error:false}; 
            
        case types.GET_POSITON_TYPES_ERROR:
            return {...state,loading:false,error:true};  
        default:
            return state
    }
}

