import Axios from 'axios';
import { getParentOrganizationID, logout } from '../../functions/CoreFunctions';

const PID = getParentOrganizationID();

const API = Axios.create({
  headers: {
    common: {
      ParentOrganizationID: PID,
    },
  },
});

API.interceptors.response.use(
  (response) => {

    return response;
  },
  (error) => {

    const { status } = error.response;
    if (status === 401) {
      //logout if unauthorized
      logout();
    } if (status === 500) {
      return Promise.reject(status);
    }
    return Promise.reject(error);
  }
);
export default API;
