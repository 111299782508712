import React, { Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { createMarkup, CustomAlertMessage, CustomPageLoading, DateTimeFormat, fixedFloatTwoDigit, getAppName } from '../../functions/CustomFunctions';
import { BANK_ACCOUNT_TYPE, CREDIT_CARD_TYPE, DTYPE_FOR_DOCUMENT_FORM, DTYPE_FOR_PAYMENT_AUTHORIZATION_DOCUMENT_FORM, ENTITY_TYPE_ID_FOR_MEMBER, PaymentMethodsCardIcon } from '../../constants/HelperConstants';
import { getDocumentDetail, getPaymentMethods, GetTicketFeeDetermine, GetTransactionFee } from '../../redux/actions';
import { Button, FormGroup, FormLabel, ListItemSecondaryAction, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { PROCESS_PAYMENT_URL, UPDATE_SUPPLEMENTDOCUMENTID_URL, UPLOAD_DOCUMENT_FORM_DATA_URL } from '../../constants/apiPaths';
import { ACH_AUTHORIZATION_DOCUMENT_FORM_ID, CC_AUTHORIZATION_DOCUMENT_FORM_ID } from '../../constants/HelperConstants';
import API from '../../redux/actions/API';
import { useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import { SELECTED_PAYMENT_METHOD } from '../../constants/LocalStorage';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import GetAppIcon from '@material-ui/icons/GetApp';
import CommonPaymentMethodCRUD from './CommonPaymentMethodCRUD';
import { Alert, AlertTitle } from '@material-ui/lab';
import SignPadDialogue from '../MyDocuments/SignPadDialogue';
import { isMobileScreen } from '../../functions/commonFunctions';
import CustomTableHeader from './table/CustomTableHeader';
import { useMemo } from 'react';
import { GET_FEE_DETERMINE_SUCCESS } from '../../redux/actions/ActionTypes';
import { useHistory, useLocation, useParams } from 'react-router';

const tableColumns = [
    { id: "name", title: "Name", align: 'left' },
    { id: "quantity", title: "Qty", align: 'right' },
    { id: "amount", title: "Amount", align: 'right' }
];

const useStyles = makeStyles((theme) => ({
    leftAlign: {
        float: 'left'
    },
    terms_and_cond: {
        backgroundColor: 'yellow',
        fontWeight: 'bold',
        fontSize: 'medium',
        width: '325px',
        padding: '8px 0px 0px 6px',
    },
    continuebtn: {
        color: '#fff',
        width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px',
        opacity: 1,
        '&:hover': {
            opacity: 0.7,
        }
    },
    continuebtn2: {
        color: '#fff',
        fontSize: isMobileScreen() ? '10px' : '12px',
        // width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: isMobileScreen() ? '10px 0px 0px -55px' : 'auto',
        // display: 'block',
        padding: '6px 12px'
    },
    tabSection: {
        border: `2px solid ${theme.primaryColor}`,
        padding: '1px 0px 1.5px 0px',
        borderRadius: '25px',
        minHeight: '43px'
    },
    formHeader: {
        fontSize: 'x-large',
        marginBottom: '1rem',
        textAlign: 'center'
    },
    table: {
        maxWidth: 400,
    },
    borderlessStyle: {
        borderBottom: 'none',
        fontWeight: 600
    },
    activeTabStyle: {
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '25px',
        color: '#fff !important',
        minHeight: '33px',
        minWidth: '186px',
    },
    inactiveTabStyle: {
        color: theme.primaryColor,
        minHeight: '33px',
        minWidth: '152px',
    },
    formControl: {
        width: "100%"
    },
    backArrow: {
        float: 'right',
        marginTop: '1.2rem',
    },
    skipButton: {
        marginBottom: '1rem',
        color: theme.primaryColor,
        display: 'block',
        padding: '5px 21px',
        borderRadius: '50px',
        width: '100%'
    },
    totalAmountStyle: {
        borderBottom: 'none',
        fontWeight: 1000,
        color: 'red',
        fontSize: 'medium'
    },
    ellipsesContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkitLineClamp': '3',
        '-webkitBoxOrient': 'vertical',
        cursor: 'pointer',
        paddingLeft: '15px'
    },
    activeLineItem: {
        backgroundColor: 'rgb(232, 244, 253)',
        border: '1px solid #9c9898'
    },
    listStyle: {
        border: '1px solid #9c9898'
    },
    rightAlignText: {
        textAlign: 'right'
    },
    subtextStyle: {
        fontSize: '10px',
        marginTop: '-5px'
    },
    subDescStyle: {
        fontWeight: 'bold',
        color: 'red'
    },
    downIconStyle: {
        height: '23px'
    },
    downButton: {
        marginTop: '5px',
        fontSize: '12px'
    },
    vieweBtn: {
        marginRight: '10px',
        padding: '6px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        fontSize: 5
    },
}));

// Custom Checkbox
const CustomCheckbox = withStyles(theme => ({
    root: {
        color: theme.primaryColor,
        '&$checked': {
            color: theme.primaryColor,
        },
    },
    checked: {},
}))((props) => <Checkbox color="default" {...props} />);

//Schema
const validationSchema = Yup.object({
    // acceptTerms: Yup.bool().oneOf([true], 'Please review Terms and Conditions')
});

export default function CommonPaymentForm(props) {
    const {
        // loading,
        payload: paymentMethods = {} } = useSelector((state) => state.GetPaymentMethodsReducer);
    const { achPays = [], ccPas = [] } = paymentMethods;

    const dispatch = useDispatch()
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    let localStoragePaymentData = JSON.parse(localStorage.getItem(SELECTED_PAYMENT_METHOD)) || {};

    // const { id: documentTransactionID } = useParams();

    //State
    const [paymentInformationStep, setPaymentInformationStep] = useState(0);
    const [pageLoading, setPageLoading] = useState(false);
    const [dialogFlag, setDialogFlag] = useState(false);
    const [signedDocumentToUpload, setSignedDocumentToUpload] = useState(null);
    const [downloadableSignedPdf, setDownloadablePdf] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [paymentFor, setPaymentFor] = useState('');
    const [selectedPaymentData, setSelectedPaymentData] = useState(localStoragePaymentData);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState(null);
    const [disableButtonFlag, setDisableButtonFlag] = useState(false);

    const { loading, documentFormDetail = {} } = useSelector(state => state.GetDocumentDetailReducer);
    const { documentName = '', documentUrl = '', documentId = '', formInfo = '', organizationId = '' } = documentFormDetail;
    //Download PDF
    const downloadPdf = () => {
        downloadableSignedPdf.save("Terms_and_Condition.pdf");
    }
    const tableHeadProps = {
        tableColumns
    };

    const dialogToggleFunc = (isDocumentSigned) => {

        setDialogFlag(!dialogFlag);
        if (dialogFlag !== true) {
            let documentFormId = (paymentType === 1) ? ACH_AUTHORIZATION_DOCUMENT_FORM_ID : CC_AUTHORIZATION_DOCUMENT_FORM_ID
        }
        // if (dialogFlag && !signedDocumentToUpload && isDocumentSigned !== true) {
        if (dialogFlag && isDocumentSigned !== true) {
            setFieldTouched('acceptTerms', true);
            setFieldValue('acceptTerms', false);
        }
    };

    //Set Signed Document to upload
    const callBackPdfFromSignDocument = (signedDocument, signedDocumentDownloadable) => {
        setDialogFlag(false);
        setSignedDocumentToUpload(signedDocument);
        setDownloadablePdf(signedDocumentDownloadable);
    }

    const {
        paymentPlanAmount, planPaymentMethod,
        currentInformationSource, buttonLabel, lineItems, IsAutoDebit = false, paymentTerms,
        customerToken, paymentSuccessCallBack, paymentPlanId, paymentPlanName, paymentPlanTermId=0,
        entityTypeId, sourceOwnerId, setAsShowLineItem, setPaymentType,
        standardTransactionFeeACH = {}, standardTransactionFeeCC = {}, BackTo = null,
        feeTypeID = 1
    } = props;
    let { total: totalAmount } = props

    // console.log(entityTypeId, sourceOwnerId, 'sourceOwnerId',feeTypeID)
    // let LineItems = lineItems
    const [LineItems, setLineItemList] = useState(lineItems);
    const { transactionFeeRecord = {} } = useSelector(state => state.GetTicketFeeDetermineReducer);
    const [total, setTotal] = useState(totalAmount);


    useMemo(() => {
        //find old feeline item  replace with new fee lineItem, because we change the Pay/card type
        var arrayofLineItemsCopy = [...LineItems];
        if (Object.keys(transactionFeeRecord).length > 0) {
            let feeRecord = arrayofLineItemsCopy.find((rec) => rec.lineTypeID == 5)
            if (feeRecord != undefined) {
                arrayofLineItemsCopy = arrayofLineItemsCopy.filter((rec) => rec.lineTypeID !== 5)
                arrayofLineItemsCopy.push(transactionFeeRecord);
                // setLineItemList(arrayofLineItemsCopy)
                //setLineItemList([...LineItems, transactionFeeRecord])
            } else {
                arrayofLineItemsCopy.push(transactionFeeRecord);
                // setLineItemList(arrayofLineItemsCopy)
                //setLineItemList([...LineItems, transactionFeeRecord])
            }
            let total = 0
            arrayofLineItemsCopy.map((rec) => total += rec.amount)
            //total = total + transactionFeeRecord.amount
            setTotal(total);
            setLineItemList([...arrayofLineItemsCopy]);
        }
    }, [transactionFeeRecord]);

    useEffect(() => {
        if (lineItems.length > 0) {
            const max = lineItems.reduce((prev, current) => (prev.amount > current.amount) ? prev : current);
            if (max) {
                setPaymentFor(max.description);
            }
        }
    }, [lineItems]);

    //Back To payment method
    const backToPaymentMethod = () => {
        setFieldTouched('acceptTerms', true);
        setFieldValue('acceptTerms', false);
        setPaymentInformationStep(0);
        if (setAsShowLineItem)
            setAsShowLineItem(true)
    }
    //move To Next
    const selectedFromListCallBack = () => {
        setPaymentInformationStep(1);
        if (setAsShowLineItem)
            setAsShowLineItem(false)
    }

    //Selected Payment Method
    const getSelectedPaymentMethod = () => {
        if ((parseInt(selectedPaymentType) === 1)) {
            const { bankAccountNumber, bankName } = achPays.find(record => parseInt(record.achpayId) === parseInt(selectedPaymentMethod));
            return (
                <ListItem >
                    <ListItemAvatar>
                        <Avatar>
                            <Icon className={"fas fa-university"} style={{ width: '100%', marginLeft: '16px', marginTop: '-1px' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`Account Number : ****${bankAccountNumber}`}
                        secondary={`Bank Name : ${bankName}`}
                    />
                </ListItem>
            );
        } else {
            const { cardType, cardNumber, expirationDate } = ccPas.find(record => parseInt(record.ccpayId) === parseInt(selectedPaymentMethod));
            return (
                <ListItem >
                    <ListItemAvatar>
                        <Avatar>
                            <Icon className={PaymentMethodsCardIcon[cardType].icon} style={{ width: '100%', marginLeft: '12px' }} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`Card Number : ****${cardNumber}`}
                        secondary={`Expires ${expirationDate.substring(4, 6)}/${expirationDate.substring(0, 4)}`}
                    />
                </ListItem>
            );
        }
    }
    console.log(currentInformationSource,'currentInformationSource')
    
    //Process Payment
    const saveFormValues = async () => {
        const { firstName, lastName, email } = currentInformationSource;
        
        const { paymentType, ccpayId = '', fortePaymentMethodToken = '', billAddressToken = '', achpayId = '' } = selectedPaymentData;
        if (!acceptTerms) {
            CustomAlertMessage("Please Accept Terms and Conditions", 'error', enqueueSnackbar);
        } else if (firstName == '' || lastName == '') {
            CustomAlertMessage("First Name and Last Name is needed. Please Contact technical support!", 'error', enqueueSnackbar);
        } else {
            // console.log({
            //     paymentType: parseInt(paymentType),
            //     firstName,
            //     lastName,
            //     email,
            //     entityTypeId,
            //     sourceOwnerId,
            //     payRecordId: (parseInt(paymentType) === 1) ? parseInt(achpayId) : parseInt(ccpayId),
            //     customerToken,
            //     billingAddressToken: billAddressToken,
            //     paymentMethodToken: fortePaymentMethodToken,
            //     planSelected: {
            //         paymentPlanTermID: parseInt(paymentPlanId || 0),
            //         paymentPlanID: parseInt(paymentPlanId || 0),
            //         name: paymentPlanName || '',
            //         intervalAmountToPay: parseFloat(paymentPlanAmount || 0)
            //     },
            //     LineItems,
            //     IsAutoDebit: IsAutoDebit,
            //     feeTypeID
            // },'save values')
            try {
                setPageLoading(true);
                setDisableButtonFlag(true);
                const options = {
                    url: PROCESS_PAYMENT_URL,
                    method: 'POST',
                    data: {
                        paymentType: parseInt(paymentType),
                        firstName,
                        lastName,
                        email,
                        entityTypeId,
                        sourceOwnerId,
                        payRecordId: (parseInt(paymentType) === 1) ? parseInt(achpayId) : parseInt(ccpayId),
                        customerToken,
                        billingAddressToken: billAddressToken,
                        paymentMethodToken: fortePaymentMethodToken,
                        planSelected: {
                            paymentPlanTermID: parseInt(paymentPlanTermId || 0),
                            paymentPlanID: parseInt(paymentPlanId || 0),
                            name: paymentPlanName || '',
                            intervalAmountToPay: parseFloat(paymentPlanAmount || 0)
                        },
                        LineItems,
                        IsAutoDebit: IsAutoDebit,
                        feeTypeID
                    }
                };
                const { data: apiResponse } = await API(options);
                const { status, message, PurchaseOrderID = 0 } = apiResponse;
                if (parseInt(status) === 1) {
                    var formData = new FormData();
                    formData.append('formFile', signedDocumentToUpload);
                    try {
                        const options = {
                            url: `${UPLOAD_DOCUMENT_FORM_DATA_URL}?entityTypeId=${entityTypeId}&sourceOwnerId=${sourceOwnerId}&ownerMemberId=${sourceOwnerId}&fileName=&fileExtension=pdf&dType=${DTYPE_FOR_PAYMENT_AUTHORIZATION_DOCUMENT_FORM}&documentFormID=${(paymentType === 1) ? ACH_AUTHORIZATION_DOCUMENT_FORM_ID : CC_AUTHORIZATION_DOCUMENT_FORM_ID}`,
                            method: 'POST',
                            data: formData
                        };
                        const { data: apiResponse } = await API(options);
                        const { status, documentID = 0 } = apiResponse;
                        if (parseInt(status) === 1) {
                            if (parseInt(PurchaseOrderID) > 0 && parseInt(documentID) > 0) {
                                try {
                                    const options = {
                                        method: 'POST',
                                        url: UPDATE_SUPPLEMENTDOCUMENTID_URL,
                                        params: {
                                            PurchaseOrderID: parseInt(PurchaseOrderID),
                                            DocumentID: parseInt(documentID)
                                        }
                                    }
                                    const { data: apiResponse } = await API(options)
                                    const { status } = apiResponse;
                                    if (status == 1) {
                                        console.info('success');
                                    }
                                } catch (error) {
                                    if (error.response) {
                                        CustomAlertMessage(error.response.statusText, 'error', enqueueSnackbar);
                                    }
                                }
                            }
                        }
                    } catch (error) {
                        if (error.response) {
                            let { data } = error.response;
                            CustomAlertMessage(data.message, 'error', enqueueSnackbar);
                        }
                    }
                    CustomAlertMessage(message, 'success', enqueueSnackbar);
                    paymentSuccessCallBack({ ...apiResponse, email, planPaymentMethod });
                    localStorage.removeItem(SELECTED_PAYMENT_METHOD);
                    setErrorMessage(null);
                } else {
                    setErrorMessage(message);
                    setDisableButtonFlag(false)
                }
            } catch (error) {
                if (error.response) {
                    let { data } = error.response;
                    setErrorMessage(data.message);
                    setDisableButtonFlag(false);
                }
            } finally {
                setPageLoading(false);
            }
        }
    }
    useMemo(() => {
        if (errorMessage !== null) {
            setTimeout(() => {
                setErrorMessage(null); // Remove error messgae after two 2 seconds
            }, 5000);
        }
    }, [errorMessage])

    //Reset Selected PaymentMethod CallBack
    const resetSelectedPaymentMethodCallBack = () => {
        setSelectedPaymentMethod(null);
        setSelectedPaymentType(null);
        if (setPaymentType)
            setPaymentType(null)
    }

    // Selected Payment Method callback
    const handleSelectedPaymentMethodCallBack = (currentRecord, paymentMethodId, paymentType) => {
        setSelectedPaymentData(currentRecord);
        setSelectedPaymentMethod(paymentMethodId);
        setSelectedPaymentType(paymentType);
        if (setPaymentType)
            setPaymentType(paymentType)
    }

    //Did Mount
    useEffect(() => {
        //If only one payment method set as default selected
        if ((achPays.length + ccPas.length) === 1) {
            let valueAsSelected = null, typeAsSelected = null, currentRecord = {};
            if (achPays.length > 0) {
                valueAsSelected = achPays[0].achpayId;
                typeAsSelected = 1;
                currentRecord = achPays[0];
            } else {
                valueAsSelected = ccPas[0].ccpayId;
                typeAsSelected = 2;
                currentRecord = ccPas[0];
            }
            currentRecord = {
                ...currentRecord,
                paymentType: typeAsSelected
            };
            localStorage.setItem(SELECTED_PAYMENT_METHOD, JSON.stringify(currentRecord));
            setSelectedPaymentMethod(valueAsSelected);
            setSelectedPaymentType(typeAsSelected);
            setSelectedPaymentData(currentRecord);
            if (setPaymentType)
                setPaymentType(typeAsSelected)
        }
        console.info('valueAsSelected', achPays, ccPas)
    }, [achPays.length, ccPas.length]);

    //Did Mount
    useEffect(() => {
        dispatch(getPaymentMethods({
            entityTypeId,
            sourceOwnerId
        }));

    }, []);


    //
    const getTransactionFee = () => {
        const { paymentType = '', payType = paymentType && (paymentType === 1) ? 'ACH' : 'CC', cardType = '' } = selectedPaymentData;
        // let pofind = LineItems.find(x => x.productTypeId === selectedFeeType)
        // feeTypeID, payType, cardType
        console.info(LineItems, 'LineItems', selectedPaymentData)
        let LineItemDataWithoutFee = LineItems.filter(x => x.lineTypeID != 5)
        let total = 0
        LineItemDataWithoutFee.map((rec) => total += rec.amount)
        if (total > 0) {
            dispatch(GetTransactionFee(total, feeTypeID, payType, cardType));
        }


    }


    const { paymentType = '' } = selectedPaymentData; // 1- Bank Account 2- Card

    let conditionalUserData = paymentType ? (paymentType === 1) ?
        [{ fieldName: "BankName", fieldValue: selectedPaymentData.bankName },
        { fieldName: "AccountHolderName", fieldValue: selectedPaymentData.accountHolderName, },
        { fieldName: "AccountType", fieldValue: selectedPaymentData.accountType },
        { fieldName: "RoutingNumber", fieldValue: selectedPaymentData.routingNumber },
        { fieldName: "BankAccountNumber", fieldValue: `****${selectedPaymentData.bankAccountNumber}` },
        { fieldName: "amount", fieldValue: `$${fixedFloatTwoDigit(total)}` },
        { fieldName: "BillingAddress1", fieldValue: selectedPaymentData.billAddress },
        { fieldName: "BillingCity", fieldValue: selectedPaymentData.billCity },
        { fieldName: "BillingState", fieldValue: selectedPaymentData.billState },
        { fieldName: "BillingZip", fieldValue: selectedPaymentData.billZip },
        { fieldName: "BillingPhoneNumber", fieldValue: selectedPaymentData.phone },
        { fieldName: "BillingEmail", fieldValue: selectedPaymentData.email },
        { fieldName: "AppName", fieldValue: getAppName() },
        { fieldName: "country", fieldValue: 'US' },
        { fieldName: "Status", fieldValue: 'Dues' },
        { fieldName: "Total", fieldValue: `$${fixedFloatTwoDigit(total)}` },
        { fieldName: "PaymentFor", fieldValue: paymentFor },
        { fieldName: "CreateDate", fieldValue: DateTimeFormat(new Date(), 'MM/DD/YYYY') }]
        :
        [{ fieldName: "AccountHolderName", fieldValue: selectedPaymentData.accountHolderName },
        { fieldName: "ExpirationDate", fieldValue: `${selectedPaymentData.expirationDate.substring(4, 6)}/${selectedPaymentData.expirationDate.substring(0, 4)}`, },
        { fieldName: "CardType", fieldValue: selectedPaymentData.cardType },
        { fieldName: "CardNumber", fieldValue: `****${selectedPaymentData.cardNumber}` },
        { fieldName: "amount", fieldValue: `$${fixedFloatTwoDigit(total)}` },
        { fieldName: "BillAddress", fieldValue: selectedPaymentData.billAddress },
        { fieldName: "BillCity", fieldValue: selectedPaymentData.billCity },
        { fieldName: "BillState", fieldValue: selectedPaymentData.billState },
        { fieldName: "BillZip", fieldValue: selectedPaymentData.billZip },
        { fieldName: "Cvv", fieldValue: selectedPaymentData.cvv },
        { fieldName: "AppName", fieldValue: getAppName() },
        { fieldName: "country", fieldValue: 'US' },
        { fieldName: "Status", fieldValue: 'Dues' },
        { fieldName: "Total", fieldValue: `$${fixedFloatTwoDigit(total)}` },
        { fieldName: "CreateDate", fieldValue: DateTimeFormat(new Date(), 'MM/DD/YYYY') }]


        : [];

    const dialogProps = {
        dialogFlag, dialogToggleFunc, content: paymentTerms,
        documentFormId: (paymentType === 1) ? ACH_AUTHORIZATION_DOCUMENT_FORM_ID : CC_AUTHORIZATION_DOCUMENT_FORM_ID,
        userData: conditionalUserData,
        callBackPdfFromSignDocument
    };
    // documentFormId:DocumentFormID = 0, organizationId = 0, documentTransactionId = 0
    const signpaddialogProps = {
        dialogFlag, dialogToggleFunc,
        //  content: paymentTerms,
        documentData: {
            // documentName,
            // documentUrl,
            // documentId,
        //  documentTransactionId:  //documentTransactionID, //34988,  //add these to work properly
            // organizationId,
            // dtsid: 0,
            documentFormId: (paymentType === 1) ? ACH_AUTHORIZATION_DOCUMENT_FORM_ID : CC_AUTHORIZATION_DOCUMENT_FORM_ID,
        },
        fieldData: conditionalUserData,
        callBackPdfFromSignDocument,
        needSignQueue: false,
        SourceOwnerID: parseInt(sourceOwnerId)
    };
    // console.log(signpaddialogProps.documentData.documentFormId,"DOCID");

    //Formik
    const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, setFieldValue } = useFormik({
        initialValues: {
            acceptTerms: false
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values) => {
            saveFormValues(values)
        }
    });

    const { acceptTerms = false } = values;
    const payMethodCrudProps = {
        selectedPaymentMethod, currentInformationSource, resetSelectedPaymentMethodCallBack,
        handleSelectedPaymentMethodCallBack, selectedFromListCallBack,
        showPaymentMethodCreateTypes: [BANK_ACCOUNT_TYPE, CREDIT_CARD_TYPE], // 1 - Bank Account 2 - Credit Card,
        chooseFromListLabel: 'Please select your payment method',
        entityTypeId, sourceOwnerId,
        getTransactionFee
    };

    return (
        <Fragment>
            {pageLoading && <CustomPageLoading flag={pageLoading} />}

            {/* Terms and Conditions Dialog  */}
            {dialogFlag &&
                <SignPadDialogue {...signpaddialogProps} ></SignPadDialogue>
            }
            {/* Terms and Conditions Dialog  */}


            {(parseInt(paymentInformationStep) === 0) ?
                <Fragment>
                    {/* <Grid item xs={12}>
                        <Typography variant="h6" className={classes.formHeader}>
                            Payment Information
                        </Typography>
                    </Grid> */}

                    <CommonPaymentMethodCRUD {...payMethodCrudProps} />
                    {BackTo &&
                        <Grid item xs={12}>
                            <Button variant="outlined" color="secondary" className={classes.skipButton} onClick={BackTo}>
                                Back
                            </Button>
                        </Grid>
                    }
                </Fragment>
                :
                <Fragment>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            {paymentTerms &&
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className={classes.formHeader}>
                                            Payment Terms
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="body2" className={classes.ellipsesContent}
                                        //onClick={dialogToggleFunc}
                                        >
                                            <div dangerouslySetInnerHTML={createMarkup(paymentTerms)} />
                                        </Typography>
                                    </Grid>
                                </Fragment>
                            }
                            {/* {!acceptTerms ? */}
                            <Grid item xs={12} sm={12} md={12} style={{ padding: '12px 12px 0px 12px', marginTop: '1rem' }}>
                                <Alert severity="warning">
                                    <AlertTitle>
                                        Please agree to the Terms and Conditions before processing Payment
                                    </AlertTitle>
                                </Alert>
                            </Grid>
                            {/* : ''} */}
                            <Grid item xs={12} sm={12} md={12} style={{ padding: '0px 12px 12px 12px', marginTop: '1rem' }}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={downloadableSignedPdf ? 6 : 12} >
                                        <FormControl required error={errors.acceptTerms && touched.acceptTerms} component="fieldset" className={classes.formControl} style={{ paddingLeft: '12px' }}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<CustomCheckbox
                                                        checked={acceptTerms}
                                                        onChange={(event) => {
                                                            const { checked } = event.target;
                                                            setFieldTouched('acceptTerms', true);
                                                            handleChange(event);
                                                            if (checked) {
                                                                dialogToggleFunc(false);
                                                            } else {
                                                                setDownloadablePdf(null);
                                                            }
                                                        }}
                                                        name="acceptTerms" color="primary" />}
                                                    label="Terms and Conditions"
                                                />
                                            </FormGroup>
                                            {/* <FormHelperText className={((errors.acceptTerms && touched.acceptTerms) || !acceptTerms) ? classes.terms_and_cond : ''}>
                                                {((errors.acceptTerms && touched.acceptTerms) || !acceptTerms) ? <div className={classes.leftAlign}><PriorityHighIcon /></div> : ''}
                                                {((errors.acceptTerms && touched.acceptTerms) || !acceptTerms) ? <div>{errors.acceptTerms}</div> : ''}
                                            </FormHelperText> */}
                                        </FormControl>
                                    </Grid>


                                    {downloadableSignedPdf &&
                                        <Grid item xs={12} sm={12} md={6} style={{ display: 'block', padding: '13px 0 0 13px' }} >
                                            <Tooltip placement="top" title="Download Document">
                                                <IconButton edge="end" aria-label="download"
                                                    // data-name={name}
                                                    // data-url={documentUrl}
                                                    // data-file_extension={fileExtension}
                                                    onClick={downloadPdf}
                                                    className={classes.vieweBtn}>
                                                    <GetAppIcon style={{ fontSize: 15 }} />
                                                </IconButton>
                                            </Tooltip>
                                            <FormLabel>
                                                Download Terms and Conditions
                                            </FormLabel>
                                        </Grid>
                                    }
                                </Grid>

                            </Grid>

                            <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                            <Grid item xs={10} sm={8} md={5}>
                                <List dense={true}>
                                    <center>
                                        {getSelectedPaymentMethod()}
                                    </center>
                                </List>
                            </Grid>
                            <Grid item xs={2} sm={2} md={3}>
                                <List dense={true}>
                                    <center>

                                        <Tooltip title="Edit Payment Method">
                                            <Button variant='contained' onClick={backToPaymentMethod} className={classes.continuebtn2}>
                                                {isMobileScreen() ? "Edit" : "Edit Payment Method"}
                                                {/* {buttonLabel} */}
                                            </Button>
                                            {/* <IconButton aria-label="delete" size="small" className={classes.backArrow}>
                                        <EditIcon onClick={backToPaymentMethod} />
                                    </IconButton> */}
                                        </Tooltip>
                                    </center>
                                </List>

                            </Grid>
                            <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />

                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.formHeader}>
                                    Payment Summary
                                </Typography>
                            </Grid>

                            <Grid item xs={1} sm={1} md={2} component={Box} display={{ xs: "block", md: 'block', sm: "block" }} />
                            <Grid item xs={10} sm={10} md={8}>
                                <div className={classes.demo}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <CustomTableHeader {...tableHeadProps} />
                                            <TableBody>
                                                {LineItems.map((row) => (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {row.description}<br />
                                                            <Typography variant="caption" display="block" gutterBottom>
                                                                {row.description2}</Typography>
                                                            <p className={classes.subtextStyle}>{row.per ? row.per : ''}</p>
                                                        </TableCell>
                                                        <TableCell align="right">{(parseInt(row.quantity) > 0) ? row.quantity : ''}</TableCell>
                                                        <TableCell align="right">${fixedFloatTwoDigit(row.amount)}</TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell component="th" scope="row" >
                                                        <span style={{ fontWeight: '700' }}>   Total</span>
                                                    </TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"><span style={{ fontWeight: '700' }}>${fixedFloatTwoDigit(total)}</span></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Grid>
                            <Grid item xs={1} sm={1} md={2} component={Box} display={{ xs: "block", md: 'block', sm: "block" }} />
                            <Grid item xs={12}>
                                {errorMessage !== null ?
                                    <Alert severity="warning">
                                        <AlertTitle>
                                            {errorMessage}
                                        </AlertTitle>
                                    </Alert>
                                    : ''}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={disableButtonFlag}
                                    type='submit' variant='contained' className={classes.continuebtn}>
                                    {buttonLabel}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="outlined" color="secondary" className={classes.skipButton} onClick={backToPaymentMethod}>
                                    Back
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Fragment>
            }

        </Fragment>
    );
}