import { ProspectsGetApplicationStatus } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetProspectsApplicationStatusReducer(state = ProspectsGetApplicationStatus,action){
    const {type,payload} = action;

    switch (type) {
        case types.GET_PROSPECT_APPLICATION_STATUS_LOADING:
            return {...state,loading:true,error:false};  

        case types.GET_PROSPECT_APPLICATION_STATUS_SUCCESS:
            return {...state,...payload,loading:false,error:false}; 
            
        case types.GET_PROSPECT_APPLICATION_STATUS_ERROR:
            return {...state,...payload,loading:false,error:true};      
            
        default:
            return state
    }
}



