// Common
export const LAYOUT_INITIAL_PATH = "/";
export const PAGE_NOT_FOUND_PATH = "*";
// AuthLayout
export const AUTH_LAYOUT_PATH = "/auth";
export const PERSON_LAYOUT_PATH = "/school";
export const PROSPECT_LAYOUT_PATH = "/intake";
export const SUPPORTER_LAYOUT_PATH = "/supporter";
export const LOGIN_PATH = "/auth/login";
export const SIGNUP_PATH = "/auth/signup";
export const PERSON_LOGIN_PATH = "/school/login";
export const PROSPECT_LOGIN_PATH = "/intake/login/:orgid";
export const CREATE_PROFILE_PATH = "/createprofile";
export const ENTITY_CHECKIN_PATH = "/planningdocument/checkin/:eventid";
export const ENTITY_CHECKINBYORG_PATH = "/planningdocument/checkinbyorg/:orgid";
export const SUPPORTER_LOGIN_PATH = "/supporter/login";
export const SIGNUP_STEPS_PATH = "/auth/SignupSteps";
export const FORGOT_PASSWORD_PATH = "/auth/forgotpassword";
export const PERSON_FORGOT_PASSWORD_PATH = "/school/forgotpassword";
export const PROSPECT_FORGOT_PASSWORD_PATH = "/intake/forgotpassword";
// AdminLayout
export const ADMIN_LAYOUT_PATH = "/admin";
export const HOME_PATH = "/home";
export const DASHBOARD_PATH = "/dashboard";
export const SCHOOL_DASHBOARD_PATH = "/schooldashboard";
export const SUPPORTER_DASHBOARD_PATH = "/supporterdashboard";
export const CANDIDATE_DASHBOARD_PATH = "/intakeprospect";
export const CANDIDATE_APPLICATION_PATH = "/candidateApplication/:id";
export const CANDIDATE_SIGN_AGREEMENT_PATH = "/candidateSignAgreement/:id";
export const EDIT_PROFILE_PATH = "/profile";
export const MY_CHAPTER_PATH = "/mychapter";
export const ADD_EDIT_CHAPTER_PATH = "/editchapter/:id";
export const LOCAL_INTAKE_SEASON_PATH = "/intakeseason/:id";
// export const ADMIN_GROUP_PATH = "/admingroup/:id";
export const ADMIN_GROUP2_PATH = "/adminGroup2/:id";

export const CHAPTERS_PATH = "/chapters";
export const CHAPTER_DETAILS_PATH = "/chapters/:id";
//Auth User
export const CHANGE_PASSWORD_PATH = "/changepassword";
export const PAYMENT_PLANS_PATH = "/paymentplans";
export const PAYMENT_PLAN_PATH = "/paymentplan";
export const MY_FINANCE_ACCOUNT_PATH = "/ledger";
export const PRODUCTS_PATH = "/products";
export const PAYMENT_PLAN_ADMIN_PATH = "/paymentPlanAdmin";
export const SETTINGS_PATH = "/settings";
export const REQUIRED_DOCUMENTS = "/requireddocuments"
export const APPROVAL_STEPS_PATH = "/approvalsteps/:id";
export const ADD_EDIT_DOCUMENT_FORM_PATH = "/documentform/:id";
export const ADD_EDIT_APPROVAL_STEP_PATH = "/editapprovalstep/:dfid/:id";
export const SIGNERS_PATH = "/signers/:dfid/:asid";
export const MEMBERS_PATH = "/members";
export const MEMBER_DETAILS_PATH = "/members/:id";
export const REGIONS_PATH = "/regions";
export const CANDIDATES_PATH = "/candidates";
export const CANDIDATE_DETAIL_PATH = "/candidates/:id";
export const REFERENCE_LETTER_PATH = "/referenceletter";
export const SCHOOLS_PATH = "/schools";
export const FORM_CENTER_PATH = "/formCenter";
export const OFFICER_REPORT_PATH = "/officerReport/:id";
export const REGION_DUES_PATH = "/regiondues/:id";
export const ADD_EDIT_COLLEGEOFFICIAL_PATH = "/editcollegeofficials/:id";
export const MEMBER_TIMELINE_PATH = "/dashboard/timeLine";
export const NATIONAL_CHAPTER_ROSTER_PATH = "/chapterRoster/:id";
export const CHAPTER_ROSTER_PATH = "/dashboard/chapterRoster/:id";
export const REGION_ROSTER_PATH = "/dashboard/regionRoster/:id";
export const REGION_ROSTER_ADMIN_PATH = "/regionRoster/:id";
export const CHAPTER_DOCUMENT_LIST_PATH = "/dashboard/documentList";
export const ACTIVITY_PATH = "/dashboard/activity";
export const CHAPTER_EVENT_LIST_PATH = "/dashboard/eventList";
export const CANDIDATE_LIST_PATH = "/dashboard/intakeseason/:id";
export const ADMIN_GROUP2_LIST_PATH = "/dashboard/adminGroup2/:id";


export const CHAPTER_ADMIN_PATH = "/dashboard/chapteradmin";
export const INTAKE_SEASON_PATH = "/dashboard/intakeseason";
export const EDIT_CHAPTER_PATH = "/dashboard/editchapter/:id";
export const CHAPTER_OFFICER_REPORT_PATH = "/dashboard/officerReport/:id";
export const LOCAL_REGION_DUES_PATH = "/dashboard/regiondues/:id";

export const ADD_EDIT_Position_PATH = "/editposition/:id";
export const EVENTS_PATH = "/events";
export const EVENT_DETAILS_PATH = "/eventdetails/:id";
export const EVENT_CHECKOUT_PATH = "/eventcheckout/:id";
export const ADD_EDIT_EVENTS_PATH = "/editevent/:id";
export const ADD_EDIT_TICKET_PATH =
  "/eventdetails/:event_Id/editticket/:ticket_id";
export const TICKETS_PATH = "/eventdetails/:event_Id/tickets";
export const IMAGES_PATH = "/eventdetails/:event_Id/images";
export const DOCUMENT_TRANSACTION_PATH = "/documents";
export const SUPPORTER_SIGNUP_PATH = "/supporter/signup";
export const NATIONAL_INTAKESEASON_PATH = "/intakeseason";
export const REGIONAL_INTAKESEASON_PATH = "/rintakeseason/:id";
export const MY_DOCUMENT_PATH = "/mydocuments";
export const ADD_EDIT_PLANNING_DOCUMENT_PATH = "/planningDocumetDetails/:id";
export const REGIONAL_DASHBOARD_PATH = "/regionaldashboard";
export const DELEGATE_FORM_PATH = "/delegateForm";

//Chapter Certification
export const CHAPTER_CERTIFICATION_FORM_PATH = "/chapterCertification/:id"
export const CHAPTER_CERTIFICATION_REPORT_PATH ='/dashboard/chapterCertification/:id'
export const CHAPTER_CERTIFICATION_SEASON = '/chapterCertificationSeason'
export const CHAPTER_CERTIFICATION_SEASON_DETAILS = '/chapterCertificationDetails/:id'
export const TEST_COMPONENT_UG_MEM_CONFORMATION = "/ugMemberConfirmation/:id"

//chapter Documentation
export const CHAPTER_DOCUMENTATION = '/chapterDocumentation/:id'
export const CHAPTER_DOCUMENTATION_REPOR_PATH = '/dashboard/chapterDocumentation/:id'



export const HELP_PATH = "/help";


export const CHAT_PATH = "/chat"

