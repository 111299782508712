import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { LOGIN_PATH, PERSON_LOGIN_PATH, PROSPECT_LOGIN_PATH, SUPPORTER_LOGIN_PATH } from '../constants/RoutePaths';
import {
  LOCALSTORAGE_AUTHTOKEN,
  LOCALSTORAGE_PROFILE,
  LOCALSTORAGE_AUTH_MEMBER_ID,
  LOCALSTORAGE_SIGNUP_STEPS_FORM_DATA,
  LOCALSTORAGE_AUTHUSER,
  LOCALSTORAGE_ENTITYTYPEID,
  LOCALSTORAGE_PRIMARY_COLOR,
  LOCALSTORAGE_APP_NAME,
  LOCALSTORAGE_PARENT_ORGANIZATION_NAME,
  LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID,
  LOCALSTORAGE_AUTH_PROSPECT_ID,
  LOCALSTORAGE_PROSPECT_PROFILE,
  LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_NAME,
  LOCALSTORAGE_PROSPECT_PLANNING_DOCUMENT_ID,
  LOCALSTORAGE_SOURCEOWNERID,
  LOCALSTORAGE_CURRENT_ORGANIZATIONID,
  LOCALSTORAGE_SUPPORTER_DETAILS
} from '../constants/LocalStorage';
import { GET_ORGANIZATION_DETAILS_FOR_PROSPECT_SUCCESS, GET_PROSPECT_DETAILS_SUCCESS } from '../redux/actions/ActionTypes';

//#rbp Auth Header with Token
export function authHeader() {
  const authToken = loggedTokenDetails(); // Get Token from LocalStorage
  return { Authorization: `Bearer ${authToken}` };
}

export function authMultipartHeader() {
  const authToken = loggedTokenDetails(); // Get Token from LocalStorage
  return { Authorization: `Bearer ${authToken}`, 'content-type': 'multipart/form-data' };
}

//#rbp Auth Logout
export function logout(history) {
  const localStorageToClear = [
    LOCALSTORAGE_AUTHTOKEN, LOCALSTORAGE_PROFILE, LOCALSTORAGE_SIGNUP_STEPS_FORM_DATA,
    LOCALSTORAGE_AUTHUSER, LOCALSTORAGE_AUTH_MEMBER_ID, LOCALSTORAGE_CURRENT_ORGANIZATIONID
  ];
  localStorageToClear.map(element => localStorage.removeItem(element));

  if (history) {
    if (localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID) == 2) {
      localStorage.removeItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID)
      history.push(LOGIN_PATH);
    }
    if (localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID) == 7) {
      localStorage.removeItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID)
      history.push(PERSON_LOGIN_PATH);
    }
    if (localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID) == 4) {
      localStorage.removeItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID)
      history.push(SUPPORTER_LOGIN_PATH);
    }
    if (localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID) == 11) {

      let organizationId = localStorage.getItem(LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID)
      history.push(`/intake/login/${organizationId}`);
      localStorage.removeItem(LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID)
      // localStorage.removeItem(LOCALSTORAGE_AUTH_PROSPECT_ID)
      localStorage.removeItem(LOCALSTORAGE_PROSPECT_PROFILE)
      localStorage.removeItem(LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_NAME)
      localStorage.removeItem(LOCALSTORAGE_PROSPECT_PLANNING_DOCUMENT_ID)
      localStorage.removeItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID)

    }

  }
  else {
    if (localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID) == 2) {

      const path = `${window.location.origin}${LOGIN_PATH}`;
      window.location = path;
      localStorage.removeItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID)

    }
    if (localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID) == 7) {
      const path = `${window.location.origin}${PERSON_LOGIN_PATH}`;
      window.location = path;
      localStorage.removeItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID)

    }
    if (localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID) == 4) {
      const path = `${window.location.origin}${SUPPORTER_LOGIN_PATH}`;
      window.location = path;
      localStorage.removeItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID)

    }
    if (localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID) == 11) {
      const path = `${window.location.origin}${PROSPECT_LOGIN_PATH}`;
      window.location = path;
      localStorage.removeItem(LOCALSTORAGE_CURRENT_ORGANIZATIONID)
    }

  }
  window.location.reload()
}

//#rbp Logged Token Details
export function loggedTokenDetails() {
  let authToken = localStorage.getItem(LOCALSTORAGE_AUTHTOKEN) || null;
  return authToken;
}

//#rbp Logged User Details
export function loggedUserDetails() {
  let sessionData = localStorage.getItem(LOCALSTORAGE_PROFILE);
  sessionData = JSON.parse(sessionData) || {};
  return sessionData;
}

export function loggedSupporterDetails() {
  let sessionData = localStorage.getItem(LOCALSTORAGE_SUPPORTER_DETAILS);
  sessionData = JSON.parse(sessionData) || {};
  return sessionData;
}

export function isAdmin(groupid, organizationId) {
  let sessionData = localStorage.getItem(LOCALSTORAGE_PROFILE);
  sessionData = JSON.parse(sessionData) || {};
  let { Details = {} } = sessionData || {};
  let { Groups = [] } = Details || {};
  if (Groups && Groups.length > 0) {
    let find = Groups.find(x => parseInt(x.GroupID) === parseInt(groupid) && parseInt(x.GroupOrganizationID) === parseInt(organizationId))
    if (find !== undefined) {
      return true;
    }
  }
  return false;
}

export function isAdminByGroup(groupid, organizationID) {
  let sessionData = localStorage.getItem(LOCALSTORAGE_PROFILE);
  sessionData = JSON.parse(sessionData) || {};
  let { Details = {} } = sessionData || {};
  let { Groups = [] } = Details || {};
  // console.info(Groups, 'Groups')
  if (Groups && Groups.length > 0) {
    let find = Groups.find(x => parseInt(x.GroupID) === parseInt(groupid) && parseInt(x.GroupOrganizationID) === parseInt(organizationID))
    if (find !== undefined) {
      return true;
    }
  }
  return false;
}

export function getOrganizationTimezone() {
  let sessionData = localStorage.getItem(LOCALSTORAGE_PROFILE);
  sessionData = JSON.parse(sessionData) || {};
  let { Organization = {} } = sessionData || {};
  let { timeZoneId = 0 } = Organization || {};
  return timeZoneId;
}

export function getOrganizationID() {
  let sessionData = localStorage.getItem(LOCALSTORAGE_PROFILE);
  sessionData = JSON.parse(sessionData) || {};
  let { Organization = {} } = sessionData || {};
  let { organizationId = 0 } = Organization || {};
  return organizationId;
}

//#rbp Logged Prospect Details
export function loggedProspectDetails() {
  let prospectData = localStorage.getItem(LOCALSTORAGE_PROSPECT_PROFILE);
  prospectData = JSON.parse(prospectData) || {};
  return prospectData;
}


//getParentOrganizationID
export function getParentOrganizationID() {
  return parseInt(process.env.REACT_APP_PARENTORGANIZATION_ID);
}

//getPrimaryColor
export function getPrimaryColor() {
  return localStorage.getItem(LOCALSTORAGE_PRIMARY_COLOR);
}

//getAppName
export function getAppName() {
  return localStorage.getItem(LOCALSTORAGE_APP_NAME);
}

export function getOrganizationName() {
  return localStorage.getItem(LOCALSTORAGE_PARENT_ORGANIZATION_NAME);
}

//Get Logged User Member Id
export function getLoggedUserMemberId() {
  return parseInt(localStorage.getItem(LOCALSTORAGE_AUTH_MEMBER_ID));
}

export function getLoggedEntityTypeID() {
  return parseInt(localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID));
}

export function getLoggedSourceOwnerID() {
  return parseInt(localStorage.getItem(LOCALSTORAGE_SOURCEOWNERID));
}

export function getDocumentTransactionStatus(statusID) {
  var statusName = ""
  if (statusID === 1) {
    statusName = "Pending"
  }
  else if (parseInt(statusID) === 2) {
    statusName = "Submitted"
  }
  else if (parseInt(statusID) === 3) {
    statusName = "Approved"
  }
  else if (parseInt(statusID) === 4) {
    statusName = "Denied"
  }
  else if (parseInt(statusID) === 5) {
    statusName = "Archived"
  }
  else {
    statusName = "Incomplete"
  }
  return statusName;
}

//#rbp Authenticate Public Routes
export function PublicRoute({ component, ...rest }) {
  // return <Route {...rest} component={component} />
  const authToken = loggedTokenDetails();
  if (!authToken) return <Route {...rest} component={component} />;
  else return <Redirect to={{ pathname: '/' }} />;
}

//#rbp Authenticate Admin Routes
export function ProtectedRoute({ component, ...rest }) {
  const authToken = loggedTokenDetails();
  if (authToken) return <Route {...rest} component={component} />;
  else return <Redirect to={{ pathname: LOGIN_PATH }} />;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export const fileTypeToStore = {
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'application/pdf': 'pdf',
  'application/msword': 'doc'
};

export const copyToClipboard = (text) => {
  const el = document.createElement("textarea");
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);

  el.select();
  const result = document.execCommand("copy");
  document.body.removeChild(el);

  return result;
};