import React, { Fragment, memo } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles(theme => ({
  buttonProgress: {
    top: "77%",
    left: "84%",
    color: "royalblue",
    position: "absolute"
  }
}));
function ConfirmationDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    conDigFlag,
    conDigTitle,
    conDigContent,
    deleteConfirmationToggle,
    handleConfirmation,
    buttonLoading,
    conformYesBtnname = '',
    conformNoBtnname = ''
  } = props;
  return (
    <Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        // fullScreen={fullScreen}
        open={conDigFlag}
        onClose={deleteConfirmationToggle}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{conDigTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{conDigContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={deleteConfirmationToggle} color="primary">
            
            {conformNoBtnname ? `${conformNoBtnname}` : 'No'}
          </Button>
          <Button onClick={handleConfirmation} color="primary">
            {conformYesBtnname ? `${conformYesBtnname}` : 'Yes'}
          </Button>
          {buttonLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(ConfirmationDialog);
