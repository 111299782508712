import { exportinitialGetCandidateList } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetExportCandidatesListReducer(state = exportinitialGetCandidateList,action){
    const {type,payload} = action;

    switch (type) {
        case types.GET_EXPORT_CANDIDATES_LIST_LOADING:
            return {...state,loading:true,error:false};  

        case types.GET_EXPORT_CANDIDATES_LIST_SUCCESS:
            return {...state,...payload,loading:false,error:false}; 
            
        case types.GET_EXPORT_CANDIDATES_LIST_ERROR:
            return {...state,loading:false,error:true};      
            
        default:
            return state
    }
}

