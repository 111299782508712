import { commonListingObjectInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetQuestionsBySectionReducer(state = commonListingObjectInitialValues, action) { 
    const { type, payload } = action;
    switch (type) { 
        case types.GET_QUESTIONS_BY_SECTION_LOADING:
            return {loading:true, data:{}, error: false };
        case types.GET_QUESTIONS_BY_SECTION_SUCCESS:
            return {data:payload,loading:false, error: false};
        case types.GET_QUESTIONS_BY_SECTION_ERROR:
            return {loading:false, error: true, data: payload};
        default:
            return state;
    }
}