import { initialMessages } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function  messagesReducer  (state = [...initialMessages], action) {
    const { type, payload } = action;
    switch (type) {
        case types.ADD_MESSAGE:
            return [...state, payload];
        default:
            return state;
    }
};  