import jsPDF from "jspdf";
import "jspdf-autotable";
import { getLoggedSourceOwnerID, getLoggedUserMemberId, getParentOrganizationID } from "./CoreFunctions";
import { DateTimeFormat, FormatToAmount } from "./CustomFunctions";
import "../assets/fonts/VerdanaNormal";
import "../assets/fonts/VerdanaBoldNormal";
import { LOCALSTORAGE_PARENT_ORGANIZATION_NAME } from "../constants/LocalStorage";

export const GenerateOfficerReportPDF = (props) => {
  const currentMember = getLoggedUserMemberId();
  let signPosition = { x: 300, y: 100 };
  const {
    currentDate,
    ParentOrganizationName,
    officerReportData = {},
    currentSign = null,
  } = props;
  const {
    OrganizationTypeID = 0,
    LeadershipOrganizationName = "",
    OrganizationName = "",
    organizationAddress = "",
    organizationAddress2 = "",
    DocumentFormDetails = {},
    SetOfficersReport = {},
    meetinglocation = {},
    officerReportSignQueue = [],
    EntityGovernance = [],
  } = officerReportData;
  const { documentContent = "", documentName = "" } = DocumentFormDetails || {};
  const {
    dayName = "",
    weekName = "",
    time,
    meetingLocation = "",
  } = meetinglocation || {};
  const { detail: officerDetails = {} } = SetOfficersReport;
  const { positions = [], currentRosterReport = {} } = officerDetails || {};
  const { memberList = [] } = currentRosterReport || {};
  let contentToDisplay = documentContent || ''
    .replaceAll("↵", "")
    .replaceAll("’", "'")
    .replaceAll("“", '"')
    .replaceAll("”", '"');

  const pdf = new jsPDF("P", "px", "a4");
  let pageHeight = pdf.internal.pageSize.height;
  pdf.setFont("Verdana");
  pdf.setFontSize(16);
  pdf.text(ParentOrganizationName, 140, 40);

  pdf.setFontType("bold");
  pdf.setFontSize(10);
  pdf.text(LeadershipOrganizationName, 180, 60);

  pdf.setFontSize(11);
  pdf.setFontType("bold");
  pdf.text(documentName, 15, 100);
  pdf.text(currentDate, 15, 115);

  pdf.setFontType("regular");
  pdf.setFontSize(13);

  pdf.fromHTML(
    contentToDisplay, // HTML string or DOM elem ref.
    15, // x coord
    125,
    {
      // y coord
      width: 400, // max width of content on PDF
    }
  );
  //1120 character is good for 15,125 coordinates, if we have 2240 character then 
  // console.info(contentToDisplay.length / 80, 'finalY', (contentToDisplay.length / 80) * 10 + 125)
  let ycoord = (contentToDisplay.length / 80) * 10 + 125
  if (ycoord > pageHeight) {
    ycoord = ycoord - pageHeight
  }
  if (contentToDisplay.length > 80 * 30) {
    ycoord = 10
    pdf.addPage();
  }
  console.info(ycoord, 'ycoord', contentToDisplay.length, 80 * 30)
  var yValue = ycoord + 20;
  pdf.setFontSize(11);
  pdf.setFontType("bold");
  pdf.text(OrganizationName, 15, yValue);
  pdf.setFontType("regular");
  pdf.text(organizationAddress, 15, yValue + 10);
  pdf.text(organizationAddress2, 15, yValue + 20);

  pdf.setFontSize(11);
  pdf.setFontType("bold");
  pdf.text("Formal Chapter Meeting Information", 250, yValue);
  pdf.setFontType("regular");
  pdf.text(weekName.replace("Week", dayName) + " @ " + time, 250, yValue + 10);
  pdf.text(meetingLocation !== null ? meetingLocation : '', 250, yValue + 20);
  let finalY = 0;
  if (
    parseInt(OrganizationTypeID) === 3 &&
    memberList &&
    memberList.length === 0
  ) {
    pdf.setFontSize(11);
    pdf.setFontType("bold");
    pdf.text(
      "OFFICER REPORT SUBMITTED WITH NO ACTIVE MEMBERS OR OFFICERS IN CHAPTER.",
      15,
      yValue + 40
    );
    finalY = yValue + 50;
  } else {
    var col = ["OFFICER", "MEMBERSHIP#", "NAME", "ADDRESS", "CONTACT"];
    var rows = [];
    positions
      .filter((x) => parseInt(x.nonApplicableStatus) !== 3)
      .forEach((element) => {
        var temp = [
          element.name,
          element.membershipNumber,
          element.memberName,
          element.memberAddress,
          element.memberContact + " " + element.memberEmail,
        ];
        rows.push(temp);
      });
    pdf.autoTable(col, rows, {
      theme: "plain",
      startX: 0,
      startY: yValue + 40,
    });
    finalY = pdf.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable
  }
  pdf.setFontSize(11);
  pdf.setFontType("bold");
  pdf.text(
    "EXTERNAL ENTITY GOVERNANCE CERTIFICATION-NO FOUNDATION INFORMATION TO REPORT",
    15,
    finalY + 20
  );
  pdf.setFontSize(12);
  if (EntityGovernance.length > 0) {
    // const parentOrganizationName = localStorage.getItem(LOCALSTORAGE_PARENT_ORGANIZATION_NAME)
    pdf.setFontType("regular");
    var splitTitle = pdf.splitTextToSize(
      "We, the officers of the " +
      OrganizationName +
      " chapter understand, certify and agree that the information provided below is true and accurate to account for, register and document any and all external entities. The entities include the name or goodwill, which bears the likeness of any" + ParentOrganizationName + " registered intellectual properties or is reasonably thought by the public to be a " + ParentOrganizationName + " affiliate. It is very important to provide at least annual reports of the officers and entity address. It is acknowledged that any changes of officers or addresses should be reported immediately to the Executive Director at IHQ.",
      400
    );
    pdf.text(15, finalY + 40, splitTitle);
    finalY += 80;
    EntityGovernance.forEach((element, key) => {
      const {
        address = "",
        foundationEin = "",
        name = "",
        memberName = "",
        phone = "",
        memberEmail = "",
      } = element;
      pdf.setFontType("bold");
      pdf.text("Foundation Entity", 15, finalY + 30);
      pdf.setFontType("regular");
      pdf.text(name, 15, finalY + 40);
      pdf.text(address, 15, finalY + 50);
      pdf.text("EIN: " + foundationEin, 15, finalY + 60);

      pdf.setFontType("bold");
      pdf.text("Foundation Entity Board President/Chairman", 250, finalY + 30);
      pdf.setFontType("regular");
      pdf.text(memberName, 250, finalY + 40);
      pdf.text(memberEmail, 250, finalY + 50);
      pdf.text(phone, 250, finalY + 60);
      finalY += 60;
    });
  } else {
    pdf.setFontType("regular");
    pdf.text(
      "We, the officers of the " +
      OrganizationName +
      " chapter attest that the Chapter has not established a foundation.",
      15,
      finalY + 40
    );
    finalY += 40;
  }
  if (finalY >= pageHeight - 140) {
    pdf.addPage();
    finalY = 20; // Restart height position
  }
  pdf.setFontType("bold");
  pdf.text("SIGNATURES OF OFFICERS", 15, finalY + 30);
  pdf.setFontType("regular");
  let xPosition = 15;
  let yPosition = finalY;
  let xSignPosition = 50;
  let ySignPosition = 0;
  if (parseInt(OrganizationTypeID) === 1) {
    if (finalY == 20) {
      ySignPosition = finalY + 160;
    } else {

      ySignPosition = finalY + 380;
      // ySignPosition = finalY + 400;
    }
  } else if (
    parseInt(OrganizationTypeID) === 3 &&
    memberList &&
    memberList.length === 0
  ) {
    ySignPosition = finalY + 500;
  } else {
    ySignPosition = finalY + 500;
  }
  officerReportSignQueue.forEach((element, key) => {
    const {
      entityName,
      createDate,
      positionName = "",
      electronicSignature = "",
      statusId = 0,
      sourceOwnerId = 0,
    } = element;
    if (parseInt(statusId) === 4 && electronicSignature !== null) {
      var img = new Image();
      img.src = electronicSignature;
      if (electronicSignature.startsWith("data:")) {
        pdf.addImage(
          electronicSignature,
          "png",
          xPosition,
          yPosition + 65,
          100,
          20
        );
      }
    }
    if (
      parseInt(statusId) === 1 &&
      currentSign !== null &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      var img = new Image();
      img.src = currentSign;
      pdf.addImage(currentSign, "png", xPosition, yPosition + 65, 100, 20);
    }
    if (
      parseInt(statusId) === 1 &&
      currentSign === null &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      let currentPage = pdf.internal.getCurrentPageInfo().pageNumber;
      signPosition = {
        x: xSignPosition,
        y: ySignPosition,
        page_number: parseInt(currentPage),
      };
    }
    pdf.line(xPosition, yPosition + 90, xPosition + 125, yPosition + 90);

    console.info(signPosition, 'signPosition', xPosition, yPosition + 90, xPosition + 125, yPosition + 90)
    pdf.setFontSize(12);
    pdf.text(
      entityName + " - " + DateTimeFormat(createDate, "MM/DD/YYYY"),
      xPosition,
      yPosition + 110
    );
    pdf.text(positionName || "", xPosition, yPosition + 125);
    xPosition += 150;
    xSignPosition += 310;
    if ((key + 1) % 3 === 0) {
      // console.info(yPosition , pageHeight-200,'pageHeight')
      if (yPosition > pageHeight - 200) {
        pdf.addPage();
        yPosition = 20; // Restart height position
        xPosition = 15;
        xSignPosition = 50;
        ySignPosition += 180;
      } else {
        yPosition += 90;
        xPosition = 15;
        xSignPosition = 50;
        ySignPosition += 180;
      }
    }

  });
  return { pdf, signPosition };
};










export const GenerateChaperDocumentaionPDF = (props) => {
  const currentMember = getLoggedUserMemberId();
  let signPosition = { x: 300, y: 100 };
  const {
    type = "",
    startDate = "",
    endDate = "",
    eventName = "",
    documentContent = "",
    documentTransactionData = [],
    examSectionsData = [],
    eventInsuranceSignQueue = [],
    currentSign = null,
    chapterName = "",
    regionName = "",
    year = ""
  } = props;
  let contentToDisplay = documentContent
    .replace("**Year**", year)
    .replace("**RegionName**", regionName)
    .replace("**ChapterName**", chapterName)
  // .replaceAll("↵", "")
  // .replaceAll("’", "'")
  // .replaceAll("“", '"')
  // .replaceAll("”", '"')


  console.log(contentToDisplay, " contentToDisplay ");
  const pdf = new jsPDF("P", "px", "a4");
  let pageHeight = pdf.internal.pageSize.height;
  let pageWidth = pdf.internal.pageSize.width;
  // pdf.setFont("Helvetica");
  pdf.addFont("verdana.ttf", "Verdana", "normal");
  pdf.setFont("Verdana");
  pdf.fromHTML(
    contentToDisplay, // HTML string or DOM elem ref.
    15, // x coord
    15,
    {
      width: pageWidth, // max width of content on PDF, adjusted to avoid overflow
    }
  );
  let EventName = ""; //Event Name can set Chapter Document
  pdf.text(EventName, pageWidth / 2, 480, 'center');
  var x = 15;
  var y = 100;
  examSectionsData && examSectionsData.forEach((section, key) => {
    const { title = "", examSectionID = 0 } = section;
    pdf.setFontSize(20);
    pdf.setFontType("bold");
    pdf.setTextColor(147, 9, 20);
    pdf.text(title + ":", x, y);
    var l = pdf.getTextWidth(title);
    pdf.setLineDash([1, 1], 0);
    pdf.line(x, y + 5, x + l, y + 5);
    pdf.setTextColor(0, 0, 0);
    y += 15;
    documentTransactionData
      .filter((x) => parseInt(x.examSectionID) === parseInt(examSectionID))
      .forEach((element, key) => {
        const {
          questionText = "",
          questionTypeID = 0,
          freeTextAnswer = "",
          answerText = "",
          orderNumber = ''
        } = element;
        pdf.setFontSize(11);
        pdf.setFontType("regular");
        //pdf.text(questionText, x, y+10);
        var lineHeight = pdf.getLineHeight(questionText);
        var splittedText = pdf.splitTextToSize(
          orderNumber + ". " + questionText,
          parseInt(pageWidth) - 20
        );
        var lines = splittedText.length; // splitted text is a string array
        var blockHeight = lines * lineHeight;
        pdf.text(splittedText, x, y + 10);
        y += blockHeight;
        if (parseInt(questionTypeID) === 1) {
          // free text
          pdf.text(freeTextAnswer, x, y + 10);
        } else if (parseInt(questionTypeID) === 2) {
          //multiple choice
          pdf.text(answerText, x, y + 10);
        } else if (parseInt(questionTypeID) === 3) {
          //true or false
          pdf.text(answerText, x, y + 10);
        } else if (parseInt(questionTypeID) === 4) {
          //multiple choice with free text
          if (answerText === "Other") {
            pdf.text(freeTextAnswer, x, y + 10);
          } else {
            pdf.text(answerText, x, y + 10);
          }
        }
        pdf.line(x, y + 15, pageWidth - 30, y + 15);
        // pdf.setLineDash([0, 0], 0);
        pdf.setLineWidth(0.5);
        pdf.line(x, y + 15, pageWidth - 30, y + 15);
        y += 20;
        if (y >= pageHeight - 30) {
          pdf.addPage();
          y = 20; // Restart height position
        }
      });
    // y += 20;
    // if (y >= pageHeight - 70) {
    pdf.addPage();
    y = 20; // Restart height position
    // }
  });

  pdf.setFontSize(12);
  pdf.setFontType("bold");
  // var splitTitle = pdf.splitTextToSize(
  //   "By submitting this form, you agree the information is factual and truthful. In the event of policy violation, your organization may be asked to submit your guest list and other supporting documents as part of the conduct process.",
  //   parseInt(pageWidth) - 20
  // );
  // pdf.text(splitTitle, 15, y + 10);
  // pdf.setFontSize(11);
  // pdf.setFontType("regular");

  if (y + 20 >= pageHeight - 50) {
    pdf.addPage();
    y = 0; // Restart height position
  }

  let yPosition = y + 20;
  let ySignPosition = y + 150; //210
  eventInsuranceSignQueue.sort((a, b) => a.priority - b.priority);
  eventInsuranceSignQueue.forEach((signqueue, key) => {
    const {
      entityName = "",
      entityEmail = "",
      statusId = 0,
      electronicSignature = "",
      signedDate = "",
      positionName = "",
      sourceOwnerId = 0,
    } = signqueue;
    var textWidth = pdf.getTextWidth(positionName ? positionName : "Position Name");
    pdf.text(positionName ? positionName : "Position Name" + ":", 15, yPosition + 50);
    pdf.line(textWidth + 20, yPosition + 50, 245, yPosition + 50);
    if (parseInt(statusId) === 4 && electronicSignature !== null) {
      var img = new Image();
      img.src = electronicSignature;
      pdf.addImage(
        electronicSignature,
        "png",
        textWidth + 30,
        yPosition + 30,
        100,
        20
      );
    }
    if (
      parseInt(statusId) === 1 &&
      currentSign !== null &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      var img = new Image();
      img.src = currentSign;
      pdf.addImage(currentSign, "png", textWidth + 30, yPosition + 30, 100, 20);
    }

    if (
      parseInt(statusId) === 1 &&
      currentSign === null &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      let currentPage = pdf.internal.getCurrentPageInfo().pageNumber;
      signPosition = {
        x: textWidth + 130,
        y: ySignPosition,
        page_number: parseInt(currentPage),
      };
    }

    pdf.text("Printed Name:", 250, yPosition + 50);
    pdf.line(300, yPosition + 50, 430, yPosition + 50);
    if (parseInt(statusId) === 4) {
      pdf.text(entityName, 310, yPosition + 48);
    }
    if (
      parseInt(statusId) === 1 &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      pdf.text(entityName, 310, yPosition + 48);
    }

    pdf.text("Email:", 15, yPosition + 70);
    pdf.line(40, yPosition + 70, 225, yPosition + 70);
    if (parseInt(statusId) === 4) {
      pdf.text(entityEmail, 50, yPosition + 68);
    }
    if (
      parseInt(statusId) === 1 &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      pdf.text(entityEmail, 50, yPosition + 68);
    }

    pdf.text("Signature Date:", 230, yPosition + 70);
    pdf.line(285, yPosition + 70, 430, yPosition + 70);
    if (parseInt(statusId) === 4) {
      const currentDate = DateTimeFormat(signedDate, "MM/DD/YYYY");
      pdf.text(currentDate, 295, yPosition + 68);
    }
    if (
      parseInt(statusId) === 1 &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      const currentDate = DateTimeFormat(new Date(), "MM/DD/YYYY");
      pdf.text(currentDate, 295, yPosition + 68);
    }

    yPosition += 50;
    ySignPosition += 100;
  });

  return { pdf, signPosition };
};

export const GenerateEventInsurancePDF = (props) => {
  const currentMember = getLoggedUserMemberId();
  let signPosition = { x: 300, y: 100 };
  const {
    type = "",
    startDate = "",
    endDate = "",
    eventName = "",
    documentContent = "",
    documentTransactionData = [],
    examSectionsData = [],
    eventInsuranceSignQueue = [],
    currentSign = null,
  } = props;
  let contentToDisplay = documentContent
    .replaceAll("↵", "")
    .replaceAll("’", "'")
    .replaceAll("“", '"')
    .replaceAll("”", '"');

  const pdf = new jsPDF("P", "px", "a4");
  let pageHeight = pdf.internal.pageSize.height;
  let pageWidth = pdf.internal.pageSize.width;
  // pdf.setFont("Helvetica");
  pdf.addFont("verdana.ttf", "Verdana", "normal");
  pdf.setFont("Verdana");
  pdf.fromHTML(
    contentToDisplay, // HTML string or DOM elem ref.
    15, // x coord
    15,
    {
      // y coord
      width: pageWidth, // max width of content on PDF
    }
  );
  let EventName = ""; //Event Name
  if (type == "officerReport") {
    EventName = "Chapter Meetings";
  } else if (type == "planningDocument") {
    EventName = `${startDate} - ${endDate}  Planning Document`;
  } else if (type == "Event") {
    EventName = eventName;
  }
  pdf.text(EventName, pageWidth / 2, 480, 'center');
  var x = 15;
  var y = 500;
  examSectionsData && examSectionsData.forEach((section, key) => {
    const { title = "", examSectionID = 0 } = section;
    pdf.setFontSize(20);
    pdf.setFontType("bold");
    pdf.setTextColor(147, 9, 20);
    pdf.text(title + ":", x, y);
    var l = pdf.getTextWidth(title);
    pdf.setLineDash([1, 1], 0);
    pdf.line(x, y + 5, x + l, y + 5);
    pdf.setTextColor(0, 0, 0);
    y += 15;
    documentTransactionData
      .filter((x) => parseInt(x.examSectionID) === parseInt(examSectionID))
      .forEach((element, key) => {
        const {
          questionText = "",
          questionTypeID = 0,
          freeTextAnswer = "",
          answerText = "",
          orderNumber = ''
        } = element;
        pdf.setFontSize(11);
        pdf.setFontType("regular");
        //pdf.text(questionText, x, y+10);
        var lineHeight = pdf.getLineHeight(questionText);
        var splittedText = pdf.splitTextToSize(
          orderNumber + ". " + questionText,
          parseInt(pageWidth) - 20
        );
        var lines = splittedText.length; // splitted text is a string array
        var blockHeight = lines * lineHeight;
        pdf.text(splittedText, x, y + 10);
        y += blockHeight;
        if (parseInt(questionTypeID) === 1) {
          // free text
          pdf.text(freeTextAnswer, x, y + 10);
        } else if (parseInt(questionTypeID) === 2) {
          //multiple choice
          pdf.text(answerText, x, y + 10);
        } else if (parseInt(questionTypeID) === 3) {
          //true or false
          pdf.text(answerText, x, y + 10);
        } else if (parseInt(questionTypeID) === 4) {
          //multiple choice with free text
          if (answerText === "Other") {
            pdf.text(freeTextAnswer, x, y + 10);
          } else {
            pdf.text(answerText, x, y + 10);
          }
        }
        pdf.setLineDash([0, 0], 0);
        pdf.setLineWidth(0.5);
        pdf.line(x, y + 15, pageWidth - 30, y + 15);
        y += 20;
        if (y >= pageHeight - 30) {
          pdf.addPage();
          y = 20; // Restart height position
        }
      });
    // y += 20;
    // if (y >= pageHeight - 70) {
    pdf.addPage();
    y = 20; // Restart height position
    // }
  });

  pdf.setFontSize(12);
  pdf.setFontType("bold");
  var splitTitle = pdf.splitTextToSize(
    "By submitting this form, you agree the information is factual and truthful. In the event of policy violation, your organization may be asked to submit your guest list and other supporting documents as part of the conduct process.",
    parseInt(pageWidth) - 20
  );
  pdf.text(splitTitle, 15, y + 10);
  pdf.setFontSize(11);
  pdf.setFontType("regular");

  if (y + 20 >= pageHeight - 50) {
    pdf.addPage();
    y = 0; // Restart height position
  }

  let yPosition = y + 20;
  let ySignPosition = y + 150; //210
  eventInsuranceSignQueue.sort((a, b) => a.priority - b.priority);
  eventInsuranceSignQueue.forEach((signqueue, key) => {
    const {
      entityName = "",
      entityEmail = "",
      statusId = 0,
      electronicSignature = "",
      signedDate = "",
      positionName = "",
      sourceOwnerId = 0,
    } = signqueue;
    var textWidth = pdf.getTextWidth(positionName ? positionName : "Position Name");
    pdf.text(positionName ? positionName : "Position Name" + ":", 15, yPosition + 50);
    pdf.line(textWidth + 20, yPosition + 50, 245, yPosition + 50);
    if (parseInt(statusId) === 4 && electronicSignature !== null) {
      var img = new Image();
      img.src = electronicSignature;
      pdf.addImage(
        electronicSignature,
        "png",
        textWidth + 30,
        yPosition + 30,
        100,
        20
      );
    }
    if (
      parseInt(statusId) === 1 &&
      currentSign !== null &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      var img = new Image();
      img.src = currentSign;
      pdf.addImage(currentSign, "png", textWidth + 30, yPosition + 30, 100, 20);
    }

    if (
      parseInt(statusId) === 1 &&
      currentSign === null &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      let currentPage = pdf.internal.getCurrentPageInfo().pageNumber;
      signPosition = {
        x: textWidth + 130,
        y: ySignPosition,
        page_number: parseInt(currentPage),
      };
    }

    pdf.text("Printed Name:", 250, yPosition + 50);
    pdf.line(300, yPosition + 50, 430, yPosition + 50);
    if (parseInt(statusId) === 4) {
      pdf.text(entityName, 310, yPosition + 48);
    }
    if (
      parseInt(statusId) === 1 &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      pdf.text(entityName, 310, yPosition + 48);
    }

    pdf.text("Email:", 15, yPosition + 70);
    pdf.line(40, yPosition + 70, 225, yPosition + 70);
    if (parseInt(statusId) === 4) {
      pdf.text(entityEmail, 50, yPosition + 68);
    }
    if (
      parseInt(statusId) === 1 &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      pdf.text(entityEmail, 50, yPosition + 68);
    }

    pdf.text("Signature Date:", 230, yPosition + 70);
    pdf.line(285, yPosition + 70, 430, yPosition + 70);
    if (parseInt(statusId) === 4) {
      const currentDate = DateTimeFormat(signedDate, "MM/DD/YYYY");
      pdf.text(currentDate, 295, yPosition + 68);
    }
    if (
      parseInt(statusId) === 1 &&
      parseInt(sourceOwnerId) === parseInt(currentMember)
    ) {
      const currentDate = DateTimeFormat(new Date(), "MM/DD/YYYY");
      pdf.text(currentDate, 295, yPosition + 68);
    }

    yPosition += 50;
    ySignPosition += 100;
  });

  return { pdf, signPosition };
};

export const generateVerificationDocument = (props) => {
  let signPosition = { x: 300, y: 100 };
  const {
    title,
    CollegeOfficialName,
    schoolName,
    chapterName,
    ParentOrganizationName,
    CollegeOfficialEmail,
    CollegeOfficialphoneNo,
  } = props;
  let documentContent = "";
  let contentToDisplay = documentContent
    .replaceAll("↵", "")
    .replaceAll("’", "'")
    .replaceAll("“", '"')
    .replaceAll("”", '"')
    .replace("**Year**", "2020")
    .replace("**RegionName**", "2020");

  const pdf = new jsPDF("P", "px", "a4");
  const getXoffsetValue = (value) => {
    return (
      pdf.internal.pageSize.width / 2 -
      (pdf.getStringUnitWidth(value) * pdf.internal.getFontSize()) / 2
    );
  };
  const fontValue = "Helvetica";
  pdf.setFont(fontValue);
  pdf.setFontSize(15);
  // pdf.text(appName,200, 60);

  pdf.setFontType("bold");
  pdf.setFont(fontValue);
  // pdf.text(documentName,148, 40);

  pdf.setFontType("regular");
  pdf.setFont(fontValue);
  pdf.setFontSize(13);

  pdf.fromHTML(
    contentToDisplay, // HTML string or DOM elem ref.
    15, // x coord
    90,
    {
      // y coord
      width: 420, // max width of content on PDF
    }
  );

  pdf.setFontSize(11);
  pdf.setFontType("bold");
  pdf.setFont(fontValue);
  pdf.text(CollegeOfficialName, 35, 100);
  pdf.setFontType("regular");
  pdf.text(title, 35, 120);
  pdf.text(CollegeOfficialphoneNo, 35, 135);
  pdf.text(CollegeOfficialEmail, 35, 150);
  pdf.text(
    "I " +
    CollegeOfficialName +
    " verify that the " +
    chapterName +
    " chapter is in:",
    35,
    180
  );
  pdf.text(
    "*Good academic standing to function as an on campus organization.",
    35,
    200
  );
  pdf.text(
    "*Good financial standing by being current with all of its required fees and dues as an organization.",
    35,
    210
  );
  pdf.text(
    "*Good behavioral standing with no pending disciplinary actions.",
    35,
    220
  );
  pdf.text(
    "I also support the efforts of the " +
    schoolName +
    " Chapter the " +
    chapterName +
    " of ",
    35,
    240
  );
  pdf.text(
    ParentOrganizationName +
    " to participate during the upcoming semester membership selection program.",
    35,
    250
  );

  let finalY = pdf.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable

  finalY = 0;
  // pdf.setFontType("bold");
  // pdf.text("SIGNATURES ", 35, 340);
  pdf.setFontType("regular");
  let xPosition = 35;
  let xSignPosition = 35;
  let yPosition = 140;
  let ySignPosition = 340; //220

  signPosition = {
    x: xSignPosition,
    y: ySignPosition,
    id: `canvas_document_pdf_${1}`,
  };
  pdf.line(xPosition, yPosition + 180, xPosition + 125, yPosition + 180);
  pdf.setFontSize(12);

  pdf.text(title, xPosition, yPosition + 200);
  pdf.text(CollegeOfficialName, xPosition, yPosition + 215);
  pdf.text(
    DateTimeFormat(new Date(), "MM/DD/YYYY"),
    xPosition,
    yPosition + 230
  );
  xPosition += 150;
  xSignPosition += 205;
  return { pdf, signPosition };
};

export const generateRecommendationLetter = (props) => {
  // let signPosition = { x: 300, y: 100 };
  const {
    memberName = "",
    chapterAddress = "",
    chapterCity = "",
    chapterState = "",
    chapterZip = "",
    chapterName = "",
    memberPhone = "",
    membershipNumber = "",
    memberEmail = "",
    bodyContent = "",
    memberPositions = [],
    reference = {},
  } = props;
  let documentContent = "";
  let contentToDisplay = documentContent
    .replaceAll("↵", "")
    .replaceAll("’", "'")
    .replaceAll("“", '"')
    .replaceAll("”", '"');

  const pdf = new jsPDF("P", "px", "a4");
  let pageHeight = pdf.internal.pageSize.height;
  let pageWidth = pdf.internal.pageSize.width;
  const getXoffsetValue = (value) => {
    return (
      pdf.internal.pageSize.width / 2 -
      (pdf.getStringUnitWidth(value) * pdf.internal.getFontSize()) / 2
    );
  };
  const fontValue = "Helvetica";
  pdf.setFont(fontValue);
  pdf.setFontSize(15);
  // pdf.text(appName,200, 60);

  pdf.setFontType("bold");
  pdf.setFont(fontValue);
  // pdf.text(documentName,148, 40);

  pdf.setFontType("regular");
  pdf.setFont(fontValue);
  pdf.setFontSize(13);

  pdf.fromHTML(
    contentToDisplay, // HTML string or DOM elem ref.
    15, // x coord
    90,
    {
      // y coord
      width: 420, // max width of content on PDF
    }
  );

  pdf.setFontSize(11);
  pdf.setFontType("bold");
  pdf.setFont(fontValue);
  pdf.text(`${reference.firstName} ${reference.middleName} ${reference.lastName}`, 35, 100);
  pdf.setFontType("regular");
  pdf.text(chapterName, 35, 120);
  pdf.text(chapterAddress, 35, 135);
  pdf.text(chapterCity + " " + chapterState + " " + chapterZip, 35, 150);
  pdf.text("Dear " + chapterName + " chapter,", 35, 190);
  var lineHeight = pdf.getLineHeight(bodyContent);
  var splittedText = pdf.splitTextToSize(bodyContent, parseInt(pageWidth) - 40);
  var lines = splittedText.length; // splitted text is a string array
  var blockHeight = lines * lineHeight;
  pdf.text(splittedText, 35, 200);
  let finalY = pdf.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable

  finalY = 0;
  // pdf.setFontType("bold");
  // pdf.text("SIGNATURES ", 35, 340);
  pdf.setFontType("regular");
  let xPosition = 35;
  // let xSignPosition = 35;
  let yPosition = 140;
  // let ySignPosition = 340; //220

  // signPosition = { x: xSignPosition, y: ySignPosition, id: `canvas_document_pdf_${1}` };
  pdf.line(
    xPosition,
    yPosition + (lines + 8) * 10,
    xPosition + lines * 10,
    yPosition + (lines + 8) * 10
  );
  pdf.setFontSize(12);

  pdf.text(memberName, xPosition, yPosition + (lines + 10) * 10);
  pdf.text(
    "MembershipNumber : " + membershipNumber,
    xPosition,
    yPosition + (lines + 11.5) * 10
  );
  pdf.text("Phone : " + memberPhone, xPosition, yPosition + (lines + 13) * 10);
  pdf.text(
    "Email : " + memberEmail,
    xPosition,
    yPosition + (lines + 14.5) * 10
  );
  if (memberPositions.length > 0) {
    pdf.setFontType("bold");
    pdf.text("Positions :", xPosition, yPosition + (lines + 16) * 10);
    pdf.setFontType("regular");
    memberPositions.map((p, index) =>
      pdf.text(
        p.entityName + " " + p.positionName,
        xPosition,
        yPosition + (lines + 16) * 10 + (index + 1) * 10
      )
    );
  }
  // pdf.text(DateTimeFormat(new Date(), 'MM/DD/YYYY'), xPosition, yPosition + 230);
  xPosition += 150;
  // xSignPosition += 205;
  return { pdf };
};

export const CreateMasterIntakePdf = (props) => {
  const currentMember = getLoggedSourceOwnerID();
  const currentDate = DateTimeFormat(new Date(), "MM/DD/YYYY");
  let signPosition = { x: 0, y: 0, page_number: 0 };
  const {
    ParentOrganizationName = '',
    masterIntakeDetails = {},
    currentSign = null,
  } = props;
  const {
    candidates = [],
    positions = [],
    signQueue = [],
    organization = {},
    schools = [],
    initiationDate = null,
    documentForm = {},
  } = masterIntakeDetails || {};
  const {
    documentName = "",
    documentContent = "",
    isUndergrad = false,
  } = documentForm || {};
  const {
    organizationName = "",
    leadershipOrganizationName = "",
    address = "",
    city = "",
    state = "",
    zip = "",
    country = "",
  } = organization || {};
  let contentToDisplay = documentContent
    .trim()
    .replaceAll("↵", "")
    .replaceAll("’", "'")
    .replaceAll("“", '"')
    .replaceAll("”", '"')
    .replaceAll(/•/g, "-");

  const pdf = new jsPDF("P", "px", "a4");
  let pageHeight = pdf.internal.pageSize.height;
  const fontValue = "Helvetica";
  pdf.setFont(fontValue);
  pdf.setFontSize(16);
  pdf.text(ParentOrganizationName, 140, 40);
  if (isUndergrad) {
    pdf.text(documentName, 90, 60);
  } else {
    pdf.text(documentName, 120, 60);
  }
  pdf.setFontSize(11);
  pdf.setFontType("bold");
  pdf.text("Document Date: " + currentDate, 20, 100);
  pdf.text(
    "Initiation Date: " +
    (initiationDate !== null
      ? DateTimeFormat(initiationDate, "MM/DD/YYYY")
      : currentDate),
    20,
    115
  );
  pdf.setFontType("regular");
  pdf.setFontSize(14);
  let yPos = 130;
  if (isUndergrad) {
    schools.forEach((element) => {
      const { schoolName = "" } = element;
      pdf.text(schoolName, 20, yPos);
      yPos += 15;
    });
  }
  pdf.setFontSize(12);
  pdf.setFontType("bold");
  let pageWidth = pdf.internal.pageSize.width;
  let xpos = (isUndergrad) ? pageWidth / 2 : 20;
  pdf.text(organizationName, xpos, (isUndergrad) ? 100 : yPos + 20);
  pdf.setFontType("regular");
  pdf.setFontSize(10);
  pdf.text(leadershipOrganizationName, xpos, (isUndergrad) ? 115 : yPos + 30);
  pdf.text(address, xpos, (isUndergrad) ? 125 : yPos + 40);
  pdf.text(city + " " + state, xpos, (isUndergrad) ? 135 : yPos + 50);
  pdf.text(country + " " + zip, xpos, (isUndergrad) ? 145 : yPos + 60);
  let xPosition1 = 20;
  if (!isUndergrad) {
    xPosition1 = 170;
  }
  positions.forEach((element) => {
    const { email, memberName, positionName = "", phone } = element;
    pdf.setFontType("bold");
    pdf.setFontSize(12);
    var splitPositionName = pdf.splitTextToSize(positionName, 150);
    var posTextWidth = pdf.getTextWidth(positionName);
    pdf.text(splitPositionName, xPosition1, yPos + 20);
    var emailTextWidth = pdf.getTextWidth(email);
    var emailData = emailTextWidth > 120 ? email.substring(0, 28) : email;
    pdf.setFontType("regular");
    pdf.setFontSize(11);
    if (posTextWidth > 150) {
      yPos += 10;
    }
    // var splitmemberName = pdf.splitTextToSize(memberName, 100);
    // var posTextWidth = pdf.getTextWidth(memberName);
    // if (posTextWidth > 100) {
    //   yPos += 10;
    // }
    // pdf.text(splitmemberName, xPosition1, yPos + 30);
    pdf.text(memberName, xPosition1, yPos + 30);

    pdf.text(emailData, xPosition1, yPos + 40);
    pdf.text(phone, xPosition1, yPos + 50);
    if (isUndergrad) {
      xPosition1 += 150;
    } else {
      xPosition1 += 150;
    }
  });

  const lineHeight = pdf.getLineHeight(contentToDisplay);

  const splittedText = pdf.splitTextToSize(
    contentToDisplay,
    parseInt(pageWidth) - 20
  );
  const lines = splittedText.length; // splitted text is a string array
  const blockHeight = lines * lineHeight;
  // pdf.fromHTML(
  //   splittedText, // HTML string or DOM elem ref.
  //   20, // x coord
  //   yPos + 70,
  //   {
  //     // y coord
  //     width: 420, // max width of content on PDF
  //   }
  // );
  let y = isUndergrad ? yPos + 70 : yPos + 80
  pdf.text(splittedText, 20, y);
  pdf.setFontSize(14);
  pdf.setFontType("bold");

  pdf.text("Candidates - " + candidates.length, 20, y + blockHeight);
  if (isUndergrad) {
    var col = [
      "NAME",
      "SSN",
      "DOB",
      "PHONE #",
      "ADDRESS",
      "IHQ FEE",
      "CLASS",
    ];
  } else {
    var col = [
      "NAME",
      "SSN",
      "DATE OF BIRTH",
      "PHONE NUMBER",
      "ADDRESS",
      "IHQ FEE",
    ];
  }
  var rows = [];
  var total = 0;
  candidates &&
    candidates.forEach((element) => {
      if (isUndergrad) {
        var temp = [
          `${element.name}\n${element.feeDescription}`,
          // pdf.fromHTML(`<b> ${element.name}</b>\n${element.feeDescription}`),
          // + " -"+element.feeDescription,
          element.ssn,
          DateTimeFormat(element.dateOfBirth, "MM/DD/YYYY"),
          element.phone,
          element.address,
          FormatToAmount(element.ihqFee),
          element.class,
        ];
      } else {
        var temp = [
          `${element.name}\n${element.feeDescription}`,
          // + " "+element.feeDescription,

          element.ssn,
          DateTimeFormat(element.dateOfBirth, "MM/DD/YYYY"),
          element.phone,
          element.address,
          FormatToAmount(element.ihqFee),
        ];
      }
      rows.push(temp);
      total += element.ihqFee;
    });
  yPos = y + blockHeight;
  pdf.setFontSize(5);
  pdf.autoTable({
    body: rows,
    columns: col,
    // didParseCell: function(cell) {
    //   console.log(cell,'rows')
    //   if (cell.row.index === 0 ) {
    //     cell.styles.fontStyle = 'bold';
    //   }
    // },
    // 0: { cellWidth: 80,fontStyle : 'bold' },
    columnStyles: {
      0: { cellWidth: 80 },
      1: { cellWidth: 50 },
      2: { cellWidth: 60 },
      3: { cellWidth: 60 },
      4: { cellWidth: 100 },
      5: { cellWidth: 40 },
      6: { cellWidth: 40 },
      // etc
    },
    margin: {
      top: yPos + 10,
      right: 8,
      bottom: 8,
      left: 5
    },
    didDrawPage: function (data) {
      // Reseting top margin. The change will be reflected only after print the first page.
      data.settings.margin.top = 10;
    },
    rowPageBreak: "avoid",
    theme: "plain"
  });
  // pdf.autoTable(col, rows,
  //    { theme: "plain", startX: 20, startY: yPos });
  let finalY = pdf.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable
  pdf.setFontSize(14);
  pdf.setFontType("bold");
  pdf.text("Total Enclosed: " + FormatToAmount(total), 20, finalY + 20);

  pdf.text("Signatures:", 20, finalY + 40);
  let xPosition = 20;
  let yPosition = finalY;
  let xSignPosition = 45;
  let ySignPosition = yPosition + 450;
  pdf.setFontType("regular");
  signQueue &&
    signQueue.forEach((element, key) => {
      const {
        entityName,
        sourceOwnerId,
        positionName = "",
        statusId = 0,
        electronicSignature,
      } = element;
      let currentPage = pdf.internal.getCurrentPageInfo().pageNumber;

      if (
        parseInt(statusId) === 1 &&
        currentSign === null &&
        parseInt(sourceOwnerId) === parseInt(currentMember)
      ) {
        signPosition = {
          x: xSignPosition,
          y: ySignPosition,
          page_number: parseInt(currentPage),
        };
      }
      if (parseInt(statusId) === 4 && electronicSignature !== null) {
        var img = new Image();
        img.src = electronicSignature;
        pdf.addImage(
          electronicSignature,
          "png",
          xPosition + 15,
          yPosition + 65,
          100,
          20
        );
      }
      if (
        parseInt(statusId) === 1 &&
        currentSign !== null &&
        parseInt(sourceOwnerId) === parseInt(currentMember)
      ) {
        var img = new Image();
        img.src = currentSign;
        pdf.addImage(
          currentSign,
          "png",
          xPosition + 15,
          yPosition + 65,
          100,
          20
        );
      }

      pdf.line(xPosition, yPosition + 95, xPosition + 125, yPosition + 95);
      pdf.setFontSize(12);
      pdf.text(
        entityName + " - " + DateTimeFormat(currentDate, "MM/DD/YYYY"),
        xPosition,
        yPosition + 115
      );
      pdf.text(positionName || "N/A", xPosition, yPosition + 130);
      xPosition += 150;
      xSignPosition += 310;

      if ((key + 1) % 3 === 0) {
        if (parseInt(yPosition) >= parseInt(pageHeight) - 200) {
          pdf.addPage();
          yPosition = 0; // Restart height position
          xPosition = 20;
          xSignPosition = 45;
          ySignPosition = 100;
        } else {
          yPosition += 95;
          xPosition = 20;
          xSignPosition = 50;
          ySignPosition += 200;
        }
      }
    });
  return { pdf, signPosition };
};

export const GenerateEventInsurancePreviewPDF = (props) => {
  const { documentContent = "" } = props;
  let contentToDisplay = documentContent
    .replaceAll("↵", "")
    .replaceAll("’", "'")
    .replaceAll("“", '"')
    .replaceAll("”", '"');

  const pdf = new jsPDF("P", "px", "a4");
  let pageWidth = pdf.internal.pageSize.width;
  pdf.addFont("verdana.ttf", "Verdana", "normal");
  pdf.setFont("Verdana");
  pdf.fromHTML(
    contentToDisplay, // HTML string or DOM elem ref.
    15, // x coord
    15,
    {
      // y coord
      width: pageWidth, // max width of content on PDF
    }
  );
  return pdf;
};
export const GeneratePlanningDocumentPDF = (props) => {
  const {
    planningDocument = [], documentTransaction
  } = props;
  const { organizationName = '', documentSeason = '' } = documentTransaction
  const ParentOrganizationName = localStorage.getItem(LOCALSTORAGE_PARENT_ORGANIZATION_NAME);
  const columns = [
    { id: "eventId", title: "Event ID" },
    { id: "title", title: "Event Title" },
    { id: "startDate", title: "Start Date" },
    { id: "endDate", title: "End Date" },
  ]

  let newPlanningDocument = planningDocument.map((ele) => {
    return {
      ...ele,
      title: `${ele.title}\nVenue : ${ele.venue}\nAddress : ${ele.eventAddress}`
    };
  });

  var pdf = new jsPDF("P", "px", "a4");
  pdf.setFont("Verdana");
  pdf.setFontSize(11);
  pdf.setFontType("regular");
  pdf.text(organizationName, 8, 20);
  pdf.text(ParentOrganizationName, 8, 30);
  pdf.setFontType("bold");
  pdf.text('Planning Document ', 8, 45);
  pdf.setFontType("regular");
  pdf.text(documentSeason, 8, 55);
  pdf.autoTable({
    body: newPlanningDocument,
    columns: columns.map(x => {
      return { header: x.title, key: x.id };
    }),
    margin: {
      top: 60,
      right: 8,
      bottom: 8,
      left: 8
    },
    rowPageBreak: "avoid",
    theme: "grid"
  });

  return { pdf };
}

export const GenerateCandidateApplicationPDF = (props) => {
  const { Summary = {}, Candidate = {}, StatusHistory = {} } = props
  const ParentOrganizationName = localStorage.getItem(LOCALSTORAGE_PARENT_ORGANIZATION_NAME);

  const { application = {}, steps = [] } = Summary
  let x = 8;
  let y = 0;

  var pdf = new jsPDF("P", "px", "a4");
  let pageHeight = pdf.internal.pageSize.height;
  const generatey = () => {
    if (parseInt(y) >= parseInt(pageHeight) - 100) {
      pdf.addPage();
      y = 20; // Restart height position
    }
    else {
      y = y + 15;
    }
    return y;
  }
  const calculateLineOftext = (contentToDisplay) => {

    const lineHeight = pdf.getLineHeight(contentToDisplay);
    const splittedText = pdf.splitTextToSize(
      contentToDisplay,
      parseInt(pageWidth) - 20
    );
    const lines = splittedText.length; // splitted text is a string array
    const blockHeight = lines * lineHeight;
    // if (y + nexty > pageHeight) {
    //     y = y + nexty - pageHeight
    //     pdf.addPage();
    //   }
    // let nexty = (contentToDisplay.length / 80) * 10 + 30
    // let noOflines = contentToDisplay.length / 80
    // if (nexty > pageHeight) {
    //   nexty = y + nexty - pageHeight
    // }
    // else if (noOflines > 1) {
    //   nexty = y + nexty
    //   pdf.addPage();
    // }
    // else {
    //   nexty = y
    // }
    return { splittedText, blockHeight };
  }
  let pageWidth = pdf.internal.pageSize.width;
  pdf.setFont("Verdana");
  pdf.setFontType("bold");
  pdf.setFontSize(16);

  pdf.text(`${ParentOrganizationName}`, x, generatey());
  pdf.text(`${Candidate.organizationName} - ${Candidate.groupName}`, x, generatey());
  pdf.text(`${Candidate.intakeSeason}`, x, generatey());
  pdf.setFontSize(12);
  pdf.setFontType("regular");
  pdf.text(`Start Date: ${DateTimeFormat(application.createDate, "MM/DD/YYYY hh:mm:ss a")}`, x, generatey());
  pdf.text(`\n`, x, generatey());

  pdf.setFont("Verdana");
  pdf.setFontSize(11);
  pdf.setFontType("regular");
  steps.forEach((rec, index) => {
    pdf.setFontType("bold");
    pdf.setFontSize(12);
    pdf.text(`${index + 1} . ${rec.title}`, x, generatey())
    pdf.line(x, generatey(), pageWidth - 30, y);
    pdf.setFontType("regular");
    pdf.setFontSize(10);
    if (rec.completed == false && application.intakeSeasonId > 0) {
      pdf.text(`${rec.title} step is not completed.`, x, generatey());
    }
    if (rec.componentName == "PersonalInformation") {
      if (rec.completed) {
        pdf.text(`${application.prefix !== null && application.prefix !== "None" ? application.prefix : ''} ${application.firstName} ${application.middleName} ${application.lastName} ${application.suffix !== null && application.suffix !== "None" ? application.suffix : ''}`, x, generatey());
        pdf.text(`Email : ${application.email}`, x, generatey());
        pdf.text(`Date Of Birth : ${DateTimeFormat(application.dateOfBirth, 'MM/DD/YYYY')}`, x, generatey());
        pdf.text(`Social Security : ${application.socialSecurity}`, x, generatey());
        pdf.text(`Phone No : ${application.phone1}`, x, generatey());
        pdf.text(`Address : ${application.presentAddress1}${application.presentAddress2 ? "," : ''}${application.presentAddress2},${application.presentCity},${application.presentState},${application.presentCountry}-${application.presentZip}`, x, generatey());
      }
    } else if (rec.componentName == "Bio") {
      if (rec.completed) {
        // var lineHeight = pdf.getLineHeight(application.bio);
        // var splittedText = pdf.splitTextToSize(
        //   application.bio,
        //   parseInt(pageWidth) - 20
        // );
        // var lines = splittedText.length; // splitted text is a string array
        // var blockHeight = lines * lineHeight;
        const { blockHeight, splittedText } = calculateLineOftext(application.bio || '')
        pdf.text(splittedText, x, y + 10);
        y += blockHeight;
        // pdf.text(`${splittedText}`, x, generatey());
      }
      // y = calculateLineOftext(splittedText)

    }
    else if (rec.componentName == "Classification") {
      if (rec.completed) {
        pdf.text(`Classification :${application.officialClassification || ''}`, x, generatey());
        pdf.text(`Career Choice : ${application.careerChoice || ''}`, x, generatey());
        pdf.text(`Hours Completed : ${application.numberOfHoursCompleted || 0}`, x, generatey());
        pdf.text(`Gpa : ${application.gpa || ""}`, x, generatey());
        pdf.text(`Graduation Date :  ${DateTimeFormat(application.expectedGraduationDate, 'MM/DD/YYYY')}`, x, generatey());
        const { blockHeight, splittedText } = calculateLineOftext(application.plansAfterGraduationd || '')
        // pdf.text(splittedText, x, y + 10);
        pdf.text(`Plans After Graduation : ${splittedText || ''}`, x, y + 10);
        y += blockHeight;
      }
    }
    else if (rec.componentName == "ReasonNonUndergraduate") {
      if (rec.completed) {
        //   pdf.text(`${rec.reasonNonUndergrad}`, x, generatey());
        // }
        // y = calculateLineOftext(rec.reasonNonUndergrad)
        const { blockHeight, splittedText } = calculateLineOftext(rec.reasonNonUndergrad || '')
        pdf.text(splittedText, x, y + 10);
        y += blockHeight;
      }
    }
    else if (rec.componentName == "Education") {
      if (rec.completed) {
        if (rec.educations.length > 0) {
          rec.educations.forEach((row, index) => {
            const { institutionType, gpa = '', degreeAwarded = '', dateReceived = '', name = '' } = row
            pdf.text(`${institutionType}`, x, generatey());
            pdf.text(`School Name : ${name}`, x, generatey());
            pdf.text(`Gpa : ${gpa}`, x, generatey());
            pdf.text(`Degree : ${degreeAwarded}`, x, generatey());
            pdf.text(`Date : ${DateTimeFormat(dateReceived, 'MM/DD/YYYY')}`, x, generatey());
          });
        } else {

          pdf.text(`No ${rec.title} added.`, x, generatey());
        }
      }
    }
    else if (rec.componentName == "Employment") {
      if (rec.completed) {
        if (rec.employments.length > 0) {
          rec.employments.forEach((row, index) => {
            const { companyName = '', duties = '', startDate = '', endDate = '', position = '', reasonForLeaving = '', stillEmployed = false } = row
            pdf.text(`Title : ${position}`, x, generatey());
            if (stillEmployed)
              pdf.text(`Date : ${DateTimeFormat(startDate, 'MM/DD/YYYY')}`, x, generatey());
            else
              pdf.text(`Date : ${DateTimeFormat(startDate, 'MM/DD/YYYY')} to ${DateTimeFormat(endDate, 'MM/DD/YYYY')}`, x, generatey());
            let dutiesTxt = duties.replace(/\n/g, " ");
            pdf.text(`Duties :`, x, generatey());
            const { blockHeight: dutiesheight, splittedText: dutiesText } = calculateLineOftext(dutiesTxt || '')
            pdf.text(dutiesText, x, y + 10);
            y += dutiesheight;
            // y = calculateLineOftext(duties)
            let reasonForLeavingTxt = reasonForLeaving.replace(/\n/g, " ");
            pdf.text(`Reason For Leaving :`, x, generatey());
            const { blockHeight, splittedText } = calculateLineOftext(reasonForLeavingTxt || 'N/A')
            pdf.text(splittedText, x, y + 10);
            y += blockHeight;
            // y = calculateLineOftext(reasonForLeaving)

          });
        } else {

          pdf.text(`No ${rec.title} added.`, x, generatey());
        }
      }
    }
    else if (rec.componentName == "PreApplicationDocuments") {
      if (rec.completed) {
        if (rec.signQueue.length > 0) {
          rec.signQueue.forEach((row, index) => {
            const { documentName = '', statusName = '' } = row
            pdf.text(`${documentName}`, x, generatey());
            pdf.text(`${statusName}`, x, generatey());
          });
        } else {

          pdf.text(`No ${rec.title} added.`, x, generatey());
        }
      }
    }
    else if (rec.componentName == "GreekRelationships") {
      if (rec.completed) {
        if (application.legacy)
          pdf.text(`Candidate Indicates to be Legacy`, x, generatey());
        if (rec.greekRelationships.length > 0) {
          rec.greekRelationships.forEach((row, index) => {
            const { relativeName = '', greekOrganization = '', relationship = '' } = row
            pdf.text(`${relativeName}`, x, generatey());
            pdf.text(`Greek Organization : ${greekOrganization}`, x, generatey());
            pdf.text(`Relation : ${relationship}`, x, generatey());
          });
        } else {

          pdf.text(`No ${rec.title} added.`, x, generatey());
        }
      }
    }
    else if (rec.componentName == "References") {
      if (rec.completed) {

        if (rec.references.length > 0) {
          rec.references.forEach((row, index) => {
            const { sponsorType = '', name = '', phoneNumber = '', email = '', documentUrl = '', docName = '' } = row
            pdf.text(`${sponsorType}`, x, generatey());
            if (documentUrl == '')
              pdf.text(`Pending`, x, generatey());
            pdf.text(`Name : ${name}`, x, generatey());
            pdf.text(`Name : ${name}`, x, generatey());
            pdf.text(`Phone : ${phoneNumber}`, x, generatey());
            pdf.text(`Email : ${email}`, x, generatey());
          });
        } else {
          pdf.text(`No ${rec.title} added.`, x, generatey());
        }
      }
    }
    else if (rec.componentName == "RequiredDocuments") {
      if (rec.completed) {

        if (rec.requiredDocuments.length > 0) {
          rec.requiredDocuments.forEach((row, index) => {
            const { name = '', documentURL = '' } = row
            pdf.text(`${name}`, x, generatey());
          });
        } else {
          pdf.text(`No ${rec.title} added.`, x, generatey());
        }
      }
    }
    else if (rec.componentName == "CustomSection") {
      if (application.intakeSeasonId == null || application.intakeSeasonId == 0) {
        if (rec.title == 'Greek Knowledge') {
          pdf.text(`${ParentOrganizationName} Knowledge`, x, generatey());
          const { blockHeight: kappaKnowledgeHeight, splittedText: kappaKnowledgeText } = calculateLineOftext(application.kappaKnowledge || '')
          pdf.text(kappaKnowledgeText, x, y + 10);
          y += kappaKnowledgeHeight;
          pdf.text(`Knowledge`, x, generatey());
          const { blockHeight: knowledgeHeight, splittedText: knowledgeText } = calculateLineOftext(application.knowledge || '')
          pdf.text(knowledgeText, x, y + 10);
          y += knowledgeHeight;
        } else if (rec.title == 'Community Involvement') {

          let communityOrganizationsTxt = (application.communityOrganizations != null) ? application.communityOrganizations.replace(/\n/g, " ") : '';
          pdf.text(`Community Orgnizations`, x, generatey());
          const { blockHeight: blockHeightCommunityOrganizationsTxt, splittedText: splittedCommunityOrganizationsText } = calculateLineOftext(communityOrganizationsTxt || 'N/A')
          pdf.text(splittedCommunityOrganizationsText, x, y + 10);
          y += blockHeightCommunityOrganizationsTxt;
          let communityProfessionalHonorsTxt = (application.communityProfessionalHonors != null) ? application.communityProfessionalHonors.replace(/\n/g, " ") : '';
          pdf.text(`Community Professional Honors`, x, generatey());
          const { blockHeight, splittedText } = calculateLineOftext(communityProfessionalHonorsTxt || 'N/A')
          pdf.text(splittedText, x, y + 10);
          y += blockHeight;
        } else if (rec.title == 'Campus Involvement') {
          let collegeHonorsAchieveTxt = (application.collegeHonorsAchieve != null) ? application.collegeHonorsAchieve.replace(/\n/g, " ") : '';
          pdf.text(`College Honors Achieve`, x, generatey());
          const { blockHeight, splittedText } = calculateLineOftext(collegeHonorsAchieveTxt || 'N/A')
          pdf.text(splittedText, x, y + 10);
          y += blockHeight;
        } else if (rec.title == 'Previous History') {
          pdf.text(`Have you ever been convicted of a Felon? : ${application.isConvictedFelon ? 'True' : 'False'}`, x, generatey());
          pdf.text(`Felon Date : ${application.felonDate || 'N/A'}`, x, generatey());
          pdf.text(`Felon Description : `, x, generatey());
          let felonDescriptionTxt = (application.felonDescription != null) ? application.felonDescription.replace(/\n/g, " ") : '';
          const { blockHeight, splittedText } = calculateLineOftext(felonDescriptionTxt || 'N/A')
          pdf.text(splittedText, x, y + 10);
          y += blockHeight;
          pdf.text(`Felon Offense : `, x, generatey());
          pdf.text(`${application.felonOffense || 'N/A'}`, x, generatey());
          pdf.text(`Felon Place : ${application.felonPlace || 'N/A'}`, x, generatey());
        }
      } else {
        if (rec.completed) {
          if (rec.questionAnswers.length > 0) {
            rec.questionAnswers.forEach((row, index) => {
              const { questionText = '', answerResult = {} } = row
              pdf.text(`${questionText}`, x, generatey());
              pdf.text(`${answerResult && answerResult.freeTextAnswer || ''}`, x, generatey());
            });
          } else {
            pdf.text(`No ${rec.title} added.`, x, generatey());
          }
        }
      }
    }
  });
  pdf.text(``, x, generatey());
  pdf.setFontType("bold");
  pdf.setFontSize(12);
  pdf.text(`Status History`, x, generatey())

  if (StatusHistory.length > 0) {
    StatusHistory.forEach((row, index) => {
      const { createDate = "",
        entityName = "",
        status = "", } = row
      if (status == 'ApplicationSubmitted') {
        pdf.text(`Submitted On : ${DateTimeFormat(createDate, "MM/DD/YYYY hh:mm:ss a")}`, x, generatey());
      }
    });
  } else {
    pdf.text(`No Status History Available.`, x, generatey());
  }
  return { pdf };
};

