import React, { Fragment, useState, useEffect } from "react";
import { Paper, Toolbar, Grid, Typography, Divider, Button, FormControl, Box, InputLabel, Select, Input, MenuItem, Checkbox, ListItemText } from "@material-ui/core"
import clsx from "clsx";
import { isMobileScreen, isTabScreen } from "../../functions/commonFunctions";
import { GET_CHAPTER_CERTIFICATION_STATUS, REGIONAL_AND_LOCAL_INTAKE_SEASON_STEP_STATUS_API, START_NEW_CHAPTER_CERTIFICATION } from "../../constants/apiPaths";
import { useSnackbar } from "notistack";
import { useHistory } from 'react-router-dom';
import {
    CustomAlertMessage,
} from "../../functions/CustomFunctions";
import {
    authHeader,
} from "../../functions/CoreFunctions";
import API from "../../redux/actions/API";
import { CustomPageLoading } from "../../functions/CustomFunctions";
import { useParams } from 'react-router'
import CustomStepperComponent from "../officerReport/common/CustomStepperComponent";
import { makeStyles } from '@material-ui/core/styles';
import ChapterCertificationPrerequisite from "./ChapterCertificationPrerequisite"
import ChapterCertificationPayment from "./ChapterCertificationPayment";
import DefaultResponsiveContainer from "../common/DefaultResponsiveContainer";
import ChapterCertificationSummary from "./ChapterCertificationSummary";
import ChapterCertificationDocuments from "./ChapterCertificationDocuments";
import IntakeFees from "../intakeSeason/Common/IntakeFees";
import { LOGGED_USER_ACCESS_LEVEL } from "../../constants/LocalStorage";
import ChapterCertificationUGMemberConfirmation from "./ChapterCertificationUGMemberConfirmation";
import ChapterCertificationSignDocuments from "./ChapterCertificationSignDocuments";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    },
    title: {
        fontSize: isMobileScreen() ? '18px' : '24px',
        fontWeight: 600,
        textAlign: 'center'
    },
    leftAlignedTitle: {
        fontSize: isMobileScreen() ? '18px' : '24px',
        fontWeight: 600,
    },
    saveButton: {
        color: 'white',
        width: '14rem',
        alignSelf: 'center',
        marginBottom: '2rem',
        background: theme.palette.primary.dark,
        marginLeft: '1rem'
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        width: '100%',
        backgroundColor: '#fff'
    },
    backbtn: {
        float: !(isMobileScreen() || isTabScreen()) ? 'right' : 'left',
        marginBottom: isMobileScreen() ? '5px' : '0px',
        marginLeft: '1rem'
    },
    btmborder: {
        borderBottom: '1px solid #eee'
    },
    mobilebtnLayout2: {
        display: 'flex',
        //  flexDirection: "row-reverse"
    },
    mobilebtnLayout: {
        display: 'flex',
        //  flexDirection: "row-reverse"
    },
    optionalText: {
        width: '100%',
        textAlign: 'center',
        borderBottom: '1px solid #eee',
        lineHeight: '0.1em',
        fontSize: '12px'
    },
    wordBreakStyle: {
        backgroundColor: '#fff',
        padding: '0 10px'
    },
    btnAddStyle: {
        marginTop: '1rem',
        width: isMobileScreen() ? '90%' : isTabScreen() ? '75%' : '50%'
    },
    formControl: {
        backgroundColor: '#fff',
        // margin: theme.spacing(1),
        width: '100%'
    },
    ellipseText: {
        whiteSpace: 'nowrap',
        width: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 0
    },
    secTitle: {
        fontSize: '22px',
        fontWeight: '900',
        marginTop: '5px',
        marginLeft: '8px'
    },
    stepSpacing: {
        fontSize: '22px',
        // fontWeight: '900',
        textAlign: 'right',
        marginTop: '5px',
        marginRight: '8px'
    },
    deletebtn: {
        marginLeft: '10px',
        marginBottom: isMobileScreen() ? '5px' : '0px',
        float: !(isMobileScreen() || isTabScreen()) ? 'right' : 'left',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        }
    },
    viewpdfbtn: {
        marginLeft: '10px',
        marginBottom: isMobileScreen() ? '5px' : '0px',
        float: 'right',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        }
    },
    viewpdfbtn2: {
        marginLeft: '10px',
        marginBottom: isMobileScreen() ? '5px' : '0px',
        float: 'center',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        }
    },
    continuebtn: {
        color: '#fff',
        // width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        // display: 'block',
        padding: '10px 35px',
        opacity: 1,
        '&:hover': {
            opacity: 0.7,
        },
        marginLeft: '5px',
        float: 'center',
        marginBottom: '15px',
        marginTop: '15px'
    },
    okbtn: {
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        padding: '10px 35px',
        opacity: 1,
        '&:hover': {
            opacity: 0.7,
        },
        marginBottom: '15px',
        marginTop: '15px'
    },
    section: {
        marginTop: '1rem',
        // marginLeft:'1rem'
        color: 'rgb(30, 70, 32)',
        backgroundColor: 'rgb(237, 247, 237)',
        padding: '12px'

    },
    avatar: {
        color: '#fff',
        backgroundColor: 'green'
    },
    delbtn: {
        marginRight: '10px',
        padding: '6px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        fontSize: 5
    },
    filterIcon: {
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
    },
    exportBtn: {
        marginRight: '10px',
    },
    tablecontainer: {
        maxHeight: 440,
        overflowX: 'hidden',
        // marginTop:'10px'
    },
    resetIcon: {
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
    },
    addBtn: {

        // width: (isMobileScreen() || isTabScreen()) ? '100%' : '100%',
        // width: '50%',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        marginLeft: '5px',
        opacity: 1,
        '&:hover': {
            opacity: 0.7,
        }
    },
    deleteBtn: {

        // width: (isMobileScreen() || isTabScreen()) ? '100%' : '100%',
        // width: '50%',
        float: 'right',
    },
    modalTitle: {
        fontSize: '1rem',
        margin: 0,

    },

    divider: {
        margin: `0 0 0 0`,
    },
    alertStyle: {
        marginBottom: '10px'
    },
    viewBtn: {
        marginRight: '10px',
        padding: '6px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        fontSize: 5
    },

    actionColumnStyle: {
        cursor: 'pointer',
    },





}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function findIndex(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

const ChapterCertification = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const { id: organzId = 0 } = useParams()
    const { organizationId: orgId } = props

    const organizationId = organzId || orgId     // get OrganizationID from props or UseParams

    // console.log(props, "ROWROWROW", organizationId);

    const [showStart, setShowStart] = useState(false)
    const [orgDetailsInfo, setOrgDetailsInfo] = useState()
    const [pageTitle, setPageTitle] = useState('')
    const [docTransactionDetail, setDocTransactionDetail] = useState()
    const [activeTab, setActiveTab] = useState(0);
    const [schools, setSchools] = useState([])
    const [chapterCertifivationSID, setChapterCertifivationSID] = useState(0)
    //Step Related
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([])
    const history = useHistory();
    const accesslevel = localStorage.getItem(LOGGED_USER_ACCESS_LEVEL);

    const handelBack = () => {
        if (accesslevel === "National") {
            history.push(`/chapters/${organizationId}`,)
        } else {
            history.push(`/dashboard/chapteradmin`,)
        }
    }

    //Tab
    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const startNewCertificationForm = async () => {
        var data = {
            OrganizationId: parseInt(organizationId),
        };
        try {
            setLoading(true);
            const options = {
                method: "POST",
                url: START_NEW_CHAPTER_CERTIFICATION,
                params: {
                    OrganizationId: parseInt(organizationId),
                },
                headers: authHeader(),
            };
            const { data: apiResponse } = await API(options);
            const { status, response, message } = apiResponse;
            if (parseInt(status) === 1) {
                // CustomAlertMessage(response.message, "success", enqueueSnackbar);
                await GetCertificationStatus()
            } else if (parseInt(status) === 3) {
                CustomAlertMessage(message, "error", enqueueSnackbar);
            }
            else {
                CustomAlertMessage(response.message, "success", enqueueSnackbar);
            }
        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                CustomAlertMessage(error.response.statusText, "error", enqueueSnackbar);
            }
        } finally {
            setLoading(false);
        }
    };


    const GetCertificationStatus = async () => {
        try {
            setLoading(true);
            const options = {
                url: GET_CHAPTER_CERTIFICATION_STATUS,
                method: "GET",
                params: {
                    organizationId: parseInt(organizationId),
                },
                headers: authHeader(),
            };
            const { data: apiResponse } = await API(options);
            const { status, response = {}, message = "" } = apiResponse;
            const { certificationState, organizationDetailInfo = {}, documentTransaction, chapterCertificationSeasonID } = response;
            const { schools = [] } = organizationDetailInfo
            const { name: title } = documentTransaction ? documentTransaction : ''
            setDocTransactionDetail(documentTransaction)
            // console.log(response.steps, 'response?.setOrgDetailsInfo ', title);
            setPageTitle(title)
            setOrgDetailsInfo(organizationDetailInfo)
            setSteps(response?.steps)
            setSchools(schools)
            setChapterCertifivationSID(chapterCertificationSeasonID)
            if (parseInt(status) === 1) {
                if (parseInt(certificationState) === 0) {
                    CustomAlertMessage(response.message, "error", enqueueSnackbar);
                }
                else if (parseInt(certificationState) === 1) { // new form Show Start Button 
                    setShowStart(true)
                } else if (parseInt(certificationState) === 2) { //Already Started Have to Complete 
                    setShowStart(false)
                    // CustomAlertMessage("Please fill all information ", "success", enqueueSnackbar);
                } else if (parseInt(certificationState) === 3) { //Submitted And Approved 
                    CustomAlertMessage("Submitted And Approved " + message, "success", enqueueSnackbar);
                } else {
                    CustomAlertMessage(response.message, "error", enqueueSnackbar);
                    // history.goBack();
                    // history.push(`chapters/${organizationId}`)
                }
            }
            else {
                CustomAlertMessage(message, "error", enqueueSnackbar);
            }

        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                CustomAlertMessage(data.message, "error", enqueueSnackbar);
            }
            CustomAlertMessage(error.message, "error", enqueueSnackbar);
        } finally {
            setLoading(false);
        }
    };


    //handle Step  
    const handleSeasonStep = async (stepStatusId, intakeSeasonID, componentName, stepData, setFormLoading) => {
        try {
            setFormLoading(true);
            const options = {
                url: REGIONAL_AND_LOCAL_INTAKE_SEASON_STEP_STATUS_API,
                method: 'PUT',
                params: {
                    intakeSeasonID: parseInt(intakeSeasonID),
                    organizationID: parseInt(organizationId),
                    Status: stepStatusId,

                    ComponentName: componentName
                },
                headers: authHeader(),
            };
            const { data: apiResponse } = await API(options);
            const { status } = apiResponse;
            if (parseInt(status) === 1) {
                if (stepStatusId == 1) {
                    CustomAlertMessage('Please make any changes', 'success', enqueueSnackbar);
                }
                else if (stepStatusId == 2) {
                    CustomAlertMessage('Successfully saved', 'success', enqueueSnackbar);
                    commonCallback();
                }
                handleUpdatevalue(stepStatusId, stepData)
            }
        } catch (error) {
            if (error.response) {
                let { data } = error.response;
                CustomAlertMessage(data.message, 'error', enqueueSnackbar);
            }
        } finally {
            setFormLoading(false);
        }

    };

    //update client side step
    const handleUpdatevalue = (stepStatusId, stepData) => {
        let stepValues = stepData;
        stepValues.completed = stepStatusId == 2 ? true : false;
        GetCertificationStatus()
        if (stepStatusId == 2)
            handleActiveStep(steps);
        setActiveStep(3)
    }

    useEffect(() => {
        handleActiveStep(steps)
    }, [steps])

    useEffect(() => {
        if (organizationId && parseInt(organizationId) > 0) {
            GetCertificationStatus()
        }
    }, [organizationId])

    //Step Related
    const handleStep = (step) => () => {
        if (steps[0]?.completed != false) {
            setActiveStep(step);
        } else {
            CustomAlertMessage("Step 1 must be completed first", "error", enqueueSnackbar);
        }

    };

    const handleChangeStep = (event) => {
        setActiveStep(event.target.value);
    }

    const incompleteStep = steps?.find((record) => !record.completed)

    const handleSelected = () => {
        // setCurrentIntakeSeason(true)
    }

    const handleActiveStep = (steps) => {
        let tab_to_active = 0;
        const incompletedSteps = steps?.filter((record) => !record.completed);
        if (incompletedSteps?.length) {
            const leastStep = incompletedSteps.reduce(function (prev, curr) {
                return prev.order < curr.order ? prev : curr;
            });
            tab_to_active = findIndex(steps, 'order', leastStep.order);
        }
        else {
            tab_to_active = steps?.length
        }
        // console.log(tab_to_active, 'componentName', steps)
        setActiveStep(tab_to_active);

    }

    // //handle Next Step with validation
    const handleNextStepWithValidation = () => {
        // Check if any incompleted step exists except current step
        const incompletedSteps = steps?.filter((record) => !record.completed && (record.order !== activeStep + 1));
        if (incompletedSteps.length) {
            // Find the least order to set as active step
            const leastStep = incompletedSteps.reduce(function (prev, curr) {
                return prev.order < curr.order ? prev : curr;
            });
            const tab_to_active = findIndex(steps, 'order', leastStep.order);
            setActiveStep(tab_to_active);
        }
        else {
            // CustomAlertMessage('All Steps are completed', 'success', enqueueSnackbar);
            setActiveStep(steps.length); // Show Summary Component
        }
    }

    //Common Callback
    const commonCallback = () => {
        GetCertificationStatus()
        handleNextStepWithValidation();
    }

    const componentsList = {

        'ChapterCertificationPrerequisite':
            <ChapterCertificationPrerequisite
                stepData={(activeStep !== null && steps?.length) ? steps[activeStep] : {}}
                handleCallback={commonCallback}
                setSteps={setSteps}
                schools={schools}
                chapterCertificationSeasonID={chapterCertifivationSID}
            />,

        'ChapterCertificationDocuments':
            <ChapterCertificationDocuments
                stepData={(activeStep !== null && steps?.length) ? steps[activeStep] : {}}
                organizationId={organizationId}
                handleCallback={commonCallback}
                documentTransactionId={(docTransactionDetail?.documentTransactionId && docTransactionDetail?.documentTransactionId) || 0}
            />,

        'ChapterCertificationPayment':
            <ChapterCertificationPayment
                stepData={(activeStep !== null && steps?.length) ? steps[activeStep] : {}}
                handleCallback={commonCallback}
                setSteps={setSteps}
                orgDetails={orgDetailsInfo}
            />,
        'IntakeFees':
            <IntakeFees
                stepData={(activeStep !== null && steps?.length) ? steps[activeStep] : {}}
                OrganizationID={orgDetailsInfo?.organizationID}
                organizationName={orgDetailsInfo?.organizationName}
                handleSeasonStep={handleSeasonStep}
                handleUpdatevalue={handleUpdatevalue}
                intakeSeasonID={0}
                commonCallback={commonCallback}
            // organizationTypeId={org.organizationTypeId}
            />,
        'ChapterCertificationUGMemberConfirmation':
            <ChapterCertificationUGMemberConfirmation
                stepData={(activeStep !== null && steps?.length) ? steps[activeStep] : {}}
                handleCallback={commonCallback}
                setSteps={setSteps}
                orgDetails={orgDetailsInfo}
                documentTransactionId={(docTransactionDetail?.documentTransactionId && docTransactionDetail?.documentTransactionId) || 0}
            />,
        'ChapterCertificationSignDocuments':
            <ChapterCertificationSignDocuments
                organizationId={organizationId}
                organizationName={orgDetailsInfo?.organizationName}
                docTransactionDetail={docTransactionDetail}
                stepData={(activeStep !== null && steps?.length) ? steps[activeStep] : {}}
                handleCallback={commonCallback}
                setSteps={setSteps}
            />,
    };

    return (

        <Fragment>
            {loading && <CustomPageLoading flag={loading} />}
            {organizationId != 0 ?
                <>
                    <TabPanel value={activeTab} index={0}>
                        <Grid container style={{ marginTop: isMobileScreen() ? '30px' : '0px' }}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    {
                                        isMobileScreen() ?
                                            <Toolbar className={classes.root}>
                                                <Grid item xs={12} md={12}>
                                                    <Typography variant="h6" className={classes.leftAlignedTitle}>
                                                        {pageTitle ? pageTitle : 'Chapter Certification'}  <br />
                                                    </Typography>

                                                    <span style={{ fontSize: 14, fontWeight: 300 }} >
                                                        <>
                                                            <a href="" onClick={() => { handelBack() }}>      {orgDetailsInfo?.organizationName} - {orgDetailsInfo?.regionName} </a>
                                                        </>
                                                    </span>
                                                    <div>
                                                        {
                                                            docTransactionDetail?.documentTransactionId &&
                                                            <span style={{ fontSize: 14, fontWeight: 300 }}>
                                                                Document transaction ID : {docTransactionDetail?.documentTransactionId}
                                                            </span>
                                                        }<br />

                                                        {docTransactionDetail?.statusName && docTransactionDetail?.statusName != "" &&
                                                            <span style={{ fontSize: 14, fontWeight: 300 }}>
                                                                Document Status :  {docTransactionDetail?.statusName}
                                                            </span>
                                                        }
                                                    </div>
                                                </Grid>
                                            </Toolbar>
                                            :
                                            <Toolbar className={classes.root}>
                                                <Grid item xs={8} md={8}>
                                                    <Typography variant="h6" className={classes.leftAlignedTitle}>
                                                        {pageTitle ? pageTitle : 'Chapter Certification'}  <br />
                                                    </Typography>

                                                    <span style={{ fontSize: 16, fontWeight: 300 }} >
                                                        <>
                                                            <a href="" onClick={() => { handelBack() }}>      {orgDetailsInfo?.organizationName} - {orgDetailsInfo?.regionName} </a>
                                                        </>
                                                    </span>
                                                </Grid>
                                                <Grid item xs={4} md={4} >
                                                    {
                                                        docTransactionDetail?.documentTransactionId &&
                                                        <span style={{ fontSize: 16, fontWeight: 300 }}>
                                                            Document transaction ID : {docTransactionDetail?.documentTransactionId}
                                                        </span>
                                                    }<br />

                                                    {docTransactionDetail?.statusName != "" &&
                                                        <span style={{ fontSize: 16, fontWeight: 300 }}>
                                                            Document Status :  {docTransactionDetail?.statusName}
                                                        </span>
                                                    }
                                                </Grid>
                                            </Toolbar>
                                    }

                                    <Divider />

                                    {showStart ?
                                        <center>
                                            <Button
                                                type="button"
                                                variant="contained"
                                                className={classes.continuebtn}
                                                onClick={() => { startNewCertificationForm() }}
                                            >
                                                Start Certification Form
                                            </Button>
                                        </center>
                                        :
                                        <Paper className={classes.paper}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} >

                                                    {isMobileScreen() || isTabScreen() ?
                                                        <FormControl className={classes.formControl}>
                                                            <InputLabel id="step-componen-label" className={classes.secTitle}>Steps</InputLabel>
                                                            {incompleteStep && (
                                                                <div className={classes.stepSpacing}>
                                                                    <Typography >
                                                                        <span>{`Step ${activeStep + 1} of ${steps.length}`}</span>
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                            <Select
                                                                style={{ padding: '5px' }}
                                                                labelId="step-componen-label"
                                                                id="step-component"
                                                                value={activeStep}
                                                                onChange={handleChangeStep}
                                                                input={<Input />}
                                                                MenuProps={MenuProps}
                                                            // disabled={IntakeSeasonTimeFrame == "Past" ? true : false}

                                                            >
                                                                {steps.map(({ title, completed }, index) => (
                                                                    <MenuItem key={index} value={index}>
                                                                        <Checkbox checked={completed} />
                                                                        <ListItemText
                                                                            primary={
                                                                                <Fragment>
                                                                                    <Typography
                                                                                        component="span"
                                                                                        variant="body1"
                                                                                        color="textPrimary"
                                                                                    >
                                                                                        <p className={classes.ellipseText}>{title}</p>
                                                                                    </Typography>
                                                                                </Fragment>
                                                                            }
                                                                        />
                                                                    </MenuItem>
                                                                ))}
                                                                {(!incompleteStep && steps?.length > 0) && (
                                                                    <MenuItem key={steps.length} value={steps.length}>
                                                                        {/* <Checkbox checked={true} /> */}
                                                                        <ListItemText
                                                                            primary={
                                                                                <Fragment>
                                                                                    <Typography
                                                                                        component="span"
                                                                                        variant="body1"
                                                                                        color="textPrimary"
                                                                                    >
                                                                                        <p className={classes.ellipseText}>Summary</p>
                                                                                    </Typography>
                                                                                </Fragment>
                                                                            }
                                                                        />
                                                                    </MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                        :
                                                        <>
                                                            {
                                                                steps?.length > 0 &&
                                                                <CustomStepperComponent
                                                                    steps={steps}
                                                                    handleStep={handleStep}
                                                                    activeStep={activeStep}
                                                                // disabled={IntakeSeasonTimeFrame == "Past" ? true : false}
                                                                // goToSummary={goToSummary}
                                                                />
                                                            }
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                            {(activeStep !== null) &&
                                                <Fragment>
                                                    {
                                                        (activeStep === steps?.length && steps?.length > 0) ?
                                                            <ChapterCertificationSummary
                                                                steps={steps}
                                                                handleCallback={commonCallback}
                                                                // setSteps={setSteps}
                                                                orgDetails={orgDetailsInfo}
                                                                documentTransactionId={(docTransactionDetail?.documentTransactionId && docTransactionDetail?.documentTransactionId) || 0}
                                                                handleSeasonStep={handleSeasonStep}
                                                                handleUpdatevalue={handleUpdatevalue}
                                                                commonCallback={commonCallback}
                                                                setSteps={setSteps}
                                                                docTransactionDetail={docTransactionDetail}
                                                                schools={schools}
                                                                chapterCertificationSeasonID={chapterCertifivationSID}
                                                            />
                                                            :
                                                            //Actual Steps
                                                            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                                                                <Grid item xs={12} >
                                                                    {
                                                                        (steps != undefined && steps.length > 0) && componentsList[steps[activeStep]?.componentName]
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                    }
                                                </Fragment>
                                            }

                                        </Paper>
                                    }
                                    <Divider style={{ paddingBottom: '0.2rem' }} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* <TabPanel value={activeTab} index={1}>
                        <>TEST SUMMARY</>
                    </TabPanel> */}
                </>
                :
                <>
                    {/* <center>
                        <DefaultResponsiveContainer>
                            <Typography variant="h6" className={classes.formHeader}>
                                {'Chapter Certification Details'}
                            </Typography>
                        </DefaultResponsiveContainer>

                        <Button
                            className={classes.continuebtn}
                            type='submit'
                            variant='contained'
                            onClick={handleSelected}
                        >
                            {'Chapter Certification Details'}
                        </Button>
                    </center> */}
                </>
            }
        </Fragment >
    )

}

export default ChapterCertification

