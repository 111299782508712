export const LOCALSTORAGE_AUTHTOKEN = "LOCALSTORAGE_AUTHTOKEN";
export const LOCALSTORAGE_PROFILE = "LOCALSTORAGE_PROFILE";
export const LOCALSTORAGE_THIRDPARTYINTEGRATION =
  "LOCALSTORAGE_THIRDPARTYINTEGRATION";
export const LOCALSTORAGE_PARENT_ORGANIZATION_NAME =
  "LOCALSTORAGE_PARENT_ORGANIZATION_NAME";
export const LOCALSTORAGE_SIGNUP_STEPS_FORM_DATA =
  "LOCALSTORAGE_SIGNUP_STEPS_FORM_DATA";
export const LOCALSTORAGE_AUTHUSER = "LOCALSTORAGE_AUTHUSER";
export const LOCALSTORAGE_AUTH_MEMBER_ID = "LOCALSTORAGE_AUTH_MEMBER_ID";
export const LOGGED_USER_ACCESS_LEVEL = "LOGGED_USER_ACCESS_LEVEL";
export const REGION_ID = "REGION_ID";
export const PAYMENT_METHOD_CUSTOMER_TOKEN = "PAYMENT_METHOD_CUSTOMER_TOKEN";
export const SELECTED_PAYMENT_METHOD = "SELECTED_PAYMENT_METHOD";
export const LOCALSTORAGE_APP_NAME = "LOCALSTORAGE_APP_NAME";
export const LOCALSTORAGE_PRIMARY_COLOR = "LOCALSTORAGE_PRIMARY_COLOR";
export const LOCALSTORAGE_ENTITYTYPEID = "LOCALSTORAGE_ENTITYTYPEID";
export const LOCALSTORAGE_AUTH_PERSON_ID = "LOCALSTORAGE_AUTH_PERSON_ID";
export const LOCALSTORAGE_AUTH_SUPPORTER_ID = "LOCALSTORAGE_AUTH_SUPPORTER_ID";
export const LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID =
  "LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_ID";
export const LOCALSTORAGE_AUTH_PROSPECT_ID = "LOCALSTORAGE_AUTH_PROSPECT_ID";
export const LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_NAME =
  "LOCALSTORAGE_PROSPECT_LOGIN_ORGANIZATION_NAME";
export const LOCALSTORAGE_PROSPECT_PLANNING_DOCUMENT_ID =
  "LOCALSTORAGE_PROSPECT_PLANNING_DOCUMENT_ID";
export const LOCALSTORAGE_PROSPECT_PROFILE = "LOCALSTORAGE_PROSPECT_PROFILE";
export const LOCALSTORAGE_SOURCEOWNERID = "LOCALSTORAGE_SOURCEOWNERID";
export const LOCALSTORAGE_LANDINGPAGEIMAGE = "LOCALSTORAGE_LANDINGPAGEIMAGE";
export const LOCALSTORAGE_CURRENT_ORGANIZATIONID =
  "LOCALSTORAGE_CURRENT_ORGANIZATIONID";
export const LOCALSTORAGE_REQUIRE_EVENT_INSURANCE =
  "LOCALSTORAGE_REQUIRE_EVENT_INSURANCE";
export const LOCALSTORAGE_SUPPORTER_DETAILS = "LOCALSTORAGE_SUPPORTER_DETAILS";
export const ISLASTMODIFIEDATEWITHINFISCALDATE =
  "ISLASTMODIFIEDATEWITHINFISCALDATE";
export const LOCALSTORAGE_INTAKELANDINGPAGEIMAGE = "LOCALSTORAGE_INTAKELANDINGPAGEIMAGE";
export const LOCALSTORAGE_CURRENT_PAGENUMBER_PAGINATION = "LOCALSTORAGE_CURRENT_PAGENUMBER_PAGINATION";

export const LOCALSTORAGE_SIGNALR_MESSSAGE = "LOCALSTORAGE_SIGNALR_MESSSAGE"
export const LOCALSTORAGE_SIGNALR_CONNECTIONID = "LOCALSTORAGE_SIGNALR_CONNECTIONID"
export const LOCALSTORAGE_QUESTIONBUILDER_DOCUMENT_FORMS = "LOCALSTORAGE_QUESTIONBUILDER_DOCUMENT_FORMS"