import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogTitle, TextField } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { VERIFY_MEMBERSHIP_NUMBER_URL } from '../../../constants/apiPaths'
import API from '../../../redux/actions/API'
import { CustomAlertMessage, CustomPageLoading, DateTimeFormat } from '../../../functions/CustomFunctions'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import Box from "@material-ui/core/Box";
import { GetPlanningDocumentEventCheckin } from '../../../redux/actions'
import { useParams } from 'react-router-dom'
import { isMobileScreen } from '../../../functions/commonFunctions'
import DefaultResponsiveContainer from '../../common/DefaultResponsiveContainer'
import { LOCALSTORAGE_PARENT_ORGANIZATION_NAME } from '../../../constants/LocalStorage'
import EntityCheckinQuestions from './EntityCheckinQuestions'
import EntityCheckinForm from './EntityCheckinForm'
import CloseIcon from '@material-ui/icons/Close';
import { US_PHONE_NUMBER_REGEX } from '../../../constants/ValidationRegex'

// Schema
const validationSchema = Yup.object({
    membershipNumber: Yup.string().when('CheckInEntityTypeID', {
        is: value => {
            return (parseInt(value) === 2)
        },
        then: Yup.string().required('Membership Number is required'),
        otherwise: Yup.string()
    }),
})

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    continuebtn: {
        color: "#fff",
        width:'100%',
        background: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: "50px",
        margin: "auto",
        display: "block",
        padding: "10px 35px",
        opacity: 1,
        "&:hover": {
          opacity: 0.7,
        },
      },
    continuebtn2: {
        color: '#fff',
        width: '50%',
        background: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: "50px",
        margin: "auto",
        padding: "10px 35px",
        opacity: 1,
        "&:hover": {
          opacity: 0.7,
        },
        marginRight: '5px'
    },
    formControl: {
        width: '100%'
    },
    formHeader: {
        fontSize: 'x-large',
        textAlign: 'center'
    },
    formSubHeader: {
        marginBottom: '1rem',
        textAlign: 'center'
    },
    formSubHeader2: {
        fontSize: '12px',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },
    imgCircle: {
        textAlign: 'center',
        border: '1px solid #eee',
        width: '113px',
        height: '113px',
        padding: '5px',
        backgroundColor: '#eee',
        borderRadius: '50%',
    },
    imgCircleDiv: {
        textAlign: 'center',
    },
    fileInput: {
        marginTop: '-30px',
        marginLeft: '-24px',
        backgroundColor: '#eee',
        padding: '4px',
        '&:hover': {
            backgroundColor: '#eee',
        }
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    infoAlertStyle: {
        color: 'rgb(102, 60, 0)',
        backgroundColor: 'rgb(232 244 253)',
        textAlign: 'center',
        marginBottom: '1rem'
    },
    infoIconStyle: {
        float: 'left',
        borderRadius: '50%',
        backgroundColor: '#7dc1f6',
        color: '#e8f4fd',
        marginTop: '19px',
        marginLeft: '10px'
    },
    modalTitle: {
        fontSize: '1rem',
        margin: 0
    },
    gridLayout: {
        width: '100%', margin: '0px'
    }
}))

const EntityCheckin = (props) => {
    const { eventid = 0, orgid = 0 } = useParams();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const initialFormValues = {
        membershipNumber: '',
        CheckInEntityTypeID: 2
    }
    const [formLoading, setFormLoading] = useState(false);
    const [isShowForm, setIsShowForm] = useState(true);
    const [isShowCheckinForm, setIsShowCheckinForm] = useState(false);
    const [foundMemberData, setFoundMemberData] = useState({});
    const { fullName: foundFullName = "", membershipNumber: foundMembsershipNumber = "" } = foundMemberData || {};
    const { loading = false, data = {} } = useSelector(state => state.GetPlanningDocumentEventCheckinReducer);
    const { endDate = '', planningDocumentEventTimeStatus = '', startDate = '', title = '', organizationName = '', organizationID = 0, eventID } = data
    const parentOrganizationName = localStorage.getItem(LOCALSTORAGE_PARENT_ORGANIZATION_NAME) || '';

    let questions = [
        { id: 1, title: 'Have you had fever,chills,clod or flu-like symptoms(cough,muscle aches,headaches,sore throat,congestion,shortness of breth etc) within the past 24 hours or in the last 7 days ?', value: null },
        { id: 2, title: 'Are you experiencing Gastrointestinal(GI) problems likenausea,vomiting or diarrhea ?', value: null },
        { id: 3, title: 'Are you experiencing any loss of smell or taste ?', value: null },
        { id: 4, title: 'Have you been close connect with a person positive for covid-19 without the constantuse of personal protective equipment(PPE) ?', value: null },
        { id: 5, title: 'Have you been out of the country in last six(6) months ?', value: null },
        { id: 6, title: 'Does the person have a fever =100.0°F ?', value: null }]

    useEffect(() => {
        dispatch(GetPlanningDocumentEventCheckin(eventid, orgid));
    }, []);

    const saveFormValues = async values => {
        if (values.CheckInEntityTypeID === 2) {
            try {
                setFormLoading(true)
                const options = {
                    method: 'GET',
                    url: VERIFY_MEMBERSHIP_NUMBER_URL,
                    params: { MembershipNumber: values.membershipNumber },
                }
                const { data: apiResponse } = await API(options)
                const { status, response } = apiResponse;
                const { foundMember, message = '' } = response
                if (status == 1) {
                    if (foundMember) {
                        setIsShowForm(false);
                        setFoundMemberData(response);
                    } else {
                        CustomAlertMessage(message, 'error', enqueueSnackbar);
                    }
                }
            } catch (error) {
                if (error.response) {
                    let { data } = error.response;
                    CustomAlertMessage(error.response.statusText, 'error', enqueueSnackbar);
                }
            } finally {
                setFormLoading(false)
            }
        }
        else {
            setIsShowForm(false)
        }
    }

    const { handleSubmit, handleChange, values, errors, touched, setFieldValue, resetForm } = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: values => {
            saveFormValues(values)
        }
    })

    let {
        membershipNumber = ""
    } = values

    const [isDialogOpen, setDialogOpen] = useState(false)

    const dialogOpen = (item) => {
        setDialogOpen(true)
    }
    const dialogClose = () => {
        resetForm()
        //setFieldValue('membershipNumber', '')
        setIsShowForm(true)
        setIsShowCheckinForm(false)
        setDialogOpen(false)
    }

    const OnClickCancel = () => {
        if (isShowForm == true && isShowCheckinForm == false) {
            window.close();
        }
        if (isShowForm == false && isShowCheckinForm == false) {
            setIsShowForm(true)
        }
        if ((isShowForm == false && isShowCheckinForm == true)) {
            setIsShowCheckinForm(false);
            setIsShowForm(false);
        }
    }

    return (
        <Fragment>
            {loading ?
                <CustomPageLoading flag={loading} />
                :
                <div style={{ marginTop: isMobileScreen() ? '4rem' : '7rem' }} className={`hideScrollBar`}>
                    <Grid container spacing={3}>
                        <DefaultResponsiveContainer>
                            <Grid item xs={12} style={{ paddingBottom: '10px', marginTop: '10px' }}>
                                <Typography style={{ textAlign: 'center', fontWeight: 700, marginTop: '1rem', fontSize: 22, marginBottom: '10px' }}>{organizationName}</Typography>
                                <Typography style={{ textAlign: 'center', fontWeight: 700, }}>{title}</Typography>
                                {startDate !== '' && startDate !== null ?
                                    <Typography style={{ textAlign: 'center', marginBottom: '10px' }}>{`Time : ${DateTimeFormat(startDate, 'MM/DD/YYYY hh:mm a')} to ${DateTimeFormat(endDate, 'MM/DD/YYYY hh:mm a')}`}</Typography>
                                    :
                                    null}
                                {Object.keys(foundMemberData).length !== 0 && planningDocumentEventTimeStatus === "Present" &&
                                    <Typography style={{ textAlign: 'center', marginBottom: '10px' }}>{foundFullName} - {foundMembsershipNumber}</Typography>
                                }
                            </Grid>
                        </DefaultResponsiveContainer>
                    </Grid>
                    {planningDocumentEventTimeStatus === "Present" ?
                        <Fragment>
                            {(isShowForm == true && isShowCheckinForm == false) ?
                                <form noValidate autoComplete='off' onSubmit={handleSubmit} style={{ marginTop: '10px' }}>
                                    {formLoading && <CustomPageLoading flag={formLoading} />}
                                    <Grid container spacing={3} className={classes.gridLayout}>
                                        <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                                        <Grid item xs={12} sm={10} md={8}>
                                            <Grid container spacing={3}>
                                                {values.CheckInEntityTypeID === 2 ?
                                                    <Fragment>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                error={errors.membershipNumber && touched.membershipNumber}
                                                                id="membershipNumber-helper-text"
                                                                label="Membership Number"
                                                                value={membershipNumber}
                                                                fullWidth
                                                                name='membershipNumber'
                                                                helperText={(errors.membershipNumber && touched.membershipNumber) ? errors.membershipNumber : ''}
                                                                variant="outlined"
                                                                onChange={handleChange}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Button
                                                                variant='contained'
                                                                className={classes.continuebtn}
                                                                onClick={() => setFieldValue("CheckInEntityTypeID", 11)}
                                                            >
                                                                I am not a Member
                                                            </Button>
                                                        </Grid>
                                                    </Fragment>
                                                    :
                                                    <Grid container spacing={3} className={classes.gridLayout}>

                                                        <DefaultResponsiveContainer>
                                                            <Grid item xs={12} style={{ paddingBottom: '10px', marginTop: '10px', marginBottom: '10px' }}>
                                                                <Grid container className={classes.infoAlertStyle}>
                                                                    <Grid item xs={12} >
                                                                        <Typography style={{ paddingTop: '10px', paddingBottom: '10px', color: 'blue', fontWeight: 700 }}> I am not a member of {parentOrganizationName}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                        </DefaultResponsiveContainer>
                                                    </Grid>
                                                }
                                                <Grid item xs={values.CheckInEntityTypeID === 2 ? 6 : 12} >
                                                    <Box textAlign='center'>
                                                        <Button
                                                            type='submit'
                                                            variant='contained'
                                                            className={values.CheckInEntityTypeID === 2 ? classes.continuebtn : classes.continuebtn2}
                                                        >
                                                            Next
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs sm={1} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                                    </Grid>

                                </form>
                                : null}
                            {(isShowForm == false && isShowCheckinForm == false) ?
                                <Fragment>
                                    <Grid container spacing={3} style={{ marginTop: '1rem' }} className={classes.gridLayout}>
                                        <Grid item xs sm={2} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                                        <Grid item xs={12} sm={8} md={8}>
                                            <EntityCheckinQuestions questions={questions} setIsShowCheckinForm={setIsShowCheckinForm} />
                                        </Grid>
                                        <Grid item xs sm={2} md={2} component={Box} display={{ xs: "none", sm: "block" }} />
                                    </Grid>
                                </Fragment>
                                : null}
                            {(isShowForm == false && isShowCheckinForm == true) ?
                                <Fragment>
                                    <Grid container spacing={3} style={{ marginTop: '1rem' }} className={classes.gridLayout}>
                                        <DefaultResponsiveContainer>
                                            <EntityCheckinForm CheckInEntityTypeID={values.CheckInEntityTypeID} eventID={eventID} organizationID={organizationID} dialogOpen={dialogOpen} />
                                        </DefaultResponsiveContainer></Grid>
                                </Fragment>
                                : null}
                        </Fragment>
                        : null}
                    <Grid container>
                        <Grid item md={5}>
                        </Grid>
                        <Grid item md={2} style={{ textAlign: 'center' }}>
                            <Button variant="outlined" onClick={OnClickCancel}>Cancel</Button>
                        </Grid>
                        <Grid item md={5}>
                        </Grid>
                    </Grid>
                </div>
            }

            <Dialog disableBackdropClick disableEscapeKeyDown fullWidth={false} maxWidth={'lg'} onClose={dialogClose} aria-labelledby="customized-dialog-title" open={isDialogOpen}>
                <DialogTitle id="customized-dialog-title" onClose={dialogClose} style={{ width: 300 }}>
                    <CloseIcon onClick={dialogClose} style={{ float: 'right', cursor: 'pointer' }} />
                    <Typography className={classes.modalTitle}>Event check in Successfully </Typography>
                </DialogTitle>
            </Dialog>

        </Fragment >
    )
}

export default EntityCheckin


