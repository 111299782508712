import * as signalR from "@microsoft/signalr";
import { CHAT_MESSAGES } from "../../constants/apiPaths";
import { getSignalRConnection } from "../../redux/actions/signalRCon";
import {
  LOCALSTORAGE_ENTITYTYPEID,
  LOCALSTORAGE_SOURCEOWNERID
} from "../../constants/LocalStorage";
import { addMessage } from "../../redux/actions/messagesActions";
import { addChatBox, removeChatBox } from "../../redux/actions/chatBoxActions"
import { store } from "../../App"
// import { useSelector } from 'react-redux';

import notificationTone from '../../../src/assets/sound/notification.mp3';

const SignalRService = () => {



  const audio = new Audio(notificationTone);
  audio.loop = false;

  const singalRConnection = new signalR.HubConnectionBuilder()
    .withUrl(CHAT_MESSAGES)
    .withAutomaticReconnect([0, 500, 1000, 1500, 2000, 3000])
    .build();

  const startSignalRConnection = async () => {
    if (singalRConnection.state === signalR.HubConnectionState.Disconnected) {
      try {
        await singalRConnection.start();
        store.dispatch(getSignalRConnection(singalRConnection));
        console.log("SignalR connection started!");
      } catch (err) {
        console.error("SignalR connection failed to start: ", err);
      }
    }
  }

  // const chatBoxes = useSelector((state) => state.ChatBoxReducer);


  const receiveSignalRMessage = async () => {
    singalRConnection.on(`Chat:${parseInt(localStorage.getItem(LOCALSTORAGE_ENTITYTYPEID))}_${parseInt(localStorage.getItem(LOCALSTORAGE_SOURCEOWNERID))}`, async (message) => {
      console.log("Received Message SIGNALRSERVICE APPPPP:", JSON.stringify(message));

      if (document.hidden) {
        audio.play();
      }


      const newMessage = {
        from: message.from,
        to: message.to,
        timestamp: message.timestamp,
        message: message.message,
        memberName: message.fromEntity.sourceName
      };
      store.dispatch(addMessage(newMessage));

      //Get all store 
      const allStore = store.getState()
      const chatBoxes = allStore.ChatBoxReducer

      //To see if chat window of the specific reciver is already open
      const found = chatBoxes.find(element => element.title == message.from);

      // if already open window do not open it
      if (!found) {
        const chatBoxProps = {
          title: message.from,
          memberName: message.fromEntity.sourceName,
          handelClose: () => store.dispatch(removeChatBox(message.from)),
        };
        store.dispatch(addChatBox(chatBoxProps));
      }

    }
    );
  };

  return {
    singalRConnection,
    startSignalRConnection,
    receiveSignalRMessage,
  };
}

export default SignalRService