import { getInvitesitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetInvitesReducer(state = getInvitesitialValues, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_INVITES_LOADING:
            return { error: false, loading: true, data: []};
        case types.GET_INVITES_SUCCESS:
            return { ...state, data: payload, loading: false };
        case types.GET_INVITES_ERROR:
            return { error: true, loading: false, data: [] };
        default:
            return state;
    }
}





