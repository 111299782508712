import {
  SIGNALR_CONNECT,
  SIGNALR_DISCONNECT
} from "./ActionTypes";
import API from "./API";

export const getSignalRConnection = (connection) => ({
  type: SIGNALR_CONNECT,
  payload: connection,
});

export const singalRDisconnect = () => ({
  type: SIGNALR_DISCONNECT,
});