import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { authHeader } from "../../../functions/CoreFunctions";
import { PLANNING_DOCUMENT_CHECKIN_API } from "../../../constants/apiPaths";
import API from "../../../redux/actions/API";
import {
  CustomAlertMessage,
  CustomPageLoading,
} from "../../../functions/CustomFunctions";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import {
  GetEventDetails,
  getEventFilterOptions,
  GetIntakeSeasonSteps,
} from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { EVENTS_PATH } from "../../../constants/RoutePaths";
import {
  LOCALSTORAGE_AUTH_MEMBER_ID,
  LOCALSTORAGE_APP_NAME,
} from "../../../constants/LocalStorage";
import { isMobileScreen } from "../../../functions/commonFunctions";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { EMAIL_REGEX } from "../../../constants/ValidationRegex";

// Schema
const validationSchema = Yup.object({
  // email: Yup.string().trim().required('Email is required').email('Give valid email'),
  email: Yup.string()
    .trim()
    .required("Email is required")
    .email("Give valid email")
    .matches(EMAIL_REGEX, "Give valid email"),
  password: Yup.string().required("Password is required"),
});

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  continuebtn: {
    color: "#fff",
    width: "100%",
    backgroundImage: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: "50px",
    margin: "auto",
    display: "block",
    padding: "10px 35px",
  },
  formControl: {
    width: "100%",
  },
  formHeader: {
    fontSize: "x-large",
    textAlign: "center",
  },
  formSubHeader: {
    marginBottom: "1rem",
    textAlign: "center",
  },
  formSubHeader2: {
    fontSize: "12px",
    textAlign: "center",
  },
  input: {
    display: "none",
  },
  imgCircle: {
    textAlign: "center",
    border: "1px solid #eee",
    width: "113px",
    height: "113px",
    padding: "5px",
    backgroundColor: "#eee",
    borderRadius: "50%",
  },
  imgCircleDiv: {
    textAlign: "center",
  },
  fileInput: {
    marginTop: "-30px",
    marginLeft: "-24px",
    backgroundColor: "#eee",
    padding: "4px",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  loginbtm: {
    color: "#fff",
    background: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: "50px",
    margin: "auto",
    display: "block",
    padding: "10px 35px",
    opacity: 1,
    "&:hover": {
      opacity: 0.7,
    },
  },
  loginbtm2: {
    color: "#fff",
    width: "100%",
    background: theme.mainColor,
    boxShadow: theme.mainColorShadow,
    borderRadius: "50px",
    margin: "auto",
    display: "block",
    padding: "10px 35px",
    opacity: 1,
    "&:hover": {
      opacity: 0.7,
    },
  },
}));

// ----------------------------------------------------------------------------
const EntityCheckinForm = (props) => {
  const { CheckInEntityTypeID, eventID, organizationID, dialogOpen } = props;
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loggedMemberId = localStorage.getItem(LOCALSTORAGE_AUTH_MEMBER_ID);

  // Form Initial Values
  const initialFormValues = {
    email: "",
    password: "",
    CheckInEntityTypeId: CheckInEntityTypeID,
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [formLoading, setFormLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Save Form Data
  const saveFormValues = async (values) => {
    let data = {
      entityTypeID: values.CheckInEntityTypeId,
      email: values.email,
      password: values.password,
      eventID: parseInt(eventID),
      organizationID: organizationID,
      sourceOwnerID: 0,
    };
    console.log(data, "data");

    try {
      setFormLoading(true);
      const options = {
        method: "POST",
        url: PLANNING_DOCUMENT_CHECKIN_API,
        data: data,
      };

      const { data: apiResponse } = await API(options);
      const { status } = apiResponse;
      if (status == 1) {
        dialogOpen();
        // CustomAlertMessage('Saved successfully!', 'success', enqueueSnackbar)
      } else {
        CustomAlertMessage(apiResponse.message, "error", enqueueSnackbar);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        // console.log(error.response,'data message')
        CustomAlertMessage(error.response.statusText, "error", enqueueSnackbar);
      }
    } finally {
      setFormLoading(false);
    }
  };

  // Formik
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => saveFormValues(values),
  });

  let {
    email = "",
    password = "",
    CheckInEntityTypeId = CheckInEntityTypeID,
  } = values;
  const appName = localStorage.getItem(LOCALSTORAGE_APP_NAME) || "";

  return (
    <Fragment>
      {CheckInEntityTypeId == 2 ? (
        <Typography style={{ marginBottom: "15px", fontWeight: "700" }}>
          Please enter your {appName} email and password to Check in
        </Typography>
      ) : (
        <Typography style={{ marginBottom: "15px", fontWeight: "700" }}>
          Please enter your candidate application email and password to Check in
        </Typography>
      )}
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        {formLoading && <CustomPageLoading flag={formLoading} />}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="email-helper-text"
              label="Email"
              value={email}
              onChange={handleChange}
              fullWidth
              name="email"
              variant="outlined"
              error={errors.email && touched.email}
              helperText={errors.email && touched.email ? errors.email : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              variant="outlined"
              error={errors.password && touched.password}
            >
              <InputLabel htmlFor="password-password">Password</InputLabel>
              <OutlinedInput
                id="password-password"
                type={showPassword ? "text" : "password"}
                value={password}
                name="password"
                fullWidth
                label="Password"
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="password"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                // labelWidth={70}
              />
              <FormHelperText>
                {errors.password && touched.password ? errors.password : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          {CheckInEntityTypeId !== 2 && (
            <Grid item xs={6}>
              <Button
                variant="contained"
                className={classes.loginbtm2}
                onClick={() => {
                  window.open(`/intake/login/${organizationID}`);
                }}
              >
                I don't have an Account. Create new Account
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={CheckInEntityTypeId !== 2 ? 6 : 12}>
            <Button
              type="submit"
              variant="contained"
              className={
                CheckInEntityTypeId == 2 ? classes.loginbtm : classes.loginbtm2
              }
            >
              check in
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default EntityCheckinForm;
