import { commonListingIArraynitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';

export default function GetDivisionsReducer(state = commonListingIArraynitialValues, action) {
    const { type, payload } = action;
    switch (type) {
        case types.GET_DIVISIONS_LOADING:
            return { loading: true, error: false, data: [] };

        case types.GET_DIVISIONS_SUCCESS:
            return { data: payload, loading: false, error: false };

        case types.GET_DIVISIONS_ERROR:
            return { data: payload, loading: false, error: true };

        default:
            return state
    }
}

