import { makeStyles } from "@material-ui/core/styles";
import React from 'react';
import { Fragment } from 'react';
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CollegeOfficialVerification from "../../schools/collegeOfficialVerification";
import { useHistory } from "react-router";
import { isMobileScreen } from "../../../functions/commonFunctions";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({

    completeStep: {
        float: 'left',
        borderRadius: '50%',
        backgroundColor: 'green',
        color: '#fff',
        padding: '2px',
    },
    formHeader: {
        textAlign: 'center',
        marginBottom: '5px'
    },
    listStyle: {
        border: '1px solid #9c9898'
    },
    deletebtn: {
        marginLeft: '10px',
        marginBottom: isMobileScreen() ? '5px' : '0px',
        float: 'right',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        '&:hover': {
            opacity: 0.7,
        }
    }
}));

const CommonPrerequesiteNotificationPage = (props) => {
    const classes = useStyles();
    const { errorMessages = [], OrganizationID, schools, AccessLevel, IntakeSeasonID, handleOverride, PreRequisiteStatus = '', seasonType = '', handleCallback, chapterCertificationSeasonID = 0 } = props;
    const history = useHistory();

    return (
        <Fragment>
            <Grid container spacing={3}>
                {errorMessages.map((row, index) => {
                    if (row.prerequisiteName === "College Official Support Required") {
                        return (
                            schools.length > 0 ?
                                <CollegeOfficialVerification title={row.prerequisiteName} OrganizationID={OrganizationID} schools={schools} AccessLevel={AccessLevel} IntakeSeasonID={IntakeSeasonID} Verifications={row.detail} seasonType={seasonType} handleCallback={handleCallback} chapterCertificationSeasonID={chapterCertificationSeasonID} />
                                :
                                <Alert severity="warning" style={{ width: '100%', marginTop: '10px' }}>{seasonType == "chapterCertification" ? <AlertTitle>{row.message}</AlertTitle> : <AlertTitle>Unable to continue, No School related to the chapter.</AlertTitle>}</Alert>
                        )
                    }
                    if (row.prerequisiteName === "Chapter Program Documentation Required") {
                        return (< Alert icon={row.status == "Passed" ? <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon> : ''} severity={row.status == "Passed" ? "info" : "warning"} style={{ width: '100%', marginTop: '10px' }} action={row.status == "Failed" &&
                            <>
                                {
                                    seasonType != "chapterCertification" &&
                                    <Button variant="outlined" color="secondary" size="small"
                                        onClick={handleOverride}
                                    >
                                        Override
                                    </Button>
                                }

                                <Button
                                    variant='contained' color="secondary"
                                    className={classes.deletebtn}
                                    onClick={() => {
                                        if (AccessLevel == "National") {
                                            history.push(`/chapterDocumentation/${OrganizationID}`)
                                        } else {
                                            history.push(`/dashboard/chapterDocumentation/${OrganizationID}`)
                                        }
                                    }}
                                >
                                    Go To Program Documentation
                                </Button>
                            </>
                        }>
                            <AlertTitle>{row.prerequisiteName}</AlertTitle>
                            {row.message}
                        </Alert >)
                    }
                    if (row.prerequisiteName === "Chapter Officer Report Required") {
                        if (AccessLevel === 'National') {
                            return (< Alert icon={row.status == "Passed" ? <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon> : ''} severity={row.status == "Passed" ? "info" : "warning"} style={{ width: '100%', marginTop: '10px' }} action={row.status == "Failed" &&
                                <>
                                    {
                                        seasonType != "chapterCertification" &&
                                        <Button variant="outlined" color="secondary" size="small"
                                            onClick={handleOverride}
                                        >
                                            Override
                                        </Button>
                                    }

                                    <Button
                                        variant='contained' color="secondary"
                                        className={classes.deletebtn}
                                        onClick={() => {
                                            if (AccessLevel == "National") {
                                                history.push(`/officerReport/${OrganizationID}`)

                                            } else {

                                                history.push(`/dashboard/officerReport/${OrganizationID}`)
                                            }
                                        }}
                                    >
                                        Go To Officer Report
                                    </Button>
                                </>
                            }>
                                <AlertTitle>{row.prerequisiteName}</AlertTitle>
                                {row.message}
                            </Alert >)
                        }
                        else {
                            return (< Alert icon={row.status == "Passed" ? <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon> : ''} severity={row.status == "Passed" ? "info" : "warning"} style={{ width: '100%', marginTop: '10px' }} action={row.status == "Failed" &&
                                <Button
                                    variant='contained' color="secondary"
                                    className={classes.deletebtn}
                                    onClick={() => { history.push(`/dashboard/officerReport/${OrganizationID}`) }}
                                >
                                    Go To Officer Report
                                </Button>
                            }>
                                <AlertTitle>{row.prerequisiteName}</AlertTitle>
                                {row.message}
                            </Alert >)

                        }

                    }
                    else
                        return (
                            < Alert icon={row.status == "Passed" ? <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon> : ''} severity={row.status == "Passed" ? "info" : "warning"} style={{ width: '100%', marginTop: '10px' }}
                                action={row.status == "Failed" && AccessLevel === 'National' && seasonType != "chapterCertification" &&
                                    <Button variant="outlined" color="secondary" size="small"
                                        onClick={handleOverride}
                                    >
                                        Override
                                    </Button>
                                }>
                                <AlertTitle>{row.prerequisiteName}</AlertTitle>
                                {row.message}
                            </Alert >
                        )

                })}

            </Grid>
        </Fragment>

    );
}

export default CommonPrerequesiteNotificationPage

//     < Alert severity = "error" style = {{ width: '100%' }}>
//         <AlertTitle>{row.prerequisiteName}</AlertTitle>
// { row.message }
// <strong>{row.prerequisiteName}</strong>
//                                         </Alert >