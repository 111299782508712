import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import App from './App';
import theme from './functions/theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Sentry from '@sentry/browser';
// import { CustomTheme } from './constants/AppTheme';
import '../src/assets/style/root.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-magic-slider-dots/dist/magic-dots.css';

//if (process.env.NODE_ENV === 'production') {
 // Sentry.init({
//    dsn: 'https://353ed420e3074f50ab574699ff025d1b@sentry.io/1889793',
//  });
//}

// const theme = {
//   mainColor: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
// };

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider maxSnack={3} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
          <App />
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  </ThemeProvider>,
  document.querySelector('#root')
);
