import { initialGetPaymentMethods } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetPaymentMethodsReducer(state = initialGetPaymentMethods,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_PAYMENT_METHOD_LOADING:
            return {...state,loading:true,error:false};   

        case types.GET_PAYMENT_METHOD_SUCCESS:
            return {...state,payload,loading:false,error:false}; 
            
        case types.GET_PAYMENT_METHOD_ERROR:
            return {...state,loading:false,error:true};  
        default:
            return state
    }
}