import { GET_ORGANIZATIONS_API, GET_ORGANIZATION_FILTERS_API, GET_ORGANIZATION_DETAILS_API, GET_REGIONAL_ROSTER_DETAILS_API, GET_OFFICERS_URL } from "../../constants/apiPaths";
import { authHeader } from "../../functions/CoreFunctions";
import {
    GET_ORGANIZATIONS_LIST_LOADING, GET_ORGANIZATIONS_LIST_SUCCESS, GET_ORGANIZATIONS_LIST_ERROR,
    GET_ORGANIZATION_FILTER_OPTIONS_LOADING, GET_ORGANIZATION_FILTER_OPTIONS_SUCCESS, GET_ORGANIZATION_FILTER_OPTIONS_ERROR,
    GET_ORGANIZATION_DETAILS_SUCCESS, GET_ORGANIZATION_DETAILS_POPUP_SUCCESS, GET_ORGANIZATION_DETAILS_ERROR, GET_ORGANIZATION_DETAILS_LOADING,
    GET_ORGANIZATION_DETAILS_POPUP_ERROR,
    GET_ORGANIZATION_DETAILS_POPUP_LOADING,
    GET_REGIONAL_ROSTER_DETAILS_LOADING,
    GET_REGIONAL_ROSTER_DETAILS_SUCCESS,
    GET_REGIONAL_ROSTER_DETAILS_ERROR,
    GET_OFFICERS_LOADING,
    GET_OFFICERS_SUCCESS,
    GET_OFFICERS_ERROR
} from "./ActionTypes";
import API from "./API";

//Get chapters
export const GetChapterList = (paramsData) => {
    return async dispatch => {
        dispatch({
            type: GET_ORGANIZATIONS_LIST_LOADING,
        });
        const options = {
            method: 'GET',
            url: GET_ORGANIZATIONS_API,
            headers: authHeader(),
            params: { ...paramsData }
        };
        API(options)
            .then(response => {
                const { status, response: apiResponse = {} } = response.data;
                if (parseInt(status) === 1) {
                    const { count, data = [] } = apiResponse;
                    dispatch({
                        type: GET_ORGANIZATIONS_LIST_SUCCESS,
                        payload: {
                            count,
                            data
                        }
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    let { data } = error.response;
                    dispatch({
                        type: GET_ORGANIZATIONS_LIST_ERROR,
                        payload: data.message
                    });
                }
            });
    };
}

//Get Chapter Filter Options
export function getChapterFilterOptions() {
    return async dispatch => {
        dispatch({
            type: GET_ORGANIZATION_FILTER_OPTIONS_LOADING,
        });
        const options = {
            method: 'GET',
            url: GET_ORGANIZATION_FILTERS_API,
            headers: authHeader(),
        };
        API(options)
            .then(response => {
                const { status, response: apiResponse = {} } = response.data;
                if (parseInt(status) === 1) {
                    dispatch({
                        type: GET_ORGANIZATION_FILTER_OPTIONS_SUCCESS,
                        payload: apiResponse
                    });
                }
            })
            .catch(error => {
                if (error.response) {
                    let { data } = error.response;
                    dispatch({
                        type: GET_ORGANIZATION_FILTER_OPTIONS_ERROR,
                        payload: data.message
                    });
                }
            });
    };
}

//Get chapter Profile

export const GetChapterDetailspopup = (organizationId) => {
    return async dispatch => {
        dispatch({
            type: GET_ORGANIZATION_DETAILS_POPUP_LOADING,
        });
        const options = {
            method: 'GET',
            url: GET_ORGANIZATION_DETAILS_API,
            headers: authHeader(),
            params: { organizationId }
        };
        API(options)
            .then(response => {
                const { status, response: initialResponse = {} } = response.data;
                if (status == 1) {

                    dispatch({
                        type: GET_ORGANIZATION_DETAILS_POPUP_SUCCESS,
                        payload: initialResponse
                    });


                }
                else {
                    dispatch({
                        type: GET_ORGANIZATION_DETAILS_POPUP_ERROR,
                        payload: initialResponse
                    });

                }
            })
            .catch(error => {
                if (error.response) {
                    let { data } = error.response;
                    dispatch({
                        type: GET_ORGANIZATION_DETAILS_POPUP_ERROR,
                        payload: data.message
                    });
                }
            });
    };
}
//Get chapter Profile
export const GetChapterDetails = (organizationId) => {
    return async dispatch => {
        if(organizationId && parseInt(organizationId) > 0)
        {
        dispatch({
            type: GET_ORGANIZATION_DETAILS_LOADING,
        });
        const options = {
            method: 'GET',
            url: GET_ORGANIZATION_DETAILS_API,
            headers: authHeader(),
            params: { organizationId }
        };
        API(options)
            .then(response => {
                const { status, response: initialResponse = {} } = response.data;
                if (status == 1) {
                    console.info(initialResponse,'initialResponse')
                        dispatch({
                            type: GET_ORGANIZATION_DETAILS_SUCCESS,
                            payload: initialResponse
                        });
                }
                else {
                    dispatch({
                        type: GET_ORGANIZATION_DETAILS_ERROR,
                        payload: initialResponse
                    });

                }
            })
            .catch(error => {
                if (error.response) {
                    let { data } = error.response;
                    dispatch({
                        type: GET_ORGANIZATION_DETAILS_ERROR,
                        payload: data.message
                    });
                }
            });
        }
    };
}


//GetRegionalRosterDetail
export const GetRegionalRosterDetail = (organizationId) => {
    return async dispatch => {
        dispatch({
            type: GET_REGIONAL_ROSTER_DETAILS_LOADING,
        });
        const options = {
            method: 'GET',
            url: GET_REGIONAL_ROSTER_DETAILS_API,
            headers: authHeader(),
            params: { organizationId }
        };
        API(options)
            .then(response => {
                const { status, response: initialResponse = {} } = response.data;
                if (status == 1) {
                        dispatch({
                            type: GET_REGIONAL_ROSTER_DETAILS_SUCCESS,
                            payload: initialResponse
                        });
                }
                else {
                    dispatch({
                        type: GET_REGIONAL_ROSTER_DETAILS_ERROR,
                        payload: initialResponse
                    });

                }
            })
            .catch(error => {
                if (error.response) {
                    let { data } = error.response;
                    dispatch({
                        type: GET_REGIONAL_ROSTER_DETAILS_ERROR,
                        payload: data.message
                    });
                }
            });
    };
}

//Get Officers
export const GetOfficers = (organizationId) => {
    return async dispatch => {
        if(organizationId && parseInt(organizationId) > 0)
        {
        dispatch({
            type: GET_OFFICERS_LOADING,
        });
        const options = {
            method: 'GET',
            url: GET_OFFICERS_URL,
            headers: authHeader(),
            params: { organizationId }
        };
        API(options)
            .then(response => {
                const { status, response: initialResponse = {} } = response.data;
                if (status == 1) {
                        dispatch({
                            type: GET_OFFICERS_SUCCESS,
                            payload: initialResponse
                        });
                }
                else {
                    dispatch({
                        type: GET_OFFICERS_ERROR,
                        payload: initialResponse
                    });

                }
            })
            .catch(error => {
                if (error.response) {
                    let { data } = error.response;
                    dispatch({
                        type: GET_OFFICERS_ERROR,
                        payload: data.message
                    });
                }
            });
        }
    };
}