import { initialMemberFilterOptionsValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetMemberFilterOptionsReducer(state = initialMemberFilterOptionsValues,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_MEMBER_FILTER_OPTIONS_LOADING:
            return {...state,loading:true,error:false};  

        case types.GET_MEMBER_FILTER_OPTIONS_SUCCESS:
            const {regions = [],positions = [] , chapters = []} = payload;
            return {
                loading:false,
                regions,
                positions,
                chapters,
                error:false
            }; 
            
        case types.GET_MEMBER_FILTER_OPTIONS_ERROR:
            return {...state,loading:false,error:true};      
            
        default:
            return state
    }
}