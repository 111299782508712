import {
  GET_ALL_ENTITYTYPES_ERROR,
  GET_ALL_ENTITYTYPES_LOADING,
  GET_ALL_ENTITYTYPES_SUCCESS,
  GET_ENTITYTYPEBYID_ERROR,
  GET_ENTITYTYPEBYID_LOADING,
  GET_ENTITYTYPEBYID_SUCCESS,
  START_PAGELOADING,
  STOP_PAGELOADING,
  UPDATE_STATE_COUNTRY_TIMEZONELIST,
} from './ActionTypes';
import API from './API';
import { GET_ALLCOUNTRIES_TIMESZONES_API, GET_ALL_ENTITYTYPES_URL, GET_ENTITYTYPEBYID_URL } from '../../constants/apiPaths';
import { authHeader } from '../../functions/CoreFunctions';

export const startPageLoading = () => {
  return {
    type: START_PAGELOADING,
  };
};

export const stopPageLoading = () => {
  return {
    type: STOP_PAGELOADING,
  };
};

const updateCountriesTimeZoneList = (data) => {
  return {
    type: UPDATE_STATE_COUNTRY_TIMEZONELIST,
    payload: data,
  };
};

export const getCountriesAndTimezones = () => {
  return (dispatch) => {
    API.get(GET_ALLCOUNTRIES_TIMESZONES_API)
      .then((res) => {
        if (res.data.status === 1) {
          dispatch(updateCountriesTimeZoneList(res.data.response));
        } else {
          console.error(res.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

//Get All EntityTypes
export function GetAllEntityTypes() {
  return async dispatch => {
    dispatch({
      type: GET_ALL_ENTITYTYPES_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ALL_ENTITYTYPES_URL,
      headers: authHeader()
    };
    API(options).then(response => {
      const { status, response: initialResponse = [] } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_ALL_ENTITYTYPES_SUCCESS,
          payload: initialResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_ALL_ENTITYTYPES_ERROR,
            payload: data.message
          });
        }
      });
  };
}

//Get All EntityType BY ID
export function GetAllEntityTypeByID(EntityTypeID) {
  return async dispatch => {
    dispatch({
      type: GET_ENTITYTYPEBYID_LOADING,
    });
    const options = {
      method: 'GET',
      url: GET_ENTITYTYPEBYID_URL,
      headers: authHeader(),
      params: { EntityTypeID: parseInt(EntityTypeID)}
    };
    API(options).then(response => {
      const { status, response: initialResponse = [] } = response.data;
      if (parseInt(status) === 1) {
        dispatch({
          type: GET_ENTITYTYPEBYID_SUCCESS,
          payload: initialResponse
        });
      }
    })
      .catch(error => {
        if (error.response) {
          let { data } = error.response;
          dispatch({
            type: GET_ENTITYTYPEBYID_ERROR,
            payload: data.message
          });
        }
      });
  };
}