import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    makeStyles,
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core'
import React, { useState, Fragment } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CommonPreviewDocumentDialog from '../common/CommonPreviewDocumentDialog'
import ChapterCertificationPayment from './ChapterCertificationPayment'
import ChapterCertificationPrerequisite from './ChapterCertificationPrerequisite'
import ChapterCertificationDocuments from './ChapterCertificationDocuments'
import clsx from "clsx";
import GetAppIcon from '@material-ui/icons/GetApp';
import { DateTimeFormat } from '../../functions/CustomFunctions'
import { getKeyByValue, fileTypeToStore } from '../../functions/CoreFunctions'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { isMobileScreen } from '../../functions/commonFunctions'
import { Alert } from '@material-ui/lab'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IntakeFees from '../intakeSeason/Common/IntakeFees'
import ChapterCertificationUGMemberConfirmation from './ChapterCertificationUGMemberConfirmation'
import ChapterCertificationSignDocuments from './ChapterCertificationSignDocuments'


const useStyles = makeStyles((theme) => ({
    iconSize: {
        fontSize: 16
    },
    btnButtons: {
        margin: '2px',
        // backgroundColor: '#eee',
        padding: '6px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        // color: '#556cd6'
    },
    delButton: {
        margin: isMobileScreen() ? '0px 0px 0px 5px' : '3px',
        // backgroundColor: '#eee',
        padding: '5px',
        color: '#fff',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        // color: '#d6557d'
    },
    cardTitle: {
        fontSize: '22px',
        fontWeight: '900',
        marginTop: '5px',
        marginLeft: '8px'
    },
    root: {
        display: 'flex'
    },
    editIco: {
        float: 'right'
    },
    divider: {
        margin: `0 0 0 0`,
    },
    doneIcon: {
        color: 'green',
        marginRight: '5px'
    },
    margin: {
        // margin: theme.spacing(1),
        border: '1px solid',
        float: 'right'
    },
    continuebtn: {
        color: '#fff',
        // width: '100%',
        backgroundImage: theme.mainColor,
        boxShadow: theme.mainColorShadow,
        borderRadius: '50px',
        margin: 'auto',
        display: 'block',
        padding: '10px 35px',
        opacity: 1,
        '&:hover': {
            opacity: 0.7,
        }
    },
    containerstyle: {
        margin: '0px 0px 0px 0px',
    },
    divStyle: {
        width: '90%',
        margin: '5% 5%'
    }
}))

const ChapterCertificationSummary = (props) => {
    const classes = useStyles()
    const { steps = [], commonCallback, handleCallback, setSteps, handleEditFromSummary, edit = true, handleChapterStep, orgDetails, documentTransactionId,
        handleSeasonStep, handleUpdatevalue, docTransactionDetail = {}, schools = [], chapterCertificationSeasonID = 0 } = props
    const [expanded, setExpanded] = useState(false)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    const [dialogFlag, setDialogFlag] = useState(false);
    const [previewDocument, setPreviewDocument] = useState(null);
    const handleDialogClose = () => {
        setDialogFlag(false);
    };
    const withDrawDialogProps = {
        dialogFlag,
        handleClose: handleDialogClose,
        previewData: previewDocument
    };

    const downloadDocument = (documentName = 'default', documentUrl = '', fileExtension = 'pdf') => {
        fetch(documentUrl, {
            method: 'GET',
            headers: {
                'Content-Type': getKeyByValue(fileTypeToStore, fileExtension),
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${documentName}.${fileExtension}`,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    //Handle Preview
    const handlePreview = (event) => {
        const { doc_url = '', file_extension = '' } = event.currentTarget.dataset;
        setPreviewDocument({ url: doc_url, extension: file_extension });
        setDialogFlag(true);
    }

    const loading = false
    return (
        <Fragment>
            <Typography className={classes.cardTitle}>Summary</Typography>
            <Fragment>

                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        {steps.map(
                            ({ title, componentName, order, detail }, index) => {
                                var productTypetableList = []
                                if (detail.productTypes != undefined) {

                                    detail.productTypes.forEach(p => {
                                        p.products.forEach(a => {
                                            productTypetableList.push(a)
                                        });
                                    });
                                }
                                return (
                                    <Accordion
                                        expanded={expanded === `sec_${index}`}
                                        onChange={handleChange(`sec_${index}`)}
                                        key={`section_${index}`}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-label='Expand'
                                            aria-controls='additional-actions1-content'
                                            id='additional-actions1-header'
                                        >
                                            <FormControlLabel
                                                style={{ marginLeft: 0 }}
                                                data-current_section={index}
                                                aria-label='Acknowledge'
                                                label={`${order}. ${title}`}
                                                control={
                                                    <Fragment />
                                                }
                                            />
                                        </AccordionSummary>

                                        <AccordionDetails style={{ borderTop: '2px solid #eee' }}>
                                            <Grid container spacing={1} >

                                                <Grid item xs={12} md={12}>
                                                    <Grid container spacing={3}>
                                                        {
                                                            orgDetails?.organizationTypeID == 1 ?
                                                                <>
                                                                    {(componentName === 'ChapterCertificationPrerequisite') ?
                                                                        <Fragment>
                                                                            <Grid container className={classes.containerstyle} style={{ marginTop: '10px' }}>
                                                                                <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                    <ChapterCertificationPrerequisite
                                                                                        stepData={steps[0]}
                                                                                        handleCallback={commonCallback}
                                                                                        schools={schools}
                                                                                        chapterCertificationSeasonID={chapterCertificationSeasonID}
                                                                                    // setSteps={setSteps}
                                                                                    />
                                                                                </div>

                                                                            </Grid>
                                                                        </Fragment>
                                                                        :
                                                                        (componentName === 'ChapterCertificationPayment') ?
                                                                            <Fragment>
                                                                                <Grid container className={classes.containerstyle} style={{ margin: '10px 0px' }}>
                                                                                    {/* <DefaultResponsiveContainer > */}
                                                                                    <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                        <ChapterCertificationPayment
                                                                                            stepData={steps[1]}
                                                                                            handleCallback={commonCallback}
                                                                                            // setSteps={setSteps}
                                                                                            orgDetails={orgDetails}
                                                                                        />
                                                                                    </div>
                                                                                    {/* </DefaultResponsiveContainer> */}
                                                                                </Grid>
                                                                            </Fragment>
                                                                            :
                                                                            (componentName === 'ChapterCertificationDocuments') ?
                                                                                <Fragment>
                                                                                    <Grid container className={classes.containerstyle} style={{ margin: '10px 0px' }}>
                                                                                        {/* <DefaultResponsiveContainer > */}
                                                                                        <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                            <List dense={true} style={{ width: '100%' }}>
                                                                                                {steps[2]?.detail?.requireDocuments.map((
                                                                                                    { name, documentId, createDate = '', documentUrl = '', fileExtension }, index) =>
                                                                                                    <ListItem style={{ borderBottom: '1px solid #eee' }}>
                                                                                                        <ListItemText
                                                                                                            primary={
                                                                                                                <>
                                                                                                                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', alignContent: 'center' }}>

                                                                                                                        <span>  <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon></span>
                                                                                                                        <span style={{ flex: 1, marginLeft: '0.2rem' }}>{name}</span>
                                                                                                                        <div style={{ textAlign: 'right' }}>
                                                                                                                            <Fragment>
                                                                                                                                <div style={{ margin: '10px 0px 10px 0px' }}>
                                                                                                                                    {documentId &&
                                                                                                                                        <>
                                                                                                                                            {(fileExtension !== 'doc') &&
                                                                                                                                                <Tooltip placement="top" title="Preview Document">
                                                                                                                                                    <IconButton edge="end" aria-label="preview"
                                                                                                                                                        data-doc_url={documentUrl}
                                                                                                                                                        data-file_extension={fileExtension}
                                                                                                                                                        onClick={handlePreview}
                                                                                                                                                        className={clsx(classes.btnButtons)}>
                                                                                                                                                        <VisibilityIcon className={classes.iconSize} />
                                                                                                                                                    </IconButton>
                                                                                                                                                </Tooltip>
                                                                                                                                            }
                                                                                                                                            <Tooltip placement="top" title="Download Document">
                                                                                                                                                <IconButton edge="end" aria-label="download"
                                                                                                                                                    data-name={name}
                                                                                                                                                    data-url={documentUrl}
                                                                                                                                                    data-file_extension={fileExtension}
                                                                                                                                                    onClick={downloadDocument}
                                                                                                                                                    className={clsx(classes.delButton)}>
                                                                                                                                                    <GetAppIcon className={classes.iconSize} />
                                                                                                                                                </IconButton>
                                                                                                                                            </Tooltip>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </Fragment>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {documentId ?
                                                                                                                        <p style={{ color: 'green', margin: 0, fontSize: '13px' }}>Last Updated : {DateTimeFormat(createDate, 'MM/DD/YYYY hh:mm A')}</p> :
                                                                                                                        ''
                                                                                                                    }
                                                                                                                </>
                                                                                                            }
                                                                                                        />

                                                                                                    </ListItem>,
                                                                                                )}
                                                                                            </List>
                                                                                        </div>
                                                                                        {/* </DefaultResponsiveContainer> */}
                                                                                    </Grid>
                                                                                </Fragment>
                                                                                :
                                                                                (componentName === 'IntakeFees') ?
                                                                                    <Fragment>
                                                                                        <Grid container className={classes.containerstyle} style={{ margin: '10px 0px' }}>
                                                                                            {/* <DefaultResponsiveContainer > */}
                                                                                            <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                                <IntakeFees
                                                                                                    stepData={steps[3]}
                                                                                                    OrganizationID={orgDetails?.organizationID}
                                                                                                    organizationName={orgDetails?.organizationName}
                                                                                                    handleSeasonStep={handleSeasonStep}
                                                                                                    handleUpdatevalue={handleUpdatevalue}
                                                                                                    intakeSeasonID={0}
                                                                                                    commonCallback={commonCallback}
                                                                                                />
                                                                                            </div>
                                                                                            {/* </DefaultResponsiveContainer> */}
                                                                                        </Grid>
                                                                                    </Fragment>
                                                                                    :
                                                                                    ''
                                                                    }
                                                                </> :
                                                                <>{
                                                                    (componentName === 'ChapterCertificationPrerequisite' && title == "Prerequisite") ?
                                                                        <Fragment>
                                                                            <Grid container className={classes.containerstyle} style={{ marginTop: '10px' }}>
                                                                                <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                    <ChapterCertificationPrerequisite
                                                                                        stepData={steps[0]}
                                                                                        handleCallback={commonCallback}
                                                                                        schools={schools}
                                                                                        chapterCertificationSeasonID={chapterCertificationSeasonID}
                                                                                    // setSteps={setSteps}
                                                                                    />
                                                                                </div>

                                                                            </Grid>
                                                                        </Fragment>
                                                                        :
                                                                        (componentName === 'ChapterCertificationDocuments') ?
                                                                            <Fragment>
                                                                                <Grid container className={classes.containerstyle} style={{ margin: '10px 0px' }}>
                                                                                    {/* <DefaultResponsiveContainer > */}
                                                                                    <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                        <List dense={true} style={{ width: '100%' }}>
                                                                                            {detail.requireDocuments.map((
                                                                                                { name, documentId, createDate = '', documentUrl = '', fileExtension }, index) =>
                                                                                                <ListItem style={{ borderBottom: '1px solid #eee' }}>
                                                                                                    <ListItemText
                                                                                                        primary={
                                                                                                            <>
                                                                                                                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', alignContent: 'center' }}>

                                                                                                                    <span>  <CheckCircleIcon style={{ color: 'green', }}></CheckCircleIcon></span>
                                                                                                                    <span style={{ flex: 1, marginLeft: '0.2rem' }}>{name}</span>
                                                                                                                    <div style={{ textAlign: 'right' }}>
                                                                                                                        <Fragment>
                                                                                                                            <div style={{ margin: '10px 0px 10px 0px' }}>
                                                                                                                                {documentId &&
                                                                                                                                    <>
                                                                                                                                        {(fileExtension !== 'doc') &&
                                                                                                                                            <Tooltip placement="top" title="Preview Document">
                                                                                                                                                <IconButton edge="end" aria-label="preview"
                                                                                                                                                    data-doc_url={documentUrl}
                                                                                                                                                    data-file_extension={fileExtension}
                                                                                                                                                    onClick={handlePreview}
                                                                                                                                                    className={clsx(classes.btnButtons)}>
                                                                                                                                                    <VisibilityIcon className={classes.iconSize} />
                                                                                                                                                </IconButton>
                                                                                                                                            </Tooltip>
                                                                                                                                        }
                                                                                                                                        <Tooltip placement="top" title="Download Document">
                                                                                                                                            <IconButton edge="end" aria-label="download"
                                                                                                                                                data-name={name}
                                                                                                                                                data-url={documentUrl}
                                                                                                                                                data-file_extension={fileExtension}
                                                                                                                                                onClick={downloadDocument}
                                                                                                                                                className={clsx(classes.delButton)}>
                                                                                                                                                <GetAppIcon className={classes.iconSize} />
                                                                                                                                            </IconButton>
                                                                                                                                        </Tooltip>
                                                                                                                                    </>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </Fragment>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {documentId ?
                                                                                                                    <p style={{ color: 'green', margin: 0, fontSize: '13px' }}>Last Updated : {DateTimeFormat(createDate, 'MM/DD/YYYY hh:mm A')}</p> :
                                                                                                                    ''
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                    />

                                                                                                </ListItem>,
                                                                                            )}
                                                                                        </List>
                                                                                    </div>
                                                                                    {/* </DefaultResponsiveContainer> */}
                                                                                </Grid>
                                                                            </Fragment>
                                                                            :
                                                                            (componentName === 'ChapterCertificationPayment') ?
                                                                                <Fragment>
                                                                                    <Grid container className={classes.containerstyle} style={{ margin: '10px 0px' }}>
                                                                                        {/* <DefaultResponsiveContainer > */}
                                                                                        <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                            <ChapterCertificationPayment
                                                                                                stepData={steps[1]}
                                                                                                handleCallback={commonCallback}
                                                                                                // setSteps={setSteps}
                                                                                                orgDetails={orgDetails}
                                                                                            />
                                                                                        </div>
                                                                                        {/* </DefaultResponsiveContainer> */}
                                                                                    </Grid>
                                                                                </Fragment>
                                                                                : (componentName === 'ChapterCertificationPrerequisite' && title == "School Verification") ?
                                                                                    <Fragment>
                                                                                        <Grid container className={classes.containerstyle} style={{ marginTop: '10px' }}>
                                                                                            <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                                <ChapterCertificationPrerequisite
                                                                                                    stepData={steps[3]}
                                                                                                    handleCallback={commonCallback}
                                                                                                    schools={schools}
                                                                                                    chapterCertificationSeasonID={chapterCertificationSeasonID}
                                                                                                // setSteps={setSteps}
                                                                                                />
                                                                                            </div>

                                                                                        </Grid>
                                                                                    </Fragment>
                                                                                    :
                                                                                    (componentName === 'IntakeFees') ?
                                                                                        <Fragment>
                                                                                            <Grid container className={classes.containerstyle} style={{ margin: '10px 0px' }}>
                                                                                                {/* <DefaultResponsiveContainer > */}
                                                                                                <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                                    <IntakeFees
                                                                                                        stepData={steps[4]}
                                                                                                        OrganizationID={orgDetails?.organizationID}
                                                                                                        organizationName={orgDetails?.organizationName}
                                                                                                        handleSeasonStep={handleSeasonStep}
                                                                                                        handleUpdatevalue={handleUpdatevalue}
                                                                                                        intakeSeasonID={0}
                                                                                                        commonCallback={commonCallback}
                                                                                                    />
                                                                                                </div>
                                                                                                {/* </DefaultResponsiveContainer> */}
                                                                                            </Grid>
                                                                                        </Fragment>
                                                                                        :
                                                                                        (componentName === 'ChapterCertificationUGMemberConfirmation') ?
                                                                                            <Fragment>
                                                                                                <Grid container className={classes.containerstyle} style={{ margin: '10px 0px' }}>
                                                                                                    {/* <DefaultResponsiveContainer > */}
                                                                                                    <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                                        <ChapterCertificationUGMemberConfirmation
                                                                                                            stepData={steps[4]}
                                                                                                            handleCallback={commonCallback}
                                                                                                            setSteps={setSteps}
                                                                                                            orgDetails={orgDetails}
                                                                                                            documentTransactionId={documentTransactionId || 0}
                                                                                                        />
                                                                                                    </div>
                                                                                                    {/* </DefaultResponsiveContainer> */}
                                                                                                </Grid>
                                                                                            </Fragment>
                                                                                            :
                                                                                            (componentName === 'ChapterCertificationSignDocuments') ?
                                                                                                <Fragment>
                                                                                                    <Grid container className={classes.containerstyle} style={{ margin: '10px 0px' }}>
                                                                                                        {/* <DefaultResponsiveContainer > */}
                                                                                                        <div xs={12} sm={12} md={12} className={classes.divStyle}  >
                                                                                                            <ChapterCertificationSignDocuments
                                                                                                                organizationId={orgDetails?.organizationID}
                                                                                                                organizationName={orgDetails?.organizationName}
                                                                                                                docTransactionDetail={docTransactionDetail}
                                                                                                                stepData={steps[6] ? steps[6] : steps[5]}
                                                                                                                handleCallback={commonCallback}
                                                                                                                setSteps={setSteps}
                                                                                                            />
                                                                                                        </div>
                                                                                                        {/* </DefaultResponsiveContainer> */}
                                                                                                    </Grid>
                                                                                                </Fragment>
                                                                                                :
                                                                                                ''
                                                                }
                                                                </>
                                                        }

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }
                        )}
                    </Grid>

                </Grid >

            </Fragment>
            {
                dialogFlag &&
                <CommonPreviewDocumentDialog {...withDrawDialogProps} />
            }

        </Fragment >
    )
}

export default ChapterCertificationSummary