import { commonListingObjectInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetCourseByCurrentSeasonReducer(state = commonListingObjectInitialValues,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_COURSES_BY_CURRENT_SEASON_LOADING:
            return {...state,loading:true,error:false};  

        case types.GET_COURSES_BY_CURRENT_SEASON_SUCCESS:
            return {data:payload,loading:false,error:false}; 
            
        case types.GET_COURSES_BY_CURRENT_SEASON_ERROR:
            return {...state,loading:false,error:true};          
            
        default:
            return state
    }
}