import { planningdocumenteventcheckinInitialValues } from "../store/InitialStore";
import * as types from '../actions/ActionTypes';
export default function GetPlanningDocumentEventsReducer(state = planningdocumenteventcheckinInitialValues,action){
    const {type,payload} = action;
    switch (type) {
        case types.GET_PLANNING_DOCUMENT_EVENTS_LOADING:
            return {data: [],loading:true,error:false};  

        case types.GET_PLANNING_DOCUMENT_EVENTS_SUCCESS:
            return {data: payload,loading:false,error:false}; 
            
        case types.GET_PLANNING_DOCUMENT_EVENTS_ERROR:
            return {data: payload,loading:false,error:true};      
            
        default:
            return state
    }
}

